import React from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import { observable, decorate, toJS, when } from 'mobx'
import { sanitize } from 'dompurify'

import { compose } from 'recompose'

import {blog_categories} from 'globalvars.js'

import {displayTimeStamp, slugify} from 'usefulFunctions.js'


import ScriptMimeLogo from 'icons/topnav/logo.js'

import AHKLogo from 'images/blog/ahklogo.png'
import TutorialImg from 'images/blog/help.png'
import CommunityImg from 'images/blog/speech-bubble.png'


import * as ROUTES from 'routes.js'

//styles
import styles from 'styles/components/blog/blogunit.module.css'

 class BlogUnit extends React.Component{

 	blog
	constructor(props){

		super(props)
		this.state = {
			blog: '',
			userid: -1,
			username: '',
			blogname: '',
			submitdate: '',
			category: '',
			thumbnailImageSrc: '',

			blogLength: 200,

		}
	}

	/* ASYNC DATA FETCH */
	/* _________________ */


	/* _________________ */


	/* LIFECYCLE METHODS */
	/* _________________ */
	componentDidUpdate(prevProps, prevState){

	}

	componentDidMount(){

		var blogunit = this.fixBlogContents()

		console.log(blogunit.category)

		blogunit.category = blogunit.category.join('').replace(/"/gm, '') 

		switch(blogunit.category){
			case 'Autohotkey News': this.setState({thumbnailImageSrc: AHKLogo})
									break;
			case 'ScriptMime News': this.setState({thumbnailImageSrc: ScriptMimeLogo})
									break;
			case 'How-to': this.setState({thumbnailImageSrc: TutorialImg})
						   break;
			case 'Community News': this.setState({thumbnailImageSrc: CommunityImg})
									break;


		}



		this.setState({
			blog: blogunit.blog,
			userid: blogunit.userid,
			username: blogunit.username,
			blogname: blogunit.blogname,
			submitdate: blogunit.submitdate,
			category: blogunit.category,

		}, () => console.log(this.state.thumbnailImageSrc))
	}
	/* _________________ */


	

	




	/* INTERNALS */
	/* _________________ */

		categoryUnit = category =>{


		}

		fixBlogContents = () =>{

			var blogunit = this.props.blog


			var blog = blogunit.blog
			var userid = blogunit.userid
			var username = blogunit.username
			var blogname = blogunit.blogname
			var submitdate = blogunit.submitdate
			var category = blogunit.categories

			blog = blogunit.blog.substring(0, this.state.blogLength) + '...'

			var sanitizedHTML = sanitize(blog, {FORBID_TAGS:['img', 'iframe', 'video']})

			submitdate = displayTimeStamp(blogunit.submitdate)

			// category = categoryUnit(category)


			blogunit = {blog: sanitizedHTML, userid: userid, username: username, blogname: blogname, submitdate:submitdate, category: category }

			return blogunit
		}

	/* _________________ */

	
	render(){

		return(
			
			<Link className={styles.maincontainer} to={`${ROUTES.BLOGPAGE}?id=${this.props.blog.id}&name=/${slugify(this.state.blogname)}`}>
				<div className={styles.leftpanel}>
					<img className={styles.thumbnail} src={this.state.thumbnailImageSrc} width="96px" alt=""/>
					{
						typeof this.state.thumbnailImageSrc !== 'string' ?
							<ScriptMimeLogo width="96px" />
						:
							null
					}
				</div>
				
				<div className={styles.rightpanel}>
					
					<div>
						<span className={styles.blogname}>{this.state.blogname}</span>

					</div>

					<div className={styles.metadata}>
						<span className={styles.username}>{this.state.username}</span>
						<span className={styles.submitdate}>{this.state.submitdate}</span>
					</div>

					<div>
						<div className={styles.category}>{this.state.category}</div>
					</div>
					
					<div className={styles.blogtext} dangerouslySetInnerHTML={{__html : this.state.blog}} ></div>



					
				</div>

			</Link>
		)

	}
}


var enhance = compose(
	inject('authStore'),
	withRouter,
	observer
	)

export default enhance(BlogUnit)

