
import React from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import { observable, decorate, toJS, when } from 'mobx'
import { sanitize } from 'dompurify'

import { compose } from 'recompose'

import requests, { Messages } from 'axios.js'

import EnvelopeIcon from 'icons/topnav/conversations/envelope.js'

import styles from 'styles/topnav/conversations.module.css'

 class Conversations extends React.Component{


 	hostuserid
	constructor(props){

		super(props)

		this.state = {
			messagecount: 0,
			messageCountAfterClick: 0,
		}
	}

	/* ASYNC DATA FETCH */
	/* _________________ */

	getMessageCount = () =>{

		var hostuserid = this.props.authStore.authFields.id
		Messages.getNumberOfMessages(hostuserid)
			.then( res =>{

				this.setState({
					messagecount: res.data.message ? res.data.message.count : 0,
					messageCountAfterClick: this.props.userStore.conversation.messageCountAfterClick
				})

				// console.log(this.state.messagecount)
				// console.log(this.state.messageCountAfterClick)

			})
	}

	/* _________________ */


	/* LIFECYCLE METHODS */
	/* _________________ */
	componentDidUpdate(){

	}

	componentDidMount(){
		var interval = null
		when( () => typeof this.props.authStore.authFields.id === 'number' ,
			  () =>{
			  	setTimeout(this.getMessageCount, 250)
			  	interval = setInterval(this.getMessageCount, 30000) 
			  }
			)

		when( () => this.props.authStore.authorizedUser === false,
			  () =>{
			  	clearInterval(interval) 
			  }
			)
	}
	/* _________________ */


	

	




	/* INTERNALS */
	/* _________________ */
	
	handleMessageClick = () =>{
		this.setState({
			messageCountAfterClick: this.state.messagecount
		})

		if(this.state.messagecount > 0)
			this.props.userStore.conversation.messageCountAfterClick = this.state.messagecount
	}

	/* _________________ */

	
	render(){
		var width = this.props.uiStore.topNavIcons.notification_bell.width
		var height = this.props.uiStore.topNavIcons.notification_bell.height



		return(
			
			<div onClick={this.handleMessageClick}>

				<EnvelopeIcon
					className={styles.envelopeicon}
					width={width}
					height={height}

				/>

				{
					this.state.messagecount - this.state.messageCountAfterClick === 0 || this.state.messagecount === 0 ?
					<div className={styles[`messagecount${0}`]}>{this.state.messagecount - this.state.messageCountAfterClick}</div>
					:
					<div className={styles.messagecount}>{this.state.messagecount - this.state.messageCountAfterClick}</div>

				}

			</div>

		)

	}
}


var enhance = compose(
	inject('authStore', 'uiStore', 'userStore'),
	withRouter,
	observer
	)

export default enhance(Conversations)

