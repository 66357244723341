
import React from 'react'

const SVG = props => (

	<svg xmlns="http://www.w3.org/2000/svg" viewBox='0 0 550 550' height={props.height} width={props.width}><path d="M256 344.275c-35.836 0-71.522-5.177-100.483-14.577-33.226-10.784-50.073-24.441-50.073-40.59V163.594c0-8.284 6.716-15 15-15h271.111c8.284 0 15 6.716 15 15v125.514c0 16.149-16.847 29.806-50.073 40.59-28.961 9.4-64.646 14.577-100.482 14.577z" data-original="#1A82C7" data-old_color="#1a82c7"/><path d="M391.556 148.594H257v195.672c35.499-.095 70.791-5.255 99.483-14.568 33.226-10.784 50.073-24.441 50.073-40.59V163.594c0-8.284-6.716-15-15-15z" data-original="#125994" data-old_color="#125994"/><path d="M460.973 468.844c-8.284 0-15-6.716-15-15V158.997c0-8.284 6.716-15 15-15s15 6.716 15 15v294.847c0 8.284-6.716 15-15 15z" fill="#383a3c" data-original="#FFD236" class="active-path" data-old_color="#ffd236"/><circle cx="460.973" cy="355.383" fill="#ffce00" r="30.125" data-original="#FF9300" data-old_color="#ff9300"/><path d="M256 243.864c-1.7 0-3.399-.289-5.024-.867l-241-85.677a15 15 0 01.033-28.278l241-85.031a14.992 14.992 0 019.981 0l241 85.031a15 15 0 01.033 28.278l-241 85.677a14.977 14.977 0 01-5.023.867z" data-original="#53B7F5" data-old_color="#53b7f5"/><path d="M501.991 129.042l-241-85.031a15.022 15.022 0 00-3.991-.813v200.624a14.986 14.986 0 004.024-.824l241-85.677a15 15 0 00-.033-28.279z" data-original="#2698D1" data-old_color="#2698d1"/><path d="M512 143.169a15 15 0 00-10.009-14.127l-5.01-1.768L257 212.589 13.793 127.707l-3.784 1.335a14.999 14.999 0 00-.033 28.278l241 85.677c2.387.867 4.601.867 5.024.867 1.7 0 3.399-.289 5.024-.867l241.005-85.679A14.997 14.997 0 00512 143.169z" fill="#383a3c" data-original="#FFD236" class="active-path" data-old_color="#ffd236"/><path d="M501.991 129.042l-5.01-1.768L257 212.589v31.232a14.986 14.986 0 004.024-.824l241.005-85.679a15 15 0 00-.038-28.276z" fill="#ffce00" data-original="#FF9300" data-old_color="#ff9300"/></svg>
	//height={props.height} width={props.width} viewBox={`0 0 ${props.viewW} ${props.viewH}`}
	
)

export default SVG

