import React from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import { observable, decorate, toJS, when } from 'mobx'
import { sanitize } from 'dompurify'

import { compose } from 'recompose'

import RegularGlobalFeed from 'pages/script/globalfeed.js'

import UserFollowGlobalFeed from 'pages/script/userfollowglobalfeed.js'

import TagFollowGlobalFeed from 'pages/script/tagsfollowglobalfeed.js'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';


import styles from 'styles/pages/script/allscriptfeeds.module.css'


/* LARGE CLASS */

 class AllScriptFeeds extends React.Component{


	constructor(props){

		super(props)
	}

	/* ASYNC DATA FETCH */
	/* _________________ */


	/* _________________ */


	/* LIFECYCLE METHODS */
	/* _________________ */
	componentDidUpdate(prevProps, prevState){

	}

	componentDidMount(){

	}
	/* _________________ */


	

	




	/* INTERNALS */
	/* _________________ */


	/* _________________ */

	
	render(){

		return(
			<div>
				{/*<div className={styles.adtop}>AD</div>

				<div className={styles.adright}>AD</div>*/}


				

				<Tabs>
				  <TabList>
				    <Tab>New</Tab>
				    <Tab>Tags Followed</Tab>
				    <Tab>Users Followed</Tab>
				    <Tab>All-Time Best</Tab>
				    <Tab>Weekly Popular</Tab>
				  </TabList>
					
					<TabPanel>
						<RegularGlobalFeed />
					</TabPanel>

					<TabPanel>
						
						<TagFollowGlobalFeed />
								
					</TabPanel>

					<TabPanel>

						<UserFollowGlobalFeed />
								
					</TabPanel>

					<TabPanel>
						<h4>Coming Soon!</h4>
				
					</TabPanel>

					<TabPanel>
						<h4>Coming Soon!</h4>
								
					</TabPanel>

				</Tabs>

				{/*<div className={styles.adbottom}>AD</div>*/}


			</div>
			



		)

	}
}


var enhance = compose(
	inject('authStore'),
	withRouter,
	observer
	)

export default enhance(AllScriptFeeds)