import React, {Suspense, lazy} from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import { observable, decorate, when, toJS } from 'mobx'

import { compose } from 'recompose'

import CodeEditor from 'components/codeeditor.js'

import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import requests, {Scripts, Search} from 'axios.js'

import * as ROUTES from 'routes.js'



import Tippy from '@tippyjs/react'
import 'tippy.js/themes/light.css'

import FilePond from 'components/files/filepond.js'



import { LazyComponent, slugify } from 'usefulFunctions.js'
import Loading from 'components/animations/loading.js'

import { Collapse } from 'react-collapse'

import { initDescription } from 'components/scripts/ckeditor.js'

import ScriptExampleGuide from 'components/scripts/scriptExampleGuide.js'

import { sanitize } from 'dompurify'

//icons
import AddItemIcon from 'icons/general/additem.js'
import RemoveItemIcon from 'icons/general/removeitem.js'
import Arrow from 'images/script/arrow.png'

//styles
import styles from 'styles/pages/script/submitscript.module.css'

import classnames from 'classnames'

const RichTextEditor = lazy( () => import('components/scripts/ckeditor.js'))

// import RichTextEditor from 'components/scripts/ckeditor.js'




//authorization-protected, page to submit scripts
 class SubmitScript extends React.Component{


	formState = {
		//script
		script: '',
		rows: 15,
		cols: 50,

		//title
		scriptTitle: '',
		tippyTitleVisible: false,
		tippyScriptNameInputContent: '',
		
		//uploads
		showThumbnailPond: false,
		showVideoEmbed: false,
		showGifsPond: false,
		showPicsPond: false,
		videoLink: '',
		badVideoLink: false,
		submitCallbacks: [],
		submitLoading: false,
		scriptReady: false,
		showExample: false,

		//description
		description: '',

		//tag
		showSearchTag2: false,
		showSearchTag3: false,
		tags: [],
		tippyTagInputContent: '',
		tippyTagVisible: false,
		tagsSearchedTaken: [],
		showSearchResultsTag1: false,
		showSearchResultsTag2: false,
		showSearchResultsTag3: false,
		tagSearchLimit: 10,


		//timers
		keytimer1: null,
		keytimer2: null,
		keytimer3: null,
		delayKeyTimer: 500,
		scripttilekeytimer: null,
		scripttitledelayKeyTimer: 500,

		//max lengths
		maxDescriptionLength: 10000,
		maxScriptLength: 10000,
		maxTagLength: 30,
		maxScripTitleLength: 100,

	}
	authStore
	scriptStore
	
	constructor(props){

		super(props)

		console.log(this.props.uiStore.maxDescriptionLength)

		var uiStore = this.props.uiStore
		this.formState.maxDescriptionLength = uiStore.maxDescriptionLength
		this.formState.maxScriptLength = uiStore.maxScriptLength
		this.formState.maxTagLength = uiStore.maxTagLength
		this.formState.maxScripTitleLength = uiStore.maxScriptNameLength


		this.formState.rows = this.props.rows ? this.props.rows : this.formState.rows 
		this.formState.cols = this.props.cols ? this.props.cols : this.formState.cols 
		this.authStore = this.props.authStore
		this.scriptStore = this.props.scriptStore

		this.tagInput = null
		
	}


	/* LIFE CYCLE */
	/*  _______________________________________ */
	componentDidUpdate(){


		if (this.formState.script.length === 0 && this.formState.scriptTitle.length === 0) {
			console.log('resetting store')
	        this.scriptStore.resetScriptToSubmit()
			console.log(`callbacks length: `+this.scriptStore.callbacks.length +'. index is '+this.scriptStore.currentScriptToSubmit.index)

			console.log(`category: ${this.scriptStore.selectedCategory}, subcategory: ${this.scriptStore.selectedSubcategory}`)
		}
		

	}

	componentDidMount(){
		
		window.onbeforeunload = e =>{
			// e.preventDefault()
			return "Script will not be saved. Are you sure?"
		}
        this.scriptStore.resetScriptToSubmit()
        this.scriptStore.resetCategories()

        when(
			() => this.props.scriptStore.currentScriptToSubmit.done === true,
			() =>{
				this.props.history.push(`${ROUTES.SCRIPTPAGE}?id=${this.scriptStore.currentScriptToSubmit.id}&name=/${slugify(this.formState.scriptTitle)}`)

			}
        )

	}

	/*  _______________________________________ */



	/* INTERNALS */
	/*  _______________________________________ */

	//script
	onChangeScript = e =>{

		var script = e.target.value

		if(script.length >= this.formState.maxScriptLength){
			script = script.substring(0, this.formState.maxScriptLength)
		}

		this.formState.script = script


	}

	//title
	scriptTitleOnChange = e =>{
		this.formState.scriptTitle = e.target.value

	}

	scriptOnTitleBlur = e =>{
		var title = this.formState.scriptTitle
		var maxTitleLength = this.formState.maxScripTitleLength


		if(title.length >maxTitleLength ){
			this.formState.tippyScriptNameInputContent=`Too long (${this.formState.maxScripTitleLength} character limit)`

			this.formState.tippyTitleVisible = true
		}
		else{
			if(this.formState.tippyScriptNameInputContent ==='Script name taken')
				this.formState.tippyTitleVisible = true
			else
				this.formState.tippyTitleVisible = false


		}

	
		
	}

	scriptTitleOnKeyUp = e =>{

		var state = this.formState
		// Init a timeout variable to be used below
		let timeout = this.formState.keytimer1;
		var title = this.formState.scriptTitle
		var maxTitleLength = this.formState.maxScripTitleLength

	
	    clearTimeout(timeout);

	    this.formState.keytimer1 = setTimeout( () => {
	    	requests.post(`${ROUTES.API.getscript}`, {scriptName: title , userId: null})
	    		.then( res =>{
	    			console.log(res.data)

	    			if(res.data.message.scriptname === title){
	    				// console.log('zam')

	    				this.formState.tippyScriptNameInputContent='Script name taken'
	    				this.formState.tippyTitleVisible = true

	    			}
	    			else{
	    				this.formState.tippyScriptNameInputContent=''
	    				title.length > maxTitleLength ? this.formState.tippyTitleVisible = true : this.formState.tippyTitleVisible = false

	    			}			

	    		})
	    }, this.formState.scripttitledelayKeyTimer);
		
	}
	//categories
	categoryClick = e =>{

		this.scriptStore.selectedCategory = e.target.innerText

	}
	subcategoryClick = e =>{

		this.scriptStore.selectedSubcategory = e.target.innerText

	}

	//submit
	submitIt = e =>{
		e.preventDefault()

		toast.info('Processing Files. Allow up to 20 seconds per gif and 3-5 seconds per image. Do not exit or change contents on page. You will be automatically redirected', {
		position: "top-right",
		autoClose: 10000,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,

		});


		var { id } = this.authStore.authFields

		var scriptTitle = this.formState.scriptTitle
		var script = this.formState.script
		
		// DD-MM-YYYY
		var dateObj = new Date()
		var date = `${dateObj.getMonth() + 1}-${dateObj.getDate()}-${dateObj.getFullYear()}`

		var categories = {category: this.scriptStore.selectedCategory, subcategory: this.scriptStore.selectedSubcategory}

		var tags = this.formState.tags

		var sanitizedHTML = sanitize(this.formState.description, {FORBID_TAGS:['img', 'iframe', 'video']})

		if(script.length > this.formState.maxScriptLength || sanitizedHTML.length > this.formState.maxDescriptionLength || this.formState.scriptTitle > this.formState.maxScripTitleLength)
		{
			toast.error('Error pending. Go over your script and make sure no errors are present before submitting.', {
			position: "top-right",
			autoClose: 10000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,

			});
		}
		// var description = this.formState.description

		requests.get(`${ROUTES.API.checkServerBusy}`)
			.then( rescheck =>{
				if(rescheck.data.message){
					requests.post(`${ROUTES.API.submitscript}`, {script: sanitize(script), userId: id, scriptname: sanitize(scriptTitle), date: date, categories: categories, tags: tags, description: sanitizedHTML})
						.then( res =>{

							Scripts.submitTags(tags)
								.then( resTags =>{

									console.log(res.data)

									this.scriptStore.resetCategories()

									this.scriptStore.currentScriptToSubmit.id = res.data.scriptid


									if(this.formState.videoLink.length > 0){
										requests.post(`${ROUTES.API.insertembedvideo}`, {link: this.formState.videoLink, userid: id, scriptid: res.data.scriptid, placementlocation: 'video'})
											.then( res =>{
												console.log(res.data)
											})
									}

									if(this.formState.submitCallbacks.length === 0){
										this.props.history.push(`${ROUTES.SCRIPTPAGE}?id=${this.scriptStore.currentScriptToSubmit.id}&name=/${slugify(this.formState.scriptTitle)}`)

									}

									else{
									


										this.formState.submitCallbacks.forEach((callback, index) => {
											// callback()
											if(index === 0)
												callback()
											this.scriptStore.callbacks.push(callback)


										})
									}
								})


							// this.props.history.push(`${ROUTES.SCRIPTPAGE}?id=${this.scriptStore.currentScriptToSubmit.id}&name=`)




						})
				}
				else{
					toast.error('Server busy, please wait about a minute and try again. If you refresh, you may lose progress.', {
					position: "top-right",
					autoClose: 10000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,

					});
				}
			})

		


	}

	//uploads
	showComponent = (state,e) =>{

		this.formState[state] = !this.formState[state]

	}

	renderExternalVideo = e =>{

		var link = e.target.value

		this.formState.videoLink = link

	}
	
	showBadExternalVideo = e =>{
		
		this.formState.badVideoLink = true

	}

	//description
	getDescription = description =>{
		this.formState.description = description
	}


	//tags
	showSearchTag2 = e =>{
		console.log(toJS(this.formState.tags))

		this.formState.showSearchTag2 = !this.formState.showSearchTag2 

		if(this.formState.showSearchTag2 === false){
			// this.formState.tags = this.formState.tags.splice(1, 1)
			this.tagInput2.value = ''
			this.formState.tags.splice(1, 1)
		console.log(toJS(this.formState.tags))

		}
	}

	showSearchTag3 = e =>{
		console.log(toJS(this.formState.tags))

		this.formState.showSearchTag3 = !this.formState.showSearchTag3

		if(this.formState.showSearchTag3 === false){
			// this.formState.tags = this.formState.tags.splice(2, 1)

			this.tagInput3.value = ''

			this.formState.tags.splice(2, 1)
		console.log(toJS(this.formState.tags))

			
		}

		
	}

	searchTag1 = e =>{
		var tag = e.target.value

		//uncomment this code in all 3 if we want to shorten the length of the tag to maxtaglength forcefully
		// if(tag.length >= this.formState.maxTagLength){
		// 	tag = tag.substring(0, this.formState.maxTagLength)
		// }
		this.formState.tags[0] = tag

		console.log(toJS(this.formState.tags))

	}

	searchTag2 = e =>{
		var tag = e.target.value

		// if(tag.length >= this.formState.maxTagLength){
		// 	tag = tag.substring(0, this.formState.maxTagLength)
		// }

		this.formState.tags[1] = tag

		console.log(toJS(this.formState.tags))
	}

	searchTag3 = e =>{
		var tag = e.target.value

		// if(tag.length >= this.formState.maxTagLength){
		// 	tag = tag.substring(0, this.formState.maxTagLength)
		// }

		this.formState.tags[2] = tag
		console.log(toJS(this.formState.tags))

	}

	tagOnBlur1 = e =>{
		var t1 = this.formState.tags[0]
		var maxTagLength = this.formState.maxTagLength

		// console.log(res.data)
		//once search is active turn this into a request

		if(typeof t1 === 'string'){

			console.log(t1.length)
			console.log(maxTagLength)

			if(t1.length >maxTagLength ){

				this.formState.tippyTagInputContent=`Too long (${this.formState.maxTagLength} character limit)`

				this.formState.tippyTagVisible = true
			}
			else{
				this.formState.tippyTagVisible = false
				// this.formState.showSearchResultsTag1 = false
			}
		}
		

		// if(res.data.message.scriptname === title){

		// 	this.formState.tippyTagInputContent='Script name taken'
		// 	this.formState.tippyTagVisible = true

		// }
		// else{
		// 	title.length > maxTagLength ? this.formState.tippyTagVisible = true : this.formState.tippyTagVisible = false

		// }			

		
		// requests.post(`${ROUTES.API.getscript}`, {scriptName: title , userId: null})
		// 	.then( res =>{


		// 	})
	}

	tagOnBlur2 = e =>{
		var t2 = this.formState.tags[1]
		var maxTagLength = this.formState.maxTagLength



		// console.log(res.data)
		//once search is active turn this into a request

		if(typeof t2 === 'string'){

			if(t2.length >maxTagLength ){
				this.formState.tippyTagInputContent=`Too long (${this.formState.maxTagLength} character limit)`

				this.formState.tippyTagVisible = true
			}
			else{
				this.formState.tippyTagVisible = false
				this.formState.showSearchResultsTag2 = false

			}
		}
	}

	tagOnBlur3 = e =>{
		var t3 = this.formState.tags[3]
		var maxTagLength = this.formState.maxTagLength


		// console.log(res.data)
		//once search is active turn this into a request

		if(typeof t3 === 'string'){

			if(t3.length >maxTagLength ){
				this.formState.tippyTagInputContent=`Too long (${this.formState.maxTagLength} character limit)`

				this.formState.tippyTagVisible = true
			}
			else{
				this.formState.tippyTagVisible = false
				this.formState.showSearchResultsTag3 = false

			}
		}
	}

	keyUpHandler1 = e =>{

		var searchquery = this.formState.tags[0]
		var limit = this.formState.tagSearchLimit
		// Init a timeout variable to be used below
		let timeout = this.formState.keytimer1;

	
	    clearTimeout(timeout);

	    this.formState.keytimer1 = setTimeout( () => {

	    	if(searchquery === '')
	    		return
	    	Search.searchTags(searchquery, limit)
	    		.then( res =>{
	    			if(res.data.message){
	    				var returnedtags = res.data.message

	    				console.log(returnedtags)

	    				returnedtags = this.dropdownSearchedTagUnits(returnedtags, 1)
	    				this.formState.tagsSearchedTaken = returnedtags

	    				this.formState.showSearchResultsTag1 = true

	    			}
	    		})
	    }, this.formState.delayKeyTimer);
	}

	keyUpHandler2 = e =>{

		var searchquery = this.formState.tags[1]
		var limit = this.formState.tagSearchLimit
		

		// Init a timeout variable to be used below
		let timeout = this.formState.keytimer2;

	
	    clearTimeout(timeout);

	    this.formState.keytimer2 = setTimeout( () => {
	    	if(searchquery === '' || this.formState.tags[0] === '')
	    		return
	    	Search.searchTags(searchquery, limit)
	    		.then( res =>{
	    			if(res.data.message){
	    				var returnedtags = res.data.message

	    				console.log(returnedtags)

	    				returnedtags = this.dropdownSearchedTagUnits(returnedtags, 2)
	    				this.formState.tagsSearchedTaken = returnedtags

	    				this.formState.showSearchResultsTag2 = true

	    			}
	    		})
	    }, this.formState.delayKeyTimer);	

	}

	keyUpHandler3 = e =>{

		var searchquery = this.formState.tags[2]
		var limit = this.formState.tagSearchLimit


		// Init a timeout variable to be used below
		let timeout = this.formState.keytimer3;

	
	    clearTimeout(timeout);

	    this.formState.keytimer3 = setTimeout( () => {
	    	if(searchquery === '' || this.formState.tags[0] === '' || this.formState.tags[2] === '')
	    		return
	    	Search.searchTags(searchquery, limit)
	    		.then( res =>{
	    			if(res.data.message){
	    				var returnedtags = res.data.message

	    				console.log(returnedtags)

	    				returnedtags = this.dropdownSearchedTagUnits(returnedtags, 3)
	    				this.formState.tagsSearchedTaken = returnedtags

	    				this.formState.showSearchResultsTag3 = true

	    			}
	    		})
	    }, this.formState.delayKeyTimer);	
	}


	dropdownSearchedTagUnits = (tags, inputselector) =>{
		// var tags = this.formState.tagsSearchedTaken

		var instance = this

		console.log(instance[`tagInput${inputselector}`])
		var renderTagUnits = tags.map( tag =>{

			return (
				<div key={tag.id + Math.random() * 10000} className={styles.dropdowncontent} onClick={() =>{ 
					instance[`tagInput${inputselector}`].value = tag.tags;
					instance.formState.tags[inputselector - 1] = tag.tags;
				}}>
					<p className={styles.dropdownunit}>{tag.tags}</p>
				</div>
			)
		})

		return renderTagUnits
	}
	/*  _______________________________________ */


	render(){

		var formState = this.formState
		const checkTagsArr = this.formState.tags.filter( tag => tag === '')

		var howtoguideclass = classnames(styles.howtoguide, styles.submitbutton)
		var howtoguideclosebuttonclass = classnames(styles.howtoguideclosebutton, styles.submitbutton)

		var submitbuttonclass = classnames(styles.submitbutton, styles.submit, {[styles.submitexpand]: this.formState.showExample}, {[styles.submitfileexpand]: (this.formState.showThumbnailPond || this.formState.showVideoEmbed) && !this.formState.showExample },{[styles.submitfileandguideexpand]: this.formState.showThumbnailPond && !this.formState.showVideoEmbed && this.formState.showExample} , {[styles.fileandvidexpand]: this.formState.showThumbnailPond && this.formState.showVideoEmbed && !this.formState.showExample}, {[styles.allexpand]: this.formState.showThumbnailPond && this.formState.showVideoEmbed && this.formState.showExample} )
		// let classNames = classnames(styles.sideMenu, { [styles.active]: this.props.menuOpen }); // <div className={classNames}></div>


		console.log(this.formState.tags)
		const submitDisabled = formState.tippyTitleVisible
			|| formState.scriptTitle.length < 5 
			|| this.scriptStore.selectedCategory === null
			|| this.scriptStore.selectedSubcategory === null
			|| checkTagsArr.length > 0 || this.formState.tags.length === 0
			|| this.formState.submitCallbacks.length === 0

		
		return (

			<div className={styles.maincontainer}>
				<h1>Submit a script!</h1>
				<p>Scripts that don't follow the rules will be removed.</p>
				<small>Scripts that are malicious will result in a permanent ban.</small>

{/*Choose category*/}
				<h3 className={styles.numberheading}><div className={styles.numbercircle}>1</div> Choose a Category for your script</h3>
				<div className={styles.categoriescontainer}>

					{this.scriptStore.categories.map((category, index) =>{
						return(
							<div  onClick={this.categoryClick} key={index} >
								<button className={styles.categorybutton}>{category}</button>
							</div>
						)
					})}

{/*Choose subcategory*/}
					<h3 className={styles.numberheading}><div className={styles.numbercircle}>2</div>And type (subcategory).</h3>
					
					{this.scriptStore.sub_categories.map((category, index) =>{
						return(
							<div onClick={this.subcategoryClick} key={index} className="">
								<button className={styles.categorybutton}>{category}</button>
							</div>
						)
					})}
					
				</div>

				<p>{this.scriptStore.selectedCategory} , {this.scriptStore.selectedSubcategory}</p>

{/*Create a tag*/}
				<h3 className={styles.numberheading}><div className={styles.numbercircle}>3</div> Give your script a tag</h3>
				<p>A category is the type of script you've made (ex. Gaming | shortcuts). A tag is for its topic (ex. Runescape).</p>
				<p>Submit up to 3 tags about your script.</p>


				<p>Check if a tag already exists by typing. If not, it will be a new tag!</p>

				
				<input 
					type="text"
					ref={ref => this.tagInput1 = ref}
					onChange={this.searchTag1}
					placeholder={'Tag (max '+ this.formState.maxTagLength +' ch.)'}
					onKeyUp={this.keyUpHandler1}
					onBlur={this.tagOnBlur1}
					className={styles.input}
				/>
				{
					this.formState.tippyTagVisible ?
					<p>{this.formState.tippyTagInputContent}</p>
					:
					null

				}
				<button disabled={this.formState.showSearchTag3 === true && this.formState.showSearchTag2 === true } onClick={this.showSearchTag2}>
					{
						this.formState.showSearchTag2 === false ?
						<AddItemIcon 
							width='20px'
							height='20px'
						/>
						:
						<RemoveItemIcon 
							width='20px'
							height='20px'
						/>
					}
				</button>
				<button disabled={this.formState.showSearchTag3 === false && this.formState.showSearchTag2 === false } onClick={this.showSearchTag3}>
					{
						this.formState.showSearchTag3 === false ?
						<AddItemIcon 
							width='20px'
							height='20px'
						/>
						:
						<RemoveItemIcon 
							width='20px'
							height='20px'
						/>
					}
				</button>


				<Collapse isOpened={this.formState.showSearchTag2}>

					
					<input 
						type="text"
						ref={ref => this.tagInput2 = ref}
						onChange={this.searchTag2}
						placeholder={'Tag (max '+ this.formState.maxTagLength +' ch.)'}
						onKeyUp={this.keyUpHandler2}
						onBlur={this.tagOnBlur2}
						className={styles.input}


					/>
					{
						this.formState.tippyTagVisible ?
						<p>{this.formState.tippyTagInputContent}</p>
						:
						null

					}
					
				</Collapse>
				
				<Collapse isOpened={this.formState.showSearchTag3}>

					<input 
						type="text"
						ref={ref => this.tagInput3 = ref}
						onChange={this.searchTag3}
						placeholder={'Tag (max '+ this.formState.maxTagLength +' ch.)'}
						onKeyUp={this.keyUpHandler3}
						onBlur={this.tagOnBlur3}
						className={styles.input}
					/>					
					{
						this.formState.tippyTagVisible ?
						<p>{this.formState.tippyTagInputContent}</p>
						:
						null

					}
				</Collapse>

				<Collapse isOpened={this.formState.showSearchResultsTag1 || this.formState.showSearchResultsTag2 || this.formState.showSearchResultsTag3 }>

					<div className={styles.dropdown}>
						{
							this.formState.tagsSearchedTaken
						}
							
					</div>
					
				</Collapse>
				



{/*Create title*/}
				<h3 className={styles.numberheading}><div className={styles.numbercircle}>4</div> Give your script a meaningful title</h3>


				<div className="pure-form form-custom" >
					<Tippy
						placement='auto'
						trigger='manual'
						content={this.formState.tippyScriptNameInputContent}
						visible={this.formState.tippyTitleVisible}
						ref={ref => this.tippyScriptNameInput = ref}
						theme='light'
					>
						<input 
							type="text"
							size="75"
							ref={ref => this.scriptNameInput = ref}
							onChange={this.scriptTitleOnChange}
							onKeyUp={this.scriptTitleOnKeyUp}
							onBlur={this.scriptOnTitleBlur}
							placeholder='Script name'/>
					</Tippy>

{/*Create description*/}
					<div >
					<h3 className={styles.numberheading}><div className={styles.numbercircle}>5</div>(Also a description!)</h3>
						<div className={styles.howtoguidecontainer}>
							<img src={Arrow} width="60px" height="39px" alt=""/>
							<div  className={howtoguideclass} onMouseEnter={() => this.formState.showExample = true}> Expand How-to Guide</div>
							
						</div>

						<Collapse isOpened={this.formState.showExample}>
							<ScriptExampleGuide/>
							<button className={howtoguideclosebuttonclass} type='button' onClick={() => this.formState.showExample = false}>Close</button>

						</Collapse>
					</div>

					<Suspense fallback={<Loading />}>
						<div className={styles.descriptioneditor}>
							<RichTextEditor contentCallback={this.getDescription}/>
							
						</div>
					</Suspense>

					{
						this.formState.description.length > this.formState.maxDescriptionLength
						?
							<p>Description too long. Max {this.formState.maxDescriptionLength} char.</p>
						:
							null
					}
	
	{/*Write script itself*/}				
				<h3 className={styles.numberheading}><div className={styles.numbercircle}>6</div> Paste script here (no formatting required)</h3>

				<textarea 
					// type="text"
					onChange={this.onChangeScript}
					cols={this.formState.cols}
					rows={this.formState.rows}
					value={formState.script}
					placeholder="Your script here..."
					name='script'
				/>



					

				</div>
			
				{
					this.formState.script.length > this.formState.maxScriptLength
					?
						<p>Description too long. Max {this.formState.maxScriptLength} char.</p>
					:
						null
				}

{/*Code Display*/}
				{formState.script.length > 0 &&
					<div className="prismeditor">
						<CodeEditor code={formState.script}/>
					</div>
				 

				}

{/*thumbnail*/}
				<h3 className={styles.numberheading}><div className={styles.numbercircle}>7</div> Choose a thumbnail gif or image (for the global feeds/search) for your script (5MB or less). </h3>
				<p>Tip: Resize gif to smaller dimensions for smaller file size.</p>
				<button className="pure-button" onClick={(e) =>{this.showComponent('showThumbnailPond', e)}}>></button> 

				{	formState.showThumbnailPond &&
					<FilePond
					table='content'
					imagesize={{width: '550'}}		
					submitCallback={callback => this.formState.submitCallbacks.push(callback)}
					acceptedFileTypes={['image/gif', 'image/png', 'image/jpeg']}		
					maxTotalFileSize='5MB'
					allowUserSubmit={false}
					placementposition='thumbnail'
					/>
				}

				

{/*hosted vid*/}

				<h3 className={styles.numberheading}><div className={styles.numbercircle}>8</div> (Optional) Submit a youtube / hosted video demo or explanation of your script.</h3>
				<button className="pure-button" onClick={(e) =>{this.showComponent('showVideoEmbed', e)}}>:)</button> 
				{ 	formState.showVideoEmbed &&
					<div>
						<input 
							type="text"
							placeholder='Paste an embedded video link.'
							size={50}
							onChange={this.renderExternalVideo}
							onError={this.showBadExternalVideo}
							className={styles.input}

						/>

						{!formState.badVideoLink && 
							<div>
								<h4>Preview</h4>
								<iframe width="560" height="315" src={formState.videoLink} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen frameBorder="0"></iframe>

								
							</div>
						}
						
						{/*So, onError event is not firing, see if we can fix if not, just get rid of this altogether and let the iframe display an error itself*/}
						{ formState.badVideoLink &&
							<div>
								<h4>Bad Link</h4>

								
							</div>
						}
					</div>
					
				}


{/*submitscript button*/}
				<button className={styles.submitbutton} onClick={this.submitIt} disabled={submitDisabled}>Submit Script</button>
{/*				<div className="space-waste"></div>
				<div className="space-waste"></div>
*/}				
{/*2 gifs*/}

				{/*<h3>5. (Optional) Submit up to 2 gifs about your script to be autoplayed (5 MB each or less). Video currently not supported.
					<button onClick={(e) =>{this.showComponent('showGifsPond', e)}}>></button> 
				</h3>*/}

				{ 	/*formState.showGifsPond &&
					<div>
						<FilePond
							table='content'
							imagesize={{width: '500'}}
							submitCallback={callback => this.formState.submitCallbacks.push(callback)}
							allowUserSubmit={false}
							maxTotalFileSize='5MB'
							acceptedFileTypes={['image/gif']}
							placementposition='gif1'
						/>
						<FilePond
							table='content'
							imagesize={{width: '500'}}
							submitCallback={callback => this.formState.submitCallbacks.push(callback)}
							allowUserSubmit={false}
							maxTotalFileSize='5MB'
							acceptedFileTypes={['image/gif']}
							placementposition='gif2'

						/>
						
					</div>*/
					
				}

{/*5 pics*/}
				{/*<h3>6. (Optional) Submit up to 5 pictures about your script (1.5 MB each or less).
					<button onClick={(e) =>{this.showComponent('showPicsPond', e)}}>></button> 
				</h3>*/}

				{ 	/*formState.showPicsPond &&
					<div>
						<FilePond
							table='content'
							imagesize={{width: '500'}}
							submitCallback={callback => this.formState.submitCallbacks.push(callback)}
							allowUserSubmit={false}
							maxTotalFileSize='1500KB'
							acceptedFileTypes={['image/png', 'image/jpeg']}
							placementposition='pic1'


						/>
						<FilePond
							table='content'
							imagesize={{width: '500'}}
							submitCallback={callback => this.formState.submitCallbacks.push(callback)}
							allowUserSubmit={false}
							maxTotalFileSize='1500KB'
							acceptedFileTypes={['image/png', 'image/jpeg']}
							placementposition='pic2'


						/>
						<FilePond
							table='content'
							imagesize={{width: '500'}}
							submitCallback={callback => this.formState.submitCallbacks.push(callback)}
							allowUserSubmit={false}
							maxTotalFileSize='1500KB'
							acceptedFileTypes={['image/png', 'image/jpeg']}
							placementposition='pic3'


						/>
						<FilePond
							table='content'
							imagesize={{width: '500'}}
							submitCallback={callback => this.formState.submitCallbacks.push(callback)}
							allowUserSubmit={false}
							maxTotalFileSize='1500KB'
							acceptedFileTypes={['image/png', 'image/jpeg']}
							placementposition='pic4'


						/>
						<FilePond
							table='content'
							imagesize={{width: '500'}}
							submitCallback={callback => this.formState.submitCallbacks.push(callback)}
							allowUserSubmit={false}
							maxTotalFileSize='1500KB'
							acceptedFileTypes={['image/png', 'image/jpeg']}
							placementposition='pic5'


						/>
						
					</div>
					*/
				}

					

				



			</div>

		)

	}
}


decorate(SubmitScript, {
	formState: observable
})

var enhance = compose(
	inject('authStore', 'scriptStore', 'uiStore'),
	withRouter,
	observer
	)

export default enhance(SubmitScript)

