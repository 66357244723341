import React from 'react'

const SVG = props => {

	return (
		<svg height={props.height} width={props.width} viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M14.3871 17V11H9.77881V17H4.01844V9H0.562222L12.083 0L23.6037 9H20.1475V17H14.3871Z" fill="#C6C6C6"/>
		</svg>



	)
	//height={props.height} width={props.width} viewBox={`0 0 ${props.viewW} ${props.viewH}`}
	
}

export default SVG