import React, { Component, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom'
import * as ROUTES from 'routes'

import { observer, inject } from 'mobx-react'
import { observable, decorate, when, toJS } from 'mobx'


import { compose } from 'recompose'

import { withProtectedRoute as ProtectedRoute }  from 'components/withProtectedRoute.js'                            


//TESTING
                        
import TestPage from 'testpage.js'


// Components / pages

import Scrolltotop from 'components/scrolltotop.js'

import Home from './components/home.js'           
import ScriptMimeUserGuide from 'pages/scriptmimeuserguide.js'           
import GetStartedAutohotkeyGuide from 'pages/getstartedautohotkeyguide.js'           


import Navbar from './components/navbar.js'
import UserTopNavbar from 'components/usertopnavbar.js'
import Root from './components/root.js'
import LoginPage from 'pages/account/login.js'
import SignUpPage from 'pages/account/signup.js'
// import PasswordForget from 'pages/account/passwordforget'
import AuthHomeBase from 'pages/authhome.js'
// import AdminDashboard from 'components/AdminDashboard'
import CodeEditor from 'components/codeeditor.js'
import GetStarted from 'pages/account/accountsettings/getStarted.js'
import Error_404 from 'pages/404.js'


//account settings | Anything to do with Account
import AccountNavigation from 'pages/account/accountsettings/accountnavigation.js'

import AccountDetails from 'pages/account/accountsettings/accountsettingsindex.js'

import PrivacyPolicy from 'pages/privacypolicy.js'
import TermsConditions from 'pages/termsconditions.js'

import SubmitScript from 'pages/submitscript.js'

import ScriptNavigation from 'pages/script/scriptnavigation.js'

import { ToastContainer } from 'react-toastify'

// import ScriptCircularNavbar from 'components/scriptcircularnav.js'

import requests, {Users} from 'axios.js'

import UserProfile from 'pages/account/userprofile.js'

import storeRefresh from 'stores/storeRefresh.js'

import Loading from 'components/animations/loading.js'

import ScriptPage from 'pages/script/scriptpage.js'

import ProfilePage from 'pages/account/profilepage.js'




import GlobalScriptFeed from 'pages/script/globalfeed.js'

import AnimaTest from 'pages/test/testanima.js'

import ConversationsPage from 'pages/topnav/conversationpage.js'

import MasterTagsPage from 'pages/script/mastertagspage.js'

import TagPage from 'pages/script/tagpage.js'

import SearchScriptsResultPage from 'pages/script/searchscriptresultpage.js'

import SearchUsersResultPage from 'pages/script/searchusersresultpage.js'

import AllScriptFeeds from 'pages/script/allscriptfeeds.js'


//blog
import BlogPage from 'pages/blog/blogpage.js'
import SubmitBlogPage from 'pages/blog/submitblog.js'

import BlogMasterPage from 'pages/blog/blogslist.js'


//misc

import SupportPage from 'pages/misc/support.js'

import Footer from 'components/footer.js'


//styles
import 'styles/animations.css'


//mobile
import MobileLandingPage from 'mobile/mobilelanding.js'



class App extends Component {

  constructor(props){
    super(props)
    this.authenticationLoaded = false
    this.isMobile = false

    let isMobile = window.matchMedia("only screen and (max-width: 860px)").matches;

    if (isMobile) {
        this.isMobile = true
    }
  }
  
  checkAdmin(){
    var role = window.localStorage.getItem('role')
    if(role === 'admin'){
      var uname = this.props.authStore.authFields.username
      this.props.authStore.addAdmin(uname)

      this.props.authStore.setAdmin(true)
      //console.log('admin is '+ this.props.authStore.authFields.username)
      //console.log('admin is2 '+ this.props.authStore.admins[1])


  
    }
  }

  getAndSetMobxStores = () =>{
    Users.getMobxStores(this.props.authStore.authFields.id)
      .then( resMobx =>{
        if(resMobx.data.message){
          //console.log(resMobx.data.message)

          var storenames = resMobx.data.message.storenames
          var storecontents = resMobx.data.message.storecontents

          storecontents = storecontents.map( storecontentsunit =>{
            return JSON.stringify(storecontentsunit)
          }) 

          storenames.forEach(storename =>{
            localStorage.setItem(storename, storecontents[storenames.indexOf(storename)])
            storeRefresh(storename, this.props[storename])
          })

          //console.log(localStorage)

          this.authenticationLoaded = true


          
        }
      })
  }

  componentDidMount(){

    //check for mobile
    


    when(
      () =>  typeof this.props.authStore.authFields.id === 'number',
      () => {

        if(localStorage.getItem('storesHaveLoaded') === null || localStorage.getItem('storesHaveLoaded') === undefined ){
          this.getAndSetMobxStores()

          localStorage.setItem('storesHaveLoaded', true)
        }
      }
    )

    window.onbeforeunload = e =>{
      // e.preventDefault()

      // //console.log('stopped')
      // return 'Are you sure?'
    }

    //console.log("welcome to scriptmime")

    var { emailVerified, authFields } = this.props.authStore
    var auth = this.props.authStore
    this.listener = this.props.firebaseStore.firebaseAccess.firebase
      .auth.onAuthStateChanged(authUser => {
        if(authUser && this.authenticationLoaded === false){

          //console.log('user is logged in:')
          //console.log(authUser)
        
          //check that token has correct values
          auth.setAuthorizedUser(true)
          var username = authUser.displayName
          var email = authUser.email
          // if(username != null) 
          auth.setUsername(authUser.displayName)

          //console.log('username is: '+auth.authFields.username)

          auth.setEmail(authUser.email)

          this.checkAdmin()


          //get userid of logged in user
          requests.post(`${ROUTES.API.getid}`, {username: username, email: email})
            .then(res =>{
              auth.setId(res.data.id)
              
              //get avatar
              requests.post(`${ROUTES.API.getavatar}`, {userid: res.data.id})
                .then( resAvatar =>{
                  var { file, width, height } = this.props.userStore.avatar

                  this.props.userStore.setAvatar(resAvatar.data.message, width, height)
                })

              
            })




          /*email verification */
          if(!authUser.emailVerified){
            authUser.sendEmailVerification()
              .then(() => {
            // this.props.authStore.emailVerified = true

            //console.log("email sent!")
          
              })

          }

          else{

              // emailVerified = true
              this.props.authStore.setEmailVerified(true)

         }
    }

  })
  }

  componentDidUpdate(){
    this.checkAdmin()

                    //console.log(this.authenticationLoaded)


    storeRefresh('userStore', this.props.userStore)
      .then( () =>{
        //console.log('userStore is hydrated!')
      })

      // console./log(this.props.scriptStore)
    storeRefresh('scriptStore', this.props.scriptStore)
      .then( () =>{
        //console.log('scriptStore is hydrated!')
      })


    storeRefresh('uiStore', this.props.uiStore)
      .then( () =>{
        //console.log('uiStore is hydrated!')
      })

    storeRefresh('commentStore', this.props.commentStore)
      .then( () =>{
        //console.log('commentStore is hydrated!')
      })


    
  }

  componentWillUnmount(){
    this.listener()

    //from login
    window.localStorage.removeItem('role')
  }

  


  render() {
    var auth = this.props.authStore.authorizedUser
    var isAdmin = this.props.authStore.isAdmin

    // //console.log(`auth:${auth}`)

    return (
      
        <div className="App">
              <Router>
                  <Scrolltotop />
                  <Navbar />
                  <UserTopNavbar />
                  <ToastContainer/>

                    

                  <main className="App-main">
                  
                  <Route exact path='/empty' component={null} key='empty'/>

                  <Route exact path={ROUTES.TEST} component={ AnimaTest} />

                  {
                    this.isMobile === false ? 
                      <Route exact path={ROUTES.LANDING} component={Root} />
                    :
                      <Route exact path={ROUTES.LANDING} component={MobileLandingPage}/>

                  }

                  
                  <Route exact path={ROUTES.INFOLANDING} component={ScriptMimeUserGuide} />
                  <Route exact path={ROUTES.DOWNLOADINFOPAGE} component={GetStartedAutohotkeyGuide} />


                  <Route exact path={ROUTES.SIGNUP} component={SignUpPage}/>
                  {auth && <Route exact path={ROUTES.AUTHHOME} component={AuthHomeBase}/> }
                  {auth && <Route exact path={ROUTES.GETSTARTED} component={GetStarted}/> }
                  {auth && <Route exact path={ROUTES.NAVIGATION} component={AccountNavigation}/> }
                  {auth && <Route exact path={ROUTES.ACCOUNTSETTINGS_DETAILS} component={AccountDetails}/> }


                  <Route exact path={ROUTES.LOGIN} component={LoginPage}/>
                  <Route exact path={ROUTES.PRIVACYPOLICY} component={PrivacyPolicy}/>
                  <Route exact path={ROUTES.TERMSCONDITIONS} component={TermsConditions}/>
                  {auth && <Route exact path={ROUTES.SCRIPT_SUBMITSCRIPT} component={SubmitScript}/>}{/*auth && <Route exact path={ROUTES.SCRIPT_SUBMITSCRIPT} component={SubmitScript}/>*/}
                  <Route exact path={ROUTES.SCRIPT_NAVIGATION} component={ScriptNavigation}/>

                  <Route exact path={ROUTES.SCRIPT_GLOBALFEED} component={AllScriptFeeds}/>

                  {auth && <Route path={ROUTES.PROFILEPAGE} component={UserProfile}/>}
                  {auth && <Route path={ROUTES.CONVERSATIONSPAGE} component={ConversationsPage}/>}


                  <Route exact path={ROUTES.LOADING} render={props => (
                        <Loading {...props} />
                    )}/>

                  <Route path={ROUTES.SCRIPTPAGE+'/:slug*'} component={ScriptPage}/>

                  <Route path={ROUTES.OTHERUSERPROFILEPAGE+'/:slug*'} component={ProfilePage}/>

                  <Route exact path={ROUTES.MASTERTAGSPAGE} component={MasterTagsPage}/>

                  <Route path={ROUTES.TAGPAGE+'/:slug*'} component={TagPage}/>
                  <Route path={ROUTES.SCRIPTSEARCHRESULTSPAGE+'/:slug*'} component={SearchScriptsResultPage}/>
                  <Route path={ROUTES.USERSEARCHRESULTSPAGE+'/:slug*'} component={SearchUsersResultPage}/>

                {/*blog*/}

                  <Route path={ROUTES.BLOGPAGE+'/:slug*'} component={BlogPage}/>
                  {auth && isAdmin && <Route exact path={ROUTES.SUBMITBLOGPAGE} component={SubmitBlogPage}/>}{/*auth && <Route exact path={ROUTES.SCRIPT_SUBMITSCRIPT} component={SubmitScript}/>*/}
                  <Route exact path={ROUTES.BLOGMASTERPAGE} component={BlogMasterPage}/>

                {/*misc*/}
                  <Route exact path={ROUTES.SUPPORTPAGE} component={SupportPage}/>





                  


                  {/*}
                  <ScriptCircularNavbar />
                  
                  {auth && <Route path={ROUTES.AUTHHOME} component={AuthHomeBase}/> }
                  <Route path='/login' component={Login}/>
                  <Route path='/signup' component={Signup}/>
                  <Route path='/forgotpassword' component={PasswordForget}/>
                  <Route path='/accountsettings' component={AccountSettings}/>
                  <Route path='/protected/home' component={AuthHomeBase}/>
                  <Route path='/protected/admin' component={AdminDashboard}/>
                  <ProtectedRoute path={ROUTES.AUTHHOME} Component={AuthHomeBase}/>
                  <Route component={Error_404}/>


                  */}
                  </main>


                  {!this.isMobile && <Footer />}

                </Router>


        </div>
     
    );
  }
}

var enhance = compose(
  inject('firebaseStore', 'authStore','userStore', 'uiStore', 'scriptStore','commentStore'),
  observer
  )

export default enhance(App);
