
import React, {lazy, Suspense} from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import { observable, decorate } from 'mobx'

import { compose } from 'recompose'

import { sanitize } from 'dompurify'
import { Collapse } from 'react-collapse'

import Loading from 'components/animations/loading.js'
import CreateNoteComment from 'components/profile/createNoteComment.js'
import NoteCommentUnit from 'components/profile/notecommentunit.js'

import styles from 'styles/components/profile/usernotes.module.css'


const RichTextEditor = lazy( () => import('components/scripts/ckeditor.js'))



 class UserNotes extends React.Component{

 	userid
	constructor(props){
		super(props)

		this.state={
			showCreateNote: true
		}

		this.userid = this.props.userid
		
	}

	/* ASYNC DATA FETCH */
	/* _________________ */


	/* _________________ */


	/* LIFECYCLE METHODS */
	/* _________________ */
	componentDidUpdate(){

	}

	componentDidMount(){
		if(this.props.showCreateNote === false){
			this.setState({
				showCreateNote: false
			})
			console.log(this.state)


		}
	}
	/* _________________ */


	

	




	/* INTERNALS */
	/* _________________ */


	/* _________________ */

	
	render(){

		return(
			<div className="container">
				{
					this.state.showCreateNote === true 
					?
						<CreateNoteComment 
							userid={this.userid}
						/>
					:
						null

				}
				<NoteCommentUnit
					userid={this.userid}
				 />
			</div>

		)

	}
}


var enhance = compose(
	inject('authStore', 'scriptStore'),
	withRouter,
	observer
	)

export default enhance(UserNotes)