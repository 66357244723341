import React from 'react'


import styles from 'styles/components/script/scriptExampleGuide.module.css'
const scriptExampleGuide = props =>{



	return (
		<div className={styles.content} >


			<h2 className={styles.p}>Quick Guide:</h2>
			<h3 className={styles.p}>Media Insert</h3>

			<p className={styles.p}>* You can insert images/gifs/videos through direct URL links from (imgur, imgBB, etc)</p>
			<h4 className={styles.p}>_Our File upload_</h4>

			<p className={styles.p}>* If you have a youtube or external embedded video submit a link for an embedded video (not the page link)</p> 
			  <p className={styles.p}> add it in the description like this:</p> 
			   <span className={styles.span}>[video]</span>

			<p className={styles.p}>* NOTE: the thumbnail (just submit below, no '[]' necessary) and youtube videos must be inserted with our upload system!</p>


			<h4 className={styles.p}>_Outside Hosted Files_</h4>

			<p className={styles.p}>* Insert any images, gifs or videos through direct URL links from (imgur, imgBB, etc) like this: <span className={styles.span}>[himg]URL[/himg]</span></p>

			<h3 className={styles.p}>Code Blocks</h3>

			<p className={styles.p}>Insert Autohotkey code blocks like this: <span className={styles.span}>[code]YOUR_CODE[/code]</span></p>
			<p className={styles.p}>Spaces or line breaks are okay!</p>
			





			<h3 className={styles.p}>Example Script</h3>

				<div className={styles.example}>			
                    <h2>Awesome Automation Script</h2>

					<p className={styles.p}>Features:</p>
				    <p className={styles.p}>> Can pretend to be you on social media. A better you.</p>
				    <p className={styles.p}>> Will live your life for you. So you don't have to!</p>
				    <p className={styles.p}>> Integrated with the Artificial Intelligence GPT-3 for even more realistic responses!</p>
				    <p className={styles.p}>> Can type 1000 words per minute and never make a mistake.</p>
				    <p className={styles.p}>> Advanced internet scraping: no more endless google searches</p>


				    <span className={styles.span}>[video]</span>
				    <p className={styles.p}>This youtube video shows step by step how to get up and running and how it works. </p>
				    <p className={styles.p}> <span className={styles.span}>[code]<br/> code block <br/> [/code] </span> </p>
				    <p className={styles.p}>This is an extra code block. </p>
				    <p className={styles.p}> <span className={styles.span}>[himg]<br/> https://i.imgur.com/kCXRY9w.gif <br/>[/himg]</span> </p>
				    <p className={styles.p}>This is an extra image. </p>


				</div>



		</div>
		

	)
}



export default scriptExampleGuide