import {observable, action, reaction, computed, decorate} from 'mobx'

import firebase from 'firebase/fireconfig'

class FirebaseStore{

	firebaseAccess ={
		firebase: firebase	
	} 


}

decorate(FirebaseStore, {
	firebaseAccess: observable
})

var firebaseStore = new FirebaseStore()

export default firebaseStore