import {observable, action, decorate, reaction} from 'mobx'



class AuthStore {
	inProgress = false
	errors = undefined
	authorizedUser = false
	emailVerified = false

	isAdmin = false

	authFields = {

		username: '',
		email: '',
		password: '',
		passwordRepeat: '',
		id: ''
	}

	/* Moderation/Adminstration */
	/*_________________________ */
	moderators = []


	//first admin is in admin[1]
	admins = []

	addAdmin(admin){

		for(var admini of this.admins){
			if(admin === admini)
				return
		}
		
		this.admins.push(admin)
	}

	addModerator(mod){
		this.moderators.push(mod)
	}

	/*_________________________ */


	constructor(){
		
		reaction( () =>{
				return this.authorizedUser 
			},
			( auth ) =>{
				if(auth === true)
					this.inProgress = true
				



			}	

		)
	}



	setUsername(username){

	this.authFields.username = username
	}

	setId(id){
		this.authFields.id = id

	}

	setAdmin(isAdmin){
		this.isAdmin = isAdmin
	}

	setPassword(password){

	this.authFields.password = password
	}

	setPasswordRepeat(password){

	this.authFields.passwordRepeat = password
	}

	setEmail(email){

	this.authFields.email = email
	}
	setAuthorizedUser(state){
		this.authorizedUser = state
	}

	reset(){

		this.username = ''
		this.email = ''

		this.password= ''
		this.passwordRepeat = ''
		this.emailVerified = false
		this.inProgress = false
		this.errors = undefined
		this.authorizedUser = false
		this.isAdmin = false

	}

	setEmailVerified(state){
		this.emailVerified = state
	}

}

decorate(AuthStore,{
	inProgress:  observable,
	authorizedUser:  observable,
	isAdmin: observable,
	errors:  observable,
	emailVerified:  observable,
	authFields:  observable,
	moderators:  observable,
	admins:  observable,
	addAdmin: action,
	addModerator: action,
	setUsername: action,
	setPassword: action,
	setPasswordRepeat: action,
	setAdmin: action,
	setEmail: action,
	reset: action,
	setEmailVerified: action,
	setAuthorizedUser: action,
	setId: action

})


var authStore = new AuthStore()

reaction( () => JSON.stringify(authStore),
			json => {
				localStorage.setItem('authStore', json)
			},
			{
				delay: 500
			})

export default authStore

