import React, {lazy, Suspense} from 'react'

import ScriptMimeLogo from 'icons/topnav/logo.js'
import * as ROUTES from 'routes.js'
import { Link, NavLink, withRouter } from 'react-router-dom'


import styles from 'styles/components/landingtopnav.module.css'
export default class LinkToProfile extends React.Component {

	componentDidMount(){
		let isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;

		this.isMobile = isMobile
	}
	render(){

		return (
		
				<div className={styles.container}>
					<Link className={styles.logo} to={ROUTES.LANDING}>
						<ScriptMimeLogo className='logo' width='90px' height='60px' /> 
					</Link>

					<Link className={styles.navunit} to={ROUTES.SIGNUP}>Sign up</Link>
					<Link className={styles.navunit} to={ROUTES.LANDING}>What is ScriptMime?</Link>
					<Link className={styles.navunit} to={ROUTES.INFOLANDING}> Features</Link>
					<Link className={styles.navunit} to={ROUTES.DOWNLOADINFOPAGE}> Get Started</Link>

				</div>
			
			

		)
	}

}