import React, {createRef} from 'react'

import { withRouter, Link } from 'react-router-dom'

import { decorate, observable } from 'mobx'

import { observer, inject } from 'mobx-react'

import { compose } from 'recompose'

import * as ROUTES from 'routes.js'

import Prism from 'prismjs'

import 'styles/scriptmime.css'

import { getOffset } from 'usefulFunctions.js'

var randomInit = (
	`#Include C:/Users/Jorge/Documents/AutoHotkey/tutorial/activescripts/fileExplorer/ExplorerGetclass.ahk
#SingleInstance, force


!MButton::

global path := Explorer_GetPath()
global all := Explorer_GetAll()
global sel := Explorer_GetSelected()
;~ WinMenuSelectItem, Boostnote,, My Storage Location, default


Menu,Menu,Add,folderpath,Function
Menu,Menu,Add,fullpath,Function
Menu,Menu,Color,0x9FBBE8,Single
Menu,Menu,Show
return
`
)

// var count = 0

// window.onclick = () =>{
// 	alert(++count)
// }


window.onresize = function(){
	
}



class CodeEditor extends React.Component{

	authStore
	firebase

	editorState ={
		init: randomInit
	}
	constructor(props){
		super(props)
		this.authStore = this.props.authStore
		this.editorState.init = this.props.code
	}
	
	componentDidMount(){
		Prism.highlightAll()
		
	}

	componentDidUpdate(){
		this.editorState.init = this.props.code
		Prism.highlightAll()
		
	}

	selectAll = e =>{
		window.getSelection().selectAllChildren(this.codeElement)
	}

	render(){
		var editorState = this.editorState
		return (
		
			<div className="code-editor">
				<div className='loading' ref={ref => this.loadingDiv = ref}>Loading</div>
				<a onClick={this.selectAll}>Select All</a>
				<pre className='line-numbers match-braces autolinker keep-markup normalize-whitespace'>
					<code className="language-autohotkey" onFocus={e => e.currentTarget.select()} ref={ref =>this.codeElement = ref}>{editorState.init}</code>
				</pre>

			</div>

		)
	}

}

decorate(CodeEditor, {
	editorState: observable
})

var enhance = compose(
	
	inject('authStore'),
	withRouter,
	observer
)

export default enhance(CodeEditor)