import React from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import { observable, decorate, toJS, when } from 'mobx'
import { sanitize } from 'dompurify'

import { compose } from 'recompose'

import { Scripts } from 'axios.js'

import {slugify} from 'usefulFunctions.js'

import * as ROUTES from 'routes.js'


//styles
import styles from 'styles/components/script/linktotag.module.css'

/* LARGE CLASS */

 class LinkToTag extends React.Component{

 	tagtag
	constructor(props){

		super(props)
		this.state = {
			tag: {}
		}
	}

	/* ASYNC DATA FETCH */
	/* _________________ */

		getTagInfo = () =>{
			Scripts.tags.getScriptByTagtag(this.props.tagtag)
				.then( res =>{
					if(res.data.message){
						this.setState({
							tag: res.data.message
						})
					}
				})
		}

	/* _________________ */


	/* LIFECYCLE METHODS */
	/* _________________ */
	componentDidUpdate(prevProps, prevState){

	}

	componentDidMount(){
		this.getTagInfo()
	}
	/* _________________ */


	

	




	/* INTERNALS */
	/* _________________ */


	/* _________________ */

	
	render(){

		return(
			<Link to={`${ROUTES.TAGPAGE}?id=${this.state.tag.id}&tag=${this.props.tagtag}/#${slugify(this.props.tagtag)}`} className={styles.tagunit}>
				{this.props.children}
			</Link>
			

		)

	}
}


var enhance = compose(
	inject('authStore'),
	withRouter,
	observer
	)

export default enhance(LinkToTag)

