import React from 'react'

const SVG = props => (
//h51 w50
	<svg height={props.height} width={props.width} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	<circle cx="12" cy="12" r="12" fill="#2678F2"/>
	<line x1="5.46852" y1="12.4143" x2="10.4685" y2="16.4143" stroke="white" stroke-width="1.5"/>
	<line x1="9.46967" y1="16.4697" x2="19.4697" y2="6.46967" stroke="white" stroke-width="1.5"/>
	</svg>


	//height={props.height} width={props.width} viewBox={`0 0 ${props.viewW} ${props.viewH}`}
	
)

export default SVG
