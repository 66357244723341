import React from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import { observable, decorate, when, toJS } from 'mobx'

import { compose } from 'recompose'

import * as ROUTES from 'routes.js'
import requests, {Profile} from 'axios.js'

 class FollowersTab extends React.Component{

 	//props
 	userid
 	followers

 	
	constructor(props){

		super(props)
		this.state = {
			followers: this.props.followers ? this.props.followers : [],
			file: '',
			width: '50px',
			height: '50px'
		}


	}

	/* ASYNC DATA FETCH */
	/* _________________ */

	// getFollowers = () =>{
	// 	var userid = this.props.userid

	// 	Profile.getFollowersForUser(userid)
	// 		.then( res =>{

	// 			if(res.data.error || res.data.message.length === 0)
	// 				return

	// 			this.setState({
	// 				followers: res.data.message
	// 			})
	// 		})


		
	// }
	/* _________________ */


	/* LIFECYCLE METHODS */
	/* _________________ */
	componentDidUpdate(){

	}

	componentDidMount(){
		// this.getFollowers()
		// when(
		// 	() =>  ,
		// 	() => {

		// 	}
		// )
	}
	/* _________________ */



	/* INTERNALS */
	/* _________________ */


	/* _________________ */

	
	render(){
		var { file, width, height } = this.props.userStore.anon

		console.log(this.state.followers)


		var followers = null
		if(this.state.followers.length > 0){
			followers = this.state.followers.map( follower =>{
				return (
					<li key={follower.followeruserid}>
						<Link to={`${ROUTES.OTHERUSERPROFILEPAGE}?id=${follower.followeruserid}`}>
							<img src={follower.filepath ? follower.filepath : file} alt='Default User' width={width} height={height}/>
							<p>{follower.username}</p>
						</Link>
					</li>
				)
			})

			
		}
		
		//USE CSS GRID to list out 10 members per row into a 10x10 grid, then a load button for more
		return(
			<div>
				<ul>
					{followers}
				</ul>
			</div>

		)

	}
}


var enhance = compose(
	inject('authStore', 'userStore'),
	withRouter,
	observer
	)

export default enhance(FollowersTab)

