

import React from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import { observable, decorate, toJS, when } from 'mobx'
import { sanitize } from 'dompurify'

import { compose } from 'recompose'

import styles from 'styles/topnav/notifications.module.css'

 class NotificationsPanel extends React.Component{


	constructor(props){

		super(props)
	}

	/* ASYNC DATA FETCH */
	/* _________________ */


	/* _________________ */


	/* LIFECYCLE METHODS */
	/* _________________ */
	componentDidUpdate(){

	}

	componentDidMount(){

	}
	/* _________________ */


	

	




	/* INTERNALS */
	/* _________________ */


	/* _________________ */

	
	render(){

		return(
			<div>
				
				<div className={styles.container}>
					{this.props.children}
				</div>
					

			</div>

		)

	}
}


var enhance = compose(
	inject('authStore'),
	withRouter,
	observer
	)

export default enhance(NotificationsPanel)
