import React, {lazy, Suspense} from 'react'

import LandingTopNav from 'components/landingtopnav.js'

import { Link, NavLink, withRouter } from 'react-router-dom'


import styles from 'styles/pages/getstartedautohotkeyguide.module.css'

import Loading from 'components/animations/loading.js'

import * as ROUTES from 'routes.js'


 /* IMAGES */

import ComputerImage from 'images/landing/1.jpg'

import Downloadahk1 from 'images/infopages/downloadahk1.png'
import Downloadahk2 from 'images/infopages/downloadahk2.png'

import HaikeiScatter from 'images/infopages/symbol-scatter-haikei.png'
import Toolboxicon from 'images/infopages/toolbox.png'
import Submissionrulesicon from 'images/infopages/submissionrulesicon.png'
import ThumbtackIcon from 'images/infopages/thumbtack.png'










import classnames from 'classnames'



import Collapse from 'react-collapse'




/* VIDEOS */

// import ShowoffVid from 'images/landing/Finalvidrealready-min4.mp4'

/* ICONS */
import BullsEyeIcon from 'images/landing/target.png'


import AddButton from 'icons/general/additem.js'




class GetStartedAutohotkeyGuide extends React.Component {

	
	constructor(props){

		super(props)
		this.state = {
			openScriptSubmissionRules: false
		}
	}
	
	render(){

		let linkclass = classnames(styles.link, styles.p); // <div className={classNames}></div>

		return (
			<div className={styles.landingpagetopcontainer}>
				<LandingTopNav className={styles.topnav}/>

				<div className={styles.container}>

					<h2 className={styles.mainheading}>Get Started With Autohotkey</h2>
					<h3 className={styles.mainsubheading}>... in 4 Easy Steps</h3>

					<div className={styles.step}>
						<div className={styles.stepheading}>
							<div className={styles.numbercircle}>1</div>
							<h3 className={styles.headingtext}>Download Autohotkey</h3>
						</div>

						<a href="https://www.autohotkey.com/" target="_blank" className={linkclass}>Click here to download Autohotkey from the official website.</a>

						<div className={styles.imagesgroup}>
							
							<div className={styles.imagegroup}>
								<div className={styles.smallnumbercircle}>1</div>
								<img className={styles.dahkimg} src={Downloadahk1} alt=""/>
							</div>

							<div className={styles.imagegroup}>
								<div className={styles.smallnumbercircle}>2</div>
								<img className={styles.dahkimg} src={Downloadahk2} alt=""/>
							</div>
							
						</div>
						<p className={styles.p}>When you are installing select, Express installation. Once you are finished, Autohotkey is located in C:\Program Files\AutoHotkey on your computer.</p>
						

					</div>
					<div className={styles.step}>
						<div className={styles.stepheading}>
							<div className={styles.numbercircle}>2</div>
							<h3 className={styles.headingtext}>Download the Recommended Scripts below </h3>
						</div>
						<img src={HaikeiScatter} width="1280px" height="" alt=""/>
						<div className={styles.row}>
							<h2 className={styles.recommendedscriptheading}>ScriptMime Toolbox</h2>
							<img src={Toolboxicon} width="71px"  alt=""/>
						</div>

						<div className={styles.paragraph}>
							<p>This is a repository of scripts that will help you make the most out of Autohotkey and ScriptMime. </p>
							<p>All the scripts are safe, easy to run User Interface programs.</p>
							<p>We recommend 2 scripts (working out of the box, no need to configure) that will help you:</p>
							
						</div>

						<div className={styles.paragraph2}>
							<p className={styles.script}> 1. Start and stop all of your scripts at once by key press. With this script, you can manage all of your scripts together under a single script! </p>
							<iframe className={styles.youtubevid} width="840" height="472" src="https://www.youtube.com/embed/yXcpUWIRwHA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>		

							<p className={styles.script}> 2. Plug and play scripts that you find on ScriptMime without having to save any files. This is a simple code editor from writing and testing Autohotkey scripts. It takes all of the hassle out of trying new scripts you find. Just Copy and Paste the code and press the Run button! </p>
							<iframe className={styles.youtubevid} width="840" height="472" src="https://www.youtube.com/embed/YtEdUrbNRQY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>		
							
						</div>

						<div className={styles.paragraph2}>
							<p>View working demos here and find the downloads here.</p>
							<a href="https://github.com/ScriptMime/Recommended-Scripts" target="_blank" className={styles.link}>Go to the official ScriptMime Github </a>

						</div>


					</div>
					<div className={styles.step}>
						<div className={styles.stepheading}>
							<div className={styles.numbercircle}>3</div>
							<h3 className={styles.headingtext}>Take a moment to read rules for script submission, comment submission, and etiquette.</h3>
						</div>

						<div className={styles.subsection}>
							<h3>Script Submission Rules</h3>
							<img src={Submissionrulesicon} alt=""/>
							<div className={styles.scriptsubmissionrules}>
													<h4>No pranks or purposefully annoying or other harmful stuff allowed policy.</h4>
													<p>Absolutely none.</p>
													<p>These may (but not limited to) include:</p>
													<p>- fake bsod</p>
													<p>- disabling mouse or keyboard</p>
													<p>- making files in every folder on the pc</p>
													<p>- deleting certain files with the intent to destroy a pc or annoy a user.</p>
													<p>- turning up the volume to 100% and playing a loud/obnoxious/rude/annoying sound</p>
													<p>- doing anything to destroy a pc or annoy a user on purpose.</p>
													<p>Why a new zero-tolerance rule?</p>
													<p>Not everyone is PC-literate. If their mouse breaks or the pc starts randomly jamming or sending other keys, they might go out and buy a new keyboard. Or even worse, a new monitor or PC all-together! That's making people waste hard-earned money.
							That is just 1 example, it applies to any situation where a user might not like what is happening to them.
							Also, anti-virus and anti-maleware products do not like AHK because of a lot of stuff users make, like 'pranks'. So AHK gets flagged as being malicious, which affects a LOT of people (usually people trying to bring AHK to work).
							 						</p>
							 						<p>If you post anything malicious, you'll get a warning and it'll be removed.</p>
							 						<p>Based on the severity of it you might even be banned, if you're a repeat offender or it's bad enough.</p>

							</div>

						</div>
					
						<div className={styles.subsection}>
							<h3>Comment Submission Rules</h3>
							<img src={Submissionrulesicon} alt=""/>
							<div className={styles.scriptsubmissionrules}>
													<h4>Writing Good comments</h4>

													<h5>Good Behavior</h5>
													<p>Using welcoming and inclusive language</p>
													<p>Being respectful of differing viewpoints and experiences</p>
													<p>Referring to people by their preferred pronouns and using gender-neutral pronouns when uncertain</p>
													<p>Gracefully accepting constructive criticism</p>
													<p>Focusing on what is best for the community</p>
													<p>Showing empathy towards other community members</p>
													<p>- turning up the volume to 100% and playing a loud/obnoxious/rude/annoying sound</p>
													<p>- doing anything to destroy a pc or annoy a user on purpose.</p>
													<p>Why a new zero-tolerance rule?</p>

													<h5>Bad Behavior</h5>

													<p>The use of sexualized language or imagery and unwelcome sexual attention or advances</p>
													<p>Trolling, insulting/derogatory comments, and personal or political attacks</p>
													<p>Public or private harassment</p>
													<p>Publishing others' private information, such as a physical or electronic address, without explicit permission</p>
													<p>Other conduct which could reasonably be considered inappropriate in a professional setting</p>
													
							 						<p>If you post anything malicious, you'll get a warning and it'll be removed.</p>
							 						<p>Based on the severity of it you might even be banned, if you're a repeat offender or it's bad enough.</p>

							</div>
						</div>

						


 


					</div>
					<div className={styles.step}>
						<div className={styles.stepheading}>
							<div className={styles.numbercircle}>4</div>
							<h3><Link to={ROUTES.INFOLANDING} className={styles.headingtext}>Read the ScriptMime User Guide to take advantage of all the ScriptMime features</Link></h3>
						</div>


					</div>

					<div className={styles.forscriptcreatorscontainer}>
						<div  className={styles.row}><img src={ThumbtackIcon} width="40px" alt=""/>For script creators</div>
						<p className={styles.creatorstext}>To create your own scripts and learn Autohotkey, view the official <a className={styles.creatorslink} href="https://www.autohotkey.com/docs/Tutorial.htm"> Autohotkey tutorial guide</a>.</p>
						<p className={styles.creatorstext}>For the documentation, <a className={styles.creatorslink} href="https://www.autohotkey.com/docs/AutoHotkey.htm"> click here.</a>.</p>


					</div>

				</div>


			</div>
		
		)
	}
	
	
}

export default GetStartedAutohotkeyGuide