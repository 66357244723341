

// import '../styles/bulma.scss'

// import 'bulma/sass/components/navbar.sass'


import React from 'react'
import { observer, inject } from 'mobx-react'
import { Link, NavLink, withRouter } from 'react-router-dom'



import { observable, decorate } from 'mobx'

import { compose } from 'recompose'

import * as ROUTES from 'routes.js'

// import styles from 'styles/components/navbar.module.css'

// import 'styles/scriptmime.css'

import styles from 'styles/components/navbartooltip.module.css'



import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
 		 

// ICONS/SVG



import ScriptMimeLogo from 'icons/topnav/logo.js'

import HomeIcon from 'icons/navbar/home.js'
import ExploreIcon from 'icons/navbar/explore.js'
import CreateScriptIcon from 'icons/navbar/createscript.js'
import TagsIcon from 'icons/navbar/tags.js'
import AccountIcon from 'icons/navbar/account.js'
import InfoPageIcon from 'icons/navbar/infopage.js'

import BlogIcon from 'icons/navbar/blog.js'

class Navbar extends React.Component{


	constructor(props){

		super(props)
	}

	componentDidUpdate(){

	}

	componentDidMount(){

	}

	
	render(){

		return(
		<div className="sidenav">
			<div className='subsection'>
				<Link to={ROUTES.LANDING}>
					<ScriptMimeLogo className='logo' width='161px' height='120px' />
				</Link>

			</div>
			<div className='subsection'>
				<Link to={ROUTES.LANDING} className='link'>
					<HomeIcon width='24px' height='17px'/> Home
				</Link>

				<Link to={ROUTES.SCRIPT_GLOBALFEED} className='link'>
					<ExploreIcon width='24px' height='17px'/> Explore
				</Link>

				<Link to={this.props.authStore.authorizedUser ? ROUTES.SCRIPT_SUBMITSCRIPT : ROUTES.SIGNUP} className='link'>
					<CreateScriptIcon width='24px' height='17px'/> Create Script
				</Link>

				<Link to={ROUTES.MASTERTAGSPAGE} className='link'>
					<TagsIcon width='24px' height='17px'/> Tags
				</Link>

				<Link to={ROUTES.NAVIGATION} className='link'>
					<AccountIcon width='24px' height='17px'/> Account
				</Link>

				<Link to={ROUTES.INFOLANDING} className='link'>
					<InfoPageIcon width='24px' height='17px'/> User Guide
				</Link>

				<Link to={ROUTES.BLOGMASTERPAGE} className='link'>
					<BlogIcon width='24px' height='17px'/> Blog & News
				</Link>


			</div>

				
		</div>
		)

	}
}



var enhance = compose(
	inject('authStore'),
	withRouter,
	observer
	)

export default enhance(Navbar)
