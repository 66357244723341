import React from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import { observable, decorate, toJS, when } from 'mobx'
import { sanitize } from 'dompurify'

import { compose } from 'recompose'

import { Blog } from 'axios.js'

import BlogUnit from 'components/blog/blogunit.js'

import Loading from 'components/animations/loading.js'

import * as ROUTES from 'routes.js'

//styles

import styles from 'styles/pages/blog/blogslist.module.css'

 class SubmitScript extends React.Component{


	constructor(props){

		super(props)

		this.state = {
			paragraphs: [],

			blogs: [],

			//limits
			blogsPerRequest: 10,
			id_last_item: null, 
			id_last_item_prev: null,
	 	    id_first_item: null,

	 	    bottomReached: false,

	 	    //timer
			scrollTimer: null,
	 	    scrollTimerDelay: 500,

	 	    //loading

	 	    loading: false

		}
	}

	/* ASYNC DATA FETCH */
	/* _________________ */

	getBlogs = () =>{

		var limit = this.state.blogsPerRequest
		// var 
		Blog.getBlogs('first', this.state.blogsPerRequest, this.state.id_last_item, )
			.then( res =>{

				console.log(res.data.message)
				if(res.data.message){
					this.setState({
						blogs: res.data.message,
						id_first_item: res.data.id_first_item,
						id_last_item: res.data.id_last_item,

						bottomReached: res.data.message.length < this.state.blogsPerRequest ? true : false
					})
				}
			})
	}

	/* _________________ */


	


	/* INTERNALS */
	/* _________________ */

	scrollBottom = e =>{



		if(window.scrollY + window.innerHeight >= document.documentElement.scrollHeight -400){

			var p = (
				<p key={Math.random() * 100}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Temporibus odit, est cum repudiandae dolore ullam dicta quas minima deserunt repellat animi, quisquam, labore voluptates debitis ipsum impedit distinctio beatae commodi?</p>
				)
			var ps = this.state.paragraphs
			// ps = ps.concat(p)
			this.setState({
				paragraphs: ps.concat(p)
			}, () =>{
				console.log()
			})
		}

	}



	loadNextBlogs = e =>{
		

		var timeout = this.state.scrollTimer

		clearTimeout(timeout)

		if(this.state.bottomReached === true){
			return

		}

		

		this.state.scrollTimer = setTimeout( () =>{

			if(window.scrollY + window.innerHeight >= document.documentElement.scrollHeight - 400){

				// if(this.state.id_last_item_prev !== this.state.id_last_item){

				// }

				this.setState({
					loading: true
				})
				Blog.getBlogs('more', this.state.blogsPerRequest, this.state.id_last_item, )
				.then( res =>{

					if(res.data.message){
						var blogs = this.state.blogs

						console.log(res.data.message)

						blogs = blogs.concat(res.data.message)

						this.setState({
							blogs: blogs,
							id_first_item: res.data.id_first_item,
							id_last_item: res.data.id_last_item,
							id_last_item_prev: this.state.id_last_item,

							bottomReached: res.data.message.length < this.state.blogsPerRequest ? true : false,
							loading: false

						})
					}
				})
				
			}
		}, this.state.scrollTimerDelay)
		
	}


	submitBlog = e =>{
		this.props.history.push(ROUTES.SUBMITBLOGPAGE)
	}
	/* _________________ */


	/* LIFECYCLE METHODS */
	/* _________________ */
	componentDidUpdate(prevProps, prevState){

	}

	componentDidMount(){
		window.addEventListener('scroll', this.loadNextBlogs)

		this.getBlogs()
	}

	componentWillUnmount() {
	    window.removeEventListener('scroll', this.loadNextBlogs );
	}

	/* _________________ */
	
	render(){

		// console.log(this.state.paragraphs)

		console.log(this.props.authStore.isAdmin)

		var blogs = this.state.blogs.map(element =>{
			return (

				<BlogUnit key ={element.id} 
					className={styles.unit}
					blog={element}
				
				
				/>
			)
		})

		return(
			
			<div className={styles.maincontainer} >

				<div className={styles.innermaincontainer}>
					<h1 className={styles.heading}>ScriptMime Blog</h1>

					{
						this.props.authStore.isAdmin === true ?
							<div>
								<button className={styles.submitblogbutton} onClick={this.submitBlog}>Submit a Blog</button>
								<p>Since you are an administrator or approved blogger, you are seeing the submit blog button. This message and the button do not appear for normal users.</p>							
							</div>
						:
							null
					}
					<div className={styles.bloglist}>
						{blogs}
											
					</div>
					{
						this.state.loading === true ?
							<Loading />
						:
							null
					}				
					{
						this.state.bottomReached === true ?
							<p className={styles.scrolltext}>That's the end!</p>
						:
							<p className={styles.scrolltext}>Keep Scrolling...</p>
					}


				</div>
				
			</div>



		)

	}
}


var enhance = compose(
	inject('authStore'),
	withRouter,
	observer
	)

export default enhance(SubmitScript)

