import React from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import { observable, decorate, when, toJS } from 'mobx'

import { compose } from 'recompose'

import requests, { Scripts } from 'axios.js'

import * as ROUTES from 'routes.js'
import { displayTimeStamp, getTimeAgoString, getOffset } from 'usefulFunctions.js'

import styles from 'styles/pages/script/feed.module.css'

import ScriptUnit from 'components/scripts/scriptunit.js'

import classnames from 'classnames'

class SavedScriptsFeed extends React.Component{

	saveruserid

	constructor(props) {


		super(props)


		this.authStore = this.props.authStore
		this.scriptStore = this.props.scriptStore

		this.state = {
		// scripts: ['a','b','c','d','e','f','g','h','i','j','k'],
		scripts: [],
		
		newest: true,
		currentPage: 1,
		scriptsPerPage: 10,
		scriptsPerRequest: 100,
		id_last_item: 1, //for keeping track of keyset pagination set - backend
		id_first_item: 1,
		pageSet: 0, // for keeping track of page numbers
		saveruserid: null,
		scripts: []

		}

 	  
 	}

	getSavedScripts = () =>{
		var userid = this.props.saveruserid

		var id_first_item = this.state.id_first_item
		var id_last_item = this.state.id_last_item


		Scripts.savedscripts.getSavedScriptsByDateEarliest(userid, this.state.scriptsPerRequest, 'first', id_first_item, id_last_item)
			.then( res =>{

				console.log(res.data)
				if(res.data.message){
					this.setState({
						scripts: res.data.message,
						id_first_item: res.data.id_first_item,
						id_last_item: res.data.id_last_item

					})
				}
			})
	}

 	componentDidMount(){
 			when(
 				() =>  typeof this.props.authStore.authFields.id === 'number',
 				() => {
 					this.getSavedScripts()

 				}
 			)
		
	}
 	

 	handleClick = () => {
 		// console.log(`currentPage will be ${event.target.id}`)
 	  this.setState({
 	    currentPage: Number(event.target.id)
 	  })

 	  var {top, left} = getOffset(this.scriptlist)
 	  window.scrollTo(0, top -180)

 	}


	get_newest = e =>{

		var userid = this.props.saveruserid

		var id_first_item = this.state.id_first_item
		var id_last_item = this.state.id_last_item

		Scripts.savedscripts.getSavedScriptsByDateEarliest(userid, this.state.scriptsPerRequest, 'first', id_first_item, id_last_item)
			.then( res =>{

				console.log(res.data)
				if(res.data.message){
					this.setState({
						scripts: res.data.message,
						id_first_item: res.data.id_first_item,
						id_last_item: res.data.id_last_item,
						newest: true

					})
				}
			})
		
	}
	get_oldest = e =>{
		var userid = this.props.saveruserid

		var id_first_item = this.state.id_first_item
		var id_last_item = this.state.id_last_item
		
		Scripts.savedscripts.getSavedScriptsByDateOldest(userid, this.state.scriptsPerRequest, 'first', id_first_item, id_last_item)
			.then( res =>{

				console.log(res.data)
				if(res.data.message){
					this.setState({
						scripts: res.data.message,
						id_first_item: res.data.id_first_item,
						id_last_item: res.data.id_last_item,
						newest: false

					})
				}
			})
	
	}

	loadPrevPages = e =>{

		var userid = this.props.saveruserid

		var id_first_item = this.state.id_first_item
		var id_last_item = this.state.id_last_item
		
		this.setState({
			pageSet: this.state.pageSet - 1
		})		

		if(this.state.newest === true){
			// Scripts.savedscripts.getSavedScriptsByDateEarliest(userid, this.state.scriptsPerRequest, 'less', id_first_item, id_last_item)
			Scripts.savedscripts.getSavedScriptsByDateEarliest(userid, this.state.scriptsPerRequest, 'less', id_last_item)
				.then( res =>{

					console.log(res.data)
					if(res.data.message){
						this.setState({
							scripts: res.data.message,
							id_first_item: res.data.id_first_item,
							id_last_item: res.data.id_last_item,
						    currentPage: this.state.currentPage - ((this.state.pageSet +1) * this.state.scriptsPerPage)


						})
					}
				})
		}

		else{
			// Scripts.savedscripts.getSavedScriptsByDateEarliest(userid, this.state.scriptsPerRequest, 'less', id_first_item, id_last_item)
			Scripts.savedscripts.getSavedScriptsByDateOldest(userid, this.state.scriptsPerRequest, 'less', id_last_item)
				.then( res =>{

					console.log(res.data)
					if(res.data.message){
						this.setState({
							scripts: res.data.message,
							id_first_item: res.data.id_first_item,
							id_last_item: res.data.id_last_item,
						    currentPage: this.state.currentPage - ((this.state.pageSet +1) * this.state.scriptsPerPage)


						})
					}
				})
		}


		// this.get_scripts(category, subcategory, 'less')
		// 	.then( () =>{
		// 		this.setState({
		// 		  currentPage: this.state.currentPage - ((this.state.pageSet +1) * this.state.scriptsPerPage)
		// 		})
		// 		console.log('PREV: currentpage!!:'+this.state.currentPage)

		// 	})


	}
	loadNextPages = e =>{
		var userid = this.props.saveruserid

		var id_first_item = this.state.id_first_item
		var id_last_item = this.state.id_last_item
		
		this.setState({
			pageSet: this.state.pageSet + 1,

		})	


		if(this.state.newest === true){
			// Scripts.savedscripts.getSavedScriptsByDateEarliest(userid, this.state.scriptsPerRequest, 'more', id_first_item, id_last_item)
			Scripts.savedscripts.getSavedScriptsByDateEarliest(userid, this.state.scriptsPerRequest, 'more', id_last_item)
				.then( res =>{

					console.log(res.data)
					if(res.data.message){
						this.setState({
							scripts: res.data.message,
							id_first_item: res.data.id_first_item,
							id_last_item: res.data.id_last_item,
				  			currentPage: this.state.currentPage + (this.state.pageSet * this.state.scriptsPerPage)



						})
					}
				})
		}

		else{
			// Scripts.savedscripts.getSavedScriptsByDateOldest(userid, this.state.scriptsPerRequest, 'more', id_first_item, id_last_item)
			Scripts.savedscripts.getSavedScriptsByDateOldest(userid, this.state.scriptsPerRequest, 'more', id_last_item)
				.then( res =>{

					console.log(res.data)
					if(res.data.message){
						this.setState({
							scripts: res.data.message,
							id_first_item: res.data.id_first_item,
							id_last_item: res.data.id_last_item,
				  			currentPage: this.state.currentPage + (this.state.pageSet * this.state.scriptsPerPage)


						})
					}
				})
		}

	}


 	render() {
 	  const { scripts, currentPage, scriptsPerPage, scriptnames, scriptdates, pageSet } = this.state;

 	  // Logic for displaying scripts
 	  const indexOfLastScript = ((currentPage - (10*pageSet)) * scriptsPerPage) ;
		

 	  const indexOfFirstScript = indexOfLastScript - scriptsPerPage;
 	  const currentScripts = scripts.slice(indexOfFirstScript, indexOfLastScript);


 	  const loadPrevButtonDisabled = this.state.pageSet === 0 	  		


 	  const loadNextButtonDisabled = this.state.scripts.length < this.state.scriptsPerRequest


 	  const renderScripts = currentScripts.map((script, index) => {
 	
		
 	    return (
			
			<ScriptUnit key={script.id}
				script={script}
			
		
			/>
 	    );
 	  });

 	  // Logic for displaying page numbers
 	  var pageNumbers = [];
 	  for (let i = 1 + (Math.ceil(this.state.scriptsPerRequest / scriptsPerPage) * this.state.pageSet); i <= Math.ceil(this.state.scriptsPerRequest / scriptsPerPage) * (this.state.pageSet + 1); i++) {
 	    pageNumbers.push(i);
 	  }

 	  pageNumbers = pageNumbers.filter(pagenum => pagenum <= Math.ceil(this.state.scripts.length / 10))

 	  const renderPageNumbers = pageNumbers.map(number => {
 	  	const pagenumberclass = classnames(styles.pagenumberunit, {[styles.pagenumberunitpressed]: number === this.state.currentPage })
 	    return (
 	      <li
 	        key={number}
 	        id={number}
 	        onClick={this.handleClick}
 	        className={pagenumberclass}
 	      >
 	        {number}
 	      </li>
 	    );
 	  });

 	  return (
 	    <div>
	
			 <div>
			 	<div>
			 		<span className={styles.filteroptionslabel} >Filter Options</span>
			 		<button disabled={this.state.newest === true} onClick={this.get_newest} className="pure-button">Newest</button>
			 		<button disabled={this.state.newest === false} onClick={this.get_oldest} className="pure-button">Oldest</button>
			 	</div>

			 	<div className={styles.pagenumberscontainer}>
			 		<button onClick={this.loadPrevPages} 
			 			disabled={loadPrevButtonDisabled}
			 			className={styles.prevpagebutton}>Previous {Math.ceil(this.state.scriptsPerRequest / scriptsPerPage)} Pages
			 		</button>

			 		<ul className={styles.pagenumbers}>
			 		  {renderPageNumbers}
			 		</ul>
			 		<button onClick={this.loadNextPages} 
			 			disabled={loadNextButtonDisabled}
			 			className={styles.nextpagebutton}>Next {Math.ceil(this.state.scriptsPerRequest / scriptsPerPage)} Pages
			 		</button>
			 	</div>

		 	      <ul className={styles.scriptscontainer} ref={ref => this.scriptlist = ref} >
		 	        {renderScripts}
		 	      </ul>
		 	      <div className={styles.pagenumberscontainer}>
		 	      	<button onClick={this.loadPrevPages} 
		 	      		disabled={loadPrevButtonDisabled}
		 	      		className={styles.prevpagebutton}>Previous {Math.ceil(this.state.scriptsPerRequest / scriptsPerPage)} Pages
		 	      	</button>

		 	      	<ul className={styles.pagenumbers}>
		 	      	  {renderPageNumbers}
		 	      	</ul>
		 	      	<button onClick={this.loadNextPages} 
		 	      		disabled={loadNextButtonDisabled}
		 	      		className={styles.nextpagebutton}>Next {Math.ceil(this.state.scriptsPerRequest / scriptsPerPage)} Pages
		 	      	</button>
		 	      </div>
		 	      

		 	</div>	
			
	 	    </div>
			 

 	  );
 	}

}



var enhance = compose(
	inject('authStore', 'scriptStore'),
	withRouter,
	observer
	)


export default enhance(SavedScriptsFeed)

