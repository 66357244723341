import React from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import { observable, decorate, when, toJS } from 'mobx'

import { compose } from 'recompose'

import ProfileScriptFeed from 'components/scripts/profilescriptsfeed.js'

import SavedScriptsFeed from 'components/scripts/savedscriptsfeed.js'


import {Profile} from 'axios.js'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import FilePond from 'components/files/filepond.js'
import Avatar from 'components/profile/avatar.js'

import UserNotes from 'components/profile/usernotes.js'
import Collapse from 'react-collapse'

import CreateDescription from 'components/profile/createdescription.js'

import {displayTimeStamp} from 'usefulFunctions.js'

import FollowersTab from 'components/profile/followerstab.js'

//icons
import VerifiedIcon from 'icons/profile/verified.js'
import BasicStarAward from 'icons/profile/basicstaraward.js'
import TopContributorAward from 'icons/profile/topcontributor.js'

import requests from 'axios.js'
import * as ROUTES from 'routes.js'

import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'


//styles
import styles from 'styles/pages/account/userprofile.module.css'

// import userAltImage from 'images/user.png'

 class UserProfile extends React.Component{

	authStore
	userStore
	constructor(props){

		super(props)
		this.authStore = this.props.authStore
		this.userStore = this.props.userStore

		this.state ={
			username: '',
			loading: true,
			loadFilePond: false,
			loadAltImage: true,
			submitCallback: null,
			//metadata
			userstatus: 'regular member',
			pageviews: 0,
			followers: [],
			datejoined: '',
			awards: [],
			flairs: [],
			description: '',
			addDescription: false,
			userid: undefined

		}


	}
/* ASYNC DATA FETCH */
	/* _________________ */

	getUserMetaData = () =>{
		var userid = this.authStore.authFields.id
		Profile.getUserMetaData(userid)
			.then( res =>{
				var data = res.data.message

				console.log(data)
				console.log(res.data.error)
				if(res.data.error || res.data.message.length === 0)
					return

				this.setState({
					userstatus: data.status === 'none' ? 'regular member' : data.status,
					pageviews: data.pageviews,
					datejoined: data.datejoined,
					awards: data.awards,
					flairs: data.flairs,
					description: data.description
				})
			})
		// var data = toJS(this.props.userStore.usermetadata)

		// console.log(data)
		// this.setState({
		// 	userstatus: data.status === 'none' ? 'regular member' : data.status,
		// 	pageviews: data.pageviews,
		// 	followers: data.followers,
		// 	datejoined: data.datejoined,
		// 	awards: data.awards,
		// 	flairs: data.flairs,
		// 	description: data.description
		// })
			
	}

	getFollowers = () =>{
		var userid = this.props.authStore.authFields.id

		Profile.getFollowersForUser(userid)
			.then( res =>{

				if(res.data.error || res.data.message.length === 0)
					return


				this.setState({
					followers: res.data.message
				})

				console.log("FOLOWERS ARE :")
				console.log(this.state.followers)

				var followers = this.state.followers
				followers = followers.filter( follower => follower.followeruserid === this.props.authStore.authFields.id)


				if(followers.length > 0){
					this.setState({
						follow: true
					})

				}
			})

	}

	/* _________________ */


	/* LIFECYCLE METHODS */
	/* _________________ */
	componentDidUpdate(){

		if(this.state.loading && this.authStore.authFields.username)
		{
			this.setState({
				loading: false,
				username: this.authStore.authFields.username
			})
		}

	}

	componentDidMount(){
		// this.userStore.setAvatar()

		when(
			() =>  typeof this.props.authStore.authFields.id === 'number',
			() => {
				this.setState({
					userid: this.props.authStore.authFields.id
				})
				this.getUserMetaData()
				this.getFollowers()
			}
		)

	}
	/* _________________ */


	

	




	/* INTERNALS */
	/* _________________ */


	
	changeAvatar= e =>{
		
		this.setState({
			loadFilePond: !this.state.loadFilePond
		})
	}


	submitFiles = e =>{
		// this.childPond.uploadAllFiles()

		requests.get(`${ROUTES.API.checkServerBusy}`)
			.then( rescheck =>{
				if(rescheck.data.message){
					if(this.state.submitCallback)
						this.state.submitCallback()	
					
				}
				else{
					toast.error('Server busy, please wait about a minute and try again. If you refresh, you may lose progress.', {
					position: "top-right",
					autoClose: 10000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,

					});
				}
			})



		// console.log('FILES PROCESSED!')
	}

	addDescription = e =>{
		this.setState({
			addDescription: !this.state.addDescription
		})
	}



	/* _________________ */

	
	render(){


		var { username } = this.authStore.authFields.username

		var { loading, loadFilePond, loadAltImage } = this.state

		var { file, width, height } = this.userStore.avatar

		width = "150px"
		height = "150px"

		console.log(`hello : ${file}`)

		console.log(toJS(this.userStore.usermetadata))

		console.log(this.state)



		return(

			

			<div className={styles.maincontainer}>
		
				<div className={styles.topbanner}>Your Profile</div>
				<div className={styles.leftpanel}>
					
		{/* Name + Flairs*/}

					<div className={styles.topcolumn}>
						<p className={styles.username}>{this.authStore.authFields.username} 
							{	
								this.state.flairs.filter(element => element === 'verified').length > 0
								?
								
								<VerifiedIcon className={styles.verified} height='15px' width='15px'/>
								:
								null
							}
						</p>
						<div>
							{
								this.state.awards.filter(element => element === 'top-contributor').length > 0
								?
								<TopContributorAward height='25px' width='25px' />
								:
								null
							}
						</div>


						<div>{this.state.userstatus}</div>
						{/*<div>{this.state.pageviews} views</div>*/}
						<div>joined {displayTimeStamp(this.state.datejoined)}</div>
	{/* Follower count*/}<div>{this.state.followers.length} followers</div>

					</div>
				
		

					
					<div className={styles.middlecolumn}>
{/* Avatar image and buttons*/}
						<div className={styles.center}>
							{loadAltImage &&<img src={file} alt='Default User' width={width}  />}
							<button onClick={this.changeAvatar} className={styles.changeavatarbutton} >Change Avatar</button>

						</div>				
						
						<div className={styles.filepondcontainer}>
							{loadFilePond && <FilePond
												table='avatars'
												imagesize={{width: '150'}}
												allowUserSubmit={false}
												maxTotalFileSize='1MB'
												acceptedFileTypes={['image/png', 'image/jpeg']}
												submitCallback={callback => this.setState({submitCallback: callback})}
												/>
							}
						</div>
						
						<div className={styles.center}>
							<button className={styles.submitavatarbutton} disabled={!this.state.loadFilePond} onClick={this.submitFiles}>SUBMIT</button>
						</div>
						
					</div>

{/*description*/}

					<div className={styles.bottomcolumn}>
						<div className={styles.descriptiontext} dangerouslySetInnerHTML={{__html: this.state.description}}></div>
{/*Change/Add description*/}

						{
							this.state.description === '' ?
							<div>
								<button className={styles.submitchangedescriptionbutton} onClick={this.addDescription}>Add Description</button>

								<Collapse isOpened={this.state.addDescription}>

									<div>
										<CreateDescription 
											cancelcallback={this.addDescription} 
										/>
									</div>
								</Collapse>
							</div>
							:
							<div>
								<button className={styles.submitchangedescriptionbutton} onClick={this.addDescription}>Change Description</button>

								<Collapse isOpened={this.state.addDescription}>

									<div>
										<CreateDescription 
											cancelcallback={this.addDescription} />
									</div>
								</Collapse>
							</div>

						}					

					</div>

				</div>

		
		

		




				<div className={styles.rightpanel}>
			{/*TABS*/}
					<Tabs>
			{/*TAB LIST*/}

					  <TabList>
					    <Tab>Notes</Tab>
					    <Tab>My Scripts</Tab>
					    <Tab>Followers</Tab>
					    <Tab>Saved Scripts</Tab>


					  </TabList>
			{/*TAB PANELS*/}

		{/*Notes*/}
					  <TabPanel>
					  {
					  	this.state.userid ?
						  	<UserNotes
						  		showCreateNote={true}
						  		userid={this.state.userid}
						  	 />
						  	:
						  	null
					  }
					  	
					  
					  </TabPanel>
		{/*My Scripts*/}
					  <TabPanel>
					{!loading || this.authStore.authFields.username ? <ProfileScriptFeed author={this.authStore.authFields.username}/> : <h3>Loading..</h3>}

					  </TabPanel>

		{/*Followers*/}
					  <TabPanel>
					  	<FollowersTab
					  		userid={this.authStore.authFields.id}
					  		followers={this.state.followers}

					  	/>
					  </TabPanel>

		{/*Saved Scripts*/}
					  <TabPanel>
					  	<SavedScriptsFeed
					  		saveruserid={this.authStore.authFields.id}
					  	/>
					  </TabPanel>
					</Tabs>


				</div>

				

			</div>

			


		)

	}
}


var enhance = compose(
	inject('authStore','userStore'),
	withRouter,
	observer
	)

export default enhance(UserProfile)

