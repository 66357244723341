import React from 'react'

const SVG = props => {

	return (
		<svg height={props.height} width={props.width} viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M3 0C2.19755 0 1.46938 0.315621 0.93103 0.828724V2.68966L2.99493 5.27514C2.997 5.27679 3 5.27534 3 5.27276C3 5.27534 3.003 5.2769 3.00506 5.27514L5.06896 2.68966V0.828724C4.53062 0.315621 3.80244 0 3 0Z" fill="#EBD6BD"/>
		<path d="M3 3.6207C2.61228 3.6207 2.26624 3.79863 2.03855 4.07711L2.99493 5.27515C2.997 5.2768 3 5.27535 3 5.27277C3 5.27535 3.003 5.2769 3.00507 5.27515L3.96145 4.07711C3.73376 3.79863 3.38772 3.6207 3 3.6207Z" fill="#4C4C4C"/>
		<path d="M3 0C2.63627 0 2.28796 0.0654828 1.96552 0.184138V1.86207L2.99493 2.68562C2.997 2.68728 3 2.68572 3 2.68314C3 2.68572 3.003 2.68728 3.00507 2.68562L4.03448 1.86207V0.184138C3.71203 0.0654828 3.36372 0 3 0Z" fill="#EBBA16"/>
		<path d="M0.97572 2.65387L1.96551 1.86207V0.184143C1.57665 0.327315 1.22534 0.548281 0.93103 0.828729V2.63235C0.93103 2.65542 0.95772 2.66825 0.97572 2.65387Z" fill="#ED8A19"/>
		<path d="M4.03448 1.86207L5.02428 2.65387C5.04228 2.66825 5.06897 2.65542 5.06897 2.63235V0.828729C4.77466 0.548281 4.42335 0.327315 4.03448 0.184143V1.86207Z" fill="#ED8A19"/>
		</svg>



	)
	//height={props.height} width={props.width} viewBox={`0 0 ${props.viewW} ${props.viewH}`}
	
}

export default SVG