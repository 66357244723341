import React from 'react'

const SVG = props => {

	return (
		<svg height={props.height} width={props.width} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="6.47656" width="3.51562" height="22.5" fill="#C4C4C4"/>
		<rect x="13.5078" width="3.51562" height="22.5" fill="#C4C4C4"/>
		<rect x="0.5" y="15.8203" width="3.51562" height="22.5" transform="rotate(-90 0.5 15.8203)" fill="#C4C4C4"/>
		<rect x="0.5" y="8.78906" width="3.51562" height="22.5" transform="rotate(-90 0.5 8.78906)" fill="#C4C4C4"/>
		</svg>


	)
	//height={props.height} width={props.width} viewBox={`0 0 ${props.viewW} ${props.viewH}`}
	
}

export default SVG