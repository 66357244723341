
import React from 'react'

const SVG = props => {

	return (
		<svg height={props.height} width={props.width} viewBox="5 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M50.9559 14.456L25.4999 29L0.0439453 14.456L25.4999 0L50.9559 14.456Z" fill="#CECDC6"/>
		<path d="M25.4999 28.9997L9.69995 19.9727L0.0439453 25.4557L25.4999 39.9997L50.9559 25.4557L41.2999 19.9727L25.4999 28.9997Z" fill="#837A70"/>
		<path d="M25.4999 39.9997L9.69995 30.9727L0.0439453 36.4557L25.4999 50.9997L50.9559 36.4557L41.2999 30.9727L25.4999 39.9997Z" fill="#313131"/>
		</svg>

	)
	//height={props.height} width={props.width} viewBox={`0 0 ${props.viewW} ${props.viewH}`}
	
}

export default SVG


