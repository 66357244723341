import React from 'react'

import 'styles/components/buttonForm.css'

function NeonButton(){
	return(
		
		<div className="buttonForm-custom">
			<a href="#">
           
            Neon Button
        </a>

		</div>
		
		)
}

export default NeonButton