
import app from 'firebase/app'
import 'firebase/auth'

const configProd = {
  apiKey: process.env.REACT_APP_PROD_API_KEY,
  authDomain: process.env.REACT_APP_PROD_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_PROD_DATABASE_URL,
  projectId: process.env.REACT_APP_PROD_PROJECT_ID,
  storageBucket: process.env.REACT_APP_PROD_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_PROD_MESSAGING_SENDER_ID,
};


const configDev = {
  apiKey: process.env.REACT_APP_DEV_API_KEY,
  authDomain: process.env.REACT_APP_DEV_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DEV_DATABASE_URL,
  projectId: process.env.REACT_APP_DEV_PROJECT_ID,
  storageBucket: process.env.REACT_APP_DEV_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_DEV_MESSAGING_SENDER_ID,
};

const config = process.env.NODE_ENV === 'production' ? configProd : configDev
// console.log()


class Firebase{

	constructor(){

		app.initializeApp(config)
		this.auth = app.auth()

		this.functionlessauth = app.auth
	}

	// *** AUTH API ***

	doCreateUserWithEmailAndPassword = (email, password) =>
		this.auth.createUserWithEmailAndPassword(email, password)

	doSignInWithEmailAndPassword = (email, password) =>
		this.auth.signInWithEmailAndPassword(email, password)

	doSignOut = () =>
		this.auth.signOut()

	doSendPasswordResetEmail = email =>
		this.auth.sendPasswordResetEmail(email)

	doPasswordUpdate = password =>
		this.auth.currentUser.updatePassword(password)

	doSignInWithCustomToken = token =>
		this.auth.signInWithCustomToken(token)


	doSendEmailVerification = () =>
		this.auth.currentUser.sendEmailVerification()

	doReauthenticateUser = credential =>
		this.auth.currentUser.reauthenticateWithCredential(credential)	

	// setAuthStateChanged = authUser =>
	// 	this.auth.onAuthStateChanged
	
	

}

var firebase = new Firebase()

export default firebase