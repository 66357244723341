import React from 'react'

const SVG = props => {

	return (
		<svg height={props.height} width={props.width} viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0)">
		<path d="M1.15789 4.44274C1.68111 4.44274 2.10526 4.01859 2.10526 3.49537C2.10526 2.97216 1.68111 2.548 1.15789 2.548C0.634677 2.548 0.210526 2.97216 0.210526 3.49537C0.210526 4.01859 0.634677 4.44274 1.15789 4.44274Z" fill="#7383BF"/>
		<path d="M4.84211 6.50001C5.36532 6.50001 5.78947 6.07586 5.78947 5.55264C5.78947 5.02942 5.36532 4.60527 4.84211 4.60527C4.31889 4.60527 3.89474 5.02942 3.89474 5.55264C3.89474 6.07586 4.31889 6.50001 4.84211 6.50001Z" fill="#7383BF"/>
		<path d="M4.84211 2.39474C5.36532 2.39474 5.78947 1.97059 5.78947 1.44737C5.78947 0.924151 5.36532 0.5 4.84211 0.5C4.31889 0.5 3.89474 0.924151 3.89474 1.44737C3.89474 1.97059 4.31889 2.39474 4.84211 2.39474Z" fill="#7383BF"/>
		<path d="M2.00245 3.16947C2.02097 3.16947 2.03971 3.16462 2.05666 3.15431L4.04697 1.95557C4.09676 1.92557 4.11276 1.86094 4.08276 1.81115C4.05276 1.76126 3.98813 1.74515 3.93824 1.77526L1.94803 2.9741C1.89824 3.0041 1.88224 3.06873 1.91224 3.11852C1.93192 3.15136 1.96666 3.16947 2.00245 3.16947Z" fill="#424A60"/>
		<path d="M4.04653 5.04431L2.05632 3.83621C2.00664 3.806 1.9419 3.82168 1.91169 3.87157C1.88148 3.92126 1.89737 3.986 1.94706 4.0161L3.93727 5.22421C3.95432 5.23463 3.97316 5.23958 3.99179 5.23958C4.02737 5.23958 4.062 5.22157 4.0819 5.18894C4.11211 5.13915 4.09632 5.07442 4.04653 5.04431Z" fill="#424A60"/>
		</g>
		<defs>
		<clipPath id="clip0">
		<rect width="6" height="6" fill="white" transform="translate(0 0.5)"/>
		</clipPath>
		</defs>
		</svg>


	)
	//height={props.height} width={props.width} viewBox={`0 0 ${props.viewW} ${props.viewH}`}
	
}

export default SVG