import React, {Suspense, lazy} from 'react'

import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import { observable, decorate, when, toJS } from 'mobx'

import { compose } from 'recompose'


import * as ROUTES from 'routes.js'

import requests, { Comments, Scripts } from 'axios.js'

import { parseQuery, getIndicesOf } from 'usefulFunctions.js'

import { sanitize } from 'dompurify'



import CreateComment from 'components/comments/createcomment.js'

import CommentUnit from 'components/comments/commentunit.js'

import { displayTimeStamp, getTimeAgoString } from 'usefulFunctions.js'


import CodeEditor from 'components/codeeditor.js'

import Prism from 'prismjs'
import LinkToTag from 'pages/script/linktotag.js'    

import {decode} from 'html-entities'

import Collapse from 'react-collapse'

import Loading from 'components/animations/loading.js'


import CommentExampleGuide from 'components/scripts/commentsExampleGuide.js'



     

    

//styles
import styles from 'styles/pages/script/scriptpage.module.css'
import classnames from 'classnames'
import Arrow from 'images/script/arrow.png'


const RichTextEditor = lazy( () => import('components/scripts/ckeditor.js'))


//vid speed is in fetDataForScript function -- 
 class ScriptPage extends React.Component{

	scriptid
	scriptname

	formState={
		scriptid: '',
		userid: '',
		scriptname: '',
		author: '',
		scripttitle: '',
		submitdate: '',
		description: '',
		filesize: {width: '888px', height:'500px'},
		files: [],
		thumbnail: [],
		gifs: [],
		pics: [],
		comments: [],
		commentrelationship: [],
		rearrangedcomments: [],
		tags: [],
		showUserComponents: false,
		showEditDescription: false,
		showEditScriptName: false,
		showEditScript: false,
		showCommentExample: false,
		cancelDescription: '',
		initDescription: '',
		editScriptNameInput: '',
		editScriptInput: '',

		rows: 15,
		cols: 50,

		maxDescriptionLength: 10000,
		maxScriptLength: 10000,
		maxTagLength: 30,
		maxScripTitleLength: 100,

		scriptnameTaken: false,
		descriptionDisabled: false,
		scriptDisabled: false,
		commentDisabled: false,


	}
	constructor(props){

		super(props)

		this.queryParams = parseQuery(this.props.location.search)
		this.scriptid = this.queryParams['id']
		this.scriptname = this.queryParams['name']

		var uiStore = this.props.uiStore


		this.formState.maxDescriptionLength = uiStore.maxDescriptionLength
		this.formState.maxScriptLength = uiStore.maxScriptLength
		this.formState.maxTagLength = uiStore.maxTagLength
		this.formState.maxScripTitleLength = uiStore.maxScriptNameLength

		this.formState.scriptid = this.scriptid
		this.formState.scriptname = this.scriptname

	

	}

		

	/* ASYNC FETCH */
	/*  _______________________________________ */

	fetchDataForScript = () =>{
		
		//get userid from authStore 11/25/2020 4:34 PM TODAY RIGHT NOW MANCALA
		requests.post(`${ROUTES.API.getscriptfromid }`, {scriptid: this.formState.scriptid})
				.then( resScript =>{
					
					console.log(resScript.data.message)
					console.log(resScript.data.error)
					this.userid = resScript.data.message ? resScript.data.message.userid : 1


					requests.post(`${ROUTES.API.getuserfromid}`, {userid: this.userid}) //res.data.message[0].userid
						.then( resUser =>{
							this.formState.author = resUser.data.message

							requests.post(`${ROUTES.API.getfilesforscriptid}`, {scriptid: this.formState.scriptid})
								.then( res =>{

									this.formState.scripttitle = resScript.data.message.scriptname

									this.formState.description = resScript.data.message.description
									this.formState.submitdate = resScript.data.message.submitdate


									this.formState.initDescription = this.formState.description

									console.log(this.formState.initDescription)


									this.formState.script = resScript.data.message.script

									this.formState.files = res.data.message || []

									
									this.formState.tags = resScript.data.message.tags ? resScript.data.message.tags : [] 

									this.formState.description = this.prepareUserSubmittedHTML(this.formState.description)

									this.formState.cancelDescription = this.formState.description

									if(res.data.error){
										console.log(res.data.error)
										return
									}
									console.log(res.data.message)



									// this.formState.description = this.formState.description.replace(/(\<p\>|\<\/p\>)/g, '')

									// console.log(this.formState.description)

									var gif1 = document.getElementsByTagName("video")[0]
									var gif2 = document.getElementsByTagName("video")[1]

									if(gif1){
										gif1.addEventListener('play', e =>{
											gif1.playbackRate = this.props.scriptStore.videoPlayBackSpeed.gifs
										
										})
									}
									if(gif2){
										gif2.addEventListener('play', e =>{
											gif2.playbackRate = this.props.scriptStore.videoPlayBackSpeed.gifs
											
										
										})
									}

									// this.formState.userid = res.data.message[0].userid		

								})

						})
					
								

					

				})

	}

	getComments = () =>{
		Comments.getScriptComments(this.scriptid)
			.then( res =>{
				console.log(res.data.message)
				this.formState.comments = res.data.message

			})
		
	}

	getCommentRelationship = () =>{

		Comments.getScriptCommentRelationship(this.scriptid)
			.then( res =>{
				
				// res.data.message ? this.formState.commentrelationship = res.data.message 
				// 	: console.log(res.data.error)

				if(res.data.message){
					this.formState.commentrelationship = res.data.message

					console.log(res.data.message)

				}

			})
	} 




	/*  _______________________________________ */
	

	/* LIFE CYCLE */
	/*  _______________________________________ */
	componentDidMount(){
	
		when(
 				() => this.props.authStore.authFields.username === this.formState.author && this.formState.author !== '' && this.props.authStore.authFields.username !== '',
 				() => {
 					this.formState.showUserComponents = true

 				}
 			)

		this.fetchDataForScript()
		this.getComments()
		this.getCommentRelationship()

	}

	componentDidUpdate(){

		if(this.firstgif)
			this.firstgif.playbackRate = .5
		if(this.secondgif)
			this.secondgif.playbackRate = .5


	}

	/*  _______________________________________ */


		/* INTERNALS */

		setRefs = () => {

			// console.log("SWAGSSS")

		}

	/*  _______________________________________ */
		
	prepareUserSubmittedHTML = htmlstring =>{

		//files
		var string_array_withcode = htmlstring.match(/\[\w+\]/gi)

		if(string_array_withcode === null ) //return value of string.match
			return htmlstring

		var string_array = string_array_withcode.filter(el => !el.includes('[code]') || !el.includes('[himg]') || !el.includes('[/code]') || !el.includes('[/himg]'))

		
		var working_string = ''

		var { width, height } = this.formState.filesize
		let string = ''

		console.log(string_array)
		console.log(string_array_withcode)

		//width=${width} height=${height}

		console.log(toJS(this.formState.files))


		if(string_array !== 'undefined' && string_array !== null ){
			string_array.forEach( (element, index) =>{

				var base = element.replace(/[\[\]]/g, "")


				var arr = this.formState.files.filter(e => base === e.placementlocation)
				// console.log(toJS(arr))

				if(arr.length === 0)
					return htmlstring


				
				switch(element){

					case '[pic1]':
						string = this.formState.files.filter( file => file.placementlocation === 'pic1').pop().path 
						htmlstring = htmlstring.replace(element, `<img src=${string} class='himg' alt="" />`)
						break;

					case '[pic2]':
						string = this.formState.files.filter( file => file.placementlocation === 'pic2').pop().path 
						htmlstring = htmlstring.replace(element, `<img src=${string} class='himg' alt="" />`)
						break;


					case '[pic3]':
						string = this.formState.files.filter( file => file.placementlocation === 'pic3').pop().path 
						htmlstring = htmlstring.replace(element, `<img src=${string} class='himg' alt="" />`)
						break;

					case '[pic4]':
						string = this.formState.files.filter( file => file.placementlocation === 'pic4').pop().path 
						htmlstring = htmlstring.replace(element, `<img src=${string} class='himg' alt="" />`)
						break;


					case '[pic5]':
						string = this.formState.files.filter( file => file.placementlocation === 'pic5').pop().path 
						htmlstring = htmlstring.replace(element, `<img src=${string} class='himg' alt="" />`)
						break;
					case '[gif1]':
						string = this.formState.files.filter( file => file.placementlocation === 'gif1').pop().path 
						htmlstring = htmlstring.replace(element, `<video autoPlay ref=${ref => this.firstgif = ref} loop muted playsInline>
							  <source src=${string} type="video/mp4" /></video>`)
						break;
					case '[gif2]':
						string = this.formState.files.filter( file => file.placementlocation === 'gif2').pop().path 
						htmlstring = htmlstring.replace(element, `<video autoPlay loop muted playsInline>
							  <source src=${string} type="video/mp4" /></video>`)
						break;
					case '[video]':
						string = this.formState.files.filter( file => file.placementlocation === 'video').pop().path 

						htmlstring = htmlstring.replace(element, `<iframe width="560" height="315" 
								src=${string} 
								frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
								allowFullScreen>
							</iframe>`)
						break;

				}


			})
		}

		//code block
		//[code][/code]

		var indexFromForHIMG = 0
		var indexFromForCode = 0

		


		if(htmlstring.includes('[code]') && htmlstring.includes('[/code]')){

			var justcodearr = string_array_withcode.filter(ele => ele.includes('[code]'))

			justcodearr.forEach((ele, indexx) =>{

					// if(indexFromForCode === 0){

					// }
					// var code = htmlstring.substring(indecesForCode[indexx], htmlstring.indexOf('[/code]')).replace('[code]', '')

					var indecesForCode = getIndicesOf('[code]', htmlstring)
					var indecesforSlashCode = getIndicesOf('[/code]', htmlstring)

					var original = htmlstring.substring(indecesForCode[0],indecesforSlashCode[0])

					
					var code = htmlstring.substring(indecesForCode[0],indecesforSlashCode[0]).replace('[code]', '')

					// code = sanitize(code, {ALLOWED_TAGS: []})

					code = code.replace(/(\<p\>)/gm, '\r\n')
					// code = code.replace(/\>/g, '')

					code = code.replace(/(\<\/p\>)/gm, '')
					// code = code.replace(/(&nbsp;|&lt;)/g, '')

					code = decode(code)

					// code = code.replace(/^(\s;)*/gm, '')
					// code = code.replace(/^(\s;)*/gm, '\t')


					// code = code.replace(/^(\s+;\s+;\s+;\s+;)|^(\s+;\s+;\s+;)|^(\s+;\s+;)|^(\s+;)/gm, '')

					code = code.replace(/^(\s\~)*|^(\~)*/gm, '')
					// code = code.replace(/&amp;/gm, '&')
					// code = code.replace(/&lt;/gm, '<')
					// code = code.replace(/&gt;/gm, '>')




					code = Prism.highlight(code.trim(), Prism.languages.autohotkey, 'autohotkey')

					// var prismcode = `<pre><code class="language-autohotkey">${code}</code></pre>`


					var prismcode = `<pre class='prismeditor line-numbers'><code class='language-autohotkey'>${code}</code></pre>`


					htmlstring = htmlstring.replace(original, prismcode )
					htmlstring = htmlstring.replace('[/code]', '')

				
			})
			
			

	  //   	console.log(htmlstring)

		}

		// var indecesforHIMG = getIndicesOf('[himg]', htmlstring)
		// var indecesforSlashHIMG = getIndicesOf('[/himg]', htmlstring)

		if(htmlstring.includes('[himg]') && htmlstring.includes('[/himg]')){


			var justhimgarr = string_array_withcode.filter(ele => ele.includes('[himg]'))

			justhimgarr.forEach((ele, indexx) =>{

				var indecesforHIMG = getIndicesOf('[himg]', htmlstring)
				var indecesforSlashHIMG = getIndicesOf('[/himg]', htmlstring)

				// var himg = htmlstring.substring(htmlstring.indexOf('[himg]'), htmlstring.indexOf('[/himg]')).replace('[himg]', '').trim()

				var original = htmlstring.substring(indecesforHIMG[0],indecesforSlashHIMG[0])

				// console.log(original)
				var himg = htmlstring.substring(indecesforHIMG[0],indecesforSlashHIMG[0])
				himg = himg.replace('[himg]', '')


				// var himg = htmlstring.substring(htmlstring.indexOf('[himg]'), htmlstring.indexOf('[/himg]')).replace('[himg]', '').trim()
				// console.log(himg)


				himg = himg.replace(/(\<p\>)/gm, '\r\n')

				himg = himg.replace(/(\<\/p\>)/gm, '')
				himg = himg.replace(/(&nbsp|&lt)/g, '\t')

				// console.log(himg)


				var himghtml = `<img src='${himg}' alt='img not loaded' class='himg' />`


				// htmlstring = htmlstring.replace(htmlstring.substring(htmlstring.indexOf('[himg]'), htmlstring.indexOf('[/himg]')), himghtml )
				htmlstring = htmlstring.replace(original, himghtml )

				htmlstring = htmlstring.replace('[/himg]', '')
				

		    	// console.log(htmlstring)
			})
			

		}


		return htmlstring
	}

	commentsRender = () =>{
		var {comments, commentrelationship} = this.formState

		if(typeof comments === 'undefined' || typeof comments === 'null' || comments.length === 0)
			return null

		console.log(toJS(commentrelationship))
		console.log(toJS(comments))

		comments = toJS(comments)
		commentrelationship = toJS(commentrelationship)
		var root_level_comments = commentrelationship.map(
			(commentrelation, index) => {
				return commentrelation.descendents[0] 
			})

		root_level_comments = [... new Set(root_level_comments)]

		console.log(root_level_comments)
		var replies = commentrelationship.map(
			(commentrelation, index) => {
				
				const [...rest] = commentrelation.descendents
				return rest 

			})

		// console.log(replies)
		var array_of_comment_obj


		var final = []
		var finalindex = 0

		var temp_obj

		var used = []
		var array_of_comment_obj = root_level_comments.reduce((acc, rootid, rootindex) =>{

		  temp_obj = {id: rootid, descendents: []}

		  replies.forEach( (innerelement, innerindex) => {
		    if(innerelement.includes(rootid)){
		       const [element, ...rest] = innerelement
		       temp_obj['descendents'].push( rest)
		    }
		  })

		  final.push(temp_obj)
		  // acc.push([])

		},[])

		// console.log(final)




		// root_level_comments.forEach((rootcomment, index) =>{
		// 	var x = {id: rootcomment.id}

		// 	replies.forEach((reply, index) =>{
		// 		x[descendents] = reply
		// 	})

		// 	// array_of_comment_obj


		// })

		var ordered_final = []
		var ordered_comments = []

		ordered_comments = root_level_comments.reduce((acc, rootid, rootindex) =>{

		  temp_obj = {id: comments.filter(ele => { return ele.id === rootid}).pop(), descendents: []}

		  var comment_holder = []
		  replies.forEach( (innerelement, innerindex) => {
		    if(innerelement.includes(rootid)){
		       const [element, ...rest] = innerelement

		       comment_holder = rest.map( (restid, restindex) =>{
					let x = comments.filter(ele => { return ele.id === restid}).pop()
					return x
		       })
		       temp_obj['descendents'].push( comment_holder)
		    }
		  })

		  ordered_final.push(temp_obj)
		  // acc.push([])

		},[])

		// console.log(ordered_final)

		var used = []

		var final_ = []

		var comments_render = ordered_final.map((comment_chain, chain_index, arr) =>{
			final_.push([])
			comment_chain.id['placement'] = 1

			used.push(comment_chain.id.id)
			
			final_[chain_index].push(comment_chain.id)

			comment_chain.descendents.forEach((descendent_array, descendent_array_index) =>{
				descendent_array.forEach( (descendent, descendent_index)=>{
						console.log(`used arr is ${used} . descendent id is ${descendent.id}. descendent_array_index : ${descendent_index} `)

					descendent['placement'] =  descendent_index + 2 

					final_[chain_index].push(descendent)

					used.push(descendent.id)


				})

			})

			return comment_chain
			
		})

		// console.log(comments_render)

		// console.log(final_)



		var roots_comments_left = comments.filter((element, index) => element.parentid === null && !used.includes(element.id))

		roots_comments_left.forEach((element, index) =>{
			final_.push([element])	
		})

		

		// console.log(final_)

		var duplicates = used.reduce(function (acc, curr) {
		  if (typeof acc[curr] == 'undefined') {
		    acc[curr] = 1;
		  } else {
		    acc[curr] += 1;
		  }

		  return acc;
		}, {});

		console.log(duplicates)


		return final_.map((comment_chain, index, arr) => {

			var innerfinal_ = comment_chain.map((inner, innerindex, innerarr) =>{
				
				var timestamp = getTimeAgoString(inner.submitdate)


				if(duplicates[inner.id] > 1){

				// 	console.log('IM HERE')
				// console.log(inner)

					duplicates[inner.id] = 0
					return (
						<CommentUnit key={inner.id}
							userid={inner.userid}
							date={timestamp}
							commentbody={inner.commentbody}
							awards={inner.privilege}
							votecount={inner.votecount}
							placement={inner.placement}
							id={inner.id}
							scriptid={inner.scriptid}
							parentid={inner.id}
						/>
					)
				} 
				// inner.parentid === null ? console.log(inner) : console.log('')
				return (
					
						(duplicates[inner.id] === 1 || inner.parentid === null) && 
						<CommentUnit key={inner.id}
							userid={inner.userid}
							date={timestamp}
							commentbody={inner.commentbody}
							awards={inner.privilege}
							votecount={inner.votecount}
							placement={inner.placement}
							id={inner.id}
							scriptid={inner.scriptid}
							parentid={inner.id}
						/>
					
				)

			})

			return (
				<ul key={index}>
					{
						innerfinal_
					}
					

				</ul>
					
				
			)
		})



	}


	// SCRIPTNAME

	editScriptName = e =>{

		this.formState.showEditScriptName = !this.formState.showEditScriptName
	}

	editScriptNameInput = e =>{

		this.formState.editScriptNameInput = e.target.value
	}

	submitScriptName = e =>{
		var scriptname = this.formState.editScriptNameInput



		Scripts.editScriptPagePart( scriptname, 'scriptname', this.scriptid)
			.then( res =>{
				
				if(res.data.message){
					this.formState.scripttitle = scriptname
					window.location.reload(false)				
				}
				else{
					//name taken
					this.formState.scriptnameTaken = true

				}

			})
	}
	/*  ________________SCRIPTNAME_______________________ */

	// TAGS --- this one is experimental, probably won't allow change tags. but not hard to implement
	// if so, update tags table and tags col in scripts table

	
	/*  ________________TAGS_______________________ */


	// SCRIPT

	editScript = e =>{

		this.formState.showEditScript = !this.formState.showEditScript
	}

	editScriptInput = e =>{

		if(e.target.value.length > this.formState.maxScriptLength){
			this.formState.scriptDisabled = true
			this.formState.editScriptInput = e.target.value

		}
		else{
			this.formState.scriptDisabled = false
			this.formState.editScriptInput = e.target.value

		}
	}

	submitScript = e =>{
		var script = this.formState.editScriptInput

		if(script.length > this.formState.maxScriptLength){
			this.formState.scriptDisabled = true
		}


		Scripts.editScriptPagePart( script, 'script', this.scriptid)
			.then( res =>{
				
				if(res.data.message){
					this.formState.script = script
					window.location.reload(false)				
				}
				else{
					//name taken


				}

			})
	}
	/*  ________________SCRIPT_______________________ */

	// DESCRIPTION

	getDescription = description =>{

		if(description.length > this.formState.maxDescriptionLength){
			this.formState.descriptionDisabled = true
		}
		else{
			this.formState.descriptionDisabled = false
		}
		this.formState.description = description
	}
	editDescription = e =>{

		this.formState.showEditDescription = true

		this.formState.description = this.formState.initDescription

		var description = this.formState.description


		var fixeddescription = description.replace(/(\<pre)/gm, '[code]<pre')
		fixeddescription = fixeddescription.replace(/(\<\/pre\>)/gm, '</pre>[/code]')

		this.formState.description = fixeddescription

		console.log(this.formState.description)

	}

	submitDescription = e =>{

		var description = this.formState.description

	

		// console.log(fixeddescription)

		var fixeddescription = this.prepareUserSubmittedHTML(description)

		Scripts.editScriptPagePart( fixeddescription, 'description', this.scriptid)
			.then( res =>{
				
				if(res.data.message){
					this.formState.description = fixeddescription
					window.location.reload(false)				
				}
				else{

				}

			})


		// window.location.reload(false)
	}

	cancel = e =>{

		// this.props.commentStore.clearRichTextEditor()
		var description = this.formState.description

		var fixeddescription = description.replace(/\[code\]/gm, '')
		fixeddescription = fixeddescription.replace(/\[\/code\]/gm, '')

		this.formState.description = this.formState.cancelDescription
		this.formState.showEditDescription = false
	}

	/*  ________________DESCRIPTION_______________________ */


	

	
	render(){

		var { files, scripttitle } = this.formState


		// var sanitizedHTML = sanitize(this.formState.description, {FORBID_TAGS:['img', 'iframe', 'video']})
		

		// var sanitizedHTML = sanitize(this.formState.description, {ALLOWED_TAGS: ['iframe', 'img', 'video', 'source', 'pre', 'code', 'div', 'span']})
		var sanitizedHTML = sanitize(this.formState.description, {ADD_TAGS: ['iframe']})

		// var fixedHTML = this.prepareUserSubmittedHTML(this.formState.description)
		
		// var randomHTML = sanitize('<img src="https://i.imgur.com/eICyIjI.gif" alt="" /> <p>stuffbro</p> <h3>aww yeah</h3> <script onload={() => {alert("HI")}}></script>', {FORBID_TAGS:['img', 'iframe', 'video']})

		var randomHTML = sanitize('<img src="https://i.imgur.com/eICyIjI.gif" alt="" /> <p>stuffbro</p> <h3>aww yeah</h3> <script onload={() => {alert("HI")}}></script>')
		// console.log(`description is ${this.formState.description}`)

		var comments = this.commentsRender()
		var fileComponents

		// console.log(toJS(this.formState.description))

		var tags = this.formState.tags.map(tag =>{
			return (
				<LinkToTag
    					key={Math.random() * 10000}
    					tagtag={tag}
    				>
    				#{tag} 
   				</LinkToTag>
			)
		})

		//styles
		var howtoguideclosebuttonclass = classnames(styles.howtoguideclosebutton, styles.submitbutton)

		

		return (
			<div className={styles.maincontainer}>
				{/*<div className={styles.adtop}>AD</div>

				<div className={styles.adright}>AD</div>*/}

{/*script meta*/}
				
				<div className={styles.metadatacontainer}>
					<h3 className={styles.scripttitle}>{this.formState.scripttitle}</h3><Collapse isOpened={this.formState.showUserComponents}>

					<button className={styles.editbutton} onClick={this.editScriptName}>Edit</button>

					<Collapse isOpened={this.formState.showEditScriptName}>
						<input
							type='text'
							placeholder='script title'
							onChange={this.editScriptNameInput}
							value={this.formState.editScriptNameInput}

						/>
						<button disabled={this.formState.editScriptNameInput === '' || this.formState.editScriptNameInput.length < 5} onClick={this.submitScriptName}>Accept</button>
						{
							this.formState.scriptnameTaken ?
								<p>Script Title taken.</p>
							:
								null
						}
					</Collapse>
					
					</Collapse>
					</div>

					<div className={styles.metadatacontainer}>

							<Link className={styles.userlink} to={`${ROUTES.OTHERUSERPROFILEPAGE}?id=${this.userid}`}><h3 className={styles.scriptauthor}>by: {this.formState.author}</h3></Link>

						<small className={styles.submitdate}>submitted {getTimeAgoString(this.formState.submitdate)}</small>

					</div>

					
					

	{/*tags*/}
					<ul className={styles.tags}>
						{tags}
					</ul>
					
	{/*description*/}		
					<div className={styles.descriptioncontainer} dangerouslySetInnerHTML = {{__html: sanitizedHTML }}></div>

	{/*edit Description*/}
					<Collapse isOpened={this.formState.showUserComponents}>

						<button className={styles.editbutton} onClick={this.editDescription}>Edit</button>

						<Collapse isOpened={this.formState.showEditDescription}>
							<Suspense fallback={<Loading/>}>
								
								<RichTextEditor
									description={this.formState.description}
									contentCallback={this.getDescription}

									// commenteditcallback={this.commenteditcallback}
								/>
								<button disabled={this.formState.description === '' || this.formState.description === undefined || this.formState.descriptionDisabled} onClick={this.submitDescription}>Submit</button>
								<button onClick={this.cancel}>Cancel</button>


							</Suspense>
							{
								this.formState.descriptionDisabled ?
									<p>Description too long. Max {this.formState.maxDescriptionLength} char.</p>
								:
									null
							}
						</Collapse>
						
					</Collapse>

	{/*script*/}
					<div className={styles.borderseparation}></div>
					<h3 className={styles.scriptheading}>Script</h3>
					<div className="prismeditor">
						<CodeEditor code={decode(this.formState.script)}/>
						
					</div>


	{/*edit Script*/}
					<Collapse isOpened={this.formState.showUserComponents}>

						<button className={styles.editbutton} onClick={this.editScript}>Edit</button>

						<Collapse isOpened={this.formState.showEditScript}>
							
							<textarea 
							// type="text"
							onChange={this.editScriptInput}
							cols={this.formState.cols}
							rows={this.formState.rows}
							value={this.formState.editScriptInput}
							placeholder="Your script here..."
							name='script'
						/>
							<button disabled={this.formState.editScriptInput === '' || this.formState.scriptDisabled} onClick={this.submitScript}>Accept</button>
							{
								this.formState.scriptDisabled ?
									<p>Script too long. Max {this.formState.maxScriptLength} char.</p>
								:
									null
							}
						</Collapse>
						
					</Collapse>

	{/*comment*/}
					<div className="space-waste"></div>

					<div className={styles.borderseparation}></div>

					<h3 className={styles.submitcommentheading}>Submit a comment</h3>

					{/*<p onMouseEnter={() => this.formState.showCommentExample = true}>Expand How-to Guide</p>*/}

					<div className={styles.howtoguidecontainer}>
						<img src={Arrow} alt=""/>
						<div  className={howtoguideclosebuttonclass} onMouseEnter={() => this.formState.showCommentExample = true}> Expand How-to Guide</div>
						
					</div>

					<Collapse isOpened={this.formState.showCommentExample}>
						<button type='button' onClick={() => this.formState.showCommentExample = false}>Close</button>
						<CommentExampleGuide/>
					</Collapse>

					<CreateComment 
						scriptid={this.scriptid}

					/>

					<div className={styles.borderseparation}></div>

					<h3 className={styles.discussionheading}>Discussion</h3>


					<div className={styles.commentsectioncontainer}>
						
						{comments}
					</div>


				{/*<div className={styles.adbottom}>AD</div>*/}
				

				
			</div>

		)

	}
}


decorate(ScriptPage, {
	formState: observable
})

var enhance = compose(
	inject('authStore', 'scriptStore', 'commentStore', 'uiStore'),
	withRouter,
	observer
	)

export default enhance(ScriptPage)

