import React from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import { observable, decorate, when, toJS } from 'mobx'

import { compose } from 'recompose'

import requests, { Search } from 'axios.js'

import * as ROUTES from 'routes.js'
import { parseQuery, getTimeAgoString, slugify, getOffset } from 'usefulFunctions.js'

import LinkToTag from 'pages/script/linktotag.js'

import {sanitize} from 'dompurify'

import ScriptUnit from 'components/scripts/scriptunit.js'

//styles
import styles from 'styles/pages/script/searchscriptresultpage.module.css'

class SearchScriptsResultPage extends React.Component{

	saveruserid

	constructor(props) {


		super(props)


		this.authStore = this.props.authStore
		this.scriptStore = this.props.scriptStore

		this.queryParams = parseQuery(this.props.location.search)
		this.searchquery = this.queryParams['query']

		this.searchquery = this.searchquery.replace(/%20/gm,' ')

		console.log(this.searchquery)

		this.state = {
		// scripts: ['a','b','c','d','e','f','g','h','i','j','k'],
		
		newest: true,
		currentPage: 1,
		scriptsPerPage: 10,
		scriptsPerRequest: 100, //scriptsPerRequest should be 10x greater than scriptsPerPage
		offset: 0,
		pageSet: 0, // for keeping track of page numbers
		scripts: [],

		}

 	}


	getAllScriptSearchResults = () =>{


		Search.searchScriptsTags(this.searchquery, this.state.scriptsPerRequest, this.state.offset,'first' )
			.then( res =>{

				console.log(res.data.message)
				if(res.data.message){
					this.setState({
						scripts: res.data.message,
						

					})

				}
			})
	}

 	componentDidMount(){
		this.getAllScriptSearchResults()
		
	}
 	

 	handleClick = () => {
 		// console.log(`currentPage will be ${event.target.id}`)
 	  this.setState({
 	    currentPage: Number(event.target.id)
 	  })

 	  var {top, left} = getOffset(this.scriptlist)
 	  window.scrollTo(0, top -275)
 	}



	loadPrevPages = e =>{
		console.log(this.state.offset)
		
		this.setState({
			pageSet: this.state.pageSet - 1,
			offset: this.state.offset - this.state.scriptsPerRequest
		}, () =>{
			Search.searchScriptsTags(this.searchquery, this.state.scriptsPerRequest, this.state.offset,'less' )
				.then( res =>{

					console.log(res.data.message)
					if(res.data.message){
						this.setState({
							scripts: res.data.message,
						    currentPage: this.state.currentPage - ((this.state.pageSet +1) * Math.ceil(this.state.scriptsPerRequest / this.state.scriptsPerPage))

						})

					}
				})

		})		


		
						console.log(this.state.currentPage)
		


		// this.get_scripts(category, subcategory, 'less')
		// 	.then( () =>{
		// 		this.setState({
		// 		  currentPage: this.state.currentPage - ((this.state.pageSet +1) * this.state.scriptsPerPage)
		// 		})
		// 		console.log('PREV: currentpage!!:'+this.state.currentPage)

		// 	})


	}
	loadNextPages = e =>{


		this.setState({
			pageSet: this.state.pageSet + 1,
			offset: this.state.offset + this.state.scriptsPerRequest

		}, () =>{
			Search.searchScriptsTags(this.searchquery, this.state.scriptsPerRequest, this.state.offset,'more' )
				.then( res =>{

					console.log(res.data.message)

					console.log(`currpage ${this.state.currentPage}  pageset ${this.state.pageSet}`)
					if(res.data.message){
						this.setState({
							scripts: res.data.message,
				  			currentPage: this.state.currentPage + (this.state.pageSet * Math.ceil(this.state.scriptsPerRequest / this.state.scriptsPerPage))
						    
						})

						console.log(this.state.currentPage)


					}
				})
		})	

		console.log(this.state.offset)


		

		
	}




 	render() {
 				var i = 0
 	  const { scripts, currentPage, scriptsPerPage, scriptnames, scriptdates, pageSet } = this.state;

 	  // Logic for displaying scripts
 	  const indexOfLastScript = ((currentPage - (10*pageSet)) * scriptsPerPage) ;

 	  // console.log(pageSet)
		

 	  const indexOfFirstScript = indexOfLastScript - scriptsPerPage;
 	  const currentScripts = scripts.slice(indexOfFirstScript, indexOfLastScript);


 	  const loadPrevButtonDisabled = this.state.pageSet === 0 	  		


 	  const loadNextButtonDisabled = this.state.scripts.length < this.state.scriptsPerRequest
 	  		// || this.state.pageSet 


 	  var scriptsRender = currentScripts.map(script =>{
 			{/*<ScriptUnit key={script.id + Math.random() * 10000}
					script={script}
				/>*/}
 			var date = getTimeAgoString(script.submitdate)

 			var description = script.description
			description = description.replace(/(&nbsp|&lt)/g, '')
			description = sanitize(description, {ALLOWED_TAGS: []})


 			var tags = script.tags.map(tag =>{
 				return (
 					<LinkToTag
 						key={script.id + Math.random() * 10000}
 						tagtag={tag}
 					>
 					#{tag}
 					</LinkToTag>
 				)
 			})
			return (

				<ScriptUnit key={script.id}
					script={script}
				
				
				/>

				// <li key={script.id + Math.random() * 10000}>
 	  //   			<div>
 	  //   				<h3>{script.scriptname} | {date}</h3>
 	  //   				<p>by {script.username}</p>
 	  //   				<p>{script.categories.map(cat => `${cat} `)}</p>
 	  //   				{tags}

 	  //   				<p>{description}</p>

 	  //   				{script.script}
 	  //   			</div>
 	  //   		</li>

		 	  
				
			)

		})

 	  // Logic for displaying page numbers
 	  const pageNumbers = [];
 	  for (let i = 1 + (Math.ceil(this.state.scriptsPerRequest / scriptsPerPage) * this.state.pageSet); i <= Math.ceil(this.state.scriptsPerRequest / scriptsPerPage) * (this.state.pageSet + 1); i++) {
 	    pageNumbers.push(i);
 	  }

 	  const renderPageNumbers = pageNumbers.map(number => {
 	    return (
 	      <li
 	        key={number}
 	        id={number}
 	        onClick={this.handleClick}
 	        className={styles.pagenumberunit}
 	      >
 	        {number}
 	      </li>
 	    );
 	  });

 	  return (
 	    <div>
	
			 <div>
			 	
			 	  <p className={styles.heading}>Search Results</p>

			 	  <div className={styles.pagenumberscontainer}>
			 	  	<button onClick={this.loadPrevPages} 
			 	  		disabled={loadPrevButtonDisabled}
			 	  		className={styles.prevpagebutton}>Previous {Math.ceil(this.state.scriptsPerRequest / scriptsPerPage)} Pages
			 	  	</button>

			 	  	<ul className={styles.pagenumbers}>
			 	  	  {renderPageNumbers}
			 	  	</ul>
			 	  	<button onClick={this.loadNextPages} 
			 	  		disabled={loadNextButtonDisabled}
			 	  		className={styles.nextpagebutton}>Next {Math.ceil(this.state.scriptsPerRequest / scriptsPerPage)} Pages
			 	  	</button>
			 	  </div>

  			 	  <p className={styles.searchquery}>for <span className={styles.query}>{this.searchquery}</span>, we found...</p>

		 	      <ul className={styles.scriptscontainer} ref={ref => this.scriptlist = ref} >
		 	        {scriptsRender}
		 	      </ul>

		 	      <div className={styles.pagenumberscontainer}>
			 	  	<button onClick={this.loadPrevPages} 
			 	  		disabled={loadPrevButtonDisabled}
			 	  		className={styles.prevpagebutton}>Previous {Math.ceil(this.state.scriptsPerRequest / scriptsPerPage)} Pages
			 	  	</button>

			 	  	<ul className={styles.pagenumbers}>
			 	  	  {renderPageNumbers}
			 	  	</ul>
			 	  	<button onClick={this.loadNextPages} 
			 	  		disabled={loadNextButtonDisabled}
			 	  		className={styles.nextpagebutton}>Next {Math.ceil(this.state.scriptsPerRequest / scriptsPerPage)} Pages
			 	  	</button>
			 	  </div>

		 	</div>	
			
	 	    </div>
			 

 	  );
 	}


}



var enhance = compose(
	inject('authStore', 'scriptStore'),
	withRouter,
	observer
	)


export default enhance(SearchScriptsResultPage)

