
import React from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import { observable, decorate, toJS, when } from 'mobx'
import { sanitize } from 'dompurify'

import { compose } from 'recompose'

import { timeDifference, getTimeAgoString } from 'usefulFunctions.js'

import requests, { Messages, Users, Search } from 'axios.js'

import CreateConversationMessage from "components/topnav/CreateConversationMessage.js"

import Collapse, {UnmountClosed} from 'react-collapse'


import Tippy from '@tippyjs/react'
import 'tippy.js/themes/light.css'
     
import UserLink from 'components/profile/userlink.js'

import styles from 'styles/pages/topnav/conversationpage.module.css'

import * as ROUTES  from 'routes.js'
import Loading from 'components/animations/loading.js'

 class ConversationPage extends React.Component{


 	hostuserid
	constructor(props){

		super(props)

		this.state = {
			messages: [],
			messagesperpage: 10,
			id_last_item: 0,
			sendMessageId: 0,
			usernameField: '',
			closeMessageBox: true,

			usersSearchedTaken: [],
			showUserResults: false,
			limit: 10,

			keytimer1: null,
			delayKeyTimer: 500,
			openSearchResults: false,

			ErrorUsernameInputvisible: false,

			//loading

			searchresultsLoading: true,


		}
	}

	/* ASYNC DATA FETCH */
	/* _________________ */

	getMessages = () =>{

		// var hostuserid = this.props.authStore.authFields.id
		var hostuserid = this.props.authStore.authFields.id
		var limit = this.state.messagesperpage
		var id_last_item = this.state.id_last_item

		var how = 'first'


		console.log(`hostuserid is ${hostuserid}`)
		Messages.getMessagesForUser(hostuserid, limit, id_last_item, how)
			.then( res =>{

				if (res.data.error)  return 

				console.log(res.data.message)
				var messages = res.data.message.length > 0 ? res.data.message : ''
				var joined = this.state.messages

				if(messages !== ''){
					this.setState({
						messages: joined.concat(messages),
						id_last_item: res.data.id_last_item
					})

					var tmpstate = this.state.messages.map(t => {
						var hold = {}
						Object.assign(hold, t)
						return hold
					})

					this.state.messages.forEach((message, index) =>{
						Users.getuserfromid(message.senderuserid)
							.then( resUser =>{

								Users.getAvatar(message.senderuserid)
									.then(resAvatar =>{
										var tmp = {}
										Object.assign(tmp, message)
										
										tmp.username = resUser.data.message ? resUser.data.message : 'User Not Found'
										tmp.avatarfile = resAvatar.data.message ? resAvatar.data.message : this.props.userStore.anon.file

										tmpstate[index] = tmp


										this.setState({
											messages: tmpstate
										})


									})
								
							

							})
					})

				} 

			})
	}

	getMoreMessages = () =>{

		// var hostuserid = this.props.authStore.authFields.id
		var hostuserid = this.props.authStore.authFields.id
		var limit = this.state.messagesperpage
		var id_last_item = this.state.id_last_item

		var how = 'more'

		console.log(`hostuserid is ${hostuserid}`)
		Messages.getMessagesForUser(hostuserid, limit, id_last_item, how)
			.then( res =>{

				if (res.data.error)  return 

				console.log(res.data.message)
				var messages = res.data.message.length > 0 ? res.data.message : ''
				var joined = this.state.messages

				if(messages !== ''){
					this.setState({
						messages: joined.concat(messages),
						id_last_item: res.data.id_last_item
					})

					var tmpstate = this.state.messages.map(t => {
						var hold = {}
						Object.assign(hold, t)
						return hold
					})

					this.state.messages.forEach((message, index) =>{
						Users.getuserfromid(message.senderuserid)
							.then( resUser =>{

								Users.getAvatar(message.senderuserid)
									.then(resAvatar =>{
										var tmp = {}
										Object.assign(tmp, message)
										
										tmp.username = resUser.data.message ? resUser.data.message : 'User Not Found'
										tmp.avatarfile = resAvatar.data.message ? resAvatar.data.message : this.props.userStore.anon.file

										tmpstate[index] = tmp


										this.setState({
											messages: tmpstate
										})


									})
								
							

							})
					})

				} 

			})
	}
	/* _________________ */


	/* LIFECYCLE METHODS */
	/* _________________ */
	componentDidUpdate(){

	}

	componentDidMount(){
		var interval = null
		when( () => typeof this.props.authStore.authFields.id === 'number' ,
			  () =>{
			  	// setTimeout(this.getMessages, 250)
			  	// interval = setInterval(this.getMessages, 10000) 

			  	this.getMessages()
			  }
			)

		// when( () => this.props.authStore.authorizedUser === false,
		// 	  () =>{
		// 	  	clearInterval(interval) 
		// 	  }
		// 	)
	}
	/* _________________ */


	

	




	/* INTERNALS */
	/* _________________ */
	loadMoreMessages = e =>{
		this.getMoreMessages()
	}

	doReport = () =>{

	}

	doBlock = () =>{

	}

	onChangeUsernameField = e =>{


		this.setState({
			usernameField: e.target.value
		})

		// if(this.state.closeMessageBox === false){

		// }
	}

	// onBlurUsernameField = e =>{
	// 	var username = this.state.usernameField

	// 	if(username.length === 0 || username === undefined || username === null)
	// 		return

	// 	Users.getidfromuser(username)
	// 		.then( res =>{
	// 			console.log(res.data.message)

	// 			if(res.data.message){
	// 				this.setState({
	// 					sendMessageId: res.data.message,
	// 					closeMessageBox:false
	// 				})	
	// 			}

	// 			else{
	// 				this.setState({
	// 					closeMessageBox: true
	// 				})
	// 			}
	// 		})
	// }

	closeMessageBox = () =>{
		this.setState({
			closeMessageBox: true
		})
	}


	usernameFieldKeyUpHandler = e =>{

		var searchquery = this.state.usernameField
		var limit = this.state.limit


		// Init a timeout variable to be used below
		let timeout = this.state.keytimer1;

	
	    clearTimeout(timeout);

	    this.setState({
	    	keytimer1: setTimeout( () => {
	    	if(searchquery === '' || searchquery === undefined || searchquery === null)
	    		return
	    	Search.searchUsers(searchquery, limit)
	    		.then( res =>{
	    			if(res.data.message){
	    				var returnedusers = res.data.message

	    				console.log(returnedusers)

	    				returnedusers = this.dropdownSearchedUserUnits(returnedusers)

	    				this.setState({
	    					usersSearchedTaken: returnedusers,
	    					showUserResults: true,
	    					openSearchResults: true,
	    					closeMessageBox:false,
	    				})


	    			}

	    			else{

	    			}
	    		})
	    }, this.state.delayKeyTimer)	
	    })

	    // this.state.keytimer1 = 
	}

	searchUsername = e =>{

		var searchquery = this.state.usernameField
		var limit = this.state.limit

		var uiStore = this.props.uiStore
		if(searchquery === '' || searchquery === undefined || searchquery === null || searchquery.length > uiStore.maxUsernameSearchBarlength)
			return

		this.setState({
			openSearchResults: true,
			searchresultsLoading: true,

		})
		Search.searchUsers(searchquery, limit)
			.then( res =>{
				if(res.data.message){
					var returnedusers = res.data.message

					console.log(returnedusers)

					returnedusers = this.dropdownSearchedUserUnits(returnedusers)

					this.setState({
						usersSearchedTaken: returnedusers,
						showUserResults: true,
						// openSearchResults: true,
						closeMessageBox:false,
						ErrorUsernameInputvisible: false,
    					searchresultsLoading: false,

					})


				}

				else{
					this.setState({
						ErrorUsernameInputvisible: true
					})
				}
			})
	}

	dropdownSearchedUserUnits = (users) =>{
		// var users = this.state.usersSearchedTaken

		var instance = this

		if(users === undefined || users.length === 0)
			return

		var renderUserUnits = users.map( user =>{

			return (
				<div key={user.id + Math.random() * 10000} className={styles.dropdowncontent} onClick={() =>{ 
					instance.usernameFieldRef.value = user.username;
					
					console.log(user.id)
					instance.setState({
						usernameField: user.username,
						sendMessageId: user.id
					})
				}}>
					<p className={styles.dropdownunit}>{user.username}</p>
				</div>
			)
		})

		return renderUserUnits
	}
	/* _________________ */

	
	render(){

		var moreDisabled = this.state.messages.length < this.state.messagesperpage ? true : false

		var messages = this.state.messages.map( message =>{

			var timeago = getTimeAgoString(message.submitdate)

			return (
				<div key={message.id + Math.floor(Math.random() * 10000)} className={styles.messageunit}
>					    <Tippy className='' 
							content={ <UserLink 
											avatarfile={message.avatarfile}
											username={message.username}
											userid={message.senderuserid}
									 />}       
							interactive={true}
							// trigger='click' 
							placement='bottom' 
							theme='light'
							// visible={true}

					     >
					     <div className={styles.firstrow}>
					     	<Link className={styles.link} to={`${ROUTES.OTHERUSERPROFILEPAGE}?id=${message.senderuserid}`}>From <span className={styles.username}>{message.username}</span></Link>

					     	<small className={styles.submitdate}>{timeago}</small>
					     	
					     </div>

						</Tippy>

				     	<pre className={styles.commentbody} dangerouslySetInnerHTML={{__html : sanitize(message.message)}} ></pre>

					    {/* 	<button>report</button>
					     	<button>block</button>*/}



					

					
				</div>
			)
		})



		//render
		return (
			
			<div className={styles.maincontainer}>
				<h2 className={styles.heading}>Private Messages</h2>
				<p>({this.state.messages.length})</p>

{/*message units*/}
				{messages}


				<button className={styles.button} disabled={moreDisabled} onClick={this.loadMoreMessages}>Load more messages</button>



				<div className={styles.borderseparation}></div>

				<h2 className={styles.heading}>Send a Message</h2>

				<p>Search for a user by typing in their username.</p>
				<p>Type as many characters as you remember and search.</p>


				
{/*username field search*/}
				<div className={styles.inputcontainer}>
					<input 
					  type="text"
					  value={this.state.usernameField}
					  ref={ref => this.usernameFieldRef = ref}
					  onChange={this.onChangeUsernameField}
					  //onBlur={this.onBlurUsernameField}
					  // onKeyUp={this.usernameFieldKeyUpHandler}
					  placeholder='Search Username'
					  name='usernameField'  
	    			  className={styles.input}

					/>

					<button className="pure-button" onClick={this.searchUsername}>Search</button>

				</div>
			
				{
					this.state.ErrorUsernameInputvisible ? 
						<p>No username found.</p>
					:
						null
				}
{/*username search results*/}
				<Collapse isOpened={this.state.openSearchResults}>

					<div className={styles.dropdown}>
						{
							this.state.searchresultsLoading ?
								<Loading />
							:
								this.state.usersSearchedTaken
						}
							
					</div>
					
				</Collapse>

{/*create message box*/}
				<UnmountClosed isOpened={!this.state.closeMessageBox}>

					<CreateConversationMessage
					  	hostuserid={this.state.sendMessageId}
					  	cancelcallback={this.closeMessageBox}
					  	closeMessageBox={this.closeMessageBox}
					/>
				</UnmountClosed>
				

			</div>

		)

	}
}


var enhance = compose(
	inject('authStore', 'userStore', 'uiStore'),
	withRouter,
	observer
	)

export default enhance(ConversationPage)

