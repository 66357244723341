import React from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import { observable, decorate, when, toJS } from 'mobx'

import { compose } from 'recompose'

import requests, { Scripts } from 'axios.js'

import * as ROUTES from 'routes.js'
import { displayTimeStamp, getTimeAgoString, parseQuery, getOffset } from 'usefulFunctions.js'

import ScriptUnit from 'components/scripts/scriptunit.js'
import TagUnit from 'pages/script/mastertagunit.js'

//styles
import styles from 'styles/pages/script/tagpage.module.css'
import classnames from 'classnames'

class TagPage extends React.Component{


	constructor(props) {


		super(props)


		this.authStore = this.props.authStore
		this.scriptStore = this.props.scriptStore

		this.queryParams = parseQuery(this.props.location.search)
		this.tagid = this.queryParams['id']
		this.tagtag = this.queryParams['tag'].replace('/', '')




		this.state = {
		// scripts: ['a','b','c','d','e','f','g','h','i','j','k'],
		scripts: [],
		
		newest: true,
		currentPage: 1,
		scriptsPerPage: 10,
		scriptsPerRequest: 100,
		id_last_item: 1, //for keeping track of keyset pagination set - backend
		id_first_item: 1,
		pageSet: 0, // for keeping track of page numbers
		saveruserid: null,
	


		}

 	  
 	}

	getScriptsByTag = () =>{

		var id_first_item = this.state.id_first_item
		var id_last_item = this.state.id_last_item

		var tag = this.tagtag


		Scripts.tags.getTaggedScriptsByDateEarliest(this.state.scriptsPerRequest, 'first', id_last_item, tag)
			.then( res =>{

				console.log(res.data)
				if(res.data.message){
					this.setState({
						scripts: res.data.message,
						id_first_item: res.data.id_first_item,
						id_last_item: res.data.id_last_item

					})
				}
			})
	}

 	componentDidMount(){
 			// when(
 			// 	() =>  typeof this.props.authStore.authFields.id === 'number',
 			// 	() => {

 			// 	}
 			// )
		this.getScriptsByTag()
		
	}
 	

 	handleClick = () => {
 		// console.log(`currentPage will be ${event.target.id}`)
 	  this.setState({
 	    currentPage: Number(event.target.id)
 	  })

 	  var {top, left} = getOffset(this.scriptlist)
 	  window.scrollTo(0, top -180)
 	}


	get_newest = e =>{

		var tag = this.tagtag

		var id_first_item = this.state.id_first_item
		var id_last_item = this.state.id_last_item

		Scripts.tags.getTaggedScriptsByDateEarliest(this.state.scriptsPerRequest, 'first', id_last_item, tag)
			.then( res =>{

				console.log(res.data)
				if(res.data.message){
					this.setState({
						scripts: res.data.message,
						id_first_item: res.data.id_first_item,
						id_last_item: res.data.id_last_item,
						newest: true

					})
				}
			})
		
	}
	get_oldest = e =>{
		
		var tag = this.tagtag

		var id_first_item = this.state.id_first_item
		var id_last_item = this.state.id_last_item
		
		Scripts.tags.getTaggedScriptsByDateOldest(this.state.scriptsPerRequest, 'first', id_last_item, tag)
			.then( res =>{

				console.log(res.data)
				if(res.data.message){
					this.setState({
						scripts: res.data.message,
						id_first_item: res.data.id_first_item,
						id_last_item: res.data.id_last_item,
						newest: false

					})
				}
			})
	
	}

	loadPrevPages = e =>{

		var tag = this.tagtag

		var id_first_item = this.state.id_first_item
		var id_last_item = this.state.id_last_item
		
		this.setState({
			pageSet: this.state.pageSet - 1
		})		

		if(this.state.newest === true){
			// Scripts.tags.getTaggedScriptsByDateEarliest(userid, this.state.scriptsPerRequest, 'less', id_first_item, id_last_item)
			Scripts.tags.getTaggedScriptsByDateEarliest(this.state.scriptsPerRequest, 'less', id_last_item, tag)
				.then( res =>{

					console.log(res.data)
					if(res.data.message){
						this.setState({
							scripts: res.data.message,
							id_first_item: res.data.id_first_item,
							id_last_item: res.data.id_last_item,
						    currentPage: this.state.currentPage - ((this.state.pageSet +1) * this.state.scriptsPerPage)


						})
					}
				})
		}

		else{
			// Scripts.tags.getTaggedScriptsByDateEarliest(userid, this.state.scriptsPerRequest, 'less', id_first_item, id_last_item)
			Scripts.tags.getTaggedScriptsByDateOldest(this.state.scriptsPerRequest, 'less', id_last_item, tag)
				.then( res =>{

					console.log(res.data)
					if(res.data.message){
						this.setState({
							scripts: res.data.message,
							id_first_item: res.data.id_first_item,
							id_last_item: res.data.id_last_item,
						    currentPage: this.state.currentPage - ((this.state.pageSet +1) * this.state.scriptsPerPage)


						})
					}
				})
		}


		// this.get_scripts(category, subcategory, 'less')
		// 	.then( () =>{
		// 		this.setState({
		// 		  currentPage: this.state.currentPage - ((this.state.pageSet +1) * this.state.scriptsPerPage)
		// 		})
		// 		console.log('PREV: currentpage!!:'+this.state.currentPage)

		// 	})


	}
	loadNextPages = e =>{
		
		var tag = this.tagtag

		var id_first_item = this.state.id_first_item
		var id_last_item = this.state.id_last_item
		
		this.setState({
			pageSet: this.state.pageSet + 1,

		})	


		if(this.state.newest === true){
			// Scripts.tags.getTaggedScriptsByDateEarliest(userid, this.state.scriptsPerRequest, 'more', id_first_item, id_last_item)
			Scripts.tags.getTaggedScriptsByDateEarliest(this.state.scriptsPerRequest, 'more', id_last_item, tag)
				.then( res =>{

					console.log(res.data)
					if(res.data.message){
						this.setState({
							scripts: res.data.message,
							id_first_item: res.data.id_first_item,
							id_last_item: res.data.id_last_item,
				  			currentPage: this.state.currentPage + (this.state.pageSet * this.state.scriptsPerPage)



						})
					}
				})
		}

		else{
			// Scripts.tags.getTaggedScriptsByDateOldest(userid, this.state.scriptsPerRequest, 'more', id_first_item, id_last_item)
			Scripts.tags.getTaggedScriptsByDateOldest(this.state.scriptsPerRequest, 'more', id_last_item, tag)
				.then( res =>{

					console.log(res.data)
					if(res.data.message){
						this.setState({
							scripts: res.data.message,
							id_first_item: res.data.id_first_item,
							id_last_item: res.data.id_last_item,
				  			currentPage: this.state.currentPage + (this.state.pageSet * this.state.scriptsPerPage)



						})
					}
				})
		}

		// this.get_scripts(category, subcategory, 'more')
		// 	.then( () =>{
		// 		this.setState({
		// 		  currentPage: this.state.currentPage + (this.state.pageSet * this.state.scriptsPerPage)
		// 		})

		// 		console.log('NEXT: currentpage!!:'+this.state.currentPage)

		// 	})

	}




 	render() {
 	  const { scripts, currentPage, scriptsPerPage, scriptnames, scriptdates, pageSet } = this.state;

 	  // Logic for displaying scripts
 	  const indexOfLastScript = ((currentPage - (10*pageSet)) * scriptsPerPage) ;

		

 	  const indexOfFirstScript = indexOfLastScript - scriptsPerPage;
 	  const currentScripts = scripts.slice(indexOfFirstScript, indexOfLastScript);


 	  const loadPrevButtonDisabled = this.state.pageSet === 0 	  		


 	  const loadNextButtonDisabled = this.state.scripts.length < this.state.scriptsPerRequest


 	  const renderScripts = currentScripts.map((script, index) => {

 	  	var date = getTimeAgoString(script.submitdate)
		
 	     	    return (
 	    			
 	    			<ScriptUnit key={script.id}
 	    				script={script}
 	    			
 	    		
 	    			/>
 	     	    )
 	  });

 	  //styles



 	  // Logic for displaying page numbers
 	  const pageNumbers = [];
 	  for (let i = 1 + (Math.ceil(this.state.scriptsPerRequest / scriptsPerPage) * this.state.pageSet); i <= Math.ceil(this.state.scriptsPerRequest / scriptsPerPage) * (this.state.pageSet + 1); i++) {
 	    pageNumbers.push(i);
 	  }

 	  const renderPageNumbers = pageNumbers.map(number => {
 	    return (
 	      <li
 	        key={number}
 	        id={number}
 	        onClick={this.handleClick}
 	        className={styles.pagenumberunit}
 	      >
 	        {number}
 	      </li>
 	    );
 	  });

 	  return (
 	     	    <div className=''>
 	    		  	
 	    		  	{/*<div className={styles.adtop}>AD</div>

 	    		  	<div className={styles.adright}>AD</div>*/}

 	    		  	<div className={styles.tagunitcontainer}>
 	    		  		<TagUnit key={this.tagid + Math.random() * 10000}
 	    		  			tagtag={this.tagtag}
 	    		  			tagid={this.tagid}

 	    		  		/>
 	    		  	</div>
 	    		  	<div>
 	    		  		<span className={styles.filteroptionslabel} >Filter Options</span>
 	    		  		<button disabled={this.state.display === 'newest'} onClick={this.get_newest} className={styles.filterbutton}>Newest</button>
 	    		  		<button disabled={this.state.display === 'oldest'} onClick={this.get_oldest} className={styles.filterbutton}>Oldest</button>
 	    		  	</div>

 	    {/*pagenumbers top*/}
 	    		  <div className={styles.pagenumberscontainer}>
 	    		  	<button onClick={this.loadPrevPages} 
 	    		  		disabled={loadPrevButtonDisabled}
 	    		  		className={styles.prevpagebutton}>Previous {Math.ceil(this.state.scriptsPerRequest / scriptsPerPage)} Pages
 	    		  	</button>

 	    		  	<ul className={styles.pagenumbers}>
 	    		  	  {renderPageNumbers}
 	    		  	</ul>
 	    		  	<button onClick={this.loadNextPages} 
 	    		  		disabled={loadNextButtonDisabled}
 	    		  		className={styles.nextpagebutton}>Next {Math.ceil(this.state.scriptsPerRequest / scriptsPerPage)} Pages
 	    		  	</button>
 	    		  </div>
 	    			
 	    {/*scripts*/}
 	     	      <ul className={styles.scriptscontainer} ref={ref => this.scriptlist = ref} >
 	     	        {renderScripts}
 	     	      </ul>
 	     	      
 	    {/*pagenumbers bottom*/}
 	     	      <div className={styles.pagenumberscontainer}>
 	     	      	<button onClick={this.loadPrevPages} 
 	     	      		disabled={loadPrevButtonDisabled}
 	     	      		className={styles.prevpagebutton}>Previous {Math.ceil(this.state.scriptsPerRequest / scriptsPerPage)} Pages
 	     	      	</button>

 	     	      	<ul className={styles.pagenumbers}>
 	     	      	  {renderPageNumbers}
 	     	      	</ul>
 	     	      	<button onClick={this.loadNextPages} 
 	     	      		disabled={loadNextButtonDisabled}
 	     	      		className={styles.nextpagebutton}>Next {Math.ceil(this.state.scriptsPerRequest / scriptsPerPage)} Pages
 	     	      	</button>
 	     	      </div>

    			  {/*<div className={styles.adbottom}>AD</div>*/}

 	     	    </div>
			 

 	  );
 	}


	// constructor(props){

	// 	super(props)
	// }

	// componentDidUpdate(){

	// }

	// componentDidMount(){
		
	// }

	
	// render(){

	// 	return(
	// 		<div>
	// 			<p>This is the global feed of scripts. </p>

				
	// 		</div>

	// 	)

	// }
}



var enhance = compose(
	inject('authStore', 'scriptStore'),
	withRouter,
	observer
	)


export default enhance(TagPage)

