import React from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import { observable, decorate } from 'mobx'

import { compose } from 'recompose'

import * as ROUTES from 'routes.js'

import {Search} from 'axios.js'

import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'

import Collapse from 'react-collapse'

import Popup from 'reactjs-popup'


import styles from 'styles/components/script/searchUser.module.css'

 class SearchUser extends React.Component{

	
	constructor(props){

		super(props)
		this.state = {
			query: '',
			usersSearchedTaken: [],
			showUserResults: false,
			limit: 10,
			maxlength: this.props.uiStore.maxUsernameSearchBarlength,
			tippyvisible: false,

		}


	}

	componentDidUpdate(){

	}

	componentDidMount(){
		
	}

	onChangeInput = e =>{
		if(e.target.value.length > this.state.maxlength){
			this.setState({
				tippyvisible: true,
				query: e.target.value

			})
			return
		}


		this.setState({
			query: e.target.value,
			tippyvisible: false

		})
	}

	submitQuery = e =>{
		e.preventDefault()
		
		var { query, limit, tippyvisible } = this.state

		if(query === '' || tippyvisible === true )
			return			

		this.props.history.push(`${ROUTES.USERSEARCHRESULTSPAGE}?query=${query}`)

		setTimeout(() => window.location.reload(false) , 10 )


		
		// Search.searchUsers(query, limit)
		// 	.then( res =>{
		// 		if(res.data.message){

		// 			var returnedusers = res.data.message

		// 			console.log(returnedusers)

		// 			returnedusers = this.dropdownSearchedUserUnits(returnedusers)

		// 			this.setState({
		// 				usersSearchedTaken: returnedusers,
		// 				showUserResults: true
		// 			})

		// 			// this.state.usersSearchedTaken = returnedusers

		// 			// this.state.showUserResults = true
		// 		}
		// 	})
		

	}

	
	
	render(){

		return (
			<div onBlur={() => this.setState({showUserResults: false})}>
			
		        <form onSubmit={this.submitQuery} className="pure-form form-custom">
				    <Tippy className='navbarTooltip' 
						content= 'Search query too large.'       
						trigger='manual' //for mouseover, trigger='mouseenter'
						placement='auto' 
						visible={this.state.tippyvisible} //to include this line and programatically control tippy visible, set trigger line to trigger='manual'
						interactive={false} //allow html content
						theme='light'
				     >
				      <input type="text" 
							onChange={this.onChangeInput}
				        	placeholder="Search User" 
				      />
				    </Tippy>

				        
						<button disabled={this.state.tippyvisible} type="submit" className="pure-button button-custom"  >Search</button>

				</form>
						

		

				

					{/*
<Collapse initialStyle={{height: '0px', overflow: 'auto'}} className={styles.dropdown} isOpened={this.state.showUserResults}>

	<div className={styles.dropdowncontent} >
		{
			this.state.usersSearchedTaken
		}
			
	</div>
	
</Collapse>

					*/}
				
			</div>

		)
	}
}


var enhance = compose(
	inject('authStore', 'uiStore'),
	withRouter,
	observer
	)

export default enhance(SearchUser)

