import React from 'react'

const SVG = props => {

	return (
		<svg height={props.height} width={props.width}  viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0)">
		<path d="M9 16.5C9.82843 16.5 10.5 15.8284 10.5 15C10.5 14.1716 9.82843 13.5 9 13.5C8.17157 13.5 7.5 14.1716 7.5 15C7.5 15.8284 8.17157 16.5 9 16.5Z" fill="#BDBDBD"/>
		<path d="M15 16.5C15.8284 16.5 16.5 15.8284 16.5 15C16.5 14.1716 15.8284 13.5 15 13.5C14.1716 13.5 13.5 14.1716 13.5 15C13.5 15.8284 14.1716 16.5 15 16.5Z" fill="#BDBDBD"/>
		<path d="M21 16.5C21.8284 16.5 22.5 15.8284 22.5 15C22.5 14.1716 21.8284 13.5 21 13.5C20.1716 13.5 19.5 14.1716 19.5 15C19.5 15.8284 20.1716 16.5 21 16.5Z" fill="#BDBDBD"/>
		<path d="M0.5 15L7.5 8" stroke="#AAAAAA" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
		<path d="M0.5 15L7.5 22" stroke="#AAAAAA" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
		<path d="M29.5 15L22.5 22" stroke="#AAAAAA" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
		<path d="M29.5 15L22.5 8" stroke="#AAAAAA" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
		</g>
		<defs>
		<clipPath id="clip0">
		<rect width="30" height="30" fill="white"/>
		</clipPath>
		</defs>
		</svg>


	)
	//height={props.height} width={props.width} viewBox={`0 0 ${props.viewW} ${props.viewH}`}
	
}

export default SVG