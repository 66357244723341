

import React from 'react'

import { withRouter, Link, Redirect, Route} from 'react-router-dom'

import { decorate, observable } from 'mobx'

import { observer, inject } from 'mobx-react'

import { compose } from 'recompose'

import * as ROUTES from 'routes.js'

class ProtectedRoute extends React.Component{


// 	render() {
//   const { path, Component, authStore: {authorizedUser} } = this.props;

//   return (
//     <Route
//       path={path}
//       component={authorizedUser ? Component : () => <Redirect to={ROUTES.LANDING} />}
//     />
//   )
// }



	render(){
		var { path, Component} = this.props
		var auth = this.props.authStore

		console.log(`authuser is ${auth.authorizedUser}`)
		console.log(`emailver is ${auth.emailVerified}`)
		return(

		<Route 
		path={path}
		render ={(props) => auth.authorizedUser ? 
			<Component {...props}/>
			: <Redirect to={ROUTES.LANDING} /> }
		/>
	)
	}
	

}

export var withProtectedRoute = compose(
	inject('authStore'),
	observer,
)(ProtectedRoute)


