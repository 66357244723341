import React from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import { observable, decorate, when, toJS } from 'mobx'

import { compose } from 'recompose'

import requests, { Search } from 'axios.js'

import * as ROUTES from 'routes.js'
import { parseQuery, getTimeAgoString, slugify, getOffset } from 'usefulFunctions.js'

import LinkToTag from 'pages/script/linktotag.js'

import {sanitize} from 'dompurify'

import styles from 'styles/components/script/searchUser.module.css'

import UserLink from 'components/profile/userlink.js'

class SearchUsersResultPage extends React.Component{

	saveruserid

	constructor(props) {


		super(props)


		this.authStore = this.props.authStore
		this.scriptStore = this.props.scriptStore

		this.queryParams = parseQuery(this.props.location.search)
		this.searchquery = this.queryParams['query']

		this.searchquery = this.searchquery.replace(/%20/gm,' ')

		console.log(this.searchquery)

		this.state = {
		// users: ['a','b','c','d','e','f','g','h','i','j','k'],
		
		newest: true,
		currentPage: 1,
		usersPerPage: 10,
		usersPerRequest: 100, //usersPerRequest should be 10x greater than usersPerPage
		offset: 0,
		pageSet: 0, // for keeping track of page numbers
		users: [],

		}

 	}



	getAllUserSearchResults = () =>{

		Search.searchUsersNavbar(this.searchquery, this.state.usersPerRequest, this.state.offset,'first')
			.then( res =>{
				if(res.data.message){

					var returnedusers = res.data.message

					console.log(returnedusers)

					// returnedusers = this.dropdownSearchedUserUnits(returnedusers)

					this.setState({
						users: returnedusers,
						showUserResults: true
					})

					// this.state.usersSearchedTaken = returnedusers

					// this.state.showUserResults = true
				}
			})
 	

		// Search.searchUsersNavbar(this.searchquery, this.state.usersPerRequest, this.state.offset,'first' )
		// 	.then( res =>{

		// 		console.log(res.data.message)
		// 		if(res.data.message){
		// 			this.setState({
		// 				users: res.data.message,
						

		// 			})

		// 		}
		// 	})
	}

 	componentDidMount(){
		this.getAllUserSearchResults()
		
	}
 	

 	handleClick = () => {
 		// console.log(`currentPage will be ${event.target.id}`)
 	  this.setState({
 	    currentPage: Number(event.target.id)
 	  })

 	  // var {top, left} = getOffset(this.scriptlist)
 	  window.scrollTo(0, 0)
 	}



	loadPrevPages = e =>{
		console.log(this.state.offset)
		
		this.setState({
			pageSet: this.state.pageSet - 1,
			offset: this.state.offset - this.state.usersPerRequest
		}, () =>{
			Search.searchUsersNavbar(this.searchquery, this.state.usersPerRequest, this.state.offset,'less' )
				.then( res =>{

					console.log(res.data.message)
					if(res.data.message){
						this.setState({
							users: res.data.message,
						    currentPage: this.state.currentPage - ((this.state.pageSet +1) * Math.ceil(this.state.usersPerRequest / this.state.usersPerPage))

						})

					}
				})

		})		


		
						console.log(this.state.currentPage)
		


		// this.get_users(category, subcategory, 'less')
		// 	.then( () =>{
		// 		this.setState({
		// 		  currentPage: this.state.currentPage - ((this.state.pageSet +1) * this.state.usersPerPage)
		// 		})
		// 		console.log('PREV: currentpage!!:'+this.state.currentPage)

		// 	})


	}
	loadNextPages = e =>{


		this.setState({
			pageSet: this.state.pageSet + 1,
			offset: this.state.offset + this.state.usersPerRequest

		}, () =>{
			Search.searchUsersNavbar(this.searchquery, this.state.usersPerRequest, this.state.offset,'more' )
				.then( res =>{

					console.log(res.data.message)

					console.log(`currpage ${this.state.currentPage}  pageset ${this.state.pageSet}`)
					if(res.data.message){
						this.setState({
							users: res.data.message,
				  			currentPage: this.state.currentPage + (this.state.pageSet * Math.ceil(this.state.usersPerRequest / this.state.usersPerPage))
						    
						})

						console.log(this.state.currentPage)


					}
				})
		})	

		console.log(this.state.offset)


		

		
	}




 	render() {
 				var i = 0
 	  const { users, currentPage, usersPerPage, pageSet } = this.state;

 	  // Logic for displaying users
 	  const indexOfLastUser = ((currentPage - (10*pageSet)) * usersPerPage) ;

 	  // console.log(pageSet)
		

 	  const indexOfFirstUser = indexOfLastUser - usersPerPage;
 	  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

 	  // console.log(indexOfFirstUser)
 	  // console.log(indexOfLastUser)


 	  const loadPrevButtonDisabled = this.state.pageSet === 0 	  		


 	  const loadNextButtonDisabled = this.state.users.length < this.state.usersPerRequest
 	  		// || this.state.pageSet 


 	  var usersRender = currentUsers.map(user =>{

 			return (
 				// <div key={user.id + Math.random() * 10000} onClick={() =>{}}>
 				// 	<p >{user.username}</p>
 				// </div>
 				<div className={styles.userlinkcontainer}>
 					<UserLink 
 						avatarfile={null}
 						username={user.username}
 						userid={user.id}
 					 /> 
 				</div>
								
 			)
 			

		})

 	  // Logic for displaying page numbers
 	  const pageNumbers = [];
 	  for (let i = 1 + (Math.ceil(this.state.usersPerRequest / usersPerPage) * this.state.pageSet); i <= Math.ceil(this.state.usersPerRequest / usersPerPage) * (this.state.pageSet + 1); i++) {
 	    pageNumbers.push(i);
 	  }

 	  const renderPageNumbers = pageNumbers.map(number => {
 	    return (
 	      <li
 	        key={number}
 	        id={number}
 	        onClick={this.handleClick}
 	        className={styles.pagenumberunit}
 	      >
 	        {number}
 	      </li>
 	    );
 	  });

 	  return (
 	    <div>
			{/*<div className={styles.adtop}>AD</div>

			<div className={styles.adright}>AD</div>*/}
			 <div>
			 		
			 	  <p className={styles.heading}>Search Results</p>


			 	  <div className={styles.pagenumberscontainer}>
			 	  	<button onClick={this.loadPrevPages} 
			 	  		disabled={loadPrevButtonDisabled}
			 	  		className={styles.prevpagebutton}>Previous {Math.ceil(this.state.usersPerRequest / usersPerPage)} Pages
			 	  	</button>

			 	  	<ul className={styles.pagenumbers}>
			 	  	  {renderPageNumbers}
			 	  	</ul>
			 	  	<button onClick={this.loadNextPages} 
			 	  		disabled={loadNextButtonDisabled}
			 	  		className={styles.nextpagebutton}>Next {Math.ceil(this.state.usersPerRequest / usersPerPage)} Pages
			 	  	</button>
			 	  </div>

			 	  <p className={styles.searchquery}>for <span className={styles.query}>{this.searchquery}</span>, we found...</p>

		 	      <ul className={styles.userresultscontainer}>
		 	        {usersRender}
		 	      </ul>
		 	     


		 	      <div className={styles.pagenumberscontainer}>
		 	      	<button onClick={this.loadPrevPages} 
		 	      		disabled={loadPrevButtonDisabled}
		 	      		className={styles.prevpagebutton}>Previous {Math.ceil(this.state.usersPerRequest / usersPerPage)} Pages
		 	      	</button>

		 	      	<ul className={styles.pagenumbers}>
		 	      	  {renderPageNumbers}
		 	      	</ul>
		 	      	<button onClick={this.loadNextPages} 
		 	      		disabled={loadNextButtonDisabled}
		 	      		className={styles.nextpagebutton}>Next {Math.ceil(this.state.usersPerRequest / usersPerPage)} Pages
		 	      	</button>
		 	      </div>
		 	     


			 	</div>	
			{/*<div className={styles.adbottom}>AD</div>*/}
			
 	    </div>
			 

 	  );
 	}


}



var enhance = compose(
	inject('authStore', 'scriptStore'),
	withRouter,
	observer
	)


export default enhance(SearchUsersResultPage)

