import React from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import { observable, decorate, when, toJS } from 'mobx'

import { compose } from 'recompose'



import 'pages/test/testanima.css'

import LandingTopNav from 'components/landingtopnav.js'


import BlogPage from 'pages/blog/blogslist.js'

import SubmitBlogPage from 'pages/blog/submitblog.js'

import PageNumbers from 'components/UI/scripts/feedpagenumbers.js'

import MobileLandingPage from 'mobile/mobilelanding.js'
 class AnimaTest extends React.Component{



	constructor(props){

		super(props)

	}
	/* LIFECYCLE METHODS */
	/* _________________ */
	componentDidUpdate(){

	}

	componentDidMount(){

	}

	
	render(){
	


		return (
			<div >
				
				<MobileLandingPage />



			</div>
	
		)

	}
}


var enhance = compose(
	inject('authStore'),
	withRouter,
	observer
	)

export default enhance(AnimaTest)

