import React, {lazy, Suspense} from 'react'

import LandingTopNav from 'components/landingtopnav.js'

import { Link, NavLink, withRouter } from 'react-router-dom'



import Loading from 'components/animations/loading.js'

import * as ROUTES from 'routes.js'


 /* IMAGES */

import ComputerImage from 'images/landing/1.jpg'
import AHKGithubImage from 'images/landing/ahkgithubpic.png'
import AHKpic from 'images/landing/ahkpic.png'
import Derp1 from 'images/landing/derp1.png'
import Derp2 from 'images/landing/derp2.png'

import Examples1 from 'images/landing/examples1.png'
import Examples2 from 'images/landing/examples2.png'
import Examples3 from 'images/landing/examples3.png'


import DashedArrow from 'icons/landing/dashed-arrow.png'


/* VIDEOS */

// import ShowoffVid from 'images/landing/Finalvidrealready-min4.mp4'

/* ICONS */
import BullsEyeIcon from 'images/landing/target.png'
import ScriptMimeLogo from 'icons/topnav/logo.js'
import FeedIcon from 'icons/landing/feedicon.js'
import TagIcon from 'images/landing/tagicon.png'


/* BACKGROUNDS  */

import {renderToStaticMarkup} from 'react-dom/server'

import Background1 from 'icons/landing/backgroundlanding1.js'
import EndlessConstellationBackground from 'icons/landing/endlessconstellation.js'


const MimeSurprised = lazy( () => import('icons/landing/mimesurprised.js') )
const MimeHappy = lazy( () => import('icons/landing/mimehappy.js') )

//styles
import styles from 'mobile/mobilelanding.module.css'
import classnames from 'classnames'


function Root(){
	
	const background1string = encodeURIComponent(renderToStaticMarkup(<Background1/>))
	const background2string = encodeURIComponent(renderToStaticMarkup(<EndlessConstellationBackground/>))

	return (
		<div >

			<div className={styles.container}>
				
				<Link className={styles.logo} to={ROUTES.LANDING}>
						<ScriptMimeLogo className='logo' width='90px' height='60px' /> 
					</Link>

				<div style={{backgroundImage: `url("data:image/svg+xml, ${background2string}")`}}>
					
					
					<div><p className={styles.mobileusernotice}>Mobile Users</p> <p className={styles.mobileusernoticecontent}>ScriptMime is best viewed on computer. Most features not available on mobile.</p></div>
					<h3 >ScriptMime is a site for sharing ready and working Autohotkey scripts.</h3>

					<div>
						<div  >
							<div className={styles.mainsubheading1}  >Uses the award-winning, open source, free scripting language Autohotkey.</div>
						</div>
						<div >
							<div className={styles.mainsubheading2}  >Built with a ton of features for finding the scripts you need fast.</div>

							<div className={styles.overview} >
								<div className={styles.overviewrow}> 
									<img src={BullsEyeIcon} width="48px" height="48px" alt=""/>
									<div>Powerful search</div>  
								</div>

								<div className={styles.overviewrow}> 
									<FeedIcon width="48px" height="48px"/>
									<div>Customized feeds built around Community</div>  
								</div>

								<div className={styles.overviewrow}> 
									<img src={TagIcon} width="42px" height="42px" alt=""/>
									<div><span >#</span>tags to group scripts that are alike</div>  
								</div>
								<Link to={ROUTES.INFOLANDING} className={styles.linklearnmore} >Learn More</Link>

							</div>
						</div>
					</div>

				<div  style={{backgroundImage: `url("data:image/svg+xml, ${background1string}")`}}> 
				
					
					<p >Autohotkey lets you create free programs or “scripts”  that simplify and automate your workflow or do just about anything.</p>

					<Suspense fallback={<Loading />}>
							<iframe className={styles.promotionalvid} height="300px" src="https://www.youtube.com/embed/jBAauyKvhAM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>		
					</Suspense>
				</div>

				
				

					<div > 
						{/*<img src={Derp1} width="134px" height="134px" alt=""/>*/}

						<Suspense fallback={<Loading />}>
							<MimeSurprised width="250px" height="368px" />
						</Suspense>
						<div className={styles.mimesubheading} >Do I need to know code?</div>  

					</div>
					<div className={styles.answermime} ><span>ScriptMime is a place to find scripts to plug and play.</span></div>

					<div > 
						{/*<img src={Derp2} width="134px" height="134px" alt=""/>*/}
						<Suspense fallback={<Loading />}>
							<MimeHappy width="250px" height="368px" />
						</Suspense>
						<div className={styles.mimesubheading} >Can I (a non-techy person) use ScriptMime and Autohotkey?</div>  
					</div>

					<div className={styles.answermime1} >Yes!</div>


				</div>

				<div >If you are...</div>


				<div className={styles.examplescontainer}> 

					<div className={styles.exampleunit}> 
						<img src={Examples1} className={styles.examplesimage} alt=""/>
						<div className={styles.exampleunitsubheading}>A pro player looking for a competitive edge, or a newbie wanting to take his game to the next level.</div>  
						<div className={styles.exampleunitcontent}>Autohotkey scripts can be used for any PC game you can think of, from First Person Shooters to single player adventures.
						 From autoclickers to automatic shield blocks, to resource collectors, auto miners, and keyboard remapping. 
						Find scripts for your favorite games. Just make sure it won’t get you into trouble :).
						</div>  

					</div>

					<div className={styles.exampleunit}> 
						<img src={Examples2} className={styles.examplesimage} alt=""/>
						<div className={styles.exampleunitsubheading}>A podcaster looking to UP his video editing workflow.</div>  
						<div className={styles.exampleunitcontent}>Look for scripts that can do anything from instantly launch the all the programs you need at the same time, 
						to moving them across multiple monitors at the touch of a keyboard. Hop between active windows like a pro. 
						Get or create a shortcut to turn 10 steps of your work flow into 1 key press.
						</div>  

					</div>

					<div className={styles.exampleunit}> 
						<img src={Examples3} className={styles.examplesimage} alt=""/>
						<div className={styles.exampleunitsubheading}>A working professional looking to answer emails, complete tasks, and  manage their environment easier.</div>  
						<div className={styles.exampleunitcontent}>Find scripts to automatically insert jargon and repetitive phrases. 
						Get scripts that create triggers for things that happen on the job, shortcuts to move around your PC without all that clicking.
						</div>  

					</div>

					<div >... And more!</div>


				</div>

				
				<div > 

					

					<div > 
						<Link className={styles.linklearnmore} to={ROUTES.INFOLANDING} >Features & Walkthrough</Link>
						<div >See how ScriptMime works, learn about how to use the features you need</div>  

					</div>

					

				</div>
				

			</div>


		</div>
	
	)
	
}

export default Root