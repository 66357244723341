
import React, {lazy, Suspense} from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import { observable, decorate, toJS, when } from 'mobx'
import { sanitize } from 'dompurify'

import { compose } from 'recompose'

import requests, { Messages } from 'axios.js'

import Loading from 'components/animations/loading.js'



const RichTextEditor = lazy( () => import('components/scripts/ckeditor.js') )


 class CreateConversationMessage extends React.Component{


	cancelcallback
	closeMessageBox
	hostuserid // person being sent message to

	constructor(props){

		super(props)

		this.state = {
			description: '',
			submitDisabled: false,
		}
	}

	/* ASYNC DATA FETCH */
	/* _________________ */


	/* _________________ */


	/* LIFECYCLE METHODS */
	/* _________________ */
	componentDidUpdate(){

	}

	componentDidMount(){

	}
	/* _________________ */



	/* INTERNALS */
	/* _________________ */

	submitComment = e =>{

		var commentbody = this.state.description

		var hostuserid = this.props.hostuserid
		var senderuserid = this.props.authStore.authFields.id

		commentbody = sanitize(commentbody)
		// var userid = this.props.authStore.authFields.id

		if(commentbody.length === 0)
			return



		Messages.sendMessageToUser(hostuserid, senderuserid, commentbody)
			.then( res =>{ 
				console.log( res.data.message)
				this.props.commentStore.clearRichTextEditor()
				this.props.closeMessageBox()
				// window.location.reload(false)

			})

		
	}

	getDescription = description =>{
		// this.formState.description = description

		if(description.length > this.props.uiStore.maxPrivateMessageLength){

			this.setState({
				description: description,
				submitDisabled: true
			})
		}
		else{
			this.setState({
				description: description,
				submitDisabled: false,
			})

		}
		
	}

	cancel = e =>{

		this.props.commentStore.clearRichTextEditor()
		
		this.props.cancelcallback ? this.props.cancelcallback() : ''
	}
	/* _________________ */

	
	render(){

		return(
			<div >


				<Suspense fallback={<Loading />}>
					<RichTextEditor
						contentCallback={this.getDescription}					
						description=' '
						cancelcallback={() => this.setState({showCommentBox: false})}
					/>
				</Suspense>
				<button disabled={this.state.submitDisabled} onClick={this.submitComment}>Submit</button>
				<button onClick={this.cancel}>Cancel</button>
				{
					this.state.description.length > this.props.uiStore.maxPrivateMessageLength 
					?
						<p>message too long. max {this.props.uiStore.maxPrivateMessageLength} char. </p>
					:
						null
				}



			</div>

		)

	}
}


var enhance = compose(
	inject('authStore', 'commentStore', 'uiStore'),
	withRouter,
	observer
	)

export default enhance(CreateConversationMessage)

