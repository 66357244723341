import React from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import { observable, decorate } from 'mobx'

import { compose } from 'recompose'

import requests, { Scripts } from 'axios.js'


import * as ROUTES from 'routes.js'
import { displayTimeStamp, getTimeAgoString, getOffset } from 'usefulFunctions.js'

//styles
import styles from 'styles/pages/script/feed.module.css'
import ScriptUnit from 'components/scripts/scriptunit.js'

import classnames from 'classnames'

class ProfileScriptFeed extends React.Component{

	authStore
	scriptStore
	username
	constructor(props) {

		/*   PROPS    */
		
		// username .. string
		// ScriptsMethodArguments: array []


		super(props)

		var { author } = this.props
		this.username = author

		console.log('username:'+this.username)


		this.authStore = this.props.authStore
		this.scriptStore = this.props.scriptStore

		this.state = {
		// scripts: ['a','b','c','d','e','f','g','h','i','j','k'],
		scripts: [],
		
		display: 'newest',
		currentPage: 1,
		scriptsPerPage: 10,
		scriptsPerRequest: 100,
		id_last_item: null, //for keeping track of keyset pagination set - backend
		id_first_item: null,
		pageSet: 0, // for keeping track of page numbers
		id_first_item_categories: [],
		id_first_item_subcategoriess: [],
		authorid: null
		// DefaultGetScriptsMethod: DefaultGetScriptsMethod,
		// ScriptsMethodArguments: ScriptsMethodArguments

		}

 	  
 	}


 	scriptObject = scriptArray =>{

 		// console.log(scriptArray)

 		if(typeof scriptArray !== 'object')
 			return {fetched_scripts: []}

		var fetched_scripts = scriptArray.map(script =>{
			return script
		})

		

		return {fetched_scripts: fetched_scripts}
	}

 	componentDidMount(){


	
 		requests.post(`${ROUTES.API.getid}`, {username: this.username, email: 'not_needed'})
 		  .then( resId =>{
			

 		  	this.setState({
 		  		authorid: resId.data.id
 		  	})

  	 		Scripts.author.byDateEarliest(1, this.scriptsPerRequest, 'first', null, this.state.authorid)
  	 			.then( resAuthor => {
  	 				console.log(resAuthor.data.message)

  	 				var {fetched_scripts} = this.scriptObject(resAuthor.data.message)
  	 				// var id_first_item = this.state.id_last_item
  					
  					var categoriesObj = {}
  					var subcategoriesObj = {}

  					this.scriptStore.categories.forEach(category =>{
  						categoriesObj[category] = null
  					})

  					this.scriptStore.sub_categories.forEach(subcategory =>{
  						subcategoriesObj[subcategory] = null
  	 						
  	 					})


  	 				this.setState({
  	 					scripts: fetched_scripts,
  	 					
  	 					id_last_item: resAuthor.data.id_last_item,
  	 					id_first_item: resAuthor.data.id_first_item,
  	 					id_first_item_categories: categoriesObj,
  	 					id_first_item_subcategoriess: subcategoriesObj
  	 						

  		 			})

  		 			
  	 			})
 		  })

 		

				

	
		
	}
 	

 	handleClick = (e) => {
 		// console.log(`currentPage will be ${event.target.id}`)

 	  this.setState({
 	    currentPage: Number(event.target.id)
 	  })

 	  var {top, left} = getOffset(this.scriptlist)
 	  window.scrollTo(0, top -150)

 	}

	categoryPressCheck = () =>{
		if(this.scriptStore.selectedCategory !== null && this.scriptStore.selectedSubcategory !== null )
			return 'both'
		else if(this.scriptStore.selectedCategory === null && this.scriptStore.selectedSubcategory === null )
			return 'neither'

		else if(this.scriptStore.selectedCategory !== null && this.scriptStore.selectedSubcategory === null )
			return 'category'
		else if(this.scriptStore.selectedSubcategory !== null && this.scriptStore.selectedCategory === null)
			return 'subcategory'
	}

	get_scripts = (category, subcategory, less_or_more, id_first_item = 1, id_last_item = 1) =>{

		// console.log(`category: ${category}, subcategory: ${subcategory}`)

		switch(this.categoryPressCheck()){
			case 'both':
				Scripts.author.byCategoryAndSubcategory(1, this.state.scriptsPerRequest, less_or_more, this.state.id_last_item, category, subcategory, this.state.authorid )
					.then( res => {

						
						var {fetched_scripts} = this.scriptObject(res.data.message)

		 				this.setState({
		 					scripts: fetched_scripts,
		 					
		 					id_last_item: res.data.id_last_item,
		 					id_first_item: res.data.id_first_item
		 				})

					})
				break;
			case 'category':
				Scripts.author.byCategory(1, this.state.scriptsPerRequest, less_or_more, this.state.id_last_item, category, this.state.authorid )
					.then( res => {
				
						var {fetched_scripts} = this.scriptObject(res.data.message)

		 				this.setState({
		 					scripts: fetched_scripts,
		 					
		 					id_last_item: res.data.id_last_item,
		 					id_first_item: res.data.id_first_item
		 				})

					})
				break;
			case 'subcategory':
				Scripts.author.bySubcategory(1, this.state.scriptsPerRequest, less_or_more, this.state.id_last_item, subcategory, this.state.authorid )
					.then( res => {

						var {fetched_scripts} = this.scriptObject(res.data.message)

		 				this.setState({
		 					scripts: fetched_scripts,
		 					
		 					id_last_item: res.data.id_last_item,
		 					id_first_item: res.data.id_first_item
		 				})

					})
				break;
			case 'neither':

				Scripts.author.byDateEarliest(1, this.state.scriptsPerRequest, less_or_more, this.state.id_last_item, this.state.authorid) 
					.then( res => {
						

						console.log(res.data)

						var {fetched_scripts} = this.scriptObject(res.data.message)



		 				this.setState({
		 					scripts: fetched_scripts,
		 					
		 					id_last_item: res.data.id_last_item,
		 					id_first_item: res.data.id_first_item
		 				})

					})
				break;


		}

		return Promise.resolve()

	}


	categoryClick = e =>{

		this.scriptStore.selectedCategory = e.target.innerText

		var category = this.scriptStore.selectedCategory
		var subcategory = this.scriptStore.selectedSubcategory


		// var tagsarr = this.state.id_first_item_tags
		// console.log(`category: ${category}, subcategory: ${subcategory}`)
		this.get_scripts(category, subcategory, 'first')
			
		// if(this.state.id_first_item_tags[category] === null){
			
		// 	this.get_scripts(category, subcategory, 'first')
		// }		

	}

	subcategoryClick = e =>{
		this.scriptStore.selectedSubcategory = e.target.innerText

		var category = this.scriptStore.selectedCategory
		var subcategory = this.scriptStore.selectedSubcategory

		// var subcategorysarr = this.state.id_first_item_subcategorys
		
		this.get_scripts(category, subcategory, 'first')


	}

	clearCategory = e =>{
		this.scriptStore.selectedCategory = null

		var category = this.scriptStore.selectedCategory

		var subcategory = this.scriptStore.selectedSubcategory 


		this.get_scripts(category, subcategory, 'first')

	}

	clearSubcategory = e =>{
		this.scriptStore.selectedSubcategory = null

		var category = this.scriptStore.selectedCategory

		var subcategory = this.scriptStore.selectedSubcategory 

		
		this.get_scripts(category, subcategory, 'first')

	}

	get_newest = e =>{

		var { scripts, display } = this.state


		if(display === 'newest')
			return

		var reverse_scripts = scripts.reverse()
	

		this.setState({
			scripts: reverse_scripts,
		
			display: 'newest'
		})


		
	}
	get_oldest = e =>{

		var { scripts, display } = this.state

		if(display === 'oldest')
			return

		var reverse_scripts = scripts.reverse()
	


		this.setState({
			scripts: reverse_scripts,
		
			display: 'oldest'
		})
	}

	loadPrevPages = e =>{
		
		this.setState({
			pageSet: this.state.pageSet - 1
		})		

		var category = this.scriptStore.selectedCategory

		var subcategory = this.scriptStore.selectedSubcategory 

		this.get_scripts(category, subcategory, 'less')
			.then( () =>{
				this.setState({
				  currentPage: this.state.currentPage - ((this.state.pageSet +1) * this.state.scriptsPerPage)
				})
				console.log('PREV: currentpage!!:'+this.state.currentPage)

			})


	}
	loadNextPages = e =>{
		
		this.setState({
			pageSet: this.state.pageSet + 1,

		})	

		
		var category = this.scriptStore.selectedCategory

		var subcategory = this.scriptStore.selectedSubcategory 

		this.get_scripts(category, subcategory, 'more')
			.then( () =>{
				this.setState({
				  currentPage: this.state.currentPage + (this.state.pageSet * this.state.scriptsPerPage)
				})

				console.log('NEXT: currentpage!!:'+this.state.currentPage)

			})

	}


 	render() {
 	  const { scripts, currentPage, scriptsPerPage, scriptnames, scriptdates, pageSet } = this.state;

 	  // Logic for displaying scripts
 	  const indexOfLastScript = ((currentPage - (10*pageSet)) * scriptsPerPage) ;

 
 	  const indexOfFirstScript = indexOfLastScript - scriptsPerPage;
 	  const currentScripts = scripts.slice(indexOfFirstScript, indexOfLastScript);



 	  const loadPrevButtonDisabled = this.state.pageSet === 0 	  		


 	  const loadNextButtonDisabled = this.state.scripts.length < this.state.scriptsPerRequest


 	  const renderScripts = currentScripts.map((script, index) => {
 
		
 	    return (
			
			<ScriptUnit key={script.id}
				script={script}
			
		
			/>
 	    )
 	  });

 	  // Logic for displaying page numbers
 	  var pageNumbers = [];
 	  for (let i = 1 + (Math.ceil(this.state.scriptsPerRequest / scriptsPerPage) * this.state.pageSet); i <= Math.ceil(this.state.scriptsPerRequest / scriptsPerPage) * (this.state.pageSet + 1); i++) {
 	    pageNumbers.push(i);
 	  }

 	  pageNumbers = pageNumbers.filter(pagenum => pagenum <= Math.ceil(this.state.scripts.length / 10))

 	  const renderPageNumbers = pageNumbers.map(number => {
 	   	  	const pagenumberclass = classnames(styles.pagenumberunit, {[styles.pagenumberunitpressed]: number === this.state.currentPage })
 	   	    return (
 	   	      <li
 	   	        key={number}
 	   	        id={number}
 	   	        onClick={this.handleClick}
 	   	        className={pagenumberclass}
 	   	      >
 	   	        {number}
 	   	      </li>
 	   	    );
 	   	  });

 	  return (
 	    <div>
		
					  	<div className={styles.filtercontainer}>
			{/*Filter new/old*/}
					  		<div>
					  			<span className={styles.filteroptionslabel} >Filter By Date </span>
					  			<button disabled={this.state.display === 'newest'} onClick={this.get_newest} className={styles.filterbutton}>Newest</button>
					  			<button disabled={this.state.display === 'oldest'} onClick={this.get_oldest} className={styles.filterbutton}>Oldest</button>
					  		</div>
			{/*clear*/}
					  		<div>
						  		<span className={styles.filteroptionslabel}>Clear</span>
						  		<button onClick={this.clearCategory} className={styles.filterbutton}>Clear Category</button>
						  		<button onClick={this.clearSubcategory} className={styles.filterbutton}>Clear Subcategory</button>	
					  		</div>
					  		
			{/*filter categories*/}
					  		<h4>Categories</h4>
					  		<div className={styles.categoriescontainer}>
					  			{this.scriptStore.categories.map((category, index) =>{
					  				//styles
					  				const categorybuttonclass = classnames(styles.categorybutton, {[styles.categorybuttonpressed]: this.props.scriptStore.selectedCategory === category})
					  					return(
					  						<div key={index} className="">
					  							<button onClick={this.categoryClick} className={categorybuttonclass} >{category}</button>
					  						</div>
					  					)
					  				})}
					  		</div>
			{/*filter subcategories*/}
					  		<h4>Subcategories</h4>
					  		<div className={styles.subcategoriescontainer}>
					  			{this.scriptStore.sub_categories.map((category, index) =>{
					  				const categorybuttonclass = classnames(styles.categorybutton, {[styles.categorybuttonpressed]: this.props.scriptStore.selectedSubcategory === category})

					  				return(
					  					<div key={index} className="">
					  						<button onClick={this.subcategoryClick} className={categorybuttonclass} >{category}</button>
					  					</div>			
					  				)
					  			})}	
					  		</div>
					  		

					  	</div>
	
			 <div>
			 	  <div className={styles.pagenumberscontainer}>
			 	  	<button onClick={this.loadPrevPages} 
			 	  		disabled={loadPrevButtonDisabled}
			 	  		className={styles.prevpagebutton}>Previous {Math.ceil(this.state.scriptsPerRequest / scriptsPerPage)} Pages
			 	  	</button>

			 	  	<ul className={styles.pagenumbers}>
			 	  	  {renderPageNumbers}
			 	  	</ul>
			 	  	<button onClick={this.loadNextPages} 
			 	  		disabled={loadNextButtonDisabled}
			 	  		className={styles.nextpagebutton}>Next {Math.ceil(this.state.scriptsPerRequest / scriptsPerPage)} Pages
			 	  	</button>
			 	  </div>

		 	      <ul ref={ref => this.scriptlist = ref}>
		 	        {renderScripts}
		 	      </ul>

		 	      <div className={styles.pagenumberscontainer}>
		 	      	<button onClick={this.loadPrevPages} 
		 	      		disabled={loadPrevButtonDisabled}
		 	      		className={styles.prevpagebutton}>Previous {Math.ceil(this.state.scriptsPerRequest / scriptsPerPage)} Pages
		 	      	</button>

		 	      	<ul className={styles.pagenumbers}>
		 	      	  {renderPageNumbers}
		 	      	</ul>
		 	      	<button onClick={this.loadNextPages} 
		 	      		disabled={loadNextButtonDisabled}
		 	      		className={styles.nextpagebutton}>Next {Math.ceil(this.state.scriptsPerRequest / scriptsPerPage)} Pages
		 	      	</button>
		 	      </div>

		 	</div>	
			
	 	    </div>
			 

 	  );
 	}


	// constructor(props){

	// 	super(props)
	// }

	// componentDidUpdate(){

	// }

	// componentDidMount(){
		
	// }

	
	// render(){

	// 	return(
	// 		<div>
	// 			<p>This is the global feed of scripts. </p>

				
	// 		</div>

	// 	)

	// }
}



var enhance = compose(
	inject('authStore', 'scriptStore'),
	withRouter,
	observer
	)


export default enhance(ProfileScriptFeed)

