
/*
          _______  _______  _______           _       
                |\     /|(  ____ \(  ____ \(  ____ \|\     /|( \      
                | )   ( || (    \/| (    \/| (    \/| )   ( || (      
                | |   | || (_____ | (__    | (__    | |   | || |      
                | |   | |(_____  )|  __)   |  __)   | |   | || |      
                | |   | |      ) || (      | (      | |   | || |      
                | (___) |/\____) || (____/\| )      | (___) || (____/\
                (_______)\_______)(_______/|/       (_______)(_______/
                                                                      
                 _______           _        _______ __________________ _______  _       
                (  ____ \|\     /|( (    /|(  ____ \\__   __/\__   __/(  ___  )( (    /|
                | (    \/| )   ( ||  \  ( || (    \/   ) (      ) (   | (   ) ||  \  ( |
                | (__    | |   | ||   \ | || |         | |      | |   | |   | ||   \ | |
                |  __)   | |   | || (\ \) || |         | |      | |   | |   | || (\ \) |
                | (      | |   | || | \   || |         | |      | |   | |   | || | \   |
                | )      | (___) || )  \  || (____/\   | |   ___) (___| (___) || )  \  |
                |/       (_______)|/    )_)(_______/   )_(   \_______/(_______)|/    )_)
                                                                                        
 _______ 
(



*/
// ARGS: ref to element
// description: get the X, Y coordinates of any element.

import datetimeDifference from 'datetime-difference'

export function getOffset( el ) {
    var _x = 0;
    var _y = 0;
    while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
        _x += el.offsetLeft - el.scrollLeft;
        _y += el.offsetTop - el.scrollTop;
        el = el.offsetParent;
    }
    return { top: _y, left: _x };
}

//same as above, less performant but more accurate
export function getOffset2(el) {
  const rect = el.getBoundingClientRect();
  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY
  };
}


//receives input string such as '?stuff=x&stuff2=y' and outputs 
//object {stuff: x, stuff2: y}
export function parseQuery( string ){

    var working_string = string.replace('?', '').split('&')

    var new_string = working_string.map( (element, index) =>{
        return element.split('=')
    }).flat()


    var keys = new_string.filter((element, index) => {
        return index % 2 === 0
    })

    var values = new_string.filter((element, index) => {
        return index % 2 !== 0
    })

    var obj = {}
    
    for(var i =0; i < keys.length; i++){
        obj[keys[i]] = values[i]
    }
    return obj
}


//wrapper function to make a lazy load component
export function LazyComponent(Component) {
  return props => (
    <Suspense fallback={<Loading />}>
      <Component {...props} />
    </Suspense>
  );
}

//takes in a timestamp from postgres and converts to 
//string in format 'DD MM YYYY HH:MM:SS'
export function displayTimeStamp(timestamp){
    if(timestamp === undefined || timestamp === '' || timestamp === null)
        return
    var working_timestamp = timestamp.replace('T', ' ').split(' ')

    // console.log(`timestamp is ${working_timestamp[0]}`)


    var date = working_timestamp[0].split('-')

    // console.log(`date is ${date[1]}`)

    var new_date = `${date[1]} ${date[2]} ${date[0]}` // 2020 08 20 ---> 08 20 2020
    var time = working_timestamp[1].split(':')

    var new_time = `${time[0]}:${time[1]}:${time[2].slice(0, time[2].length - 5)}`

    // return `${new_date} , ${new_time}`
    return `${new_date}`

}
//no longer used
export function displayTimeStamp2(timestamp){
    if(timestamp === undefined || timestamp === '' || timestamp === null)
        return

    var working = timestamp.split('-')

    var date = working.slice(0,3)

    var time = working.slice(3,6)


    var final = `${date[0]} ${date[1]} ${date[2]} , ${time[0]}:${time[1]}`
    return final
}
//takes in timestamp from postgres and a time to compare , current time
//gives the difference in time between the two in seconds, minutes, or hours depending on which is greater
export function timeDifference(timestamp){

    var date = timestamp.slice(0,11).replace(/[-]/g, '/').replace(/[T\s]/g, '')
    var time = timestamp.slice(11,20).replace(/[\s\.]/g, '')

    // var timearray = time.replace(/[\sa-zA-z\.]/g, '').split(':')
    // var datearray = date.replace(/[\sa-zA-z\.]/g, '').split('-')


    //these two lines assume server is in Hetzner cloud, Nuremberg time
 
    var currtime = new Date().toLocaleTimeString("en-US", {hour12: false, timeZone: 'Europe/Berlin'})
    var currdate = new Date().toLocaleDateString("en-US", {timeZone: 'Europe/Berlin'})


    // var currtime = new Date().toLocaleTimeString("en-US", {hour12: false})
    // var currdate = new Date().toLocaleDateString("en-US")

    var timecorrection = currtime.toString().slice()
    if(currtime[0] === '2' && currtime[1] === '4'){
        timecorrection = timecorrection.replace(/^.{2}/g, "00")

    }


    var currdatetime = `${currdate},${timecorrection}`

    // console.log(currdatetime)

    var serverdatetime = `${date},${time}`


    serverdatetime = new Date(serverdatetime);
    currdatetime = new Date(currdatetime);

    const result = datetimeDifference(serverdatetime, currdatetime); 

    // console.log(result)
    var { years, hours, minutes, months, seconds, days} = { ...result }

    //SAME DAY
    if(years === 0 && months === 0 && days === 0){
        //highest result is seconds
        if(hours*1 === 0 && minutes*1 === 0){
            return {seconds: seconds}
        }
        //highest result is minutes
        else if(hours*1 === 0){
            return {minutes: minutes}
        }
        //highest result is hours
        else{
            return {hours: hours}
        }
    }

    //24 hours or more
    else if(years === 0 && months === 0){
        return {days: days}
    }
    else if(years === 0)
        return {months: months}

    //for years return exact date
    else{
        return {exactdate: {years: years, months: months, days: days}}
    }
}

//piggy backs on above function, will return the appropriate '2 days ago' type string given a timestamp
export function getTimeAgoString(timestamp){
    var date_times = ['exactdate', 'months', 'days', 'hours', 'minutes', 'seconds']

    var timediffresult = timeDifference(timestamp)
    var timediff

    date_times.forEach( date_time =>{
        if(timediffresult.hasOwnProperty(date_time)){
            if(date_time !== 'exactdate'){
                timediff = `${timediffresult[date_time]} ${date_time} ago`

            }
            else{

                var temparrexactdate = []
                for( const property in timediffresult)
                    temparrexactdate.push(property)

                timediff = `${timediffresult[temparrexactdate[0]]} ${temparrexactdate[0]}, ${timediffresult[temparrexactdate[1]]} ${temparrexactdate[1]} ${timediffresult[temparrexactdate[2]]} ${temparrexactdate[2]} ago`

            }
        }
    })

    return timediff
}





// turn a URL to a slug

export function slugify(string){
    return string
        .toString()
        .toLowerCase()
        .replace(/\s+/g, "-")
        .replace(/[^\w\-]+/g, "")
        .replace(/\-\-+/g, "-")
        .replace(/^-+/, "")
        .replace(/-+$/, "")
}

//helper function to get indeces of all occurrences of a substring in a string
export function getIndicesOf(searchStr, str, caseSensitive) {
            var searchStrLen = searchStr.length;
            if (searchStrLen == 0) {
                return [];
            }
            var startIndex = 0, index, indices = [];
            if (!caseSensitive) {
                str = str.toLowerCase();
                searchStr = searchStr.toLowerCase();
            }
            while ((index = str.indexOf(searchStr, startIndex)) > -1) {
                indices.push(index);
                startIndex = index + searchStrLen;
            }
            return indices;
        }