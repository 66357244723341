import React from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import { observable, decorate, when, toJS } from 'mobx'

import { compose } from 'recompose'

import requests, {Users, Comments, Scripts} from 'axios.js'

import * as ROUTES from 'routes.js'

import Collapse from 'react-collapse'

import GamingSVG from 'icons/circularnav/joystick.js'
import ProductivitySVG from 'icons/circularnav/graph.js'
import JustforfunSVG from 'icons/circularnav/party_popper.js'
import SchoolworkSVG from 'icons/circularnav/school.js'
import EditingcreativeSVG from 'icons/circularnav/lightbulb.js'
import SoftwareSVG from 'icons/circularnav/dvd.js'

import AutomationsSVG from 'icons/circularnav/automations.js'
import ShortcutsSVG from 'icons/circularnav/shortcuts.js'
import GeneralSVG from 'icons/circularnav/hammer.js'
import GUISVG from 'icons/circularnav/GUI.js'

import { displayTimeStamp2, displayTimeStamp, slugify, getTimeAgoString } from 'usefulFunctions.js'


import styles from 'styles/components/script/scriptunit.module.css'

import Tippy from '@tippyjs/react'
import 'tippy.js/themes/light.css'
     
import UserLink from 'components/profile/userlink.js'
        
import LinkToTag from 'pages/script/linktotag.js'    


import AddItem from 'icons/general/additem.js'

import DeleteScriptButton from 'components/scripts/deleteScript.js'

 class ScriptUnit extends React.Component{

	authStore
	scriptStore
	// props

	script

	// scriptid
	// userid
	// tags
	// scriptname
	// submitdate
	



	constructor(props){

		super(props)

		this.authStore = this.props.authStore
		this.scriptStore = this.props.scriptStore

		// console.log(this.props.script)

		this.state ={
			author: '',
			thumbnail: undefined,
			descriptionlimit: 400,
			avatarfile: '',
			numbercomments: 0,
			tippy: {visible: false, content: 'TEST'},
			tippySave: {visible: false, content: 'TEST'},

			votecount: 0,
			saved: 'unsaved',
			savedscriptid: 0,
			showDescription: false,
			showDeleteButton: false,
			thumbnailtype: ''
		}
	}

	/* ASYNC DATA FETCH */
	/* _________________ */


	getAuthor = () =>{
		requests.post(`${ROUTES.API.getuserfromid}`, {userid: this.props.script.userid})
			.then( res =>{

				this.setState({
					author: res.data.message
				})
			})
	}

	getAvatar = () =>{
		Users.getAvatar(this.props.script.userid)
			.then( res =>{
				
				this.setState({
					avatarfile: res.data.message ? res.data.message : this.props.userStore.anon.file
				})

			})
	}

	getScriptFiles = () =>{
		requests.post(`${ROUTES.API.getfilesforscriptid}`, {scriptid: this.props.script.id})
			.then( res =>{


				if(!res.data.message)
					return
				

				// var thumbnail = res.data.message.filter( ele => ele.placementlocation === 'thumbnail')



				var thumbnail = res.data.message.filter( ele => {
			

					if(ele.placementlocation === 'thumbnail')
						return true
				

				})


				if(thumbnail.length === 0)
					return


				thumbnail = thumbnail.pop()

				this.setState({
					thumbnail: thumbnail.path,
					thumbnailtype: thumbnail.filename.endsWith('.mp4') ? 'gif' : 'img'
				})
			})
	}

	getNumComments = () =>{

		Comments.getNumComments(this.props.script.id)
			.then( res =>{

				this.setState({
					numbercomments: res.data.message.count
				})

			})
	}


	/* _________________ */


	/* LIFECYCLE METHODS */
	/* _________________ */
	componentDidUpdate(){
		
		if(this.thumbnailref)
			this.thumbnailref.playbackRate = this.props.scriptStore.videoPlayBackSpeed.thumbnail

	}
	componentDidMount(){

		
		this.getAuthor()

		this.getAvatar()

		this.getScriptFiles()

		this.getNumComments()

		this.votecount = this.props.script.votecount

		this.setState({
			votecount: this.props.script.votecount,
		})
    	var role = window.localStorage.getItem('role')

		when(
			() => Object.keys(this.props.scriptStore.savedScripts).length > 0,
			() =>{
				if(this.props.scriptStore.savedScripts[this.props.script.id]){
					this.setState({
						saved: this.props.scriptStore.savedScripts[this.props.script.id]
					})
				}
			}
		)

		when( () => (typeof this.props.authStore.authFields.id === 'number' && this.props.authStore.authFields.id === this.props.script.userid) || (role === 'admin')  ,
			  () =>{
				  	this.setState({
				  		showDeleteButton: true
				  	})
			  }
			)

	}

	
	/* _________________ */


	

	




	/* INTERNALS */
	/* _________________ */
	getCategorySVG = (category, width, height) =>{
		switch(category){
			case "gaming": return <GamingSVG className={styles.svg} width={width} height={height} />
					
			case "productivity": return <ProductivitySVG  className={styles.svg} width={width} height={height} />
					
			case "just_for_fun": return <JustforfunSVG  className={styles.svg} width={width} height={height} />
					
			case "school_work": return <SchoolworkSVG  className={styles.svg} width={width} height={height} />
					
			case "editing_creative": return <EditingcreativeSVG  className={styles.svg} width={width} height={height} />
					
			case "software":return  <SoftwareSVG  className={styles.svg} width={width} height={height} />
					
		}

	}
	getSubcategorySVG = (subcategory, viewW, viewH, width, height) =>{
		switch(subcategory){
			 
			case 'shortcuts': return <ShortcutsSVG className={styles.svg}  width={width} height={height}  />
					
			case 'GUI': return <GUISVG  className={styles.svg} width={width} height={height} />
					
			case 'automation': return <AutomationsSVG  className={styles.svg} width={width} height={height} />
					
			case 'general': return <GeneralSVG  className={styles.svg} width={width} height={height} />
					
		}


	}

	//vote system
	authorized = () =>{

		return this.props.authStore.authorizedUser ? true : false
	}
	
	handleUpvote = e =>{
		var instance = this

		var votestate = 'upvoted'

		if(this.authorized() ){

			this.props.scriptStore.votedScripts[this.props.script.id] = 'upvoted'

			console.log(localStorage.getItem('scriptStore'))
			this.setState({
				votecount: (this.votecount - this.state.votecount >= 0) ? this.votecount + 1 : this.state.votecount

			})


			Scripts.setScriptVoteCount(this.props.script.id, votestate)
				.then( res =>{
					console.log(res.data.message)
				})
		}
		else{
			this.setState({
				tippy: {visible: true, content: 'Must be logged in to vote.'}
			})
		}

	

	}

	handleDownvote = e =>{

		var votestate = 'downvoted'

		if(this.authorized()){
			this.props.scriptStore.votedScripts[this.props.script.id] = 'downvoted'
			console.log(localStorage.getItem('scriptStore'))


			this.setState({
				votecount: (this.votecount - this.state.votecount > 0) ?  this.state.votecount : this.votecount - 1 

			})

			Scripts.setScriptVoteCount(this.props.script.id, votestate)
				.then( res =>{
					console.log(res.data.message)
				})

		}
		else{
			this.setState({
				tippy: {visible: true, content: 'Must be logged in to vote.'}
			})
		}
		

	}

	voteBlur = e => {
		this.setState({
			tippy: {visible: false, content: ''}
		})
	}


	//save script

	saveScript = e =>{

		var script = this.props.script.script
		var userid = this.props.script.userid
		var scriptname = this.props.script.scriptname
		var submitdate = this.props.script.submitdate
		var categories = this.props.script.categories
		var tags = this.props.script.tags
		var description = this.props.script.description
		var votecount = this.props.script.votecount
		var awards = this.props.script.awards
		var scriptid = this.props.script.id
		var saveruserid = this.props.authStore.authFields.id

		console.log(this.props.script)


		if(this.authorized()){

			if(this.state.saved === 'unsaved'){
				Scripts.savedscripts.saveScript(script, userid, scriptname, submitdate, categories, tags, description, votecount, awards, scriptid, saveruserid)
				.then( res =>{
					console.log(res.data.message)
					this.setState({
						saved: 'saved'
					})

					this.props.scriptStore.savedScripts[this.props.script.id] = 'saved'
			console.log(localStorage.getItem('scriptStore'))


				})
			}
			
			else{
				Scripts.savedscripts.unsaveScript( scriptid, saveruserid)
				.then( res =>{
					console.log(res.data.message)
					this.setState({
						saved: 'unsaved'
					})

					this.props.scriptStore.savedScripts[this.props.script.id] = 'unsaved'

				})
			}

		}
		else{
			this.setState({
				tippySave: {visible: true, content: 'Must be logged in to save.'}
			})
		}

		
	}

	saveBlur = e => {
		this.setState({
			tippySave: {visible: false, content: ''}
		})
	}


	/* _________________ */

	


	
	render(){

		var category = this.props.script.categories[0]
		var subcategory = this.props.script.categories[1]


		var width = '100px'
		var height = '100px'

		var shortdescription = this.props.script.description.substring(0, this.state.descriptionlimit) + ' ...'


		var alternativecategorythumbnail = this.getCategorySVG(category, '300px', '300px')
		var alternativesubcategorythumbnail = this.getSubcategorySVG(subcategory, '550', '550', '50px', '50px')

		//comments
		var vote = this.props.scriptStore.votedScripts[this.props.script.id]
		
		var upvoteDisabled = vote === 'upvoted'
		var downvoteDisabled = vote === 'downvoted'
		//

		//saved scripts

		var alternativethumbnails = (

			<Link to={`${ROUTES.SCRIPTPAGE}?id=${this.props.script.id}&name=/${slugify(this.props.script.scriptname)}`} className={styles.thumbnailcontainer}>
				{alternativecategorythumbnail}
				{alternativesubcategorythumbnail}
			</Link>
		)

		var thumbnailcontainer = (

			<Link to={`${ROUTES.SCRIPTPAGE}?id=${this.props.script.id}&name=/${slugify(this.props.script.scriptname)}`} className={styles.gifcontainer}>

				{
					this.state.thumbnailtype === 'gif' ?
						<video  className={styles.gif} ref={ref => this.thumbnailref = ref} autoPlay loop muted playsInline>
						  <source src={this.state.thumbnail} type="video/mp4" />

						</video>
					:
						<img className={styles.thumbnailimg} ref={ref => this.thumbnailref = ref} src={this.state.thumbnail} alt=""/>
				}
				
			</Link>
			
		)

		var tags = this.props.script.tags.map(tag =>{
			return (
				<LinkToTag
					key={Math.random() * 10000}
					tagtag={tag}
				>
				#{tag}<span> </span> 
				</LinkToTag>
			)
		})
		return(
			
			<li className={styles.container}>
{/*scriptname*/}
				<h2 className={styles.scriptname}>{this.props.script.scriptname}</h2>

				<Collapse isOpened={this.state.showDeleteButton}>
					
					<DeleteScriptButton scriptid={this.props.script.id}  />
				</Collapse>
				
{/*thumbnail*/}
				{
					this.state.thumbnail ?
					thumbnailcontainer
					:
					alternativethumbnails
				}
{/*description*/}

				<button onClick={() => this.setState({showDescription: !this.state.showDescription})}><AddItem width='15px' height='15px' /></button>

				<Collapse isOpened={this.state.showDescription}>
					<div className={styles.descriptioncontainer}>
					
						<p dangerouslySetInnerHTML={{__html: shortdescription}} className={styles.description}></p>

						
					</div>

				</Collapse>
				<div className={styles.bottomrow}>
{/*categories*/}
					<div className={styles.categories}>
						<Link className={styles.category}><span className={styles.categorytext}>{category}</span>  {this.getCategorySVG(category, '24px', '24px')}</Link>
						<Link className={styles.subcategory}><span className={styles.subcategorytext}>{subcategory}</span>  {this.getSubcategorySVG(subcategory,'550', '550', '20px', '20px')}</Link>

					</div>

{/*comment number*/}
					<div className={styles.commentsnumber}>
						{this.state.numbercomments} Comments
					</div>

{/*userlink tippy*/}
					    <Tippy className='navbarTooltip' 
							content={ <UserLink 
											avatarfile={this.state.avatarfile}
											username={this.state.author}
											userid={this.props.script.userid}
									 />}       
							interactive={true}
							// trigger='click' 
							placement='auto' 
							theme='light'
							// visible={true}

					     >
{/*script meta data*/}
					     <div className={styles.metadata}>
					     	<img src={this.state.avatarfile} width='30px' height='30px' alt="user"/>
					     	<p className={styles.author}>by {this.state.author}</p>
					     	<p className={styles.submitdate}>{getTimeAgoString(this.props.script.submitdate)}</p>
					     </div>

					    </Tippy>


					
				</div>


{/*scriptname */}
				<h3>{this.props.script.scriptname} |  </h3>
{/*tags*/}
				<div className={styles.tags}>
					{tags}
				</div>

{/*vote system*/}
				<Tippy
					content={this.state.tippy.content}
					// trigger='manual'
					placement='auto'
					visible={this.state.tippy.visible}
					theme='light' 
				>
					<div onBlur={this.voteBlur}>
						<button className={styles.upvotebutton} disabled={upvoteDisabled} onClick={this.handleUpvote}>upvote</button>
						<span  >{this.state.votecount}</span>
						<button className={styles.upvotebutton} disabled={downvoteDisabled} onClick={this.handleDownvote}>downvote</button>
						
					</div>


				</Tippy>

{/*save script*/}
				<Tippy
					content={this.state.tippySave.content}
					// trigger='manual'
					placement='auto'
					visible={this.state.tippySave.visible}
					theme='light' 
				>
					<div onBlur={this.saveBlur}>
						{
							this.state.saved === 'unsaved' ?

								<button className={styles.savebutton} onClick={this.saveScript}>Save</button>
							:
								<button className={styles.savebutton} onClick={this.saveScript}>Unsave</button>


						}
						
					</div>


				</Tippy>
				
			</li>
		)

	}
}


var enhance = compose(
	inject('authStore', 'scriptStore', 'userStore'),
	withRouter,
	observer
	)

export default enhance(ScriptUnit)

