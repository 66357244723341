import React from 'react'

const SVG = props => {

	return (
		
		<svg height={props.height} width={props.width} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 20.0919C-0.0976311 20.4824 -0.0976311 21.1156 0.292893 21.5061C0.683417 21.8966 1.31658 21.8966 1.70711 21.5061L10.8995 12.3137L20.0921 21.5062C20.4826 21.8967 21.1158 21.8967 21.5063 21.5062C21.8968 21.1157 21.8968 20.4825 21.5063 20.092L12.3137 10.8995L21.5061 1.70711C21.8966 1.31658 21.8966 0.683417 21.5061 0.292893C21.1156 -0.0976314 20.4824 -0.0976308 20.0919 0.292893L10.8995 9.48524L1.70729 0.293C1.31677 -0.0975244 0.683604 -0.0975244 0.29308 0.293C-0.0974445 0.683524 -0.0974439 1.31669 0.29308 1.70721L9.48532 10.8995L0.292893 20.0919Z" fill="#B1B1B1"/>
		</svg>


	)
	//height={props.height} width={props.width} viewBox={`0 0 ${props.viewW} ${props.viewH}`}
	
}

export default SVG