import {observable, action, decorate, when, computed, reaction} from 'mobx'


import { requests, Scripts } from 'axios.js'


import userAltImage from 'images/user.png'


class UserStore {


	
	avatar = {
		file: userAltImage,
		width: '50px',
		height: '50px'

	}

	anon = {
		file: userAltImage,
		width: '50px',
		height: '50px'
	}

	scripts = {
		self: []
	}

	usermetadata = {
		status: 'none',
		pageviews: 0,
		flairs: [],
		description: "",
		datejoined: "",
		awards: []

	}

	
	notification = {
		typeIcon: {width: '25px', height: '25px'},
		click: false,
		notificationCountAfterClick: 0
	}

	conversation = {
		click: false,
		messageCountAfterClick: 0
	}

	setAvatar(file,width, height){
		this.avatar.file = file
		this.avatar.width = width

		this.avatar.height = height

	}

}



decorate(UserStore,{
	avatar: observable,
	anon: observable,
	scripts: observable,
	notification: observable,
	conversation: observable,
	usermetadata: observable,
	setAvatar: action,
})
// decorate(UserStore,{
// 	avatar: [persist('object'), observable],

// 	scripts: [persist('object'), observable],
// 	setAvatar: action,
// 	hydrateStore: action
// })

// hydrate('user', userStore ).then( () => console.log('userStore hydrated!'))



var userStore = new UserStore()

reaction( () => JSON.stringify(userStore),
			json => {
				localStorage.setItem('userStore', json)
			},
			{
				delay: 500
			})


export default userStore







// const hydrate = create({
//   storage: localStorage,
//   jsonify: false,
// });




// const data = observable({
// 	avatar: {
// 		file: userAltImage,
// 		width: '50px',
// 		height: '50px'
// 	}

// })

// const schema = {
// 	avatar: {
// 		type: 'object',
// 		schema: {
// 			file: true,
// 			width: true,
// 			height: true
// 		}
// 	}
// }

// const userStore = persist(schema)(data)

// export default userStore
// hydrate('user', userStore ).then( () => console.log('userStore hydrated!'))




// import { action, observable, computed, decorate } from 'mobx';
// import { persistence, useClear, useDisposers, isSynchronized, StorageAdapter } from 'mobx-persist-store';

// function readStore(name) {
//   return new Promise((resolve) => {
//     const data = localStorage.getItem(name);
//     resolve(JSON.parse(data));
//   });
// }

// function writeStore(name, content) {
//   return new Promise((resolve) => {
//     localStorage.setItem(name, JSON.stringify(content));
//     resolve();
//   });
// }

// class UserStore {
//   counter: number = 0;

//   	avatar = {
// 		file: userAltImage,
// 		width: '50px',
// 		height: '50px'

// 	}

// 	scripts = {
// 		self: []
// 	}

// 	setAvatar(file,width, height){
// 		this.avatar.file = file
// 		this.avatar.width = width

// 		this.avatar.height = height

// 	}

//   tickCounter = () => {
//     this.counter = this.counter + 1;
//   };

//   clearStore = () => {
//     useClear(this)
//   }

//   persistDespose = () => {
//     useDisposers(this)
//   }

//   get isSynchronized(){
//     return isSynchronized(this)
//   }
// }

// decorate(UserStore, {
//   counter: observable,
//   avatar: observable,
//   scripts: observable,
//   setAvatar: action,
//   tickCounter: action,
//   clearStore: action,
//   persistDispose: action,
//   isSynchronized: computed
// })

// persistence({
//   name: 'UserStore',
//   properties: ['counter', 'avatar', 'scripts'],
//   adapter: new StorageAdapter({
//     read: readStore,
//     write: writeStore,
//   }),
//   reactionOptions: { // optional
//     delay: 2000
//   },
// })(UserStore);

// export default new UserStore();
