import React from 'react'

const SVG = props => (
//h51 w50
	<svg height={props.height} width={props.width} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M14.7873 21.6164L3.86816 42.1885L13.7837 41.2725L18.5829 49.9984L29.5021 29.4263L14.7873 21.6164Z" fill="#E95454"/>
	<path d="M16.0378 44.0081C16.3442 43.35 16.0237 42.5637 15.3828 42.2229L14.7544 41.8884C14.1259 41.5304 13.8868 40.7347 14.2291 40.0907C14.5762 39.4357 15.3922 39.1855 16.0472 39.5326L17.823 40.4752C18.3717 40.766 19.0595 40.5549 19.3425 40.0031L19.3862 39.9187C19.7114 39.3981 19.5191 38.7103 18.9782 38.4243L13.6664 35.6057C13.0192 35.2618 12.7018 34.4787 13.0035 33.819C13.3365 33.1171 14.1775 32.8404 14.8544 33.1999L19.7817 35.8152C20.3304 36.106 21.0182 35.8949 21.309 35.3462L21.3371 35.2743L21.345 35.2587C21.6592 34.6834 21.4387 33.9612 20.8588 33.6533L18.095 32.187C17.4478 31.843 17.1305 31.0599 17.4369 30.3924C17.7667 29.6983 18.6109 29.4138 19.2877 29.7733L22.3407 31.3944C22.9113 31.6977 23.5929 31.4444 23.8883 30.8691C23.893 30.8613 23.893 30.8613 23.8962 30.8535C23.9008 30.8457 23.9008 30.8457 23.904 30.8379C24.2151 30.2704 24.0431 29.5639 23.471 29.2621L20.1772 27.5145C19.5379 27.1752 19.2205 26.3921 19.5222 25.7324C19.8552 25.0305 20.6962 24.7538 21.3731 25.1133L29.4988 29.4263L18.5796 49.9984L15.5704 44.5287C15.7642 44.3974 15.933 44.2348 16.0378 44.0081Z" fill="#ED6362"/>
	<path d="M35.2123 21.6164L46.1314 42.1885L36.2159 41.2725L31.4167 49.9984L20.4976 29.4263L35.2123 21.6164Z" fill="#ED6362"/>
	<path d="M33.9621 44.0081C33.6557 43.35 33.9761 42.5637 34.6171 42.2229L35.2455 41.8884C35.8739 41.5304 36.1131 40.7347 35.7707 40.0907C35.4237 39.4357 34.6077 39.1856 33.9527 39.5326L32.1769 40.4752C31.6282 40.766 30.9403 40.555 30.6574 40.0031L30.6136 39.9187C30.2885 39.3982 30.4808 38.7103 31.0216 38.4243L36.3351 35.6042C36.9822 35.2603 37.2996 34.4771 36.9979 33.8174C36.6649 33.1155 35.8239 32.8388 35.147 33.1984L30.2197 35.8137C29.671 36.1044 28.9832 35.8934 28.6924 35.3447L28.6643 35.2728L28.6565 35.2572C28.3423 34.6819 28.5627 33.9597 29.1426 33.6517L31.9064 32.1854C32.5536 31.8415 32.8709 31.0583 32.5645 30.3908C32.2347 29.6967 31.3906 29.4122 30.7137 29.7718L27.6576 31.396C27.087 31.6993 26.4054 31.446 26.11 30.8707C26.1053 30.8629 26.1053 30.8629 26.1021 30.8551C26.0974 30.8473 26.0974 30.8473 26.0943 30.8395C25.7832 30.272 25.9552 29.5654 26.5273 29.2637L29.8211 27.516C30.4604 27.1768 30.7778 26.3936 30.4761 25.734C30.1431 25.0321 29.3021 24.7554 28.6252 25.1149L20.4995 29.4279L31.4187 50L34.4279 44.5303C34.2356 44.3974 34.0668 44.2348 33.9621 44.0081Z" fill="#E95454"/>
	<path d="M24.9999 36.489C35.076 36.489 43.2443 28.3206 43.2443 18.2445C43.2443 8.16833 35.076 0 24.9999 0C14.9237 0 6.75537 8.16833 6.75537 18.2445C6.75537 28.3206 14.9237 36.489 24.9999 36.489Z" fill="#FFCC5B"/>
	<path d="M27.4745 16.1513H15.9129C15.014 16.1513 14.2762 15.4135 14.2762 14.5146L14.2887 14.4021V14.377C14.2762 13.4282 15.05 12.6528 16.0004 12.6528H24.0135C25.075 12.6528 26.0129 11.8665 26.0754 10.8035C26.1255 9.69204 25.2391 8.7541 24.1261 8.7541H15.6377C14.7014 8.7541 14.001 7.96779 14.0135 7.02985C14.0135 7.01734 14.0135 7.01734 14.0135 7.00484C14.0135 6.99233 14.0135 6.99233 14.0135 6.97983C14.001 6.04345 14.6998 5.25558 15.6377 5.25558H24.52C25.569 5.25558 26.5069 4.46928 26.5694 3.41878C26.6194 2.29482 25.7331 1.36938 24.6201 1.36938H18.1155C11.4827 4.10348 6.80859 10.6253 6.80859 18.2445C6.80859 25.8637 11.4827 32.3839 18.117 35.1149H26.3756C27.4245 35.1149 28.3624 34.3286 28.425 33.2781C28.475 32.1541 27.5886 31.2303 26.4756 31.2303L17.0806 31.2287C15.9723 31.2287 15.0797 30.3017 15.1391 29.1793C15.1938 28.1288 16.138 27.3394 17.19 27.3394H20.8324C21.8797 27.3097 22.7254 26.4515 22.7254 25.3963C22.7254 24.3223 21.8516 23.4469 20.7761 23.4469H15.2547C14.3559 23.4469 13.618 22.7106 13.6305 21.8102V21.6727C13.568 20.7863 14.2808 20.0359 15.1672 20.0359H27.362C28.4234 20.0359 29.3614 19.2496 29.4239 18.1991C29.4739 17.0767 28.586 16.1513 27.4745 16.1513Z" fill="#FDBC4B"/>
	<path d="M24.9999 31.2662C17.8184 31.2662 11.9766 25.4228 11.9766 18.2429C11.9766 11.063 17.8184 5.22119 24.9999 5.22119C32.1813 5.22119 38.0231 11.0646 38.0231 18.2445C38.0231 25.4244 32.1813 31.2662 24.9999 31.2662Z" fill="#FFDB70"/>
	<path opacity="0.2" d="M38.0232 18.2445C38.0232 11.3163 32.5801 5.64954 25.7472 5.25873H24.5341C23.7337 5.25873 23.0194 5.85901 22.9709 6.65938C22.9334 7.51604 23.6087 8.22262 24.4575 8.22262L31.6234 8.22418C32.4691 8.22418 33.1491 8.93076 33.1038 9.78741C33.0615 10.5894 32.3425 11.1912 31.539 11.1912H28.7611C27.9623 11.2146 27.3167 11.8681 27.3167 12.6731C27.3167 13.4923 27.9842 14.1598 28.8033 14.1598H33.0146C33.7009 14.1598 34.2621 14.7225 34.2527 15.4072V15.5104C34.2996 16.1873 33.7572 16.7579 33.0803 16.7579H23.7806C22.9709 16.7579 22.2565 17.3581 22.208 18.1585C22.1705 19.0152 22.8458 19.7218 23.6947 19.7218H32.5113C33.1976 19.7218 33.7588 20.2845 33.7588 20.9692L33.7494 21.0552V21.074C33.7587 21.7977 33.1678 22.3886 32.4441 22.3886H26.3318C25.5221 22.3886 24.8077 22.9889 24.7592 23.7987C24.7217 24.6459 25.397 25.3619 26.2459 25.3619H32.7192C33.4336 25.3619 33.9667 25.9622 33.9573 26.6766C33.9573 26.686 33.9573 26.686 33.9573 26.6953C33.9573 26.7047 33.9573 26.7047 33.9573 26.7141C33.9667 27.4285 33.4336 28.0288 32.7192 28.0288H25.9457C25.1453 28.0288 24.4309 28.6291 24.3825 29.4294C24.345 30.2876 25.0203 30.9927 25.8691 30.9927H27.6559C33.5696 29.7608 38.0232 24.5131 38.0232 18.2445Z" fill="white"/>
	<path d="M34.092 15.3603H27.7578L25.7991 9.3356C25.5474 8.5618 24.4515 8.5618 24.1999 9.3356L22.2427 15.3603H15.9085C15.094 15.3603 14.7548 16.403 15.4145 16.8813L20.5388 20.6049L18.5816 26.6296C18.3299 27.4034 19.2163 28.0475 19.876 27.5691L25.0002 23.8455L30.1245 27.5691C30.7842 28.0475 31.6706 27.4034 31.4189 26.6296L29.4617 20.6049L34.586 16.8813C35.2441 16.403 34.9064 15.3603 34.092 15.3603Z" fill="#EC9922"/>
	</svg>



	//height={props.height} width={props.width} viewBox={`0 0 ${props.viewW} ${props.viewH}`}
	
)

export default SVG
