import React from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import { observable, decorate } from 'mobx'

import { compose } from 'recompose'

import ProfilePage from 'pages/account/profilepage.js'

 class TestPage extends React.Component{


	constructor(props){

		super(props)
	}
	/* LIFECYCLE METHODS */
	/* _________________ */
	componentDidUpdate(){

	}

	componentDidMount(){

	}
	/* _________________ */


	

	/* ASYNC DATA FETCH */
	/* _________________ */


	/* _________________ */




	/* INTERNALS */
	/* _________________ */

	

	/* _________________ */

	
	render(){

		return(
			
			<div>
				<h3>HELLo</h3>

				<ScriptUnit 
				scriptid={6}
				userid={97}
				tags={['productivity', 'automation']}
				scriptname='SCRIPT1'
				submitdate='08-20-2020-21-34-35'

				/>

			</div>
		)

	}
}


var enhance = compose(
	inject('authStore'),
	withRouter,
	observer
	)

export default enhance(TestPage)

