import React from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import { observable, decorate } from 'mobx'

import { compose } from 'recompose'

import CaretDown from 'icons/topnav/caret-down.js'

import * as ROUTES from 'routes.js'

import SearchUser from 'components/scripts/searchUser.js'

import SearchScript from 'components/scripts/searchScript.js'

import Avatar from 'components/profile/avatar.js'

import TopnavDropdown from 'components/topnav/topnav_dropdown.js'

import NotificationsMaster from 'components/topnav/notifications.js'

import Conversations from 'components/topnav/conversations.js'

import PCreimaginedcatchphrase from 'icons/topnav/pcreimagined.js' 


 class UserTopNavbar extends React.Component{


	constructor(props){

		super(props)
	}

	componentDidUpdate(){

	}

	componentDidMount(){

	}

	
	render(){
		var { topNavIcons } = this.props.uiStore

		var { width, height } = this.props.userStore.avatar

		var { authorizedUser } = this.props.authStore

		return (

			<header className="topNavbar">
				

				<div className='container'>

					<Link className="topLogo" to={ROUTES.INFOLANDING}><PCreimaginedcatchphrase/></Link>
					<SearchUser/>
					
					<SearchScript/>

					<ul className=''>
						
						<li className='list-item'>
							
							<Link to={authorizedUser ? ROUTES.PROFILEPAGE : ROUTES.SIGNUP} className="link-custom ">
								<Avatar
									width={width}
									height={height}
								/>
							</Link>

						</li>
						<li className='list-item'>
	{/*						<Link className="link-custom ">Notifications</Link>
	*/}						
							<div className="notificationsmenu">
								<NotificationsMaster />

							</div>

						</li>
						<li className='list-item'>
							<Link className="link-custom" to={authorizedUser ? ROUTES.CONVERSATIONSPAGE : ROUTES.SIGNUP}><Conversations/></Link>

						</li>
						<li className='list-item'>
							<div className="link-custom dropdown ">Settings
								<CaretDown
								width={topNavIcons.caret_down.width}
								height={topNavIcons.caret_down.height}
								 />
								<div className="dropdown-content">
									<TopnavDropdown auth={this.props.authStore.authorizedUser} />

								 </div> 

							
							</div>
							
							
						</li>

					</ul>
				</div>
			</header>
			)

		// return(
		// 	<div className="topNavbar pure-menu pure-menu-horizontal">
		// 		<ul className="pure-menu-list">
		// 			<li className="pure-menu-item">
		// 				<span>ScriptMime</span>
		// 			</li>
		// 			<li className="pure-menu-item">
		// 				<Link className='pure-menu-link link-custom'>LINK</Link>
		// 			</li>
		// 			<li className="pure-menu-item">
		// 				<Link className='pure-menu-link link-custom'>LINK2</Link>
		// 			</li>
		// 			<li className="pure-menu-item">
		// 				<Link className='pure-menu-link link-custom'>LINK3</Link>
		// 			</li>
		// 		</ul>
		// 	</div>

		// )

	}
}


var enhance = compose(
	inject('authStore', 'uiStore', 'firebaseStore', 'userStore'),
	withRouter,
	observer
	)

export default enhance(UserTopNavbar)

