import React from 'react'
import { Link, withRouter } from 'react-router-dom'


import styles from 'styles/components/footer.module.css'
import BottomDivider from 'images/landing/divider.png'

import * as ROUTES from 'routes.js'



export default class Footer extends React.Component {

	render(){
		return (
			<div>
				<img src={BottomDivider} className={styles.bottomdivider} alt=""/>

				<div className={styles.footer}>

					<p className={styles.smdescription}>ScriptMime -  A vast social network for free software & automation tools </p>
					<p className={styles.smdescription2}>Powered for Autohotkey </p>

					<Link className={styles.leftlink}>Company</Link>
					<Link to={ROUTES.TERMSCONDITIONS} className={styles.rightlink}>Terms</Link>
					<Link to={ROUTES.PRIVACYPOLICY} className={styles.rightlink}>Privacy</Link>
					<Link to={ROUTES.SUPPORTPAGE} className={styles.rightlink}>Support</Link>
					<a href="https://docs.google.com/forms/d/e/1FAIpQLSet4lN_v9y_YherE8n4RN0gTTSKk15iW9mXfwk_iBpU67rNmA/viewform" target="_blank" className={styles.rightlink}>Give Us Feedback❤</a>


					<Link className={styles.smlink}>ScriptMime LLC</Link>


				</div>

			</div>
			

		)
	}

}