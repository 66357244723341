

// import React from 'react'

// import { useQuery } from 'react-query'

// import requests from 'axios.js'


import React from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import { observable, decorate, when, toJS } from 'mobx'

import { compose } from 'recompose'

import requests from 'axios.js'

import * as ROUTES from 'routes.js'

const fetchAvatar = async (id) => {

	const res = await requests.post(`${ROUTES.API.getavatar}`, {userid: id})
	  
	return res.data
	
	  
}

 class Avatar extends React.Component{

	userStore
	width
	height
	userid

	getfilepathcallback
	constructor(props){

		super(props)
		this.userStore = this.props.userStore

		this.state = {
			avatarfile: ''
		}

		
	}
	fetchAvatar = () =>{

		requests.post(`${ROUTES.API.getavatar}`, {userid: this.props.userid})
			.then( res =>{

				if(res.data.message){
					this.setState({
						avatarfile: res.data.message
					})

					if(this.props.getfilepathcallback)
						this.props.getfilepathcallback(res.data.message)
				}
				else{
					this.setState({
						avatarfile: this.props.userStore.anon.file
					})
				}
				
			})
	}

	callAvatarFunction = () =>{
		var url
		if(!this.props.userid){
			url = fetchAvatar(this.props.authStore.authFields.id)
			if(url.message){
				this.userStore.avatar.file = url.message
			}


		}
		else{

			this.fetchAvatar()
		

		}
	}

	componentDidUpdate(){
		
	}

	componentDidMount(){
		when(
			() => typeof this.props.authStore.authFields.id === 'number',
			() => {
				if(!this.props.userid)
					this.callAvatarFunction()
			}
		)

		if(this.props.userid)
			this.callAvatarFunction()
		
		
	}


	
	render(){

		var { file, width, height } = this.userStore.avatar

		var userid = this.props.userid
		
		return (
			<div>
				{

					userid ?
						<img src={this.state.avatarfile} width={this.props.width} height={this.props.height} alt="user"/>
					 :
						<img src={file} width={this.props.width} height={this.props.height} alt="user"/>
				}
			</div>
			

		)

	}
}


var enhance = compose(
	inject('authStore', 'userStore'),
	withRouter,
	observer
	)

export default enhance(Avatar)



// function Avatar(props) {

// 	const { width, height } = props



//    // const { isLoading, isError, data, error } = useQuery('avatar', fetchAvatar)

//  	console.log(data)
//    return (
		
// 		<img src="data" alt="HELLo"/>
			
		
//    )

// }

// export default Avatar
