import React from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import { observable, decorate, toJS, when } from 'mobx'
import { sanitize } from 'dompurify'

import { compose } from 'recompose'
import * as ROUTES from 'routes.js'

import requets, {Users, Profile} from 'axios.js'

import styles from 'styles/components/profile/userlink.module.css'

import VerifiedIcon from 'icons/profile/verified.js'
import BasicStarAward from 'icons/profile/basicstaraward.js'
import TopContributorAward from 'icons/profile/topcontributor.js'

import {displayTimeStamp, getTimeAgoString, timeDifference} from 'usefulFunctions.js'

import Popup from 'reactjs-popup'

import CreateConversationMessage from 'components/topnav/CreateConversationMessage.js'


import Avatar from 'components/profile/avatar.js'

        
  


 class UserLink extends React.Component{


 	avatarfile //if none, pass in null
 	username
 	userid

	constructor(props){

		super(props)
		this.joindate
		this.awards //received 1 or more awards
		this.flairs // verified or not
		this.status // admin, mod, regular user

		this.state = {
			joindate: '',
			status: 'member',
			flairs: undefined,
			awards: [],
			description: '',
			descriptionMax: 100, // chars
			openMessageBoxStatus: false,
		
		}

	}

	/* ASYNC DATA FETCH */
	/* _________________ */

	getUserData = () =>{
		Profile.getUserMetaData(this.props.userid)
			.then( res =>{
				if(res.data.error)
					return

				

				this.setState({
					joindate: res.data.message.datejoined,
					status: res.data.message.status === 'none' ? 'member' : res.data.message.status,
					flairs: typeof res.data.message.flairs[0] === 'string' ? res.data.message.flairs[0] : undefined,
					description: sanitize(res.data.message.description, {ALLOWED_TAGS: []}).substring(0, this.state.descriptionMax) + '...'
				})

				//awards
				if(res.data.message.awards.length > 0){

					var stringtmp = res.data.message.awards
					stringtmp = stringtmp.flat()
					// var awardsarr = stringtmp.split(',')
					if(stringtmp.includes('top-contributor')){
						this.setState({
							awards: <TopContributorAward height='25px' width='25px' />
						})
					}

				}

				//flairs
				if(res.data.message.flairs.length > 0){
					var stringtmp = res.data.message.flairs
					stringtmp = stringtmp.flat()
					// var awardsarr = stringtmp.split(',')
					if(stringtmp.includes('verified')){
						this.setState({
							flairs: <VerifiedIcon height='15px' width='15px'/>

						})
					}
				}




				// console.log(this.state)
			})
	}

	/* _________________ */


	/* LIFECYCLE METHODS */
	/* _________________ */
	componentDidUpdate(){

	}

	componentDidMount(){
		this.getUserData()

		
	}
	/* _________________ */






	/* INTERNALS */
	/* _________________ */

	closeMessageBox = () =>{
		this.setState({
			openMessageBoxStatus: false
		})
	}

	/* _________________ */

	
	render(){
		var avatar = this.props.avatarfile !== null ? this.props.avatarfile : <Avatar width="75px" height="75px" userid={this.props.userid}/>

		var width = this.props.userStore.avatar.width
		var height = this.props.userStore.avatar.height


		return(
			<div className={styles.container} className={styles.container}> 
				<div className={styles.toprow}>
					<Link className={styles.link} to={`${ROUTES.OTHERUSERPROFILEPAGE}?id=${this.props.userid}`}>
{/*avatar*/}					
						{
							this.props.avatarfile !== null ? 
								<img className={styles.avatar} src={this.props.avatarfile} width={width} height={height} alt="user"/>
							:
								<Avatar width={width} height={height} userid={this.props.userid}/>
						}
						
{/*username + flair*/}	
						<div className={styles.username}>{this.props.username} 
							{
								this.state.flairs ? 
									this.state.flairs
									:
									null
							}
						</div>
					</Link>
				</div>

				<div className={styles.middlerow}>

					<p className={styles.status}>{this.state.status}</p>
{/*Description*/}
					<p className={styles.description}>{this.state.description}</p>
{/*awards*/}
					<div className={styles.awards}>
						{
							this.state.awards ? 
								this.state.awards
								:
								null
						}
					</div>
{/*joindate*/}
					<p className={styles.joindate}>joined {displayTimeStamp(this.state.joindate)}</p>
				</div>

				<div className={styles.bottomrow}>
{/*send message*/}				
					<Popup
					  trigger={	  	    
					  	      	<button disabled={!this.props.authStore.authorizedUser} className={styles.sendmessage}>Send Message</button>
					  		  }
					  modal
					  closeOnDocumentClick
					  closeOnEscape
					  open={this.state.openMessageBoxStatus}
					>
					  <CreateConversationMessage
					  	hostuserid={this.props.userid}
					  	cancelcallback={this.closeMessageBox}
					  />
					</Popup>
					

				</div>

				
					
					
				
					


			</div>

		)

	}
}


var enhance = compose(
	inject('authStore', 'userStore', 'uiStore'),
	withRouter,
	observer
	)

export default enhance(UserLink)

