// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform'

import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';



import React, { Component } from 'react'

import { compose } from 'recompose'

import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'



// import imageCompression  from 'browser-image-compression';

// var imageCompression = require('./dependencies/imageCompression')


var server_address = process.env.REACT_APP_SERVER_ADDRESS_FILEPOND

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginImageTransform, FilePondPluginFileValidateSize, FilePondPluginFileValidateType);


// Our app
class Filepond extends Component {

    authStore
    userStore
    scriptStore

    /* props: */

    table // our database table to put files into
    allowUserSubmit // keep the upload button or not
    submitCallback // if this is passed, then we want to call this pond's uploadFiles method in the parent
    maxTotalFileSize // obv


    /*table specific props */

    //avatars

    //content

    placementposition // gif1, gif2 etc

    constructor(props) {
        super(props);

        this.authStore = this.props.authStore
        this.userStore = this.props.userStore
        this.scriptStore = this.props.scriptStore


        this.table = this.props.table

        this.imagesize = this.props.imagesize

        if(this.props.placementposition){
          this.placementposition = this.props.placementposition
        }
        else{
          this.placementposition = -1          
        }


        // imageCompression(new Image(), options)

        this.state = {
            // Set initial files, type 'local' means this is a file
            // that has already been uploaded to the server (see docs)
            files: []
        };
    }

    componentDidMount(){
      

    }
    
    handleInit() {
        console.log('FilePond instance has initialised', this.pond);

    }


    //handle response from server and next actions based on table
    handleOnLoad(data) {
      console.log(`Received : ${data} from server`)

      var response = JSON.parse(data)

      console.log(response)

      // this.userStore.writeStore()
      // this.userStore.avatar.file = response.message

      if(this.props.table === 'avatars')
        this.userStore.setAvatar(response.message, '60px', '60px')

      else if(this.props.table === 'content'){


        // this.scriptStore.currentScriptToSubmit.index += 1
        // this.scriptStore.currentScriptToSubmit.start = true

      }



      // var fileUrl = data.substring(data.indexOf('')
    }
    
    uploadAllFiles = () =>{

      if(this.pond.getFiles().length > 0)
        this.pond.processFiles()


    }
    

    render() {

        const { id } = this.authStore.authFields


        var i = 0
        return (
              <div className= 'fileuploading'>
               
          
                <FilePond ref={ref => this.pond = ref}
                          files={this.state.files}
                          allowMultiple={false}
                          maxFiles={1}
                          instantUpload={false}
                          allowImageExifOrientation={true}
                          allowImageResize={true}
                          allowFileSizeValidation={true}

                          maxTotalFileSize={this.props.maxTotalFileSize || '2MB' }
                          labelMaxTotalFileSizeExceeded={`Max total file size exceeded. Keep it under ${this.props.maxTotalFileSize}`}

                          allowFileTypeValidation={true}
                          acceptedFileTypes={this.props.acceptedFileTypes || ['image/*']}

                          // imageResizeTargetWidth={2560}
                          // imageResizeTargetHeight={1440}
                          imageResizeMode={"contain"}
                          allowImageTransform={true}
                          allowProcess={this.props.allowUserSubmit === false ? this.props.allowUserSubmit :  true}
                          // imageTransformOutputQuality={75}
                          server={{
                            url: server_address,
                            process: {
                              url: `api/upload/${id}/${this.table}/${this.scriptStore.currentScriptToSubmit.id}/${this.placementposition}/${this.imagesize.width || '500'}`,//aspect ratio is 16:9 default, imagesize is format {width: 100, height: 100} 
                              onload: ( data ) => this.handleOnLoad(data),
                              // ondata: formData =>{
                              //   formData.append('swag', 'muffins')
                              //   return formData
                              // }
                            }
                          }}

                          onaddfile={(err, file) =>{
                            if(!err){
                              if(this.props.submitCallback)
                                this.props.submitCallback(this.uploadAllFiles)
                            }
                          }}
                          onprocessfiles={() =>{
                            // console.log('incrementing index in scriptstore')

                            this.scriptStore.currentScriptToSubmit.index += 1
                          }}
                          onupdatefiles={fileItems =>{
                            this.setState({
                              files: fileItems.map(file => file.file)
                            })
                          }}
                          oninit={() => this.handleInit() }
                          userId={3}

                          imageTransformImageFilter={(file) => new Promise(resolve => {

                            // no gif mimetype, do transform
                            if (!/image\/gif/.test(file.type)) return resolve(true);

                            const reader = new FileReader();
                            reader.onload = () => {

                                var arr = new Uint8Array(reader.result),
                                i, len, length = arr.length, frames = 0;

                                // make sure it's a gif (GIF8)
                                if (arr[0] !== 0x47 || arr[1] !== 0x49 || 
                                    arr[2] !== 0x46 || arr[3] !== 0x38) {
                                    // it's not a gif, we can safely transform it
                                    resolve(true);
                                    return;
                                }

                                for (i=0, len = length - 9; i < len, frames < 2; ++i) {
                                    if (arr[i] === 0x00 && arr[i+1] === 0x21 &&
                                        arr[i+2] === 0xF9 && arr[i+3] === 0x04 &&
                                        arr[i+8] === 0x00 && 
                                        (arr[i+9] === 0x2C || arr[i+9] === 0x21)) {
                                        frames++;
                                    }
                                }

                                // if frame count > 1, it's animated, don't transform
                                if (frames > 1) {
                                    return resolve(false);
                                }

                                // do transform
                                resolve(true);
                            }
                            reader.readAsArrayBuffer(file);

                            })

                          }

                          onupdatefiles={(fileItems) => {
                              // Set current file objects to this.state
                              this.setState({
                                  files: fileItems.map(fileItem => fileItem.file)
                              });

                              console.log(this.pond.getFiles())
                              
                          }}
                          >

                </FilePond>
              </div>
        );
    }
}


var enhance = compose(
  inject('authStore','userStore', 'scriptStore'),
  withRouter,
  observer
  )

export default enhance(Filepond)