import { action, observable, computed, decorate, reaction } from 'mobx'




// var circularNavIcons_width = `30px`
// var circularNavIcons_height = `30px`

// var topNavIcons_width = '20px'
// var topNavIcons_height = '20px'



//Store information relevant to the application as a whole

class UiStore {
	
	language = "en_US"

	//Circular Navmenu
	circularNavIcons={
		gaming: {width: '30px', height: '30px'},
		productivity: {width: '30px', height: '30px'},
		just_for_fun: {width: '30px', height: '30px'},
		school_work: {width: '30px', height: '30px'},
		editing_creative: {width: '30px', height: '30px'},
		software: {width: '30px', height: '30px'},

	}

	circularNavIcons_set(category, width, height){
		circularNavIcons[`${category}`].width = width
		circularNavIcons[`${category}`].height = height

	}

	//Top Navbar
	topNavIcons={
		caret_down: {width: '20px', height: '20px'},
		notification_bell: {width: '20px', height: '20px'}
	}

	topNavIcons_set(category, width, height){
		topNavIcons[`${category}`].width = width
		topNavIcons[`${category}`].height = height

	}

	maxUsernameSearchBarlength = 100
	maxScriptTagSearchBarlength = 100
	maxTagLength = 30
	maxScriptLength = 40000
	maxScriptNameLength = 100
	maxDescriptionLength = 10000

	maxUserProfileDescriptionLength = 500
	maxUserProfileNoteLength = 500
	maxPrivateMessageLength = 2000



}

decorate(UiStore, {
	language:  observable,
	circularNavIcons: observable,
	circularNavIcons_set: action,
	topNavIcons:  observable,
	maxUsernameSearchBarlength: observable,
	maxScriptTagSearchBarlength: observable,
	maxTagLength: observable,
	maxScriptLength: observable,
	maxUserProfileDescriptionLength: observable,
	maxUserProfileNoteLength: observable,
	maxDescriptionLength: observable,
	maxPrivateMessageLength: observable,

	topNavIcons_set: action


})

var uiStore = new UiStore()

reaction( () => JSON.stringify(uiStore),
			json => {
				localStorage.setItem('uiStore', json)

			},
			{
				delay: 500
			})


export default uiStore

