import React from 'react'

const SVG = props => {

	return (
		<svg height={props.height} width={props.width} viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M2.54413 9.99014H4.04265V5.99408C4.04265 4.89517 4.94176 3.99605 6.04068 3.99605H10.0367V2.49753C10.0367 1.09891 11.1356 0 12.5343 0C13.9329 0 15.0318 1.09891 15.0318 2.49753V3.99605H19.0279C20.1268 3.99605 21.0259 4.89517 21.0259 5.99408V9.79033H19.5274C18.0288 9.79033 16.83 10.9892 16.83 12.4877C16.83 13.9862 18.0288 15.185 19.5274 15.185H21.0259V18.9813C21.0259 20.0802 20.1268 20.9793 19.0279 20.9793H15.2316V19.4808C15.2316 17.9822 14.0328 16.7834 12.5343 16.7834C11.0357 16.7834 9.83693 17.9822 9.83693 19.4808V20.9793H6.04068C4.94176 20.9793 4.04265 20.0802 4.04265 18.9813V14.9852H2.54413C1.14551 14.9852 0.0465927 13.8863 0.0465927 12.4877C0.0465927 11.0891 1.14551 9.99014 2.54413 9.99014Z" fill="#C4C4C4"/>
		</svg>


	)
	//height={props.height} width={props.width} viewBox={`0 0 ${props.viewW} ${props.viewH}`}
	
}

export default SVG