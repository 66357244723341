

import React from 'react'

const SVG = props => (
	<svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 550 550" height={props.height} width={props.width}><path d="M189.365 251.814l59.145-65.324L71.076 8.172c-10.593-10.593-28.248-10.593-38.841 0L8.4 32.007C-2.193 42.6-2.193 60.255 8.4 70.848l180.965 180.966z" data-original="#E15649" fill="#e15649"/><path d="M470.083 506.931l42.372 5.297-6.179-43.255-38.841-26.483-24.717 25.6 27.365 38.841z" data-original="#C3C7CB" fill="#c3c7cb"/><path d="M282.055 306.545L443.6 468.09l24.717-24.717-161.545-161.545-24.717 24.717z" data-original="#E0E0E0" fill="#e0e0e0"/><path d="M194.662 203.262c-2.648 0-4.414-.883-6.179-2.648L38.414 50.545c-3.531-3.531-3.531-8.828 0-12.359 3.531-3.531 8.828-3.531 12.359 0l150.069 150.069c3.531 3.531 3.531 8.828 0 12.359-1.766 1.765-3.532 2.648-6.18 2.648" data-original="#BC342E" fill="#bc342e"/><path d="M282.055 147.648c20.303-20.303 22.952-52.083 1.766-73.269-41.49-41.49-65.324-37.076-112.11-37.076 70.62-30.896 121.82-37.958 173.02-14.124 22.069 9.71 30.897 18.538 48.552 36.193l31.779 31.779c7.945 7.945 13.241 18.538 15.007 29.131 2.648 16.772-1.766 20.303 9.71 32.662 3.531 3.531 8.828 3.531 12.359 0 3.531-3.531 8.828-3.531 12.359 0l30.897 30.897c7.062 7.062 7.062 17.655 0 24.717l-62.676 62.676c-7.062 7.062-17.655 7.062-24.717 0L388.87 241.22c-3.531-3.531-3.531-8.828 0-12.359 3.531-3.531 3.531-9.71 0-13.241-12.359-12.359-15.89-7.945-32.662-9.71-11.476-1.766-21.186-7.062-29.131-15.007l-45.022-43.255" data-original="#7F7C79" class="active-path" data-old_color="#7F7C79" fill="#a6acb0"/><path d="M282.055 147.648L8.4 453.966c-10.593 10.593-11.476 28.248-.883 37.959l12.359 12.359c10.593 10.593 27.366 9.71 37.959-.883l274.538-307.2-50.318-48.553z" data-original="#565656" fill="#565656"/><path d="M238.8 300.366l24.717 24.717 62.676-62.676-28.248-28.248-59.145 66.207z" data-original="#E15649" fill="#e15649"/></svg>

	//height={props.height} width={props.width} viewBox={`0 0 ${props.viewW} ${props.viewH}`}
	
)

export default SVG

