import React from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import { observable, decorate, when, toJS } from 'mobx'

import { compose } from 'recompose'

import ProfileScriptFeed from 'components/scripts/profilescriptsfeed.js'

import requests, {Profile, Users, Notifications} from 'axios.js'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import FilePond from 'components/files/filepond.js'
import Avatar from 'components/profile/avatar.js'

import UserNotes from 'components/profile/usernotes.js'
import Collapse from 'react-collapse'

import CreateDescription from 'components/profile/createdescription.js'

import {displayTimeStamp, parseQuery} from 'usefulFunctions.js'

import FollowersTab from 'components/profile/followerstab.js'



//icons
import VerifiedIcon from 'icons/profile/verified.js'
import BasicStarAward from 'icons/profile/basicstaraward.js'
import TopContributorAward from 'icons/profile/topcontributor.js'

//styles
import styles from 'styles/pages/account/profilepage.module.css'


// `${ROUTES.OTHERUSERPROFILEPAGE}?id=${}&username=/${slugify()}`

 class ProfilePage extends React.Component{

	authStore
	userStore
	constructor(props){

		super(props)
		this.authStore = this.props.authStore
		this.userStore = this.props.userStore

		this.queryParams = parseQuery(this.props.location.search)
		this.userid = this.queryParams['id']

		// this.scriptname = this.queryParams['name']

		this.state ={
			username: '',
			loading: true,
			loadFilePond: false,
			loadAltImage: true,
			submitCallback: null,
			follow: false,
			followers: [],
			userid: this.userid,
			filepath: '',
			//metadata
			userstatus: 'regular member',
			pageviews: 0,
			datejoined: '',
			awards: [],
			flairs: [],
			description: '',
			addDescription: false

		}

	}
/* ASYNC DATA  */
	/* _________________ */

	getUserMetaData = () =>{
		var userid = this.userid
		Profile.getUserMetaData(userid)
			.then( res =>{
				var data = res.data.message

				// console.log(data)
				// console.log(res.data.error)
				if(res.data.error || res.data.message.length === 0)
					return

				Users.getuserfromid(userid)
					.then( res2 =>{

						var username = res2.data.message
						this.setState({
							userstatus: data.status === 'none' ? 'regular member' : data.status,
							pageviews: data.pageviews,
							datejoined: data.datejoined,
							awards: data.awards,
							flairs: data.flairs,
							description: data.description,
							username: username
						})

					})
				
			})
			
	}

	getFollowers = () =>{
		var userid = this.userid

		Profile.getFollowersForUser(userid)
			.then( res =>{

				if(res.data.error || res.data.message.length === 0)
					return


				this.setState({
					followers: res.data.message
				})

				console.log("FOLOWERS ARE :")
				console.log(this.state.followers)

				
			})

	}

	isFollowing = e =>{
		var followers = this.state.followers
		followers = followers.filter( follower => follower.followeruserid === this.props.authStore.authFields.id)


		console.log(followers)
		if(followers.length > 0){
			this.setState({
				follow: true
			})

		}
	}



	/* _________________ */


	/* LIFECYCLE METHODS */
	/* _________________ */
	componentDidUpdate(){

		// if(this.state.loading && this.authStore.authFields.username)
		// {
		// 	this.setState({
		// 		loading: false,
		// 		username: this.authStore.authFields.username
		// 	})
		// }

	}

	componentDidMount(){
		// this.userStore.setAvatar()

		this.getUserMetaData()
		this.getFollowers()
		when(
			() => typeof this.props.authStore.authFields.id === 'number',
			() => {

				this.isFollowing()

			}
		)

	}
	/* _________________ */


	

	




	/* INTERNALS */
	/* _________________ */

	followUser = () =>{
		var followeruserid = this.props.authStore.authFields.id
		Profile.followUser(this.state.userid, followeruserid, this.props.authStore.authFields.username, this.props.userStore.avatar.file )
			.then( res =>{
				console.log(res.data.message)

				Notifications.triggerFollowerNotification(this.state.userid, followeruserid)
					.then( resNoti =>{
						if(res.data.message){
							this.setState({
								follow: true
							})
						}
					})
				
			})
	}

	unfollowUser = () =>{
		var followeruserid = this.props.authStore.authFields.id
		var hostuserid = this.state.userid
		Profile.unfollowUser( followeruserid )
			.then( res =>{
				console.log(res.data.message)

				Notifications.unfollowDelete(hostuserid, followeruserid)
					.then( resNoti =>{
						if(res.data.message){
							this.setState({
								follow: false
							})
						}
					})
				
			})
	}

	getfilepathcallback = filepath =>{
		this.setState({
			filepath: filepath
		})
	}
	/* _________________ */

	
	render(){


		// var { username } = this.authStore.authFields.username

		// var { loading, loadFilePond, loadAltImage } = this.state

		var { file, width, height } = this.userStore.anon

		// console.log("HELLOW UWU")
		console.log(this.state)

		// console.log(`hello : ${file}`)

		return(

			

			<div className={styles.maincontainer}>
				<div className={styles.topbanner}>Profile</div>

				<div className={styles.leftpanel}>
					
					<div className={styles.topcolumn}>
						
			{/* Name + Flairs*/}
						<p className={styles.username}>{this.state.username} 
							{	
								this.state.flairs.filter(element => element === 'verified').length > 0
								?
								<VerifiedIcon height='15px' width='15px'/>
								:
								null
							}
						</p>
						<div>
							{
								this.state.awards.filter(element => element === 'top-contributor').length > 0
								?
								<TopContributorAward height='25px' width='25px' />
								:
								null
							}
						</div>


						<div>{this.state.userstatus}</div>
						{/*<div>{this.state.pageviews} views</div>*/}
						<div>joined {displayTimeStamp(this.state.datejoined)}</div>

			{/* Follower count*/}

						<div>{this.state.followers.length} followers</div>
					</div>
		
				<div className={styles.middlecolumn}>
			{/* Avatar image and buttons*/}

					{/*loadAltImage &&<img src={file} alt='Default User' width={width} height={height}/>*/}

					<Avatar userid={this.state.userid}
						width='150px'
						height='150px'
						getfilepathcallback={this.getfilepathcallback}
					 />
				</div>
			
				<div className={styles.bottomcolumn}>
			{/*description*/}

					<div dangerouslySetInnerHTML={{__html: this.state.description}}></div>


			{/*Follow*/}
					{
						this.state.follow === false ?
							<button className={styles.followbutton} onClick={this.followUser} >Follow</button>
						:
							<button className={styles.followbutton} onClick={this.unfollowUser} >Unfollow</button>

					}
				</div>

			
			</div>
	



		{/*TABS*/}
				<Tabs>
		{/*TAB LIST*/}

				  <TabList>
				    <Tab>Notes</Tab>
				    <Tab>My Scripts</Tab>
				    <Tab>Followers</Tab>

				  </TabList>
		{/*TAB PANELS*/}

				  <TabPanel>
				  	<UserNotes
				  		showCreateNote={false}
				  		userid={this.userid}
				  	/>
				  
				  </TabPanel>
				  <TabPanel>
				{/*!loading || this.state.username ? <ProfileScriptFeed author={this.state.username}/> : <h3>HELLO</h3>*/}
					<ProfileScriptFeed author={this.state.username}/>
				  </TabPanel>

				  <TabPanel>
				  	<FollowersTab
				  		userid={this.userid}
				  		followers={this.state.followers}
				  	/>
				  </TabPanel>
				</Tabs>

				

			</div>

			


		)

	}
}


var enhance = compose(
	inject('authStore','userStore'),
	withRouter,
	observer
	)

export default enhance(ProfilePage)

