import React, {lazy, Suspense} from 'react'

import LandingTopNav from 'components/landingtopnav.js'

import { Link, NavLink, withRouter } from 'react-router-dom'


import styles from 'styles/pages/root.module.css'

import Loading from 'components/animations/loading.js'

import * as ROUTES from 'routes.js'


 /* IMAGES */

import ComputerImage from 'images/landing/1.jpg'
import AHKGithubImage from 'images/landing/ahkgithubpic.png'
import AHKpic from 'images/landing/ahkpic.png'
import Derp1 from 'images/landing/derp1.png'
import Derp2 from 'images/landing/derp2.png'

import Examples1 from 'images/landing/examples1.png'
import Examples2 from 'images/landing/examples2.png'
import Examples3 from 'images/landing/examples3.png'


import DashedArrow from 'icons/landing/dashed-arrow.png'


/* VIDEOS */

// import ShowoffVid from 'images/landing/Finalvidrealready-min4.mp4'

/* ICONS */
import BullsEyeIcon from 'images/landing/target.png'
import ScriptMimeLogo from 'icons/topnav/logo.js'
import FeedIcon from 'icons/landing/feedicon.js'
import TagIcon from 'images/landing/tagicon.png'


/* BACKGROUNDS  */

import {renderToStaticMarkup} from 'react-dom/server'

import Background1 from 'icons/landing/backgroundlanding1.js'
import EndlessConstellationBackground from 'icons/landing/endlessconstellation.js'


const MimeSurprised = lazy( () => import('icons/landing/mimesurprised.js') )
const MimeHappy = lazy( () => import('icons/landing/mimehappy.js') )


//styles

import classnames from 'classnames'


function Root(){
	
	const background1string = encodeURIComponent(renderToStaticMarkup(<Background1/>))
	const background2string = encodeURIComponent(renderToStaticMarkup(<EndlessConstellationBackground/>))

	return (
		<div className={styles.landingpagetopcontainer}>
			<LandingTopNav className={styles.topnav}/>

			<div className={styles.container}>
				<img className={styles.computerimg} src={ComputerImage}  alt=""/>


				<div className={styles.computertext1}>This is your computer. Your work horse.</div>

				<div className={styles.computertext2}>You used to think</div>
				<div className={styles.computertext3}>they were magical.</div>

				

				<div className={styles.gradientcomputerimg}>
					<div className={styles.computertext4}>We’re bringing the magic back. 100% free.</div>
					<Link to={ROUTES.INFOLANDING} className={styles.buttonoffers}>See What ScriptMime Offers</Link>
				</div>

				<div className={styles.graycirclecontainer}>
					<div className={styles.graycircle}></div>
					<div className={styles.graycircle}></div>
					<div className={styles.graycircle}></div>
				</div>


				<div style={{backgroundImage: `url("data:image/svg+xml, ${background2string}")`}}>
					
					<div className={styles.introducingrow}>
						<div>Introducing</div>
						<Link className={styles.logo} to={ROUTES.LANDING}>
							<ScriptMimeLogo className='logo' width='120px' height='90px' /> 
						</Link>
					</div>

					<p className={styles.introducingrow2}>ScriptMime is a site for sharing ready and working Autohotkey scripts.</p>

					<div className={styles.introducingrow3}>
						<div className={styles.column1}>
							<div className={styles.description1} >Uses the award-winning, open source, free scripting language Autohotkey.</div>
							<img className={styles.pic1} src={AHKpic} alt=""/>
						</div>
						<div className={styles.column2}>
							<div className={styles.description2} >Built with a ton of features for finding the scripts you need fast.</div>
							<Link to={ROUTES.INFOLANDING} className={styles.buttonlearnmore}>Learn More</Link>

							<div className={styles.container}>
								<div className={styles.row}> 
									<img src={BullsEyeIcon} width="48px" height="48px" alt=""/>
									<div>Powerful search</div>  
								</div>

								<div className={styles.row}> 
									<FeedIcon width="48px" height="48px"/>
									<div>Customized feeds built around Community</div>  
								</div>

								<div className={styles.row}> 
									<img src={TagIcon} width="42px" height="42px" alt=""/>
									<div><span className={styles.hashtag}>#</span>tags to group scripts that are alike</div>  
								</div>
							</div>
						</div>
					</div>
				{/*UNCOMMENT THESE TWO TO SEPARATE THEM INTO 2 DIV AREAS instead of one big area*/}
			{/*	</div>

				
				<div className={styles.videoarea} style={{backgroundImage: `url("data:image/svg+xml, ${background2string}")`}} >*/}
					
					<p className={styles.introducingrow4}>Autohotkey lets you create free programs or “scripts”  that simplify and automate your workflow or do just about anything.</p>

					<Suspense fallback={<Loading />}>
							<iframe className={styles.youtubevid} width="840" height="472" src="https://www.youtube.com/embed/jBAauyKvhAM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>		
					</Suspense>
				</div>

				
				
				<div className={styles.derpcontainer} style={{backgroundImage: `url("data:image/svg+xml, ${background1string}")`}}> 

					<div className={styles.row}> 
						{/*<img src={Derp1} width="134px" height="134px" alt=""/>*/}

						<Suspense fallback={<Loading />}>
							<MimeSurprised width="250px" height="368px" />
						</Suspense>
						<div className={styles.derpquestion}>Do I need to know code?</div>  

					</div>
					<div className={styles.no}>No! <span className={styles.nospan}>ScriptMime is a place to find scripts to plug and play.</span></div>
					<img src={DashedArrow} className={styles.dashedarrow} width="100px" alt=""/>

					<div className={styles.row}> 
						{/*<img src={Derp2} width="134px" height="134px" alt=""/>*/}
						<Suspense fallback={<Loading />}>
							<MimeHappy width="250px" height="368px" />
						</Suspense>
						<div className={styles.derpquestion2}>Can I (a non-techy person) use ScriptMime and Autohotkey?</div>  
					</div>

					<div className={styles.yes}>Yes!</div>


				</div>

				<div className={styles.ifyouare}>If you are...</div>


				<div className={styles.examplescontainer}> 

					<div className={styles.unit1}> 
						<img className={styles.pic1} src={Examples1} alt=""/>
						<div className={styles.description}>A pro player looking for a competitive edge, or a newbie wanting to take his game to the next level.</div>  
						<div className={styles.subdescription}>Autohotkey scripts can be used for any PC game you can think of, from First Person Shooters to single player adventures.
						 From autoclickers to automatic shield blocks, to resource collectors, auto miners, and keyboard remapping. 
						Find scripts for your favorite games. Just make sure it won’t get you into trouble :).
						</div>  

					</div>

					<div className={styles.unit2}> 
						<img className={styles.pic2} src={Examples2} alt=""/>
						<div className={styles.description2}>A podcaster looking to UP his video editing workflow.</div>  
						<div className={styles.subdescription2}>Look for scripts that can do anything from instantly launch the all the programs you need at the same time, 
						to moving them across multiple monitors at the touch of a keyboard. Hop between active windows like a pro. 
						Get or create a shortcut to turn 10 steps of your work flow into 1 key press.
						</div>  

					</div>

					<div className={styles.unit3}> 
						<img className={styles.pic3} src={Examples3} alt=""/>
						<div className={styles.description}>A working professional looking to answer emails, complete tasks, and  manage their environment easier.</div>  
						<div className={styles.subdescription}>Find scripts to automatically insert jargon and repetitive phrases. 
						Get scripts that create triggers for things that happen on the job, shortcuts to move around your PC without all that clicking.
						</div>  

					</div>

					<div className={styles.andmore}>... And more!</div>


				</div>

				
				<div className={styles.learnmorecontainer}> 

					<div className={styles.topleftunit}> 
						<Link to={ROUTES.DOWNLOADINFOPAGE} className={styles.getstartedbutton}>Get Started</Link>
						<div className={styles.description}> Learn how to quickly get started with Autohotkey in this 4 step guide</div>  

					</div>

					<div className={styles.toprightunit}> 
						<Link to={ROUTES.INFOLANDING} className={styles.featuresbutton}>Features & Walkthrough</Link>
						<div className={styles.description2}>See how ScriptMime works, learn about how to use the features you need</div>  

					</div>

					

				</div>
				<div className={styles.or}>OR</div>

				<Link to={ROUTES.SIGNUP} className={styles.buttonsignup}>Sign up & Use ScriptMime for Free</Link>


			</div>


		</div>
	
	)
	
}

export default Root