
// import commonStore from './stores/commonStore';
import authStore from './stores/authStore';


import axios from 'axios'
import * as ROUTES from 'routes.js'

const API_ROOT = process.env.REACT_APP_SERVER_ADDRESS;


console.log('API_ROOT:' + API_ROOT)
// const encode = encodeURIComponent;

const handleErrors = err => {
  if (err && err.response && err.response.status === 401) {
    authStore.logout();
  }
  return err;
};

// const responseBody = res => res.body;

// const tokenPlugin = req => {
//   if (commonStore.token) {
//     req.set('authorization', `Token ${commonStore.token}`);
//   }
// };

const requests = {
  delete: url =>
    axios
      .delete(`${API_ROOT}${url}`),
      // .use(tokenPlugin)
      // .end(handleErrors)
      // .then(responseBody),
  get: url =>
    axios
      .get(`${API_ROOT}${url}`),
      // .use(tokenPlugin)
      // .end(handleErrors)
      // .then(responseBody),
  put: (url, body) =>
    axios
      .put(`${API_ROOT}${url}`, body),
      // .use(tokenPlugin)
      // .end(handleErrors)
      // .then(responseBody),
  post: (url, body) =>
    axios
      .post(`${API_ROOT}${url}`, body),
      // .use(tokenPlugin)
      // .end(handleErrors)
      // .then(responseBody),
};

const Auth = {};

const Tags = {};

const Users = {
  getid: (username) =>{
    return requests.post(`${ROUTES.API.getid}`, {username: username})
  },

  getuserfromid: (userid) =>{
    return requests.post(`${ROUTES.API.getuserfromid}`, {userid: userid})
  },

  getidfromuser: (username) =>{
    return requests.post(`${ROUTES.API.getidfromuser}`, {username: username})
  },

  getAvatar: (userid) =>{
    return requests.post(`${ROUTES.API.getavatar}`, {userid: userid})
  },

  getInitialUserMetaData: (userid) =>{
    return requests.post(`${ROUTES.API.getInitialUserMetaData}`, {userid: userid})
  },

  storeMobxStores: (userid, storenames, storecontents) =>{
    return requests.post(`${ROUTES.API.storeMobxStores}`, {userid: userid, storenames: storenames, storecontents: storecontents})
  },

  getMobxStores: (userid) =>{
    return requests.post(`${ROUTES.API.getMobxStores}`, {userid: userid})
  },

  changePasswordDatabase: (userid, password) =>{
    return requests.post(`${ROUTES.API.changePasswordDatabase}`, {userid: userid, password: password})
  },


  addUsertoMailList: (uid, email, username) =>{
    return requests.post(`${ROUTES.API.addUsertoMailList}`, {uid: uid, email: email, username: username})
  },


};

// const limit = (count, p) => `limit=${count}&offset=${p ? p * count : 0}`;
// const omitSlug = article => Object.assign({}, article, { slug: undefined })

const Scripts = {

  author:{
    byDateEarliest: (page, limit, less_or_more, id_last_item, userid) =>{
    
      return requests.post(`${ROUTES.API.authorgetallscripts}`, {how:'byDateEarliest', less_or_more: less_or_more , args:{limit: limit, id_last_item: id_last_item, userid: userid}})
    },

    byDateLatest: (page, limit, less_or_more, id_last_item, userid) =>{
    
      return requests.post(`${ROUTES.API.authorgetallscripts}`, {how:'byDateLatest', less_or_more: less_or_more , args:{limit: limit, id_last_item: id_last_item, userid: userid}})
    },
    byCategory: (page, limit, less_or_more, id_last_item, category, userid) =>{

      return requests.post(`${ROUTES.API.authorgetallscripts}`, {how:'byCategory', less_or_more: less_or_more , args:{limit: limit,id_last_item: id_last_item, category: category, userid: userid}})

    },
    bySubcategory: (page, limit, less_or_more, id_last_item, subcategory, userid) =>{
      return requests.post(`${ROUTES.API.authorgetallscripts}`, {how:'bySubcategory', less_or_more: less_or_more , args:{limit: limit, id_last_item: id_last_item, subcategory: subcategory, userid: userid}})

    },
    byCategoryAndSubcategory: (page, limit, less_or_more, id_last_item, category, subcategory, userid) =>{
      return requests.post(`${ROUTES.API.authorgetallscripts}`, {how:'byCategoryAndSubcategory', less_or_more: less_or_more , args:{limit: limit, id_last_item: id_last_item, category: category, subcategory:subcategory, userid: userid}})

    }


  },

  byAuthor: (page, limit, less_or_more, id_last_item, username, email) =>{

    // console.log('HERE???')

    requests.post(`${ROUTES.API.getid}`, {username: username, email: email})
      .then( res =>{

        var userId = res.data.id
        return requests.post(`${ROUTES.API.getallscripts}`, {how:'byAuthor', less_or_more: less_or_more , args:{limit: limit, id_last_item: id_last_item, userId: userId}})
      })
    
    
  },
  byDateEarliest: (page, limit, less_or_more, id_last_item) =>{
    
    return requests.post(`${ROUTES.API.getallscripts}`, {how:'byDateEarliest', less_or_more: less_or_more , args:{limit: limit, id_last_item: id_last_item}})
  },

  byDateLatest: (page, limit, less_or_more, id_last_item) =>{
    
    return requests.post(`${ROUTES.API.getallscripts}`, {how:'byDateLatest', less_or_more: less_or_more , args:{limit: limit, id_last_item: id_last_item}})
  },

  byCategory: (page, limit, less_or_more, id_last_item, category) =>{

    return requests.post(`${ROUTES.API.getallscripts}`, {how:'byCategory', less_or_more: less_or_more , args:{limit: limit,id_last_item: id_last_item, category: category}})

  },

  bySubcategory: (page, limit, less_or_more, id_last_item, subcategory) =>{
    return requests.post(`${ROUTES.API.getallscripts}`, {how:'bySubcategory', less_or_more: less_or_more , args:{limit: limit, id_last_item: id_last_item, subcategory: subcategory}})

  },

  byCategoryAndSubcategory: (page, limit, less_or_more, id_last_item, category, subcategory) =>{
    return requests.post(`${ROUTES.API.getallscripts}`, {how:'byCategoryAndSubcategory', less_or_more: less_or_more , args:{limit: limit, id_last_item: id_last_item, category: category, subcategory:subcategory}})

  },

  submitTags: (tags) =>{
    return requests.post(`${ROUTES.API.submitTags}`, {tags: tags})

  },

  setScriptVoteCount: (scriptid, state) =>{
    return requests.post(`${ROUTES.API.setScriptVoteCount}`, {scriptid: scriptid, state: state})

  },

  editScriptPagePart: (part,column,  scriptid) =>{
    return requests.post(`${ROUTES.API.editScriptPagePart}`, {part: part,column: column, scriptid: scriptid})

  },

  deleteScript: (scriptid) =>{
    return requests.post(`${ROUTES.API.deleteScript}`, {scriptid: scriptid})

  },

  alltimebest: {
    byDateEarliest: (page, limit, less_or_more, id_last_item) =>{
      
      return requests.post(`${ROUTES.API.getallscriptsalltimebest}`, {how:'byDateEarliest', less_or_more: less_or_more , args:{limit: limit, id_last_item: id_last_item}})
    },

    byDateLatest: (page, limit, less_or_more, id_last_item) =>{
      
      return requests.post(`${ROUTES.API.getallscriptsalltimebest}`, {how:'byDateLatest', less_or_more: less_or_more , args:{limit: limit, id_last_item: id_last_item}})
    },

    byCategory: (page, limit, less_or_more, id_last_item, category) =>{

      return requests.post(`${ROUTES.API.getallscriptsalltimebest}`, {how:'byCategory', less_or_more: less_or_more , args:{limit: limit,id_last_item: id_last_item, category: category}})

    },

    bySubcategory: (page, limit, less_or_more, id_last_item, subcategory) =>{
      return requests.post(`${ROUTES.API.getallscriptsalltimebest}`, {how:'bySubcategory', less_or_more: less_or_more , args:{limit: limit, id_last_item: id_last_item, subcategory: subcategory}})

    },

    byCategoryAndSubcategory: (page, limit, less_or_more, id_last_item, category, subcategory) =>{
      return requests.post(`${ROUTES.API.getallscriptsalltimebest}`, {how:'byCategoryAndSubcategory', less_or_more: less_or_more , args:{limit: limit, id_last_item: id_last_item, category: category, subcategory:subcategory}})

    },
  },

  userfollow: {
    byDateEarliest: (page, followeruserid, limit, less_or_more, id_last_item) =>{
      
      return requests.post(`${ROUTES.API.getallscriptsuserfollow}`, {how:'byDateEarliest',followeruserid: followeruserid, less_or_more: less_or_more , args:{limit: limit, id_last_item: id_last_item, }})
    },

    byDateLatest: (page, followeruserid, limit, less_or_more, id_last_item) =>{
      
      return requests.post(`${ROUTES.API.getallscriptsuserfollow}`, {how:'byDateLatest',followeruserid: followeruserid, less_or_more: less_or_more , args:{limit: limit, id_last_item: id_last_item, }})
    },

    byCategory: (page, followeruserid, limit, less_or_more, id_last_item, category) =>{

      return requests.post(`${ROUTES.API.getallscriptsuserfollow}`, {how:'byCategory',followeruserid: followeruserid, less_or_more: less_or_more , args:{limit: limit,id_last_item: id_last_item, category: category, }})

    },

    bySubcategory: (page, followeruserid, limit, less_or_more, id_last_item, subcategory) =>{
      return requests.post(`${ROUTES.API.getallscriptsuserfollow}`, {how:'bySubcategory',followeruserid: followeruserid, less_or_more: less_or_more , args:{limit: limit, id_last_item: id_last_item, subcategory: subcategory, }})

    },

    byCategoryAndSubcategory: (page, followeruserid, limit, less_or_more, id_last_item, category, subcategory) =>{
      return requests.post(`${ROUTES.API.getallscriptsuserfollow}`, {how:'byCategoryAndSubcategory',followeruserid: followeruserid, less_or_more: less_or_more , args:{limit: limit, id_last_item: id_last_item, category: category, subcategory:subcategory, }})

    },
  },

  tagsfollow: {
    byDateEarliest: (page, followeruserid, limit, less_or_more, id_last_item) =>{
      
      return requests.post(`${ROUTES.API.getallscriptstagfollow}`, {how:'byDateEarliest',followeruserid: followeruserid, less_or_more: less_or_more , args:{limit: limit, id_last_item: id_last_item, }})
    },

    byDateLatest: (page, followeruserid, limit, less_or_more, id_last_item) =>{
      
      return requests.post(`${ROUTES.API.getallscriptstagfollow}`, {how:'byDateLatest',followeruserid: followeruserid, less_or_more: less_or_more , args:{limit: limit, id_last_item: id_last_item, }})
    },

    byCategory: (page, followeruserid, limit, less_or_more, id_last_item, category) =>{

      return requests.post(`${ROUTES.API.getallscriptstagfollow}`, {how:'byCategory',followeruserid: followeruserid, less_or_more: less_or_more , args:{limit: limit,id_last_item: id_last_item, category: category, }})

    },

    bySubcategory: (page, followeruserid, limit, less_or_more, id_last_item, subcategory) =>{
      return requests.post(`${ROUTES.API.getallscriptstagfollow}`, {how:'bySubcategory',followeruserid: followeruserid, less_or_more: less_or_more , args:{limit: limit, id_last_item: id_last_item, subcategory: subcategory, }})

    },

    byCategoryAndSubcategory: (page, followeruserid, limit, less_or_more, id_last_item, category, subcategory) =>{
      return requests.post(`${ROUTES.API.getallscriptstagfollow}`, {how:'byCategoryAndSubcategory',followeruserid: followeruserid, less_or_more: less_or_more , args:{limit: limit, id_last_item: id_last_item, category: category, subcategory:subcategory, }})

    },
  },



  savedscripts: {
    getSavedScriptsByDateEarliest: (userid, limit, less_or_more, id_last_item) =>{
      
      return requests.post(`${ROUTES.API.getallscriptssaved}`, {how:'byDateEarliest', less_or_more: less_or_more , args:{limit: limit, id_last_item: id_last_item, userid: userid}})
    },

    getSavedScriptsByDateOldest: (userid, limit, less_or_more, id_last_item) =>{
      
      return requests.post(`${ROUTES.API.getallscriptssaved}`, {how:'byDateOldest', less_or_more: less_or_more , args:{limit: limit, id_last_item: id_last_item, userid: userid}})
    },

    saveScript: (script, userid, scriptname, submitdate, categories, tags, description, votecount, awards, scriptid, saveruserid) =>{
    return requests.post(`${ROUTES.API.saveScript}`, {script: script, userid: userid, scriptname: scriptname, submitdate: submitdate, categories: categories, tags: tags, description: description, votecount: votecount, awards: awards, scriptid: scriptid, saveruserid: saveruserid})

    },

    unsaveScript: (scriptid, saveruserid) =>{
    return requests.post(`${ROUTES.API.unsaveScript}`, {scriptid: scriptid, saveruserid: saveruserid})

    },

  },

  tags:{
    getAllTagsByDateEarliest: (limit, less_or_more, id_last_item) =>{
    return requests.post(`${ROUTES.API.getAllTags}`, {how:'byDateEarliest', less_or_more: less_or_more , args:{limit: limit, id_last_item: id_last_item}})

    },


    getAllTagsByDateOldest: ( limit, less_or_more, id_last_item) =>{
      
      return requests.post(`${ROUTES.API.getAllTags}`, {how:'byDateOldest', less_or_more: less_or_more , args:{limit: limit, id_last_item: id_last_item}})
    },

    getNumScriptsPublished: ( tag) =>{
    return requests.post(`${ROUTES.API.getNumScriptsPublished}`, {  tag: tag})

    },

    followTag: ( tag, followeruserid) =>{
    return requests.post(`${ROUTES.API.followTag}`, {  tag: tag, followeruserid: followeruserid})

    },

    unfollowTag: ( tag, followeruserid) =>{
    return requests.post(`${ROUTES.API.unfollowTag}`, {  tag: tag, followeruserid: followeruserid})

    },

    getTaggedScriptsByDateEarliest: (limit, less_or_more, id_last_item, tag) =>{
    return requests.post(`${ROUTES.API.getAllTaggedScripts}`, {how:'byDateEarliest', less_or_more: less_or_more , args:{limit: limit, id_last_item: id_last_item, tag: tag}})

    },


    getTaggedScriptsByDateOldest: ( limit, less_or_more, id_last_item, tag) =>{
      
      return requests.post(`${ROUTES.API.getAllTaggedScripts}`, {how:'byDateOldest', less_or_more: less_or_more , args:{limit: limit, id_last_item: id_last_item, tag: tag}})
    },

    getScriptByTagtag: ( tagtag) =>{
    return requests.post(`${ROUTES.API.getScriptByTagtag}`, {  tagtag: tagtag})

    },


  }

}
  


const Comments = {
  
  submitScriptComment: (commentbody, scriptid, userid, parentid) =>{
    return requests.post(`${ROUTES.API.submitscriptcomment}`, {commentbody: commentbody, scriptid, scriptid, userid: userid, parentid: parentid})
  },

  editComment: (commentbody, commentid) =>{
    return requests.post(`${ROUTES.API.editcomment}`, {commentbody: commentbody, commentid: commentid})
  },

  getScriptComments: (scriptid) =>{
    return requests.post(`${ROUTES.API.getcommentsbyscriptid}`, {scriptid: scriptid})
  },

  getScriptCommentRelationship: (scriptid) =>{
    return requests.post(`${ROUTES.API.getscriptcommentrelationship}`, {scriptid: scriptid})
    
  },

  getCommentVoteCount: (commentid, state) =>{
    return requests.post(`${ROUTES.API.getCommentVoteCount}`, {commentid: commentid, state: state})
    
  },

  getNumComments: (scriptid) =>{
    return requests.post(`${ROUTES.API.getNumComments}`, {scriptid: scriptid})
    
  },

  
  getUseridFromScriptid: (scriptid) =>{
    return requests.post(`${ROUTES.API.getUseridFromScriptid}`, {scriptid: scriptid})
    
  },

};

const Profile = {
  //comments
  submitUserProfileComment: (commentbody, userid, ) =>{
    return requests.post(`${ROUTES.API.submitUserProfileComment}`, {commentbody: commentbody,  userid: userid, })
  },

  getUserProfileCommentsByUserid: (userid, limit, id_last_item) =>{
    return requests.post(`${ROUTES.API.getUserProfileCommentsByUserid}`, { userid: userid, limit: limit, id_last_item: id_last_item})
  },
  deleteProfileComment: (commentid) =>{
    return requests.post(`${ROUTES.API.deleteProfileComment}`, { commentid: commentid})
  },
  //usermetadata
  getUserMetaData: (userid) =>{
    return requests.post(`${ROUTES.API.getUserMetaData}`, { userid: userid})
  },
  submitProfileDescription: (commentbody, userid, ) =>{
    return requests.post(`${ROUTES.API.submitProfileDescription}`, {commentbody: commentbody,  userid: userid, })
  },

  //followers
  getFollowersForUser: ( userid, ) =>{
    return requests.post(`${ROUTES.API.getFollowersForUser}`, { userid: userid, })
  },

  followUser: ( hostuserid, followeruserid, username, filepath ) =>{
    return requests.post(`${ROUTES.API.followUser}`, { hostuserid: hostuserid, followeruserid: followeruserid, username: username, filepath: filepath })
  },

  unfollowUser: ( followeruserid ) =>{
    return requests.post(`${ROUTES.API.unfollowUser}`, {  followeruserid: followeruserid })
  },

};

const Notifications = {
  triggerRootCommentNotification: (hostuserid, commenteruserid, comment, scriptid) =>{
    return requests.post(`${ROUTES.API.triggerRootCommentNotification}`, {hostuserid: hostuserid, commenteruserid: commenteruserid, comment: comment, scriptid: scriptid })
  },

  triggerReplyCommentNotification: (hostuserid, commenteruserid, comment, scriptid) =>{
    return requests.post(`${ROUTES.API.triggerReplyCommentNotification}`, {hostuserid: hostuserid, commenteruserid: commenteruserid, comment: comment, scriptid: scriptid })
  },

  triggerFollowerNotification: (hostuserid, followeruserid) =>{
    return requests.post(`${ROUTES.API.triggerFollowerNotification}`, {hostuserid: hostuserid, followeruserid: followeruserid})
  },

  unfollowDelete: (hostuserid, followeruserid) =>{
    return requests.post(`${ROUTES.API.unfollowDelete}`, {hostuserid: hostuserid, followeruserid: followeruserid})
  },

  getAllNotificationsForUser: (hostuserid, limit) =>{
    return requests.post(`${ROUTES.API.getAllNotificationsForUser}`, {hostuserid: hostuserid, limit: limit})
  },

  getReplyCommentHostUserid: (id) =>{
    return requests.post(`${ROUTES.API.getReplyCommentHostUserid}`, {id: id})
  },

  deleteNotification: (notificationid, type) =>{
    return requests.post(`${ROUTES.API.deleteNotification}`, {notificationid: notificationid, type: type})
  },

  deleteAllNotifications: (hostuserid) =>{
    return requests.post(`${ROUTES.API.deleteAllNotifications}`, {hostuserid: hostuserid})
  },

};

const Messages = {

  getMessagesForUser: (hostuserid, limit, id_last_item, how) =>{
    return requests.post(`${ROUTES.API.getMessagesForUser}`, { hostuserid: hostuserid, limit: limit, id_last_item: id_last_item, how: how})
  },

  getNumberOfMessages: (hostuserid) =>{
    return requests.post(`${ROUTES.API.getNumberOfMessages}`, { hostuserid: hostuserid})
  },

  sendMessageToUser: (hostuserid, senderuserid, message ) =>{
    return requests.post(`${ROUTES.API.sendMessageToUser}`, { hostuserid: hostuserid, senderuserid: senderuserid, message: message})
  },

}

const Search = {

  searchScriptsTags: (searchquery, limit, offset, less_or_more) =>{
    return requests.post(`${ROUTES.API.searchScriptsTags}`, {less_or_more: less_or_more, args:{ searchquery: searchquery, limit: limit, offset: offset} })
  },
  searchTags: (searchquery, limit) =>{
    return requests.post(`${ROUTES.API.searchTags}`, {searchquery: searchquery, limit: limit})
  },
  searchUsers: (searchquery, limit) =>{
    return requests.post(`${ROUTES.API.searchUsers}`, {searchquery: searchquery, limit: limit})
  },
  searchUsersNavbar: (searchquery, limit, offset, less_or_more) =>{
    return requests.post(`${ROUTES.API.searchUsersNavbar}`, {less_or_more: less_or_more, args:{ searchquery: searchquery, limit: limit, offset: offset} })
  },



};

const Blog = {

  getBlogs: ( less_or_more, limit, id_last_item) =>{
    
      return requests.post(`${ROUTES.API.getBlogs}`, {less_or_more: less_or_more, args:{limit: limit, id_last_item: id_last_item}})
    },

  submitBlog: (blog, userid, username, blogname, categories, ) =>{
    return requests.post(`${ROUTES.API.submitBlog}`, {blog: blog, userid: userid, username:username, blogname:blogname, categories:categories})
  },
  getBlog: (blogname) =>{
    return requests.post(`${ROUTES.API.getBlog}`, {blogname:blogname})
  },

  getBlogFromId: (blogid) =>{
    return requests.post(`${ROUTES.API.getBlogFromId}`, {blogid:blogid})
  },

  editBlogPagePart: (part,column,  blogid) =>{
    return requests.post(`${ROUTES.API.editBlogPagePart}`, {part: part,column: column, blogid: blogid})

  },


}


export {
  Scripts,
  Auth,
  Comments,
  Profile,
  Tags,
  Notifications,
  Messages,
  Users,
  Search,
  Blog
};

export default requests