import React, { Component } from 'react'
import lottie from 'lottie-web'
import animationData from 'lotties/14721-loading.json'

class UncontrolledLottie extends Component {

  state = {delay: 0}

  componentDidMount(){

    let animObj = lottie.loadAnimation({
      container: this.lottiediv,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationData
    })
  }

  render(){

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    return (
      <div >
        
        <div ref={ref =>this.lottiediv = ref} style={{width:'200px', height:'300px'}}></div>
      </div>
    )
  }
}

export default UncontrolledLottie

/*
<Lottie options={defaultOptions}
              height={400}
              width={400}
              delay={this.state.delay}
        />
*/