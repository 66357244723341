import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { observer, inject } from 'mobx-react'

import { observable, decorate, when } from 'mobx'

import { SignUpLink } from 'pages/account/signup'

import * as ROUTES from 'routes.js'

import { compose } from 'recompose'

import requests, {Users} from 'axios.js'

import { GoogleReCaptcha } from 'react-google-recaptcha-v3'

import { toast } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

import styles from 'styles/pages/account/login.module.css'

import GoogleLogo from 'images/account/google.png'

var server_address = process.env.REACT_APP_SERVER_ADDRESS

// console.log(server_address+'')




class LoginPage extends React.Component {


  loggedIn = () =>{
    var authStore = this.props.authStore

    // logged in but no username yet
    if(authStore.authorizedUser && (authStore.authFields.username === '' || authStore.authFields.username === null) ){
      // console.log('nope?')

      // this.props.history.push(ROUTES.GETSTARTED)
      this.props.history.push(ROUTES.AUTHHOME)

    }

    // signed in with google or github and no id
    else if(authStore.authorizedUser && authStore.authFields.username.length > 0 && typeof authStore.authFields.id !== 'number'){
      console.log("HERE IN LOGIN")

      Users.getid(authStore.authFields.username)
        .then( res =>{
          if(res.data.id){
            this.props.authStore.authFields.id = res.data.id
            this.props.history.push(ROUTES.AUTHHOME)
            
          }
        })
    }

    // else if(authStore.authorizedUser && authStore.authFields.username.length > 0){
    //   this.props.history.push(ROUTES.AUTHHOME)
    // }
  }

  componentDidMount(){
    when(
      () => this.props.authStore.authFields.username.length > 0 || typeof this.props.authStore.authFields.id === 'number' || this.props.authStore.authorizedUser === true ,
      () => {
        this.loggedIn()
      }
    )
  }
  render(){

    
    //NO GITHUB FOR NOW
    return (
      <div className={styles.maincontainer}>
        <LoginForm />

        <div className={styles.bottomrow}>
          <SignUpLink />
          <GoogleSignIn signup={false} />
        </div>
        
        
        {/*<GithubSignIn signup={false} />*/}
      </div>
    )
  }
  

}

var enhance = compose(
  inject('authStore', 'scriptStore'),
  withRouter,
  observer
  )

export default enhance(LoginPage)

/*GITHUB LOGIN */
class GithubSignInBase extends React.Component{
  authStore
  firebase
  cstate ={
    form: null
  }
  constructor(props){
    super(props)
    this.authStore = this.props.authStore
    this.firebase = this.props.firebaseStore.firebaseAccess.firebase
    
  }

  componentDidUpdate(){
    var authStore = this.props.authStore
    var firebase = this.firebase

    var redirect = window.localStorage.getItem('loadingRedirect')

    if(redirect){
      firebase.auth.getRedirectResult()
      .then( result =>{

        if(result){
          console.log('made it to redirect promise!')

          if(!result.user)
            return
          var user = result.user

          requests.post(`${ROUTES.API.checkusername}`, {username: user.displayName, email: user.email })
            .then( res =>{
              if (result.credential){
                //import API access token
                var token = result.credential.accessToken
                window.localStorage.setItem('loadingRedirect', false)

                var uid = user.uid

                if(res.data.valid){ //username not exist in db
                  requests.post(`${ROUTES.API.signup3rdparty}`, {username: user.displayName, password: '', email: user.email, role: 'none', uid: uid  })
                    .then( res =>{
                      // this.props.history.push(ROUTES.AUTHHOME)
                      console.log('signed user up after 3rd party login')

                    })
                }

                else{
                  toast.error('Email taken. You have been signed In with a temporary account.', {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  });

                }

              }
            })

        }
      
      
    })
      .catch( error => {

        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;

      })
    }

    if(authStore.authorizedUser && (authStore.authFields.username === '' || authStore.authFields.username === null) ){
      // console.log('nope?')

      this.props.history.push(ROUTES.GETSTARTED)
    }

    else if(authStore.authorizedUser && authStore.authFields.username.length > 0){
      this.props.history.push(ROUTES.AUTHHOME)
    }
  }
 

  signInWithGithub = e =>{

    window.localStorage.setItem('loadingRedirect', true)

    var firebase = this.firebase
    var provider = new firebase.functionlessauth.GithubAuthProvider() 

    
    firebase.auth.signInWithRedirect(provider)
    

  }

  render(){
    
    return (

      <button  onClick={this.signInWithGithub}>Sign {this.props.signup ? 'Up' : 'In'} With Github</button>
    )
  }
}

decorate(GithubSignInBase, {
  cstate: observable
})

var GithubSignIn = compose(
  inject('authStore', 'firebaseStore'),
  withRouter,
  observer
  )(GithubSignInBase)



/* GOOGLE SIGN IN */
class GoogleSignInBase extends React.Component{

  firebase
  constructor(props){
    super(props)
    this.firebase = this.props.firebaseStore.firebaseAccess.firebase
    
  }

  componentDidMount(){

    this.firebase.auth.getRedirectResult()
      .then( result =>{
         if(result){
          console.log('made it to redirect promise!')

          if(!result.user)
            return
          var user = result.user

          requests.post(`${ROUTES.API.checkusername}`, {username: user.displayName, email: user.email })
            .then( res =>{
              if (result.credential){
                //import API access token
                var token = result.credential.accessToken
                // window.localStorage.setItem('loadingRedirect', false)

                var uid = user.uid

                if(res.data.valid){ //username not exist in db
                  requests.post(`${ROUTES.API.signup3rdparty}`, {username: user.displayName, password: '', email: user.email, role: 'none', uid: uid  })
                    .then( res =>{
                      // this.props.history.push(ROUTES.AUTHHOME)
                      console.log('signed user up after 3rd party login')

                      Users.addUsertoMailList(user.uid, user.email, user.displayName)
                        .then( resEmailList =>{
                          
                        })

                    })
                }

                else{
                  toast.error('Email taken. You have been signed In with a temporary account.', {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  });

                }

              }
            })
          

          

        }
      })

  }

  signInWithGoogle = e =>{
    var firebase = this.firebase

    if(typeof this.props.authStore.authFields.id === 'number'){
      console.log('hiooo')
      return
    }

    var provider = new firebase.functionlessauth.GoogleAuthProvider() 

    
    firebase.auth.signInWithRedirect(provider)
    

  }

  render(){
    var disabled = typeof this.props.authStore.authFields.id === 'number'
    return (

      <button disabled={disabled} className={styles.googlesigninbutton} onClick={this.signInWithGoogle}><img src={GoogleLogo} width="50px" height="50px" alt=""/> Sign {this.props.signup ? 'Up' : 'In'} With Google</button>
    )
  }
}

var GoogleSignIn = compose(
  inject('authStore', 'firebaseStore'),
  withRouter,
  observer
  )(GoogleSignInBase)


// @inject('authStore', 'firebaseStore')
// @withRouter
// @observer

class LoginFormBase extends React.Component{
  
  formState ={
    email: '',
    password: '',
    recaptcha: false,
    signupError: '',
  }

  authStore

  constructor(props){
    
    super(props)

  }

  componentDidMount(){
    this.authStore = this.props.authStore
  }






  onSubmit = event => {

    event.preventDefault()
    
    this.formState.signupError = ''
    var { authFields, errors, inProgress } = this.props.authStore

    var { email, password } = this.formState

    var firebase = this.props.firebaseStore.firebaseAccess.firebase

    if(typeof this.props.authStore.authFields.id === 'number')
      this.formState.signupError = 'Already logged in to an account. Please log out.'

    requests.post(`${ROUTES.API.login}`, {email: email, password: password})
      .then( res =>{
      
        //there will be an object {message:err} if user is not found
          console.log('message'+res.data)

        if(res.data.message){
          console.log(res.data.message)
          return res.data.message
        }

        //email doesn't exist, (user not found)
        if(res.data.err1){
          this.formState.signupError = 'user associated with this email not found'
          return
        }
        //password is wrong
        if(res.data.err2){
          this.formState.signupError = 'incorrect password'
          return
          
        }
        //server error
        if(res.data.err3){
          this.formState.signupError = 'server error. Try again in a few minutes.'
          return
          
        }

        else if(res.data.role){
          console.log(`we got a role ${res.data.role}`)
          var role = res.data.role
          if(role === 'admin'){
            window.localStorage.setItem('role', 'admin')
          }
        }

        //receive token, role in res.data
        firebase.doSignInWithCustomToken(res.data.token)
          .then( authUser =>{
            // authStore.setAuthorizedUser(true)

            // authStore.setUsername(authUser.displayName)
            // authStore.setPassword(authUser.password)
            // authStore.setEmail(authUser.email)
            // authStore.setEmailVerified(authUser.emailVerified)

            this.props.history.push(ROUTES.AUTHHOME)

          })
          .catch( error =>{
            var errorCode = error.code;
            var errorMessage = error.message;
            if (errorCode === 'auth/invalid-custom-token') {
              alert('The token you provided is not valid.');
            } else {
              console.error(error);
            }

          })

      })

  }

  onChangeEmail = event => {

    this.formState.email = event.target.value

  }


  onChangePassword = event => {
    
    this.formState.password = event.target.value

  }

  recaptchaVerify = token => {
    this.formState.recaptcha = true
     // console.log('recaptcha')
  }


  render(){

    var { authFields, errors, inProgress } = this.props.authStore

    var formState = this.formState
    var isInvalid = formState.email === '' ||
      formState.password === '' 
      // || formState.recaptcha === false


    return (

    <div className={styles.loginform} >
      <p className={styles.heading}>Login</p>
      <form className={styles.mainform} onSubmit={this.onSubmit}>
        <input 
          type="text"
          value={formState.email}
          onChange={this.onChangeEmail}
          placeholder='email'
          name='email'  
          className={styles.input}
        />
       
        <input 
        type="password"
        value={formState.password}
        onChange={this.onChangePassword}
        placeholder='password'
        name='password' 
        className={styles.input} 
      />


      {/**/}  
      <GoogleReCaptcha onVerify={this.recaptchaVerify}/>
        <button className={styles.submitbutton} disabled={isInvalid}>Login</button>
        {
          formState.signupError !== '' ?
            <p>{formState.signupError}</p>
          :
            null

        }
      </form>
    </div>

    )
  }

}

decorate(LoginFormBase,{
  formState: observable
})

var LoginForm = compose(
  inject('authStore', 'firebaseStore'),
  withRouter,
  observer
  )(LoginFormBase)

// export default LoginPage



export { LoginForm, GoogleSignIn, GithubSignIn }