//get the stored class

const storeRefresh = (storeString, storeInstance) =>{
  let json = localStorage.getItem(storeString)
  
  //it's in json
  json = JSON.parse(json)


  //get an array of strings of each observable in the class -- ex ['avatar', 'scripts']
  let obj = Object.getOwnPropertyNames(storeInstance)


  obj.forEach( (property, index, arr) =>{
    //check if the json object has the name of the class property
    if(json !== null && json.hasOwnProperty(property)){
      storeInstance[property] = json[property]
    }
              
  })

  return Promise.resolve()
}


export default storeRefresh