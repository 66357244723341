


import React from 'react'

const SVG = (props) =>

	{			
	return(



	<svg height={props.height} width={props.width} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
		 viewBox="0 0 511.997 511.997"  >
	<g>
		<path style={{fill:'#FFAF46'}} d="M255.998,51.199c-4.71,0-8.533-3.823-8.533-8.533V8.533c0-4.71,3.823-8.533,8.533-8.533
			c4.71,0,8.533,3.823,8.533,8.533v34.133C264.532,47.376,260.717,51.199,255.998,51.199z"/>
		<path style={{fill:'#FFAF46'}} d="M174.933,72.916c-3.055,0-5.871-1.621-7.398-4.267l-17.066-29.55
			c-2.381-4.062-1.015-9.293,3.046-11.673s9.293-1.015,11.673,3.046c0.017,0.034,0.034,0.068,0.06,0.094l17.066,29.55
			c2.355,4.079,0.956,9.301-3.123,11.656C177.903,72.524,176.435,72.916,174.933,72.916z"/>
		<path style={{fill:'#FFAF46'}} d="M115.576,132.264c-1.502,0-2.97-0.393-4.267-1.143l-29.55-17.066c-4.096-2.33-5.53-7.535-3.2-11.639
			c2.33-4.096,7.535-5.53,11.639-3.2c0.034,0.017,0.068,0.034,0.094,0.06l29.55,17.066c4.079,2.355,5.478,7.577,3.123,11.656
			C121.447,130.635,118.631,132.264,115.576,132.264z"/>
		<path style={{fill:'#FFAF46'}} d="M93.868,213.33H59.735c-4.71,0-8.533-3.823-8.533-8.533c0-4.71,3.823-8.533,8.533-8.533h34.133
			c4.71,0,8.533,3.823,8.533,8.533C102.401,209.507,98.587,213.33,93.868,213.33z"/>
		<path style={{fill:'#FFAF46'}} d="M86.043,311.461c-4.71,0-8.533-3.823-8.533-8.533c0-3.046,1.63-5.862,4.267-7.39l29.55-17.066
			c4.062-2.381,9.293-1.024,11.673,3.046c2.381,4.062,1.024,9.293-3.046,11.673c-0.034,0.017-0.068,0.034-0.102,0.06l-29.55,17.066
			C89.012,311.069,87.545,311.461,86.043,311.461z"/>
		<path style={{fill:'#FFAF46'}} d="M425.954,311.461c-1.502,0-2.97-0.393-4.267-1.143l-29.55-17.066
			c-4.096-2.33-5.529-7.535-3.2-11.639c2.33-4.096,7.535-5.53,11.639-3.2c0.034,0.017,0.068,0.034,0.102,0.06l29.55,17.066
			c4.079,2.355,5.478,7.577,3.123,11.656C431.825,309.832,429.009,311.461,425.954,311.461z"/>
		<path style={{fill:'#FFAF46'}} d="M452.262,213.33h-34.133c-4.71,0-8.533-3.823-8.533-8.533c0-4.71,3.823-8.533,8.533-8.533h34.133
			c4.71,0,8.533,3.823,8.533,8.533C460.795,209.507,456.981,213.33,452.262,213.33z"/>
		<path style={{fill:'#FFAF46'}} d="M396.421,132.264c-4.71,0-8.533-3.823-8.533-8.533c0-3.046,1.63-5.862,4.267-7.39l29.55-17.066
			c4.062-2.381,9.293-1.015,11.673,3.046c2.381,4.062,1.015,9.293-3.046,11.673c-0.034,0.017-0.068,0.034-0.094,0.06l-29.55,17.066
			C399.39,131.872,397.923,132.264,396.421,132.264z"/>
		<path style={{fill:'#FFAF46'}} d="M337.064,72.916c-4.71-0.009-8.533-3.831-8.525-8.542c0-1.493,0.393-2.961,1.143-4.258l17.066-29.55
			c2.33-4.096,7.535-5.53,11.639-3.2c4.096,2.33,5.53,7.535,3.2,11.639c-0.017,0.034-0.034,0.068-0.06,0.102l-17.066,29.55
			C342.943,71.295,340.119,72.916,337.064,72.916z"/>
		<path style={{fill:'#FFAF46'}} d="M298.664,170.664c-18.85,0-34.133,15.283-34.133,34.133v17.066h-17.066v-17.066
			c0-18.85-15.283-34.133-34.133-34.133S179.2,185.947,179.2,204.797s15.283,34.133,34.133,34.133h17.066v93.865
			c0,4.71,3.823,8.533,8.533,8.533c4.71,0,8.533-3.823,8.533-8.533v-93.865h17.066v93.865c0,4.71,3.823,8.533,8.533,8.533
			s8.533-3.823,8.533-8.533v-93.865h17.066c18.85,0,34.133-15.283,34.133-34.133S317.514,170.664,298.664,170.664z M196.266,204.797
			c0-9.429,7.637-17.066,17.066-17.066s17.066,7.637,17.066,17.066v17.066h-17.066C203.912,221.863,196.266,214.226,196.266,204.797z
			 M298.664,221.863h-17.066v-17.066c0-9.429,7.637-17.066,17.066-17.066c9.429,0,17.066,7.637,17.066,17.066
			C315.731,214.226,308.094,221.863,298.664,221.863z"/>
	</g>
	<path style={{fill:'#231F20'}} d="M262.629,68.428c-75.314-3.618-139.304,54.501-142.922,129.824
		c-2.074,43.127,16.375,84.692,49.748,112.083c6.016,4.71,9.591,11.878,9.745,19.515v20.01c0.043,10.812,6.878,20.428,17.066,24.029
		v10.103c0.026,6.315,2.398,12.399,6.656,17.066c-8.874,9.651-8.874,24.482,0,34.133c-9.489,10.402-8.747,26.521,1.655,36.01
		c4.727,4.309,10.897,6.681,17.288,6.656c4.591,0.119,8.414-3.507,8.533-8.098c0.043-1.792-0.486-3.55-1.519-5.018
		c-0.273-0.521-0.589-1.015-0.956-1.476c-0.7-0.64-1.502-1.169-2.372-1.553c-0.247-0.128-0.495-0.23-0.751-0.333
		c-0.862-0.316-1.766-0.495-2.688-0.538c-0.085,0-0.162-0.051-0.247-0.051c-4.71,0-8.533-3.823-8.533-8.533
		c0-4.71,3.823-8.533,8.533-8.533h34.133c4.71,0,8.533-3.823,8.533-8.533c0-4.71-3.823-8.533-8.533-8.533h-34.133
		c-4.71,0-8.533-3.823-8.533-8.533s3.823-8.533,8.533-8.533h34.133c4.71,0,8.533-3.823,8.533-8.533s-3.823-8.533-8.533-8.533h-34.133
		c-4.71,0-8.533-3.823-8.533-8.533v-8.533h42.666c4.71,0,8.533-3.823,8.533-8.533c0-4.71-3.823-8.533-8.533-8.533h-51.199
		c-4.71,0-8.533-3.823-8.533-8.533v-20.01c-0.137-12.757-6.016-24.78-16-32.725c-50.969-41.889-58.333-117.178-16.443-168.147
		c23.961-29.158,60.296-45.277,97.995-43.477c63.052,3.755,112.57,55.423,113.645,118.569c0.307,36.317-16.025,70.783-44.33,93.549
		c-9.907,8.166-15.573,20.386-15.402,33.22v19.02c0,4.71-3.823,8.533-8.533,8.533h-25.6c-4.71,0-8.533,3.823-8.533,8.533
		c0,4.71,3.823,8.533,8.533,8.533h17.066v8.533c0,4.71-3.823,8.533-8.533,8.533h-8.533c-4.71,0-8.533,3.823-8.533,8.533
		s3.823,8.533,8.533,8.533h8.533c4.71,0,8.533,3.823,8.533,8.533s-3.823,8.533-8.533,8.533h-8.533c-4.71,0-8.533,3.823-8.533,8.533
		c0,4.71,3.823,8.533,8.533,8.533h8.533c4.71,0,8.533,3.823,8.533,8.533c0,4.71-3.823,8.533-8.533,8.533h-42.666
		c-4.71,0-8.533,3.823-8.533,8.533c0,4.71,3.823,8.533,8.533,8.533h32.674c-4.71,13.329-19.336,20.318-32.665,15.607
		c-4.079-1.442-7.731-3.9-10.607-7.134c-3.132-3.524-8.525-3.831-12.049-0.7c-3.524,3.132-3.831,8.525-0.7,12.049l0,0
		c15.607,17.587,42.512,19.191,60.099,3.584c7.262-6.443,12.109-15.181,13.721-24.755c13.363-4.173,20.812-18.398,16.631-31.761
		c-1.109-3.55-2.987-6.809-5.495-9.557c8.875-9.651,8.875-24.482,0-34.133c4.258-4.668,6.63-10.752,6.656-17.066V373.89
		c10.189-3.601,17.024-13.218,17.066-24.029v-19.02c-0.111-7.663,3.208-14.984,9.054-19.942
		c32.366-26.018,51.046-65.424,50.679-106.955C391.301,131.77,334.692,72.711,262.629,68.428z"/>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	</svg>


	)
}

export default SVG;




