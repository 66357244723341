import React from 'react'

import { withRouter, Link } from 'react-router-dom'

import { decorate, observable } from 'mobx'

import { observer, inject } from 'mobx-react'

import { compose } from 'recompose'

import * as ROUTES from 'routes.js'

import { Users } from 'axios.js'

import Collapse from 'react-collapse'


import styles from 'styles/pages/account/accountsettings/accountsettingsindex.module.css'

class AccountSettings extends React.Component{

	authStore
	firebase
	constructor(props){
		super(props)
		this.authStore = this.props.authStore
		this.firebase = this.props.firebaseStore.firebaseAccess.firebase


		this.state = {
			messageVerificationEmail: '',
			messagePasswordEmail: '',
			messageEmailEmail: '',
			currentPasswordField: '',
			newPasswordField: '',
			reauthenticated: false,
			showChangePasswordForm: false,
		}
	}

	componentDidMount(){


		
	}

	handleSendVerificationEmail = e =>{

		

		var confirm = window.confirm('Resend Verification email?')

		if(confirm === true){
			this.setState({
				messageVerificationEmail: '',
				messagePasswordEmail: '',
				messageEmailEmail: ''


			})
			this.firebase.doSendEmailVerification()
				.then( res =>{

					console.log(res)
					this.setState({
						messageVerificationEmail: 'Verification Email sent. Please check your spam folder just in case.'
					})
				})
				.catch( err =>{
					console.log(err)
				})
		}
		

	}

	handleChangePassword = e =>{
		this.setState({
			messageVerificationEmail: '',
			messagePasswordEmail: '',
			messageEmailEmail: '',
			showChangePasswordForm: !this.state.showChangePasswordForm,
		})		

		// this.firebase.doSendPasswordResetEmail(email)
		// 	.then( res =>{

		// 		console.log(res)
		// 		this.setState({
		// 			messagePasswordEmail: 'Password Reset Email sent. Please check your spam folder just in case.'
		// 		})
		// 	})
		// 	.catch( err =>{
		// 		console.log(err)
		// 	})
	}

	submitChangePassword = e =>{

		e.preventDefault()
		var email = this.firebase.auth.currentUser.email

		//password criteria
		var oldpassword = this.state.currentPasswordField
		var password = this.state.newPasswordField
		if(password === undefined || password === null || password.length < 8){

			this.setState({
				currentPasswordField: '',
				newPasswordField: '',
				messagePasswordEmail: 'password too short'
			})
			return
		}
		else if(oldpassword !== password ){
			this.setState({
				currentPasswordField: '',
				newPasswordField: '',
				messagePasswordEmail: "passwords don't match"
			})
			return
		}

		const credential = this.firebase.functionlessauth.EmailAuthProvider.credential(
		    email, 
		    oldpassword
		);

		this.firebase.doReauthenticateUser(credential)
			.then( res =>{
				console.log('changing password. reauthencating')


				this.firebase.doPasswordUpdate(password)
					.then(resPasswordUpdate =>{

						var userid = this.props.authStore.authFields.id

						Users.changePasswordDatabase(userid, password)
							.then( resPasswordDatabase =>{
								this.setState({
									currentPasswordField: '',
									newPasswordField: '',
									messagePasswordEmail: "password successfully changed."

								})
							})
					})
					.catch(errPasswordUpdate =>{
						console.log(errPasswordUpdate)
					})


				
			})
			.catch( err =>{
				console.log(err)

				this.setState({
					currentPasswordField: '',
					newPasswordField: '',
					messagePasswordEmail: "current password is incorrect"

				})
			})

	}

	// handleChangeEmail = e =>{
	// 	this.setState({
	// 		messageVerificationEmail: '',
	// 		messagePasswordEmail: '',
	// 		messageEmailEmail: '',
			
	// 	})

	// 	var email = this.firebase.auth.currentUser.email

	// 	this.firebase.doSendPasswordResetEmail(email)
	// 		.then( res =>{

	// 			console.log(res)
	// 			this.setState({
	// 				messageEmailEmail: 'Password Reset Email sent. Please check your spam folder just in case.'
	// 			})
	// 		})
	// 		.catch( err =>{
	// 			console.log(err)
	// 		})
	// }

	onChangeCurrentPassword = e =>{

		this.setState({
			currentPasswordField: e.target.value
		})
	}

	onChangeNewPassword = e =>{
		this.setState({
			newPasswordField: e.target.value
		})

	}

	render(){
		return (
			
			<div className={styles.container}>

				<h3 className={styles.heading}>Account Settings</h3>

				<div className={styles.column}>
					<p>Lost your verification email?</p>
					<button className={styles.mainbutton} onClick={this.handleSendVerificationEmail}>Resend verification Email</button>

					<p>{this.state.messageVerificationEmail}</p>

				</div>

				<div className={styles.column}>
					<p>Change your password.</p>
					<button className={styles.mainbutton} onClick={this.handleChangePassword}>{this.state.showChangePasswordForm ? 'hide' : 'Reset Password' }</button>

					<Collapse isOpened={this.state.showChangePasswordForm}>
						{/*className='pure-form'*/}

						<div className={styles.form}>
							<form  onSubmit={this.submitChangePassword} className="pure-form form-custom">
								
								<input 
									name="oldpassword"
									type="password"
									onChange={this.onChangeCurrentPassword}
									value={this.state.currentPasswordField}
									placeholder="current password"
								/>
								<input 
									name="newpassword"
									type="password"
									onChange={this.onChangeNewPassword}
									value={this.state.newPasswordField}
									placeholder="new password"
								/>

								<button type='submit' className="pure-button button-custom">Change Password</button>
							</form>	

						</div>
						
					</Collapse>

					

					
					<p>{this.state.messagePasswordEmail}</p>

				</div>
				{/*<div>
					<p>Change your email address. </p>
					<button onClick={this.handleChangeEmail}>change email</button>
					<p>{this.state.messageEmailEmail}</p>

				</div>*/}

				{/*<div>
					<input type="file"/>
					<img src="" alt={unknownUserImage}/>
					<button className="a">Change Profile Image</button>
				</div>*/}
			</div>

		)
	}

}

var enhance = compose(
	
	inject('authStore', 'firebaseStore'),
	withRouter,
	observer
)

export default enhance(AccountSettings)

