import React, {lazy, Suspense} from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import { observable, decorate, toJs, when } from 'mobx'

import { compose } from 'recompose'

import * as ROUTES from 'routes.js'

// import RichTextEditor from 'components/scripts/ckeditor.js'

import styles from 'styles/components/comments/createcomment.module.css'

import requests, { Comments, Notifications } from 'axios.js'

import { sanitize } from 'dompurify'

import Loading from 'components/animations/loading.js'

const RichTextEditor = lazy( () => import('components/scripts/ckeditor.js') )

//create a comment on the script page
 class CreateComment extends React.Component{

	formState = {
		description: '',
		scriptuserid: null,
		truncateNotificationFactor: 22 //chars
	}

	cancelcallback
	scriptid
	parentid

	constructor(props){

		super(props)
	}
	/* LIFECYCLE METHODS */
	/* _________________ */
	componentDidUpdate(){

	}

	componentDidMount(){
		this.parentid = this.props.parentid ? this.props.parentid : null 

		when( 
			() => typeof this.props.authStore.authFields.id === 'number' ,
			() => {
				this.getUseridFromScriptid()
			} 
		)
	}
	/* _________________ */


	

	/* ASYNC DATA FETCH */
	/* _________________ */
	getUseridFromScriptid = () =>{


		if(typeof this.props.scriptid === 'number' || typeof this.props.scriptid === 'string'){
			Comments.getUseridFromScriptid(this.props.scriptid)
				.then( res =>{

					if(res.data.error)
						return

					this.formState.scriptuserid = res.data.message.userid

				})
		}
	}

	/* _________________ */




	/* INTERNALS */

	submitComment = e =>{

		var commentbody = sanitize(this.formState.description)
		var scriptid = this.props.scriptid
		var userid = this.props.authStore.authFields.id
		var parentid = this.parentid

		var propsid = this.props.id
		var hostuserid = this.formState.scriptuserid

		var commentShorted = commentbody.substring(0, this.formState.truncateNotificationFactor)
		

		if(commentbody.length === 0)
			return


		Comments.submitScriptComment(commentbody, scriptid, userid, parentid )
			.then( res =>{
				console.log(res.data)

				if(parentid === null){
					Notifications.triggerRootCommentNotification(hostuserid, userid, commentShorted, scriptid)
						.then( resNoti => {
							this.props.commentStore.clearRichTextEditor()

							window.location.reload(false)
						})
				}
				else{

					Notifications.getReplyCommentHostUserid(propsid)
						.then( resId => {

							console.log(resId.data.message)
							Notifications.triggerReplyCommentNotification(resId.data.message.userid, userid, commentShorted, scriptid)
								.then( resNoti => {
									this.props.commentStore.clearRichTextEditor()

									window.location.reload(false)
								})
						})
					
				}
				

				

				// this.props.history.push(ROUTES.EMPTY)
				// window.setTimeout(()=>{
				// 	var {pathname} = this.props.location

				// 	var currentpath = `${pathname}${this.props.location.search}`
				// 	this.props.history.replace(currentpath)
				// },100)

			})

	}
	/* _________________ */

	getDescription = description =>{
		this.formState.description = description
	}

	cancel = e =>{

		this.props.commentStore.clearRichTextEditor()
		
		this.props.cancelcallback ? this.props.cancelcallback() : ''
	}
	/* _________________ */

	
	render(){

		console.log('ITS'+this.formState.scriptuserid)

		console.log('parentid '+this.parentid)
		return(

			<div className={styles.container}>
			

				<Suspense fallback={<Loading />}>
					<RichTextEditor
						contentCallback={this.getDescription}
						
						description=' '
					/>
				</Suspense>
				<div className={styles.buttonrow}>
					<button className={styles.submitbutton} onClick={this.submitComment}>Submit</button>
					<button className={styles.submitbutton} onClick={this.cancel}>Cancel</button>
					

				</div>


			</div>
		)

	}
}

decorate(CreateComment, {
	formState: observable
})

var enhance = compose(
	inject('authStore', 'commentStore'),
	withRouter,
	observer
	)

export default enhance(CreateComment)

