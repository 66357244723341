import React from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import { observable, decorate, when, toJS } from 'mobx'

import { compose } from 'recompose'

import requests, { Scripts } from 'axios.js'

import * as ROUTES from 'routes.js'
import { displayTimeStamp, getTimeAgoString, slugify, getOffset } from 'usefulFunctions.js'

import TagUnit from 'pages/script/mastertagunit.js'

     

//styles

import styles from 'styles/pages/script/mastertagspage.module.css'

class MasterTagsPage extends React.Component{

	saveruserid

	constructor(props) {


		super(props)


		this.authStore = this.props.authStore
		this.scriptStore = this.props.scriptStore

		this.state = {
		// tags: ['a','b','c','d','e','f','g','h','i','j','k'],
		
		newest: true,
		currentPage: 1,
		tagsPerPage: 10,
		tagsPerRequest: 100,
		id_last_item: 1, //for keeping track of keyset pagination set - backend
		id_first_item: 1,
		pageSet: 0, // for keeping track of page numbers
		tags: [],

		}

 	}


	getAllTags = () =>{

		var id_first_item = this.state.id_first_item
		var id_last_item = this.state.id_last_item


		Scripts.tags.getAllTagsByDateEarliest(this.state.tagsPerRequest, 'first', id_first_item, id_last_item)
			.then( res =>{

				if(res.data.message){
					this.setState({
						tags: res.data.message,
						id_first_item: res.data.id_first_item,
						id_last_item: res.data.id_last_item

					})

				}
			})
	}

 	componentDidMount(){
		this.getAllTags()
		
	}
 	

 	handleClick = () => {
 		// console.log(`currentPage will be ${event.target.id}`)
 	  this.setState({
 	    currentPage: Number(event.target.id)
 	  })

 	  var {top, left} = getOffset(this.taglist)
 	  window.scrollTo(0, top -180)
 	}


	get_newest = e =>{


		var id_first_item = this.state.id_first_item
		var id_last_item = this.state.id_last_item

		Scripts.tags.getAllTagsByDateEarliest(this.state.tagsPerRequest, 'first', id_first_item, id_last_item)
			.then( res =>{

				console.log(res.data)
				if(res.data.message){
					this.setState({
						tags: res.data.message,
						id_first_item: res.data.id_first_item,
						id_last_item: res.data.id_last_item,
						newest: true

					})
				}
			})
		
	}
	get_oldest = e =>{

		var id_first_item = this.state.id_first_item
		var id_last_item = this.state.id_last_item
		
		Scripts.tags.getAllTagsByDateOldest(this.state.tagsPerRequest, 'first', id_first_item, id_last_item)
			.then( res =>{

				console.log(res.data)
				if(res.data.message){
					this.setState({
						tags: res.data.message,
						id_first_item: res.data.id_first_item,
						id_last_item: res.data.id_last_item,
						newest: false

					})
				}
			})
	
	}

	loadPrevPages = e =>{
		var id_first_item = this.state.id_first_item
		var id_last_item = this.state.id_last_item
		
		this.setState({
			pageSet: this.state.pageSet - 1
		})		

		if(this.state.newest === true){
			// Scripts.tags.getAllTagsByDateEarliest(this.state.tagsPerRequest, 'less', id_first_item, id_last_item)
			Scripts.tags.getAllTagsByDateEarliest(this.state.tagsPerRequest, 'less', id_last_item)
				.then( res =>{

					console.log(res.data)
					if(res.data.message){
						this.setState({
							tags: res.data.message,
							id_first_item: res.data.id_first_item,
							id_last_item: res.data.id_last_item,
							newest: true,
						    currentPage: this.state.currentPage - ((this.state.pageSet +1) * this.state.tagsPerPage)


						})
					}
				})
		}

		else{
			// Scripts.tags.getAllTagsByDateOldest(this.state.tagsPerRequest, 'less', id_first_item, id_last_item)
			Scripts.tags.getAllTagsByDateOldest(this.state.tagsPerRequest, 'less', id_last_item)
				.then( res =>{

					console.log(res.data)
					if(res.data.message){
						this.setState({
							tags: res.data.message,
							id_first_item: res.data.id_first_item,
							id_last_item: res.data.id_last_item,
							newest: false,
						    currentPage: this.state.currentPage - ((this.state.pageSet +1) * this.state.tagsPerPage)


						})
					}
				})
		}


		// this.get_scripts(category, subcategory, 'less')
		// 	.then( () =>{
		// 		this.setState({
		// 		  currentPage: this.state.currentPage - ((this.state.pageSet +1) * this.state.tagsPerPage)
		// 		})
		// 		console.log('PREV: currentpage!!:'+this.state.currentPage)

		// 	})


	}
	loadNextPages = e =>{
		var id_first_item = this.state.id_first_item
		var id_last_item = this.state.id_last_item
		
		this.setState({
			pageSet: this.state.pageSet + 1,

		})	


		if(this.state.newest === true){
			// Scripts.tags.getAllTagsByDateEarliest(this.state.tagsPerRequest, 'more', id_first_item, id_last_item)
			Scripts.tags.getAllTagsByDateEarliest(this.state.tagsPerRequest, 'more', id_last_item)
				.then( res =>{

					console.log(res.data)
					if(res.data.message){
						this.setState({
							tags: res.data.message,
							id_first_item: res.data.id_first_item,
							id_last_item: res.data.id_last_item,
							newest: true,
				  			currentPage: this.state.currentPage + (this.state.pageSet * this.state.tagsPerPage)



						})
					}
				})
		}

		else{
			// Scripts.tags.getAllTagsByDateOldest(this.state.tagsPerRequest, 'more', id_first_item, id_last_item)
			Scripts.tags.getAllTagsByDateOldest(this.state.tagsPerRequest, 'more', id_last_item)
				.then( res =>{

					console.log(res.data)
					if(res.data.message){
						this.setState({
							tags: res.data.message,
							id_first_item: res.data.id_first_item,
							id_last_item: res.data.id_last_item,
							newest: false,
				  			currentPage: this.state.currentPage + (this.state.pageSet * this.state.tagsPerPage)

						})
					}
				})
		}

	}




 	render() {
 				var i = 0
 	  const { tags, currentPage, tagsPerPage, scriptnames, scriptdates, pageSet } = this.state;

 	  // Logic for displaying tags
 	  const indexOfLastTag = ((currentPage - (10*pageSet)) * tagsPerPage) ;

 	  // console.log(pageSet)
		

 	  const indexOfFirstTag = indexOfLastTag - tagsPerPage;
 	  const currentTags = tags.slice(indexOfFirstTag, indexOfLastTag);


 	  const loadPrevButtonDisabled = this.state.pageSet === 0 	  		


 	  const loadNextButtonDisabled = this.state.tags.length < this.state.tagsPerRequest
 	  		// || this.state.pageSet 


 	  var tagsRender = currentTags.map(tag =>{
 	
			return (

				<TagUnit key={tag.id + Math.random() * 10000}
					tag={tag}
				/>
			)

		})

 	  // Logic for displaying page numbers
 	  const pageNumbers = [];
 	  for (let i = 1 + (Math.ceil(this.state.tagsPerRequest / tagsPerPage) * this.state.pageSet); i <= Math.ceil(this.state.tagsPerRequest / tagsPerPage) * (this.state.pageSet + 1); i++) {
 	    pageNumbers.push(i);
 	  }

 	  const renderPageNumbers = pageNumbers.map(number => {
 	    return (
 	      <li
 	        key={number}
 	        id={number}
 	        onClick={this.handleClick}
 	        className={styles.pagenumberunit}
 	      >
 	        {number}
 	      </li>
 	    );
 	  });

 	  return (
	
		 <div>
		 	{/*<div className={styles.adtop}>AD</div>

		 	<div className={styles.adright}>AD</div>*/}

		 	<div className={styles.filteroptions}>
		 		<span className={styles.heading}>Filter Options</span>
	 		   
		 		<button disabled={this.state.newest === true} onClick={this.get_newest} className="pure-button">Newest</button>
		 		<button disabled={this.state.newest === false} onClick={this.get_oldest} className="pure-button" >Oldest</button>
		 		<button disabled={true} onClick={this.get_newest} className="pure-button" >Most Popular<small className={styles.comingsoon} >coming soon</small></button>

		 	</div>

		 	<div className={styles.pagenumberscontainer}>
		 		<button onClick={this.loadPrevPages} 
		 			disabled={loadPrevButtonDisabled}
		 			className={styles.prevpagebutton}>Previous {Math.ceil(this.state.tagsPerRequest / tagsPerPage)} Pages
		 		</button>

		 		<ul className={styles.pagenumbers}>
		 		  {renderPageNumbers}
		 		</ul>
		 		<button onClick={this.loadNextPages} 
		 			disabled={loadNextButtonDisabled}
		 			className={styles.nextpagebutton}>Next {Math.ceil(this.state.tagsPerRequest / tagsPerPage)} Pages
		 		</button>
		 	</div>

	 	      <ul className={styles.tagscontainer} ref={ref => this.taglist = ref} >
	 	        {tagsRender}
	 	      </ul>
	 	      

	 	    <div className={styles.pagenumberscontainer}>
	 	    	<button onClick={this.loadPrevPages} 
	 	    		disabled={loadPrevButtonDisabled}
	 	    		className={styles.prevpagebutton}>Previous {Math.ceil(this.state.tagsPerRequest / tagsPerPage)} Pages
	 	    	</button>

	 	    	<ul className={styles.pagenumbers}>
	 	    	  {renderPageNumbers}
	 	    	</ul>
	 	    	<button onClick={this.loadNextPages} 
	 	    		disabled={loadNextButtonDisabled}
	 	    		className={styles.nextpagebutton}>Next {Math.ceil(this.state.tagsPerRequest / tagsPerPage)} Pages
	 	    	</button>
	 	    </div>

			{/*<div className={styles.adbottom}>AD</div>*/}
	 	      
	 	</div>	
		
		 

 	  );
 	}


}



var enhance = compose(
	inject('authStore', 'scriptStore'),
	withRouter,
	observer
	)


export default enhance(MasterTagsPage)

