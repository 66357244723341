import React from 'react'

const SVG = props => {

	return (
		<svg height={props.height} width={props.width} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M10.5 3.56604C6.99854 3.56604 4.16037 6.40461 4.16037 9.90566C4.16037 12.7145 5.98895 15.0927 8.51886 15.9251V19.8113H9.31131V21H11.6887V19.8113H12.4811V15.9251C15.011 15.0927 16.8396 12.7145 16.8396 9.90566C16.8396 6.40461 14.0014 3.56604 10.5 3.56604Z" fill="#E0E0E0"/>
		<path d="M10.5 0C10.2809 0 10.1038 0.177113 10.1038 0.396226V1.98113C10.1038 2.20025 10.2809 2.37736 10.5 2.37736C10.7191 2.37736 10.8962 2.20025 10.8962 1.98113V0.396226C10.8962 0.177113 10.7191 0 10.5 0Z" fill="#E0E0E0"/>
		<path d="M20.0094 9.50946H18.4245C18.2054 9.50946 18.0283 9.68657 18.0283 9.90569C18.0283 10.1248 18.2054 10.3019 18.4245 10.3019H20.0094C20.2285 10.3019 20.4056 10.1248 20.4056 9.90569C20.4056 9.68657 20.2285 9.50946 20.0094 9.50946Z" fill="#E0E0E0"/>
		<path d="M2.57546 9.50946H0.990556C0.771443 9.50946 0.59433 9.68657 0.59433 9.90569C0.59433 10.1248 0.771443 10.3019 0.990556 10.3019H2.57546C2.79458 10.3019 2.97169 10.1248 2.97169 9.90569C2.97169 9.68657 2.79458 9.50946 2.57546 9.50946Z" fill="#E0E0E0"/>
		<path d="M16.9442 2.9012L15.8237 4.02173C15.6688 4.17665 15.6688 4.42707 15.8237 4.58199C15.9009 4.65926 16.0024 4.69809 16.1038 4.69809C16.2052 4.69809 16.3067 4.65926 16.3839 4.58199L17.5045 3.46147C17.6594 3.30654 17.6594 3.05613 17.5045 2.9012C17.3495 2.74628 17.0987 2.74667 16.9442 2.9012Z" fill="#E0E0E0"/>
		<path d="M4.61602 15.2294L3.4955 16.3499C3.34057 16.5048 3.34057 16.7552 3.4955 16.9101C3.57276 16.9874 3.67419 17.0262 3.77563 17.0262C3.87706 17.0262 3.9785 16.9874 4.05576 16.9101L5.17629 15.7896C5.33121 15.6347 5.33121 15.3843 5.17629 15.2294C5.02136 15.0744 4.77095 15.0744 4.61602 15.2294Z" fill="#E0E0E0"/>
		<path d="M16.3839 15.2294C16.229 15.0744 15.9786 15.0744 15.8237 15.2294C15.6688 15.3843 15.6688 15.6347 15.8237 15.7896L16.9442 16.9101C17.0215 16.9874 17.1229 17.0262 17.2243 17.0262C17.3258 17.0262 17.4272 16.9874 17.5045 16.9101C17.6594 16.7552 17.6594 16.5048 17.5045 16.3499L16.3839 15.2294Z" fill="#E0E0E0"/>
		<path d="M4.05576 2.90117C3.90084 2.74624 3.65042 2.74624 3.4955 2.90117C3.34057 3.05609 3.34057 3.30651 3.4955 3.46143L4.61602 4.58196C4.69329 4.65922 4.79472 4.69805 4.89616 4.69805C4.99759 4.69805 5.09902 4.65922 5.17629 4.58196C5.33121 4.42703 5.33121 4.17662 5.17629 4.02169L4.05576 2.90117Z" fill="#E0E0E0"/>
		<path d="M6.1415 10.3019C5.92238 10.3019 5.74527 10.1248 5.74527 9.90566C5.74527 7.28383 7.87816 5.15094 10.5 5.15094C10.7191 5.15094 10.8962 5.32805 10.8962 5.54717C10.8962 5.76628 10.7191 5.94339 10.5 5.94339C8.31519 5.94339 6.53772 7.72086 6.53772 9.90566C6.53772 10.1248 6.36061 10.3019 6.1415 10.3019Z" fill="#C2C2C2"/>
		<path d="M8.51886 17.0377V19.8113H9.31131V21H11.6887V19.8113H12.4811V17.0377H8.51886Z" fill="#B1B1B1"/>
		</svg>



	)
	//height={props.height} width={props.width} viewBox={`0 0 ${props.viewW} ${props.viewH}`}
	
}

export default SVG