import React, {Suspense, lazy} from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import { observable, decorate, when } from 'mobx'

import { compose } from 'recompose'

import * as ROUTES from 'routes.js'

import requests, { Users, Comments } from 'axios.js'

import Avatar from 'components/profile/avatar.js'

import { sanitize } from 'dompurify'


import Tippy from '@tippyjs/react'
import 'tippy.js/themes/light.css'

import Collapse from 'react-collapse'

import CreateComment from 'components/comments/createcomment.js'

import Loading from 'components/animations/loading.js'

import Prism from 'prismjs'

import { parseQuery, getIndicesOf } from 'usefulFunctions.js'


import {decode} from 'html-entities'

//styles
import styles from 'styles/components/comments/commentunit.module.css'
import classnames from 'classnames'

const RichTextEditor = lazy( () => import('components/scripts/ckeditor.js'))



class CommentBox extends React.Component{

	
	userid
	scriptid
	id
	parentid
	date
	commentbody
	awards
	votecount
	placement
	constructor(props){

		super(props)
		this.state={
			username: '',
			votecount: 0,
			commentbody: '',
			awards: '',
			date: '',
			avatarfile: '',
			placement: 0,
			tippy: {visible: false, content: 'TEST'},
			reply: {showEditor: false},
			showCommentBox: true,
			showEditBox: false,
			description: '',
			commentInit: '',
		}
	}

	/* ASYNC DATA FETCH */
	/* _________________ */

	getUsername =  () =>{
		requests.post(`${ROUTES.API.getuserfromid}`, {userid: this.props.userid })
			.then( res => {
				
				res.data.message ? this.setState({
					username: res.data.message
				})
				: console.log(res.data.error)
			})

		
	}

	getAvatar = () =>{
		Users.getAvatar(this.props.userid)
			.then( res =>{
				
				this.setState({
					avatarfile: res.data.message ? res.data.message : this.props.userStore.anon.file
				})

			})
	}

	

	/* _________________ */

	/* LIFECYCLE METHODS */
	/* _________________ */
	componentDidUpdate(){

	}
	componentDidMount(){

		this.votecount = this.props.votecount
		
		this.getUsername()

		this.getAvatar()

		var commentUpgraded = this.prepareUserSubmittedHTML(this.props.commentbody)

		var commentinit = this.props.commentbody
		var fixedcomment = commentinit.replace(/(\<pre)/gm, '[code]<pre')
		fixedcomment = fixedcomment.replace(/(\<\/pre\>)/gm, '</pre>[/code]')

		console.log(fixedcomment)

		this.setState({
			votecount: this.props.votecount,
			commentbody: commentUpgraded,
			awards: this.props.awards,
			date: this.props.date,
			placement: this.props.placement,
			commentInit: fixedcomment
		})

	}
	/* _________________ */
	

	


	/* EVENTS */
	/* _________________ */

	commentBlur = e => {
		this.setState({
			tippy: {visible: false, content: ''}
		})
	}

	/* INTERNALS */
	/* _________________ */

	



	authorized = () =>{

		return this.props.authStore.authorizedUser ? true : false
	}
	
	handleUpvote = e =>{
		var instance = this

		var votestate = 'upvoted'

		if(this.authorized() ){

			this.props.commentStore.votedComments[this.props.id] = 'upvoted'
			this.setState({
				votecount: (this.votecount - this.state.votecount >= 0) ? this.votecount + 1 : this.state.votecount

			})
			Comments.getCommentVoteCount(this.props.id, votestate)
				.then( res =>{
					console.log(res.data.message)
				})
		}
		else{
			this.setState({
				tippy: {visible: true, content: 'Must be logged in to vote.'}
			})
		}

	

	}

	handleDownvote = e =>{

		var votestate = 'downvoted'

		if(this.authorized()){
			this.props.commentStore.votedComments[this.props.id] = 'downvoted'

			this.setState({
				votecount: (this.votecount - this.state.votecount > 0) ?  this.state.votecount : this.votecount - 1 

			})
			Comments.getCommentVoteCount(this.props.id, votestate)
				.then( res =>{
					console.log(res.data.message)
				})
		}
		else{
			this.setState({
				tippy: {visible: true, content: 'Must be logged in to vote.'}
			})
		}
		

	}

	handleReport = e =>{

		function report(){
			
			console.log('HI')
		}
		this.authorized() ? 
		report()
		:
		this.setState({
			tippy: {visible: true, content: 'Must be logged in to report.'}
		})
	}

	handleReply = e =>{

		var instance = this
		function reply(){
			
			instance.setState({
				reply: {showEditor: true},
				showCommentBox: true
			})

		}
		this.authorized() ? 
		reply()
		:
		this.setState({
			tippy: {visible: true, content: 'Must be logged in to reply.'}
		})
	}

	handleAward = e =>{
		function award(){
			
			console.log('HI')
		}
		this.authorized() ? 
		award()
		:
		this.setState({
			tippy: {visible: true, content: 'Must be logged in to award this comment.'}
		})
	}

	handleEdit = e =>{

		var instance = this
		function edit(){
			
			instance.setState({
				showEditBox: true
			})

		}
		this.authorized() ? 
		edit()
		:
		this.setState({
			tippy: {visible: true, content: 'Must be logged in to edit this comment.'}
		})
	}

	getDescription = description =>{
		this.setState({
			description: description
		})
	}

	editComment = e =>{

		
		Comments.editComment(this.state.description === '' ? this.state.commentbody : this.state.description, this.props.id)
			.then( res => {

				console.log(res.data.message)

				this.setState({
					commentbody: res.data.message ? res.data.message.commentbody : this.state.commentbody,
					showEditBox: false,
				})

				window.location.reload(false)
			})
	}

	

	cancel = e =>{

		// this.props.commentStore.clearRichTextEditor()
		this.setState({
			showEditBox: false
		})
		

	}

	prepareUserSubmittedHTML = htmlstring =>{
		

		//files
		var string_array_withcode = htmlstring.match(/\[\w+\]/gi)

		if(string_array_withcode === null ) //return value of string.match
			return htmlstring

		var string_array = string_array_withcode.filter(el => !el.includes('[code]') || !el.includes('[himg]'))

		
		var working_string = ''

		let string = ''

		console.log(string_array)
		//width=${width} height=${height}



		//code block
		//[code][/code]

		if(htmlstring.includes('[code]') && htmlstring.includes('[/code]')){

			var justcodearr = string_array_withcode.filter(ele => ele.includes('[code]'))

			justcodearr.forEach((ele, indexx) =>{

					// if(indexFromForCode === 0){

					// }
					// var code = htmlstring.substring(indecesForCode[indexx], htmlstring.indexOf('[/code]')).replace('[code]', '')

					var indecesForCode = getIndicesOf('[code]', htmlstring)
					var indecesforSlashCode = getIndicesOf('[/code]', htmlstring)

					var original = htmlstring.substring(indecesForCode[0],indecesforSlashCode[0])

					console.log(`${indecesForCode[0]}, ${indecesforSlashCode[0]}`)
					
					var code = htmlstring.substring(indecesForCode[0],indecesforSlashCode[0]).replace('[code]', '')
					console.log(code)

					// code = sanitize(code, {ALLOWED_TAGS: []})

					code = code.replace(/(\<p\>)/gm, '\r\n')
					// code = code.replace(/\>/g, '')

					code = code.replace(/(\<\/p\>)/gm, '')
					// code = code.replace(/(&nbsp;|&lt;)/g, '')

					// code = code.replace(/^(\s;)*/gm, '')
					// code = code.replace(/^(\s;)*/gm, '\t')


					// code = code.replace(/^(\s+;\s+;\s+;\s+;)|^(\s+;\s+;\s+;)|^(\s+;\s+;)|^(\s+;)/gm, '')

					code = code.replace(/^(\s\~)*|^(\~)*/gm, '')

					// console.log(code)

					code = decode(code)

					// code = code.replace(/&amp;/gm, '&')
					// code = code.replace(/&lt;/gm, '<')
					// code = code.replace(/&gt;/gm, '>')


					code = Prism.highlight(code.trim(), Prism.languages.autohotkey, 'autohotkey')

					// var prismcode = `<pre><code class="language-autohotkey">${code}</code></pre>`
					var prismcode = `<pre class='prismeditor line-numbers'><code class='language-autohotkey'>${code}</code></pre>`


					htmlstring = htmlstring.replace(original, prismcode )
					htmlstring = htmlstring.replace('[/code]', '')

				
			})
			

	  //   	console.log(htmlstring)

		}

		if(htmlstring.includes('[himg]') && htmlstring.includes('[/himg]')){


			var justhimgarr = string_array_withcode.filter(ele => ele.includes('[himg]'))

			justhimgarr.forEach((ele, indexx) =>{

				var indecesforHIMG = getIndicesOf('[himg]', htmlstring)
				var indecesforSlashHIMG = getIndicesOf('[/himg]', htmlstring)

				// var himg = htmlstring.substring(htmlstring.indexOf('[himg]'), htmlstring.indexOf('[/himg]')).replace('[himg]', '').trim()

				var original = htmlstring.substring(indecesforHIMG[0],indecesforSlashHIMG[0])

				// console.log(original)
				var himg = htmlstring.substring(indecesforHIMG[0],indecesforSlashHIMG[0])
				himg = himg.replace('[himg]', '')


				// var himg = htmlstring.substring(htmlstring.indexOf('[himg]'), htmlstring.indexOf('[/himg]')).replace('[himg]', '').trim()
				// console.log(himg)


				himg = himg.replace(/(\<p\>)/gm, '\r\n')

				himg = himg.replace(/(\<\/p\>)/gm, '')
				himg = himg.replace(/(&nbsp;|&lt;)/g, '\t')

				// console.log(himg)


				var himghtml = `<img src='${himg}' alt='img not loaded'  class='himg' />`


				// htmlstring = htmlstring.replace(htmlstring.substring(htmlstring.indexOf('[himg]'), htmlstring.indexOf('[/himg]')), himghtml )
				htmlstring = htmlstring.replace(original, himghtml )

				htmlstring = htmlstring.replace('[/himg]', '')
				

		    	// console.log(htmlstring)
			})
			

		}



		return htmlstring
	}




	/* _________________ */

	
	
	render(){
		
		//commentposition starts at 1 
		var commentposition = `comment${this.state.placement}`

		var vote = this.props.commentStore.votedComments[this.props.id]
		
		var upvoteDisabled = vote === 'upvoted'
		var downvoteDisabled = vote === 'downvoted'

		var editDisabled = this.props.authStore.authFields.id !== this.props.userid



		//styles
		var upvotebuttonclass = classnames(styles.innerelement, styles.upvotebutton)
		var editbuttonclass = classnames(styles.innerelement, styles.editbutton)
		var replybuttonclass = classnames(styles.innerelement, styles.replybutton)



		// console.log(this.state.commentbody)


		return (
				<Tippy
					content={this.state.tippy.content}
					// trigger='manual'
					placement='auto'
					visible={this.state.tippy.visible}
					theme='light' 

					>
										
					<li onBlur={this.commentBlur} className={`${styles.commentunit} ${styles[commentposition]}`}>
						<div className={styles.userrow}>
					
							{/*<span>{this.props.id}</span>*/}
							<img src={this.state.avatarfile} width='40px' height='40px' alt="user"/>
							<Link className={styles.innerelement} to={`${ROUTES.OTHERUSERPROFILEPAGE}?id=${this.props.userid}`} >{this.state.username}</Link>
							<span className={styles.innerelement}>{this.state.date}</span>
						</div>
		
						<div dangerouslySetInnerHTML={{__html : sanitize(this.state.commentbody)}} className={styles.commentbody}>
							
						</div>
		
						<div className={styles.buttonrow}>
							<button className={upvotebuttonclass} disabled={upvoteDisabled} onClick={this.handleUpvote}>upvote</button>
							<span className={styles.innerelement}>{this.state.votecount}</span>
							<button className={upvotebuttonclass} disabled={downvoteDisabled} onClick={this.handleDownvote}>downvote</button>
		
		
							<button className={replybuttonclass} onClick={this.handleReply}>reply</button>
		
							{/* uncomment these when resuming development
								<button className={styles.innerelement} onClick={this.handleAward}>award</button>
								<button className={styles.innerelement} onClick={this.handleReport}>report</button>
								

							*/}
		
							<button className={editbuttonclass} disabled={editDisabled} onClick={this.handleEdit}>edit</button>		
		
						</div>
						
						<Collapse isOpened={this.state.reply.showEditor}>
							
							{
								this.state.showCommentBox ?
								<CreateComment 
									scriptid={this.props.scriptid}
									id={this.props.id}
									parentid={this.props.parentid}
									cancelcallback={() => this.setState({showCommentBox: false})}

								/>
								:
								null
							}
							
							

						</Collapse>

						<Collapse isOpened={this.state.showEditBox}>
							
							{
								this.state.showEditBox ?
								
								<Suspense fallback={<Loading/>}>
									
									<RichTextEditor
										description={this.state.commentInit}//this.state.commentbody
										contentCallback={this.getDescription}

										// commenteditcallback={this.commenteditcallback}
									/>
									<button onClick={this.editComment}>Submit</button>
									<button onClick={this.cancel}>Cancel</button>


								</Suspense>

								:
								null
							}
							
							

						</Collapse>
		
					</li>
		
				</Tippy>)

	}
}


var enhance = compose(
	inject('authStore', 'commentStore', 'userStore'),
	withRouter,
	observer
	)

export default enhance(CommentBox)

