import React from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import { observable, decorate } from 'mobx'

import { compose } from 'recompose'
import * as ROUTES from 'routes.js'

import 'styles/topnav/topnav_dropdown.css'
import Signout from 'pages/signout.js'



import Ellipse from 'images/topnav_dropdown/ellipse-20@2x.svg'
import Gear from 'images/topnav_dropdown/gear-icon@2x.svg'

import Vector1 from "images/topnav_dropdown/vector@2x.svg" 
import Vector2 from "images/topnav_dropdown/vector-2@2x.svg"
import Vector3 from "images/topnav_dropdown/vector-4@2x.svg"

import Vector4 from "images/topnav_dropdown/vector-6@2x.svg"

import MapIcon from "images/topnav_dropdown/135-map@2x.svg"



export default class TopnavDropdown extends React.Component {

	render(){
		var auth = this.props.auth
		return (
			<div className="frame-topnav_dropdown border-class-1 anima-screen">
			  <div className="rectangle-1-C61RwL"></div>
			  <div className="rectangle-2-C61RwL"></div>
			  <div className="rectangle-3-C61RwL"></div>
			  <div className="rectangle-4-C61RwL"></div>
			  <div className="frame-2-C61RwL">
			    <div className="gear-icon-gUEd1c"><img className="gear-icon-jdaxU4" src={Gear}/></div>
			    {
			    	auth ?

						<Signout>					    
							<button className="settings-10910-gUEd1c font-class-1 border-class-1">Sign out</button>
				    	</Signout>			    	:
					    <Link to={ROUTES.SIGNUP} className="settings-10910-gUEd1c font-class-1 border-class-1">Sign up</Link>
			    		
			    }
			  </div>
			  <Link to={auth ? ROUTES.LANDING : ROUTES.LANDING} className="frame-3-C61RwL">
			    <div className="account-10914-FY3HhL font-class-1 border-class-1">Account</div>
			    <div className="x356-paper-plane-FY3HhL">
			      <img className="vector-vFtSul" src={Vector1} />
			      <img className="vector-iljROx" src={Vector2} />
			      <img className="vector-KM0rBV" src={Vector3} />
			    </div>
			  </Link>
			  <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSet4lN_v9y_YherE8n4RN0gTTSKk15iW9mXfwk_iBpU67rNmA/viewform" className="frame-4-C61RwL">
			    <div className="subscribe-11327-61Z7WH font-class-1 border-class-1">Contact Us</div>
			    <div className="x131-star-61Z7WH"><img className="vector-obsLlx" src={Vector4} /></div>
			  </a>
			  <Link to={ ROUTES.DOWNLOADINFOPAGE} className="frame-5-C61RwL">
			    <div className="help-guides-11347-pqQuwH font-class-1 border-class-1">Help &amp; Guides</div>
			    <img className="x135-map-pqQuwH" src={MapIcon}/>
			  </Link>
			  <img className="ellipse-18-C61RwL" src={Ellipse} />
			  <img className="ellipse-19-C61RwL" src={Ellipse} />
			  <img className="ellipse-20-C61RwL" src={Ellipse} />
			</div>

		)
	}

}
