import React from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'
import styles from 'styles/components/authhome.module.css'

import { observable, decorate, when, toJS } from 'mobx'

import { compose } from 'recompose'

import Signout from 'pages/signout'

import requests, {Users} from 'axios.js'

import * as ROUTES from 'routes.js'

import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import TwitterLogo from 'images/account/twitter.png'

import Airplaneicon from 'images/account/airplaneicon.png'
import Arrowicon from 'images/account/arrowicon.png'
import Lightbulb from 'images/account/lightbulbicon.png'
import Pencilicon from 'images/account/pencilicon.png'
import Tagicon from 'images/account/tagicon.png'




function EmailVerifiedPopup(){
	return (

		<div className={styles.emailverifiedpopup}>Your email is now verified</div>
	)
}

function EmailNotVerifiedPopup(){
	return (

		<div className={styles.emailnotverifiedpopup}>Please verify your email</div>
	)
}




 class AuthHomeBase extends React.Component{

	cstate = {
		form: this.props.authStore.authFields.username === '' ? null : 'not null',
		username: '',
		isInvalidUsername: true
	}
	constructor(props){

		super(props)
		this.cstate.username = this.props.authStore.authFields.username
		this.firebase = this.props.firebaseStore.firebaseAccess.firebase

	}

	/* ASYNC DATA */

	getUserMetaData = () =>{
		var userid = this.props.authStore.authFields.id
		Users.getInitialUserMetaData(userid)
			.then( res =>{

				console.log(res.data.message)
				if(res.data.message){
					this.props.userStore.usermetadata.status = res.data.message.status
					this.props.userStore.usermetadata.pageviews =  res.data.message.pageviews
					this.props.userStore.usermetadata.flairs =  res.data.message.flairs
					this.props.userStore.usermetadata.description =  res.data.message.description
					this.props.userStore.usermetadata.datejoined =  res.data.message.datejoined
					this.props.userStore.usermetadata.awards =  res.data.message.awards

				}
			})
	}


	


	/*____________*/


	/* LIFECYCLE METHODS */

	componentDidUpdate(){
		var authStore = this.props.authStore

		// if(authStore.authorizedUser && (authStore.authFields.username === '' || authStore.authFields.username === null) ){
		// 	// console.log('nope?')

		// 	this.props.history.push(ROUTES.GETSTARTED)
		// }

	}

	componentDidMount(){
		
		when(
			() => typeof this.props.authStore.authFields.id === 'number' ,
			() => {
				// console.log("WHAT IS GOING ON")
				this.getUserMetaData()
			}
		)
	}

	/*____________*/
	

	
	/* INTERNALS */

	clickme = e =>{
		
		this.props.history.push('/')

	}

	handleSendVerificationEmail = e =>{

		

		var confirm = window.confirm('Resend Verification email?')

		if(confirm === true){
			
			this.firebase.doSendEmailVerification()
				.then( res =>{

					toast.success('Verification Email sent. Please check your spam folder just in case.', {
						position: "top-right",
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,

					});
					// this.setState({
					// 	messageVerificationEmail: 'Verification Email sent. Please check your spam folder just in case.'
					// })
				})
				.catch( err =>{
					console.log(err)
				})
		}
		

	}
	/*____________*/


	

	render(){

		var { authFields, errors, inProgress, emailVerified } = this.props.authStore

		

	 //    var FormComponent = this.cstate.form
		// if(FormComponent === null){
		// 	console.log('made it')
		// 	FormComponent = this.makeFormComponent()
		// }	    

		// this.cstate = {...this.props.authStore}
		// var { authFields, errors, inProgress, emailVerified } = this.cstate
		console.log(`emailver is ${emailVerified}`)

		console.log(`authfields:`)
		console.log(toJS(authFields))

		return (
			
			<div className={styles.maincontainer}>

				
					 <div className={styles.topcontainer} >
					 	<div className={styles.leftpanel}>
							<h1>Welcome, {authFields.username}!</h1>
					 		{emailVerified ? <EmailVerifiedPopup/> : <EmailNotVerifiedPopup/> }

					 		{
					 			!emailVerified ?
					 			<button onClick={this.handleSendVerificationEmail} className={styles.resendverificationemailbutton}>Resend Verification Email</button>
					 			:
					 			null
					 		}
					 	</div>
					 	<div className={styles.rightpanel}>
							<a className={styles.twitterlink} href="https://twitter.com/MimeScript"><img src={TwitterLogo} width="50px" alt=""/>Like ScriptMime? Follow us on Twitter!</a>

							<Link className={styles.rightpanellinktag} to={ROUTES.MASTERTAGSPAGE}><img className={styles.icon} src={Tagicon} width="50px" alt=""/>View the most popular tags</Link>
							<Link className={styles.rightpanellinkpencil} to={ROUTES.SCRIPT_GLOBALFEED}><img className={styles.icon} src={Pencilicon} width="7px" alt=""/>Explore the newest scripts from the community and people you follow</Link>
							<Link className={styles.rightpanellinkairplane} to={ROUTES.SCRIPT_SUBMITSCRIPT}><img className={styles.icon} src={Airplaneicon} width="50px" alt=""/>Got an idea for a script? Submit it!</Link>

					 	</div>



					</div>

					<div className={styles.bottompanel}>

						<div className={styles.heading}>Your Next Steps<Link to={ROUTES.LANDING}><img className={styles.icon} src={Arrowicon} width="30px" alt=""/></Link> </div>
						

						<div className={styles.content}>

						<div className={styles.leftcolumn}>
							<Link className={styles.link} to={ROUTES.DOWNLOADINFOPAGE}><img className={styles.icon} src={Lightbulb} width="20px" alt=""/>View our 4 step quick start guide to using Autohotkey and ScriptMime. </Link>
							<small className={styles.small}>5 minute read</small>
							
						</div>

						<div className={styles.rightcolumn}>
							<Link className={styles.link} to={ROUTES.INFOLANDING}><img className={styles.icon} src={Lightbulb} width="20px" alt=""/>Get familiar with all the features of ScriptMime with our user guide. </Link>
							<small className={styles.small}>5 minute read</small>
							
						</div>

						</div>
					</div>
				

			</div>

		)
	}
}

decorate(AuthHomeBase, {
  cstate: observable
})

var enhance = compose(
	inject('authStore', 'userStore', 'firebaseStore'),
	withRouter,
	observer
)

export default enhance(AuthHomeBase)

