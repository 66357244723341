import React from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import { observable, decorate } from 'mobx'

import { compose } from 'recompose'
import * as ROUTES from 'routes.js'



 class Error_404 extends React.Component{


	constructor(props){

		super(props)
	}

	componentDidUpdate(){

	}

	componentDidMount(){

	}

	
	render(){

		return(
			<div className="404">
				<h1>404! Page not found!</h1>
				<p>
					<Link to={ROUTES.LANDING}>Click here to go back to home.</Link>
				</p>
			</div>

		)

	}
}


var enhance = compose(
	inject('authStore'),
	withRouter,
	observer
	)

export default enhance(Error_404)

