import React from 'react'

import { withRouter, Link } from 'react-router-dom'

import { decorate, observable } from 'mobx'

import { observer, inject } from 'mobx-react'

import { compose } from 'recompose'

import requests, {Users} from 'axios.js'

import * as ROUTES from 'routes.js'

class Signout extends React.Component{

	authStore
	firebase
	constructor(props){
		super(props)
		this.authStore = this.props.authStore
		this.firebase = this.props.firebaseStore.firebaseAccess.firebase
	}

	componentDidMount(){

		
	}

	signout = e =>{

	    if(window.localStorage.getItem('role'))
	    	window.localStorage.removeItem('role')

	    if(window.localStorage.getItem('storesHaveLoaded'))
	    	window.localStorage.removeItem('storesHaveLoaded')

	    var userid = this.props.authStore.authFields.id
		var stores = []
		var storecontents = []

		var keys = Object.keys(localStorage)

		stores = keys.filter(key => key.includes('Store'))
		storecontents = stores.map(store =>{
			return localStorage.getItem(store)
		})

		console.log(stores)
		console.log(storecontents)

		if(typeof userid !== 'number'){
			this.firebase
				.doSignOut()
					.then( () =>{
						
						localStorage.clear()
						this.authStore.reset()
						
						// localStorage.removeItem('authStore')

						// this.props.history.push(ROUTES.SIGNUP)
						alert('signed out!')

						
					})
		}

		Users.storeMobxStores(userid, stores, storecontents)
			.then( resMobx =>{

				console.log(resMobx.data)

				this.firebase
					.doSignOut()
						.then( () =>{
							
							localStorage.clear()
							this.authStore.reset()
							
							// localStorage.removeItem('authStore')

							// this.props.history.push(ROUTES.SIGNUP)
							// alert('signed out!')
							window.location.reload(false)

							
						})

				
			})
			
		
	}

	render(){
		return (
		
			<div onClick={this.signout} className="signout">
				{/*<button onClick={this.signout}>Sign out</button>*/}
				{this.props.children}

			</div>

		)
	}

}

var enhance = compose(
	
	inject('authStore', 'firebaseStore'),
	withRouter,
	observer
)

export default enhance(Signout)