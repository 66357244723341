import React from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import { observable, decorate, toJS, when } from 'mobx'
import { sanitize } from 'dompurify'

import { compose } from 'recompose'

import {Scripts} from 'axios.js' 

import { toast } from 'react-toastify'



 class SubmitScript extends React.Component{

 	scriptid

	constructor(props){

		super(props)
	}

	/* ASYNC DATA FETCH */
	/* _________________ */


	/* _________________ */


	/* LIFECYCLE METHODS */
	/* _________________ */
	componentDidUpdate(prevProps, prevState){

	}

	componentDidMount(){

	}
	/* _________________ */



	/* INTERNALS */
	/* _________________ */

	deleteScript = e =>{
		var confirm = window.confirm('Are you sure you want to delete this script, and all its contents?')

		var scriptid = this.props.scriptid
		if(confirm){
			Scripts.deleteScript(scriptid)
				.then( res =>{
					
					if(res.data.message){
						console.log('script deleted')

						toast.success('Script successfully deleted', {
						position: "top-right",
						autoClose: 10000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,

						});
					}					

				})
		}
	}

	/* _________________ */

	
	render(){

		return(
			
			<button onClick={this.deleteScript}>Delete</button>

		)

	}
}


var enhance = compose(
	inject('authStore'),
	withRouter,
	observer
	)

export default enhance(SubmitScript)