import React, {Suspense, lazy} from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import { observable, decorate, toJS, when } from 'mobx'
import { sanitize } from 'dompurify'

import { compose } from 'recompose'

import { Blog } from 'axios.js'

import { displayTimeStamp, getTimeAgoString, parseQuery, getIndicesOf } from 'usefulFunctions.js'
import {decode} from 'html-entities'
import Collapse from 'react-collapse'

import Loading from 'components/animations/loading.js'

import * as ROUTES from 'routes.js'

//styles

import styles from 'styles/pages/blog/blogpage.module.css'


const RichTextEditor = lazy( () => import('components/scripts/ckeditor.js'))



/* LARGE CLASS */

 class BlogPage extends React.Component{

 		formState = {
 			paragraphs: [],

 			blogid: '',
 			userid: '',
 			blogname: '',
 			author: '',
 			blogtitle: '',
 			submitdate: '',
 			blog: '',
 			filesize: {width: '888px', height:'500px'},
 			files: [],
 			thumbnail: [],
 			gifs: [],
 			pics: [],
 		
 			showUserComponents: false,
 			showEditBlog: false,
 			showEditBlogName: false,
 			showEditBlog: false,
 			showCommentExample: false,
 			cancelBlog: '',
 			initBlog: '',
 			editBlogNameInput: '',
 			editScriptInput: '',

 			rows: 15,
 			cols: 50,

 			maxBlogLength: 20000,
 			maxblogitleLength: 100,

 			blognameTaken: false,
 			blogDisabled: false,
 			scriptDisabled: false,
 			commentDisabled: false,

 			//limits
 			blogsPerRequest: 10,
 			id_last_item: null, 
 	 	    id_first_item: null,
 		}
	constructor(props){

		super(props)

		this.queryParams = parseQuery(this.props.location.search)
		this.blogid = this.queryParams['id']
		this.blogname = this.queryParams['name']

		this.formState.blogid = this.blogid
		this.formState.blogname = this.blogname
	}

	/* ASYNC DATA FETCH */
	/* _________________ */

		fetchDataForBlog = () =>{
			
			//get userid from authStore 11/25/2020 4:34 PM TODAY RIGHT NOW MANCALA

			Blog.getBlogFromId(this.formState.blogid)
					.then( resBlog =>{
						
						console.log(resBlog.data.message)
						console.log(resBlog.data.error)
						this.userid = resBlog.data.message ? resBlog.data.message.userid : 1

						this.formState.author = resBlog.data.message.username
						this.formState.blogtitle = resBlog.data.message.blogname



						this.formState.blog = resBlog.data.message.blog
						this.formState.submitdate = resBlog.data.message.submitdate


						this.formState.initBlog = this.formState.blog



						

						this.formState.blog = this.prepareUserSubmittedHTML(this.formState.blog)

						this.formState.cancelBlog = this.formState.blog

						

						

					})

		}

	/* _________________ */


	


	/* INTERNALS */
	/* _________________ */

	prepareUserSubmittedHTML = htmlstring =>{
		

		//files
		var string_array_withcode = htmlstring.match(/\[\w+\]/gi)

		if(string_array_withcode === null ) //return value of string.match
			return htmlstring

		var string_array = string_array_withcode.filter(el => !el.includes('[code]') || !el.includes('[himg]'))

		
		var working_string = ''

		let string = ''

		console.log(string_array)
		//width=${width} height=${height}



		//code block
		//[code][/code]

		if(htmlstring.includes('[code]') && htmlstring.includes('[/code]')){

			var justcodearr = string_array_withcode.filter(ele => ele.includes('[code]'))

			justcodearr.forEach((ele, indexx) =>{

					// if(indexFromForCode === 0){

					// }
					// var code = htmlstring.substring(indecesForCode[indexx], htmlstring.indexOf('[/code]')).replace('[code]', '')

					var indecesForCode = getIndicesOf('[code]', htmlstring)
					var indecesforSlashCode = getIndicesOf('[/code]', htmlstring)

					var original = htmlstring.substring(indecesForCode[0],indecesforSlashCode[0])

					console.log(`${indecesForCode[0]}, ${indecesforSlashCode[0]}`)
					
					var code = htmlstring.substring(indecesForCode[0],indecesforSlashCode[0]).replace('[code]', '')
					console.log(code)

					// code = sanitize(code, {ALLOWED_TAGS: []})

					code = code.replace(/(\<p\>)/gm, '\r\n')
					// code = code.replace(/\>/g, '')

					code = code.replace(/(\<\/p\>)/gm, '')
					// code = code.replace(/(&nbsp;|&lt;)/g, '')

					// code = code.replace(/^(\s;)*/gm, '')
					// code = code.replace(/^(\s;)*/gm, '\t')


					// code = code.replace(/^(\s+;\s+;\s+;\s+;)|^(\s+;\s+;\s+;)|^(\s+;\s+;)|^(\s+;)/gm, '')

					code = code.replace(/^(\s\~)*|^(\~)*/gm, '')

					// console.log(code)

					code = decode(code)

					console.log(code)

					code = Prism.highlight(code.trim(), Prism.languages.autohotkey, 'autohotkey')

					// var prismcode = `<pre><code class="language-autohotkey">${code}</code></pre>`
					var prismcode = `<pre class='prismeditor line-numbers'><code class='language-autohotkey'>${code}</code></pre>`


					htmlstring = htmlstring.replace(original, prismcode )
					htmlstring = htmlstring.replace('[/code]', '')

				
			})
			

	  //   	console.log(htmlstring)

		}

		if(htmlstring.includes('[himg]') && htmlstring.includes('[/himg]')){


			var justhimgarr = string_array_withcode.filter(ele => ele.includes('[himg]'))

			justhimgarr.forEach((ele, indexx) =>{

				var indecesforHIMG = getIndicesOf('[himg]', htmlstring)
				var indecesforSlashHIMG = getIndicesOf('[/himg]', htmlstring)

				// var himg = htmlstring.substring(htmlstring.indexOf('[himg]'), htmlstring.indexOf('[/himg]')).replace('[himg]', '').trim()

				var original = htmlstring.substring(indecesforHIMG[0],indecesforSlashHIMG[0])

				// console.log(original)
				var himg = htmlstring.substring(indecesforHIMG[0],indecesforSlashHIMG[0])
				himg = himg.replace('[himg]', '')


				// var himg = htmlstring.substring(htmlstring.indexOf('[himg]'), htmlstring.indexOf('[/himg]')).replace('[himg]', '').trim()
				// console.log(himg)


				himg = himg.replace(/(\<p\>)/gm, '\r\n')

				himg = himg.replace(/(\<\/p\>)/gm, '')
				himg = himg.replace(/(&nbsp;|&lt;)/g, '\t')

				// console.log(himg)


				var himghtml = `<img src='${himg}' alt='img not loaded'  class='himg' />`


				// htmlstring = htmlstring.replace(htmlstring.substring(htmlstring.indexOf('[himg]'), htmlstring.indexOf('[/himg]')), himghtml )
				htmlstring = htmlstring.replace(original, himghtml )

				htmlstring = htmlstring.replace('[/himg]', '')
				

		    	// console.log(htmlstring)
			})
			

		}



		return htmlstring
	}

	scrollBottom = e =>{

		if(window.scrollY + window.innerHeight >= document.documentElement.scrollHeight - 400){

			var p = (
				<p key={Math.random() * 100}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Temporibus odit, est cum repudiandae dolore ullam dicta quas minima deserunt repellat animi, quisquam, labore voluptates debitis ipsum impedit distinctio beatae commodi?</p>
				)
			var ps = this.state.paragraphs
			// ps = ps.concat(p)
			this.setState({
				paragraphs: ps.concat(p)
			}, () =>{
				console.log()
			})
		}

	}



	

// Blog Name
	editBlogName = e =>{

		this.formState.showEditBlogName = !this.formState.showEditBlogName
	}

	editBlogNameInput = e =>{

		this.formState.editBlogNameInput = e.target.value
	}

	submitBlogName = e =>{
		var blogname = this.formState.editBlogNameInput



		Blog.editBlogPagePart( blogname, 'blogname', this.blogid)
			.then( res =>{
				
				if(res.data.message){
					this.formState.blogtitle = blogname
					window.location.reload(false)				
				}
				else{
					//name taken
					this.formState.blognameTaken = true

				}

			})
	}

// blog text

	getBlog = blog =>{

		if(blog.length > this.formState.maxBlogLength){
			this.formState.blogDisabled = true
		}
		else{
			this.formState.blogDisabled = false
		}
		this.formState.blog = blog
	}
	editBlog = e =>{

		this.formState.showEditBlog = true

		this.formState.blog = this.formState.initBlog

		var blog = this.formState.blog


		var fixedblog = blog.replace(/(\<pre)/gm, '[code]<pre')
		fixedblog = fixedblog.replace(/(\<\/pre\>)/gm, '</pre>[/code]')

		this.formState.blog = fixedblog

		console.log(this.formState.blog)

	}


	submitBlog = e =>{

		var blog = this.formState.blog

	

		// console.log(fixedblog)

		var fixedblog = this.prepareUserSubmittedHTML(blog)

		Blog.editBlogPagePart( fixedblog, 'blog', this.blogid)
			.then( res =>{
				
				if(res.data.message){
					this.formState.blog = fixedblog
					window.location.reload(false)				
				}
				else{

				}

			})


		// window.location.reload(false)
	}

	cancel = e =>{

		// this.props.commentStore.clearRichTextEditor()
		var blog = this.formState.blog

		var fixedblog = blog.replace(/\[code\]/gm, '')
		fixedblog = fixedblog.replace(/\[\/code\]/gm, '')

		this.formState.blog = this.formState.cancelBlog
		this.formState.showEditBlog = false
	}

	/* _________________ */


	/* LIFECYCLE METHODS */
	/* _________________ */
	componentDidUpdate(prevProps, prevState){

	}

	componentDidMount(){
	
		when(
			() => this.props.authStore.authFields.username === this.formState.author && this.formState.author !== '' && this.props.authStore.authFields.username !== '',
			() => {
				this.formState.showUserComponents = true

			}
		)

		this.fetchDataForBlog()

	}

	componentWillUnmount() {
	    // window.removeEventListener('scroll' );
	}

	/* _________________ */
	
	render(){

		var sanitizedHTML = sanitize(this.formState.blog, {ADD_TAGS: ['iframe']})

		return(
			
			<div className={styles.maincontainer}  >

				{/*{this.state.paragraphs}*/}

{/*blog meta*/}

				<div className={styles.metadatacontainer}>
					<h3 className={styles.blogtitle}>{this.formState.blogtitle}</h3>
					<Collapse isOpened={this.formState.showUserComponents}>

						<button className={styles.editbutton} onClick={this.editBlogName}>Edit</button>

						<Collapse isOpened={this.formState.showEditBlogName}>
							<input
								type='text'
								placeholder='blog title'
								onChange={this.editBlogNameInput}
								value={this.formState.editBlogNameInput}

							/>
							<button disabled={this.formState.editBlogNameInput === '' || this.formState.editBlogNameInput.length < 5} onClick={this.submitBlogName}>Accept</button>
							{
								this.formState.blognameTaken ?
									<p>Blog Title taken.</p>
								:
									null
							}
						</Collapse>
					
					</Collapse>
				</div>

					<div className={styles.secondrowmetadatacontainer}>

							<Link className={styles.userlink} to={`${ROUTES.OTHERUSERPROFILEPAGE}?id=${this.userid}`}><span className={styles.blogauthor}>by: {this.formState.author}</span></Link>

						<span className={styles.submitdate}>submitted {displayTimeStamp(this.formState.submitdate)}</span>

					</div>

{/*blog categories*/}

				{/*<ul className={styles.tags}>
					{tags}
				</ul>
				*/}
{/*blog text*/}		
				<div className={styles.blogcontainer} dangerouslySetInnerHTML = {{__html: sanitizedHTML }}></div>

{/*edit blog text*/}
				<Collapse isOpened={this.formState.showUserComponents}>

					<button className={styles.editbutton} onClick={this.editBlog}>Edit</button>

					<Collapse isOpened={this.formState.showEditBlog}>
						<Suspense fallback={<Loading/>}>
							
							<RichTextEditor
								description={this.formState.blog}
								contentCallback={this.getBlog}

								// commenteditcallback={this.commenteditcallback}
							/>
							<button disabled={this.formState.blog === '' || this.formState.blog === undefined || this.formState.blogDisabled} onClick={this.submitBlog}>Submit</button>
							<button onClick={this.cancel}>Cancel</button>


						</Suspense>
						{
							this.formState.blogDisabled ?
								<p>Blog too long. Max {this.formState.maxBlogLength} char.</p>
							:
								null
						}
					</Collapse>
					
				</Collapse>
			</div>




		)

	}
}

decorate(BlogPage, {
	formState: observable
})

var enhance = compose(
	inject('authStore'),
	withRouter,
	observer
	)

export default enhance(BlogPage)

