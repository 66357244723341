import React from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import { observable, decorate, toJS, when } from 'mobx'

import { compose } from 'recompose'
import requests, {Profile} from 'axios.js'
import {displayTimeStamp, displayTimeStamp2, getTimeAgoString} from 'usefulFunctions.js'
import {sanitize} from 'dompurify'


import styles from 'styles/components/profile/notecommentunit.module.css'

import NoteIcon from 'images/profile/note.png'

 class NoteCommentUnit extends React.Component{


 	userid
 	formState={
 		notes: [],
 		description: '',
 		notesperpage: 10,
 		id_last_item: 0
 	}
	constructor(props){

		super(props)
		this.authStore = this.props.authStore
	}

	/* ASYNC DATA FETCH */
	/* _________________ */

	getNotes = () =>{

		// var userid = this.props.authStore.authFields.id
		var userid = this.props.userid
		var limit = this.formState.notesperpage
		var id_last_item = this.formState.id_last_item

		console.log(`userid is ${userid}`)
		Profile.getUserProfileCommentsByUserid(userid, limit, id_last_item)
			.then( res =>{

				if (res.data.error)  return 
				var notes = res.data.message.length > 0 ? res.data.message : ''
				var joined = this.formState.notes
				if(notes !== ''){
					this.formState.notes = joined.concat(notes)
				} 
				console.log(toJS(this.formState.notes))

				this.formState.id_last_item = res.data.id_last_item

				console.log(this.formState.id_last_item)
			})
	}

	/* _________________ */


	/* LIFECYCLE METHODS */
	/* _________________ */
	componentDidUpdate(){

	}

	componentDidMount(){
		when( 
			() => typeof this.props.authStore.authFields.id === 'number'
		,
			() =>{
				this.getNotes()
			}
		)
	}
	/* _________________ */


	

	




	/* INTERNALS */
	/* _________________ */

	loadMoreNotes = e =>{
		this.getNotes()
	}
	/* _________________ */

	
	render(){
		// this.getNotes()
		// console.log(this.formState.notes.length)

		var moreDisabled = this.formState.notes.length < this.formState.notesperpage ? true : false
		var notes = this.formState.notes.map( note =>{
			return (
				<li className={styles.noteunit} key={note.id}>
					<img src={NoteIcon} width="50px" height="50px" alt=""/>
					<div className={styles.row}>
	 					<p className={styles.submitdate}>{getTimeAgoString(note.submitdate)}</p>
						<pre dangerouslySetInnerHTML={{__html : sanitize(note.commentbody)}} ></pre>
					</div>
					
					
				</li>
			)
		})

		return (
			<div className={styles.notescontainer}>
				{notes}
				<button className={styles.morebutton} disabled={moreDisabled} onClick={this.loadMoreNotes}>More</button>
			</div>

		)

	}
}

decorate(NoteCommentUnit, {
	formState: observable
})

var enhance = compose(
	inject('authStore'),
	withRouter,
	observer
	)

export default enhance(NoteCommentUnit)

