import {observable, action, decorate, reaction, when} from 'mobx'


import { requests, Scripts } from 'axios.js'


class CommentStore {
	clear = false


	//there is a when in ckeditor.js that will clear the editor when set to true
	clearRichTextEditor(){
		this.clear = true
	}

	votedComments = {}
}

decorate(CommentStore,{
	clear: observable,
	clearRichTextEditor: action,
	votedComments: observable
})


var commentStore = new CommentStore()



// when( () =>  commentStore.currentScriptToSubmit.index === commentStore.callbacks.length,
// 		() => commentStore.currentScriptToSubmit.done = true)



reaction( () => JSON.stringify(commentStore),
			json => {
				localStorage.setItem('commentStore', json)
			},
			{
				delay: 500
			})

export default commentStore

