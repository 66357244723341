import React from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import { observable, decorate } from 'mobx'

import { compose } from 'recompose'

import styles from 'styles/pages/termsconditions.module.css'


 class TermsConditions extends React.Component{


	constructor(props){

		super(props)
	}

	componentDidUpdate(){

	}

	componentDidMount(){

	}

	
	render(){

		return(
			<div className={styles.container}>
				<h3>Web Site Terms and Conditions of Use</h3>

				<section>
					<h5>1. Terms</h5>
					<p>By accessing this web site, you are agreeing to be bound by these web site Terms and Conditions of Use, our Privacy Policy, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this web site are protected by applicable copyright and trade mark law.</p>
				</section>
				<section>
					<h5>2. Use License</h5>
					<p>Permission is granted to temporarily download one copy of the materials (information or software) on ScriptMime's web site for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:
modify or copy the materials;
use the materials for any commercial purpose, or for any public display (commercial or non-commercial);
attempt to decompile or reverse engineer any software contained on ScriptMime's web site;
remove any copyright or other proprietary notations from the materials; or
transfer the materials to another person or "mirror" the materials on any other server.
This license shall automatically terminate if you violate any of these restrictions and may be terminated by ScriptMime at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.
					</p>
				</section>
				<section>
					<h5>3. Disclaimer</h5>
					<p>The materials on ScriptMime's web site are provided "as is". ScriptMime makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. Further, ScriptMime does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its Internet web site or otherwise relating to such materials or on any sites linked to this site.</p>
				</section>
				<section>
					<h5>4. Limitations</h5>
					<p>In no event shall ScriptMime or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption,) arising out of the use or inability to use the materials on ScriptMime's Internet site, even if ScriptMime or an authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</p>
				</section>
				<section>
					<h5>5. Revisions and Errata</h5>
					<p>The materials appearing on ScriptMime's web site could include technical, typographical, or photographic errors. ScriptMime does not warrant that any of the materials on its web site are accurate, complete, or current. ScriptMime may make changes to the materials contained on its web site at any time without notice. ScriptMime does not, however, make any commitment to update the materials.</p>
				</section>
				<section>
					<h5>6. Links</h5>
					<p>ScriptMime has not reviewed all of the sites linked to its Internet web site and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by ScriptMime of the site. Use of any such linked web site is at the user's own risk.</p>
				</section>
				<section>
					<h5>7. Copyright / Takedown</h5>
					<p>Users agree and certify that they have rights to share all content that they post on ScriptMime — including, but not limited to, information posted in scripts, discussions, and comments. This rule applies to prose, code snippets, collections of links, etc. Regardless of citation, users may not post copy and paste content that does not belong to them. Users assume all risk for the content they post, including someone else's reliance on its accuracy, claims relating to intellectual property, or other legal rights. If you believe that a user has plagiarized content, misrepresented their identity, misappropriated work, or otherwise run afoul of DMCA regulations, please email helpscriptmime@gmail.com. ScriptMime may remove any content users post for any reason.</p>
				</section>
				<section>
					<h5>8. Site Terms of Use Modifications</h5>
					<p>ScriptMime may revise these terms of use for its web site at any time without notice. By using this web site you are agreeing to be bound by the then current version of these Terms and Conditions of Use.</p>
				</section>
				<section>
					<h5>9. ScriptMime Trademarks and Logos Policy</h5>
					<p>All uses of the ScriptMime logo, ScriptMime badges, brand slogans, iconography, and the like, may only be used with express permission from ScriptMime. ScriptMime reserves all rights, even if certain assets are included in ScriptMime open source projects. Please contact scriptmime@gmail.com with any questions or to request permission.</p>
				</section>
				<section>
					<h5>10. Reserved Names</h5>
					<p>ScriptMime has the right to maintain a list of reserved names which will not be made publicly available. These reserved names may be set aside for purposes of proactive trademark protection, avoiding user confusion, security measures, or any other reason (or no reason).</p>
					<p>Additionally, ScriptMime reserves the right to change any already-claimed name at its sole discretion. In such cases, ScriptMime will make reasonable effort to find a suitable alternative and assist with any transition-related concerns.</p>
				</section>
				<section>
					<h5>11. Content Policy</h5>
					<p>The following policy applies to comments, articles, and all other works shared on the ScriptMime platform:</p>
					<p>Users must make a good-faith effort to share content that is on-topic, of high-quality, and is not designed primarily for the purposes of promotion or creating backlinks.</p>
					<p>Posts must contain substantial content — they may not merely reference an external link that contains the full post.
If a post contains affiliate links, that fact must be clearly disclosed. For instance, with language such as: “This post includes affiliate links; I may receive compensation if you purchase products or services from the different links provided in this article.”
					</p>
					<p>ScriptMime reserves the right to remove any content that it deems to be in violation of this policy at its sole discretion. Additionally, ScriptMime reserves the right to restrict any user’s ability to participate on the platform at its sole discretion.</p>
				</section>
				<section>
					<h5>12. Governing Law</h5>
					<p>Any claim relating to ScriptMime's web site shall be governed by the laws of the State of Florida without regard to its conflict of law provisions.</p>
				</section>
				<section>
					<h5>13. Autohotkey’s Terms of Use</h5>
					<p>By using ScriptMime you agree to abide by Autohotkey’s software license, terms of use, and privacy policy found on Autohotkey.com. </p>
					<p>ScriptMime is not affiliated with Autohotkey LLC , autohotkey.com or any of its subsidiaries. </p>

				</section>

				<h3>Acknowledgements and Attributions</h3>

				<h3>Icons</h3>
				<div>Icons made by <a href="http://www.dariusdan.com/" title="Darius Dan">Darius Dan</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
				<div>Icons made by <a href="http://www.freepik.com/" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>

				<div>Icons made by <a href="https://smashicons.com/" title="Smashicons">Smashicons</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
				<div>Icons made by <a href="https://www.flaticon.com/authors/pixel-perfect" title="Pixel perfect">Pixel perfect</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
				<div>Icons made by <a href="https://www.flaticon.com/authors/dinosoftlabs" title="DinosoftLabs">DinosoftLabs</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
				<div>Icons made by <a href="https://www.flaticon.com/authors/vectors-market" title="Vectors Market">Vectors Market</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>

				<h3>Backgrounds</h3>
				<div>I customized the wicked cool background on our landing/other pages at SVGBackgrounds.com.</div>
				
			</div>

		)

	}
}


var enhance = compose(
	inject('authStore'),
	withRouter,
	observer
	)

export default enhance(TermsConditions)

