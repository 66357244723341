

import React from 'react'

const SVG = props => (

	<svg xmlns="http://www.w3.org/2000/svg" height={props.height} width={props.width} viewBox='0 0 550 550'><path d="M501.333 448.004H64V10.67C64 4.779 59.224.003 53.333.003S42.667 4.779 42.667 10.67v437.333h-32C4.776 448.004 0 452.779 0 458.67s4.776 10.667 10.667 10.667h32v32c0 5.891 4.776 10.667 10.667 10.667S64 507.228 64 501.337v-32h437.333c5.891 0 10.667-4.776 10.667-10.667s-4.776-10.666-10.667-10.666z" data-original="#455A64" class="active-path" data-old_color="#455A64" fill="#959ba0"/><path d="M96 426.67c-5.891-.008-10.66-4.791-10.651-10.682a10.664 10.664 0 012.331-6.641l85.333-106.667a10.668 10.668 0 017.723-4.011 10.496 10.496 0 018.128 3.115l33.003 33.024 56.96-94.955a10.668 10.668 0 018.533-5.163 11.157 11.157 0 019.045 3.989l28.544 35.691 37.952-180.5c1.217-5.764 6.877-9.45 12.641-8.232a10.667 10.667 0 015.343 2.899l55.296 55.296L492.8 68.27c3.53-4.716 10.215-5.678 14.931-2.149s5.678 10.215 2.149 14.931l-.013.017-64 85.333c-3.535 4.712-10.221 5.666-14.934 2.131a10.63 10.63 0 01-1.13-.979l-50.069-50.091-38.614 183.469c-1.213 5.765-6.87 9.454-12.635 8.241a10.665 10.665 0 01-6.138-3.782l-33.088-41.344-56.107 93.504c-3.006 5.066-9.55 6.737-14.616 3.731a10.645 10.645 0 01-2.066-1.598l-34.133-34.133-77.995 97.131A10.672 10.672 0 0196 426.67z" data-original="#2196F3" data-old_color="#2196F3" fill="#69e781"/></svg>
	//height={props.height} width={props.width} viewBox={`0 0 ${props.viewW} ${props.viewH}`}
	
)

export default SVG

