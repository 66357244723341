//CommentBox to create, submit, edit a comment on a page


import React, {lazy, Suspense} from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'
import { observable, decorate } from 'mobx'
import { compose } from 'recompose'
import * as ROUTES from 'routes.js'
import styles from 'styles/components/comments/createcomment.module.css'
import requests, { Profile } from 'axios.js'
import Loading from 'components/animations/loading.js'

import { sanitize } from 'dompurify'




const RichTextEditor = lazy( () => import('components/scripts/ckeditor.js') )


 class CreateDescription extends React.Component{

	formState = {
		description: '',
		submitDisabled: false,
	}

	cancelcallback


	constructor(props){

		super(props)
	}
	/* LIFECYCLE METHODS */
	/* _________________ */
	componentDidUpdate(){

	}

	componentDidMount(){
	}
	/* _________________ */

	/* ASYNC DATA FETCH */
	/* _________________ */


	/* _________________ */


	/* INTERNALS */

	submitComment = e =>{

		var commentbody = this.formState.description
		commentbody = sanitize(commentbody)
		var userid = this.props.authStore.authFields.id


		if(commentbody.length === 0 )
			return

		Profile.submitProfileDescription(commentbody, userid)
			.then( res =>{
				// console.log('submitted : '+commentbody)
				window.location.reload(false)
			})
		

	}
	/* _________________ */

	getDescription = description =>{

		if(description.length > this.props.uiStore.maxUserProfileDescriptionLength){
			this.formState.submitDisabled = true
		}
		else{
			this.formState.submitDisabled = false

		}
		this.formState.description = description
	}

	cancel = e =>{

		this.props.commentStore.clearRichTextEditor()
		
		this.props.cancelcallback ? this.props.cancelcallback() : ''
	}
	/* _________________ */

	
	render(){

		return(

			<div className={styles.container}>
			

				<Suspense fallback={<Loading />}>
					<RichTextEditor
						contentCallback={this.getDescription}
						
						description=' '
					/>
				</Suspense>
				<div className={styles.row}>
					<button className={styles.submitbutton} disabled={this.formState.submitDisabled} onClick={this.submitComment}>Submit</button>
					<button className={styles.submitbutton} onClick={this.cancel}>Cancel</button>

				</div>
			
				{
					this.formState.description.length > this.props.uiStore.maxUserProfileDescriptionLength 
					?
						<p>description too long. max {this.props.uiStore.maxUserProfileDescriptionLength} </p>
					:
						null
				}


			</div>
		)

	}
}

decorate(CreateDescription, {
	formState: observable
})

var enhance = compose(
	inject('authStore', 'commentStore', 'uiStore'),
	withRouter,
	observer
	)

export default enhance(CreateDescription)

