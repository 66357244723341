
import React from 'react'

const SVG = props => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 550 550" height={props.height} width={props.width}><path d="M468.293 98.357H0V29.674c0-6.897 5.591-12.488 12.488-12.488h443.317c6.897 0 12.488 5.591 12.488 12.488v68.683z" data-original="#FCD462" fill="#fcd462"/><path d="M468.293 98.357H222.357l40.595-48.207a31.22 31.22 0 0123.88-11.11h181.46v59.317z" data-original="#F6C358" fill="#f6c358"/><path d="M455.805 451.106H12.488C5.591 451.106 0 445.515 0 438.619V98.357h468.293v340.262c0 6.896-5.591 12.487-12.488 12.487z" data-original="#44C4A1" data-old_color="#44C4A1" fill="#e3e9ed"/><circle cx="63.875" cy="57.769" r="18.732" data-original="#27A2DB" data-old_color="#27A2DB" fill="#fff"/><circle cx="120.07" cy="57.769" r="18.732" data-original="#E56353" fill="#e56353"/><circle cx="176.265" cy="57.769" r="18.732" data-original="#DC8744" fill="#dc8744"/><path data-original="#3EA69B" class="active-path" data-old_color="#3EA69B" fill="#fff" d="M221.659 151.402h24.976v246.653h-24.976z"/><path data-original="#E1E6E9" data-old_color="#E1E6E9" fill="#374957" d="M282.537 151.402h149.854v18.732H282.537zM282.537 208.384h149.854v18.732H282.537zM282.537 265.366h149.854v18.732H282.537zM282.537 322.373h149.854v18.732H282.537zM282.537 379.317h149.854v18.732H282.537zM35.902 265.366h149.854v18.732H35.902zM35.902 322.373h149.854v18.732H35.902zM35.902 379.317h149.854v18.732H35.902z"/><path data-original="#27A2DB" data-old_color="#27A2DB" fill="#fff" d="M35.902 151.402h149.854v75.739H35.902z"/></svg>

	//height={props.height} width={props.width} viewBox={`0 0 ${props.viewW} ${props.viewH}`}
	
)

export default SVG

