import React, { Component } from 'react';

import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import { observable, decorate, when } from 'mobx'

import { compose } from 'recompose'


import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


export var initDescription = `
                                        
See the guide above for help writing description.
    
`


// console.log(ClassicEditor.builtinPlugins.map( plugin => plugin.pluginName ));

class RichTextEditor extends Component {

    
    formState ={
        initData: this.props.description || initDescription,
        editor: null
    }

    contentCallback
    description
    commenteditcallback
    constructor(props){
        super(props)

        

    }

    componentDidMount(){

        when(
            () => this.props.commentStore.clear === true,
            () => {
                this.formState.editor.setData('')
                this.props.commentStore.clear = false
            }
        )
    }
    render() {
        return (
            <div className="">
                <CKEditor
                    editor={ ClassicEditor }
                    data={this.formState.initData}
                    onInit={ editor => {
                        // You can store the "editor" and use when it is needed.
                        // console.log( 'Editor is ready to use!', editor );

                        this.formState.editor = editor

                        // console.log(editor)
                    } }
                    onChange={ ( event, editor ) => {
                        if(this.props.contentCallback)
                            this.props.contentCallback(editor.getData())
                        
                        // console.log( data );
                    } }
                    onBlur={ ( event, editor ) => {
                        // console.log( 'Blur.', editor );
                        
                        if(this.props.contentCallback)
                            this.props.contentCallback(editor.getData())

                        if(this.props.commenteditcallback)
                            this.props.commenteditcallback(editor.getData())

                    } }
                    onFocus={ ( event, editor ) => {
                        // console.log( 'Focus.', editor );
                            } 
                    }

                    config={{
                        removePlugins: ['ImageUpload']
                    }}
                    
                />
            </div>
        );
    }
}

decorate(RichTextEditor, {
    formState: observable
})

var enhance = compose(
    inject('authStore','scriptStore', 'commentStore'),
    withRouter,
    observer
    )

export default enhance(RichTextEditor)

