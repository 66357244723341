import React from 'react'


// import '../styles/bulma.scss'

import UncontrolledLottie from './animations/lottietest'
// import BellAnim from 'components/animations/newanim.js'


import {ReactComponent as UbuntuLogo} from 'images/logo.svg'


import {observer, inject } from 'mobx-react'

import { compose } from 'recompose'


import classnames from 'classnames'


// @inject('uiStore')
// @observer
class Home extends React.Component{
	
	state = {

	}

	constructor(){
		super()
	}

	render(){


		let state = this.state

		var { authStore } = this.props

		// uiStore.addData({file: 'https:www.google.com', name: 'motoyasu.png'})
		// uiStore.addData({file: 'https:www.FB.com', name: 'naofumi.png'})
		// let classNames = classnames(styles.sideMenu, { [styles.active]: this.props.menuOpen }); // <div className={classNames}></div>


		return (

		
		<div className="container">
			{/*<Hinge><Review /></Hinge>   <img src={ubuntuLogo} ></img>*/}

			{/*<UncontrolledLottie />*/}

			{/*<BellAnim />*/}
			
			{/*<UbuntuLogo />*/}

			{/*<h2>What's new?</h2>
			<p>ScripMime Blog</p>


			<section>Hello</section>

			<main>
				<article>
					<aside>
						<h1>Aside 1</h1>
					</aside>
					<section>
						<h1>Section header</h1>
						<p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.</p>  

					</section>
					<aside>
						<h1>Aside 2</h1>
					</aside>
				</article>
			</main>
			<footer>
			    <p>Footer</p>
			</footer>
			*/}

		</div>
	
		)

	}
	
	
}

var enhance = compose(
	inject('authStore'),
	observer
	)

export default enhance(Home)