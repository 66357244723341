import React from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'


import {renderToStaticMarkup} from 'react-dom/server'

import Background1 from 'icons/landing/backgroundlanding1.js'

import styles from 'styles/pages/misc/support.module.css'

export default class SupportPage extends React.Component {

	render(){

	const background1string = encodeURIComponent(renderToStaticMarkup(<Background1/>))

		return (
			
			<div className={styles.maincontainer} style={{backgroundImage: `url("data:image/svg+xml, ${background1string}")`}}>
				
				<h3 className={styles.header}>Contact Us</h3>

				<p className={styles.inquiryheader}>Feedback</p>
				<a href="https://docs.google.com/forms/d/e/1FAIpQLSet4lN_v9y_YherE8n4RN0gTTSKk15iW9mXfwk_iBpU67rNmA/viewform" target="_blank">If you would like to leave us feedback, please use this form. </a>
				<small className={styles.feedbackmessage}>Thanks for your time! Please give feedback on any bugs you found, or ideas you have to improve ScriptMime! What would you like to see added, too? Every single suggestion will be read carefully, including yours. We'll work hard to meet your expectations, so don't hold back!</small>

				<p className={styles.inquiryheader}>Technical Inquiry</p>
				<p>Send all inquiries to helpscriptmime@gmail.com</p>


				<p className={styles.inquiryheader}>Business and Other Inquiry</p>
				<p>Send all inquiries to scriptmime@gmail.com</p>


			</div>
		)
	}

}
