

import React from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import requests, { Users } from 'axios.js'

import { observable, decorate, toJS, when } from 'mobx'

import { compose } from 'recompose'

import { timeDifference } from 'usefulFunctions.js'

import * as ROUTES from 'routes.js'

import SocialIcon from 'icons/topnav/notifications/social.js'
import XButtonIcon from 'icons/general/xclosebutton.js'


import styles from 'styles/topnav/notification.module.css'



 class FollowerNotification extends React.Component{
 	
 	hostuserid
 	followeruserid
 	timestamp
 	deleteFunction
 	id
	constructor(props){

		super(props)

		this.followeruserid = this.props.followeruserid
		this.hostuserid = this.props.hostuserid

		this.timestamp = this.props.timestamp
		this.deleteFunction = this.props.deleteFunction


		// var t = '2021-01-17 04:07:40.210151'

		var date_times = ['exactdate', 'months', 'days', 'hours', 'minutes', 'seconds']

		var timediffresult = timeDifference(this.timestamp)
		var timediff

		date_times.forEach( date_time =>{
			if(timediffresult.hasOwnProperty(date_time)){
				if(date_time !== 'exactdate'){
					timediff = `${timediffresult[date_time]} ${date_time} ago`

				}
				else{

					var temparrexactdate = []
					for( const property in timediffresult)
						temparrexactdate.push(property)

					timediff = `${timediffresult[temparrexactdate[0]]} ${temparrexactdate[0]}, ${timediffresult[temparrexactdate[1]]} ${temparrexactdate[1]} ${timediffresult[temparrexactdate[2]]} ${temparrexactdate[2]} ago`

				}
			}
		})


		this.state = {
			followerusername: '',
			timedifference: timediff,
			followerprofilepic: '',
		}

		// console.log(this.state.timedifference)

	}

	/* ASYNC DATA FETCH */
	/* _________________ */

	getInfo = () =>{

		Users.getuserfromid(this.followeruserid)
			.then( resFollowerUsername =>{

				this.setState({
					followerusername: resFollowerUsername.data.message ? resFollowerUsername.data.message : 'user'
				})  

				Users.getAvatar(this.followeruserid)
					.then(resAvatar =>{
						if(resAvatar.data.message){
							this.setState({
								followerprofilepic: resAvatar.data.message
							})

						}
						else{
							this.setState({
								followerprofilepic: this.props.userStore.anon.file
							})
						}


					})
			})
	}

	/* _________________ */


	/* LIFECYCLE METHODS */
	/* _________________ */
	componentDidUpdate(){

	}

	componentDidMount(){
		when( () => typeof this.props.authStore.authFields.id === 'number' ,
			  () =>{
			  	this.getInfo()
			  }
			)

	}
	/* _________________ */



	/* INTERNALS */
	/* _________________ */


	/* _________________ */

	
	render(){

		var xbuttonwidth = '12px'
		var xbuttonheight =  '12px'

		
		return(
			//className={styles.notificationcontainer}


			<div className={styles.container}>

				<div className={styles.top}>
					<Link  to={`${ROUTES.OTHERUSERPROFILEPAGE}?id=${this.followeruserid}`}>
						<img className={styles.avatar} src={this.state.followerprofilepic} width={this.props.userStore.avatar.width} height={this.props.userStore.avatar.height} alt="user"/>
					</Link>
					<div className={styles.textblock}>
						<Link className={styles.linktoprofile} to={`${ROUTES.OTHERUSERPROFILEPAGE}?id=${this.followeruserid}`}>
							<p className={styles.followtext}><span className={styles.followerusername}>{this.state.followerusername}</span> followed you</p>
						</Link>
						
					</div>
					<div onClick={(e) => this.deleteFunction(e, this.props.id, 'follower')}>
						<XButtonIcon className={styles.xbutton}
							width={xbuttonwidth}
							height={xbuttonheight}
						/>

					</div>
				</div>
				
				<div className={styles.bottom}>
					<div className={styles.timedifference}>
						{this.state.timedifference}
					</div>

					<SocialIcon className={styles.typeIcon}
						onClick={this.deleteFunction}
						width={this.props.userStore.notification.typeIcon.width}
						height={this.props.userStore.notification.typeIcon.height}
					/>
				</div>
				

			</div>

		)

	}
}


var enhance = compose(
	inject('authStore', 'userStore'),
	withRouter,
	observer
	)

export default enhance(FollowerNotification)
