import React from 'react'


import styles from 'styles/components/script/scriptExampleGuide.module.css'
const blogExampleGuide = props =>{



	return (
		<div className={styles.content} >


			<h2 className={styles.p}>Quick Guide:</h2>
			<h3 className={styles.p}>Media Insert</h3>

			<p className={styles.p}>* You can insert images/gifs/videos through direct URL links from (imgur, imgBB, etc)</p>

			<h4 className={styles.p}>_Outside Hosted Files_</h4>

			<p className={styles.p}>* Insert any images, gifs or videos through direct URL links from (imgur, imgBB, etc) like this: <span className={styles.span}>[himg]URL[/himg]</span></p>

			<h3 className={styles.p}>Code Blocks</h3>

			<p className={styles.p}>Insert Autohotkey code blocks like this: <span className={styles.span}>[code]YOUR_CODE[/code]</span></p>
			<p className={styles.p}>Spaces or line breaks are okay!</p>
			





			<h3 className={styles.p}>Example Blog</h3>

				<div className={styles.example}>			
                    <h2>Big announcement</h2>

					<p className={styles.p}>5/14/2021 11:54 AM</p>
				    


				    
				    <p className={styles.p}>Today I undoubtedly ate a banana. </p>
				    <p className={styles.p}> <span className={styles.span}>[code]<br/> code block <br/> [/code] </span> </p>
				    <p className={styles.p}>This is an extra code block. </p>
				    <p className={styles.p}> <span className={styles.span}>[himg]<br/> https://i.imgur.com/kCXRY9w.gif <br/>[/himg]</span> </p>
				    <p className={styles.p}>This is an extra image. </p>


				</div>



		</div>
		

	)
}



export default blogExampleGuide