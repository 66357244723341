import React from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import { observable, decorate, toJS, when } from 'mobx'
import { sanitize } from 'dompurify'

import { compose } from 'recompose'
import { displayTimeStamp, getTimeAgoString, slugify } from 'usefulFunctions.js'
import requests, { Scripts } from 'axios.js'

import * as ROUTES from 'routes.js'

import Tippy from '@tippyjs/react'
import 'tippy.js/themes/light.css'

//styles
import styles from 'styles/components/script/mastertagunit.module.css'

/* master tag unit */

 class TagUnit extends React.Component{

 	tag
 	tagid
 	tagtag
	constructor(props){

		super(props)
		this.state = {
			scriptcount: 0,
			tippyFollow: {visible: false, content: 'TEST'},
			followtag: 'unfollowed',
			tagtag: '',
			tagid: '',
			submitdate: undefined,
			loading: true
		}


	}

	/* ASYNC DATA FETCH */
	/* _________________ */
	getNumScriptsPublished = () =>{
		Scripts.tags.getNumScriptsPublished(this.state.tagtag)
			.then( res =>{
				if(res.data.message){
					
					if(this.props.scriptStore.followedTags[this.state.tagid]){ //this.props.tag.id
						this.setState({
							followtag: this.props.scriptStore.followedTags[this.state.tagid], //this.props.tag.id
						}, () => console.log(this.state.followtag))


					}
					this.setState({
						scriptcount: res.data.message,
						loading: false
					})

				}
			})
	}

	/* _________________ */


	/* LIFECYCLE METHODS */
	/* _________________ */
	componentDidUpdate(prevProps, prevState){

		// console.log(prevState)

		// if(typeof prevState.tagid === 'number'){
		// 	this.setState({
		// 		tagid: prevState.tagid
		// 	})
		// }

	}

	componentDidMount(){

		if(this.props.tag){
			console.log('here1')

			this.setState({
				tagtag: this.props.tag.tags,
				tagid: this.props.tag.id,
				submitdate: this.props.tag.submitdate
		
			}, () => this.getNumScriptsPublished())
		}

		else if(this.props.tagtag && this.props.tagid){

			console.log('here')
			this.setState({
				tagtag: this.props.tagtag,
				tagid: this.props.tagid,
				submitdate: this.props.submitdate
			}, () => this.getNumScriptsPublished())
		}


		when(
			() => Object.keys(this.props.scriptStore.followedTags).length > 0,
			() =>{

				// console.log(this.state.tagid)
				// console.log(this.props.scriptStore.followedTags[this.state.tagid])
				if(this.props.scriptStore.followedTags[this.state.tagid]){ //this.props.tag.id
					this.setState({
						followtag: this.props.scriptStore.followedTags[this.state.tagid] //this.props.tag.id
					}, () => console.log(this.state.followtag))


				}
			}
		)

	}
	/* _________________ */


	

	




	/* INTERNALS */
	/* _________________ */

	authorized = () =>{

		return this.props.authStore.authorizedUser ? true : false
	}

	followTag = (tag, e) =>{

		var followeruserid = this.props.authStore.authFields.id

		console.log(tag)

		if(this.authorized() ){

			Scripts.tags.followTag(tag, followeruserid)
				.then( res =>{

					if(res.data.message){
						this.props.scriptStore.followedTags[this.state.tagid] = 'followed' //this.props.tag.id

						this.setState({
							followtag: 'followed'
						})

					}
				})
		}

		else{
			this.setState({
				tippyFollow: {visible: true, content: 'Must be logged in to follow.'}
			})
		}


		
	}

	unfollowTag = (tag, e) =>{

		var followeruserid = this.props.authStore.authFields.id


		console.log(this.authorized())
		if(this.authorized() ){

			Scripts.tags.unfollowTag(tag, followeruserid)
				.then( res =>{

					console.log("I DELETED IT")

					if(res.data.message){
						this.props.scriptStore.followedTags[this.state.tagid] = 'unfollowed'

						this.setState({
							followtag: 'unfollowed'
						})

					}
				})
		}

		else{
			this.setState({
				tippyFollow: {visible: true, content: 'Must be logged in to follow.'}
			})
		}
	}

	followBlur = e => {
		this.setState({
			tippyFollow: {visible: false, content: ''}
		})
	}

	/* _________________ */

	
	render(){




		return (
			<div className={styles.maincontainer} >
				<div className={styles.toprow}>
					<Link to={`${ROUTES.TAGPAGE}?id=${this.state.tagid}&tag=${this.state.tagtag}/#${slugify(this.state.tagtag)}`}>
						<h3>#{this.state.tagtag}</h3>

					</Link>
					{
						this.state.submitdate !== undefined ?
							<p>since {displayTimeStamp(this.state.submitdate).substring(0, this.state.submitdate.length - 13)}</p>
						:
							null

					}

					
				</div>
				<div className={styles.bottomrow}>
					<Link to={`${ROUTES.TAGPAGE}?id=${this.state.tagid}&tag=${this.state.tagtag}/#${slugify(this.state.tagtag)}`}>See Scripts</Link>
					<p>{this.state.scriptcount} scripts published</p>
					
					<Tippy
						content={this.state.tippyFollow.content}
						// trigger='manual'
						placement='auto'
						visible={this.state.tippyFollow.visible}
						theme='light' 
					>
					{
						this.state.followtag === 'unfollowed' ?
							<button disabled={this.state.loading === true} className={styles.followbutton} onBlur={this.followBlur} onClick={(e) => this.followTag(this.state.tagtag, e)}>Follow</button>
						:
							<button disabled={this.state.loading === true} className={styles.followbutton} onBlur={this.followBlur} onClick={(e) => this.unfollowTag(this.state.tagtag, e)}>Unfollow</button>

					}


				</Tippy>

				</div>
			</div>

		)

	}
}


var enhance = compose(
	inject('authStore', 'scriptStore'),
	withRouter,
	observer
	)

export default enhance(TagUnit)

