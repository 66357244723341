
import React from 'react'

const SVG = props => {

	return (
		<svg height={props.height} width={props.width} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M37.059 16H26V4.941C26 2.224 23.718 0 21 0C18.282 0 16 2.224 16 4.941V16H4.941C2.224 16 0 18.282 0 21C0 23.718 2.224 26 4.941 26H16V37.059C16 39.776 18.282 42 21 42C23.718 42 26 39.776 26 37.059V26H37.059C39.776 26 42 23.718 42 21C42 18.282 39.776 16 37.059 16Z" fill="#000"/> 
		</svg>

	)
	//height={props.height} width={props.width} viewBox={`0 0 ${props.viewW} ${props.viewH}`}
	
}

export default SVG


