import React from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'
import styles from 'styles/components/authhome.module.css'

import { observable, decorate } from 'mobx'

import { compose } from 'recompose'

import Signout from 'pages/signout'

import requests from 'axios.js'

import * as ROUTES from 'routes.js'

const GetStarted = () =>{

	return(
		<GetStartedForm />
	
	)


}

export default GetStarted


// @inject('authStore')
// @withRouter
// @observer

 class GetStartedBase extends React.Component{
	
	authStore
	firebase
	cstate = {
		form: this.props.authStore.authFields.username === '' ? null : 'not null',
		username: '',
		isInvalidUsername: true,
		usernameTaken: false,
		usernameTooShort: false,
		usernameTooLong: false
	}
	constructor(props){

		super(props)
		this.cstate.username = this.props.authStore.authFields.username
		this.firebase = this.props.firebaseStore.firebaseAccess.firebase
		this.authStore = this.props.authStore
		this.firebase = this.props.firebaseStore.firebaseAccess.firebase

	}

	componentDidUpdate(){
		// console.log('mobx don fail me now')
		var authStore = this.props.authStore
		
		if(authStore.authorizedUser && authStore.authFields.username !== null && authStore.authFields.username.length > 0){
			this.props.history.push(ROUTES.AUTHHOME)
		}

	}

	componentDidMount(){
		
	}

	async isValidUsername(username){

		var res = await requests.post(`${ROUTES.API.checkusername}`, {username: username}) 	
		
		//true is returned from server if username not found in db, good to go
		return res.data.valid
	}
	changeUsernameForm = e =>{
		// var { username, isInvalidUsername } = this.cstate
		this.cstate.username = e.target.value

		this.isValidUsername(this.cstate.username)
			.then( res =>{

				this.cstate.usernameTaken = !res
				this.cstate.usernameTooLong = this.cstate.username.length > 20
				this.cstate.usernameTooShort = this.cstate.username.length <= 2
				this.cstate.isInvalidUsername = res && this.cstate.username.length < 20 && this.cstate.username.length > 2 
					? false : true 


			})
	}

	submitUsername = e =>{

		e.preventDefault()

		if(this.props.authStore.authFields.username.length > 0){
			this.props.history.push(ROUTES.AUTHHOME)
			return
		}

		var all_set = window.confirm('this username? :'+this.cstate.username)

		if(all_set){
			var uid = this.firebase.auth.currentUser.uid      
			requests.post(`${ROUTES.API.signup3rdparty}`, 
	        	{username: this.cstate.username , password: '', email: this.authStore.authFields.email, role: 'none', uid:uid} )
					.then( res =>{
						this.firebase.auth.currentUser.updateProfile({
							displayName: this.cstate.username,
							password: res.data.password
						})
							.then(() => {
								this.props.history.push(ROUTES.AUTHHOME)
							})
					})
		}
	}

	

	clickme = e =>{
		
		this.props.history.push('/')

	}

	render(){

		var { authFields, errors, inProgress, emailVerified } = this.props.authStore
    

		console.log(`emailver is ${emailVerified}`)
		return (
			

			<div className="getstarted-form">
				<form onSubmit={this.submitUsername}>				
					<p>You don't have a username yet! <br/> Let's fix that.</p>
					<input 
						type="text" 
						value={this.cstate.username}
						placeholder='username'
						onChange={this.changeUsernameForm}
						name='authhome-usernamefield'
					/>

					<button disabled={this.cstate.isInvalidUsername}>Next</button>
					{this.cstate.usernameTaken && <p>Username taken.</p>}
					{this.cstate.usernameTooShort && <p>Username too short</p>}
					{this.cstate.usernameTooLong && <p>Username too long</p>}
				</form>

			</div>    	

		)
	}
}

decorate(GetStartedBase, {
  cstate: observable
})

var GetStartedForm = compose(
	inject('authStore','firebaseStore'),
	withRouter,
	observer
)(GetStartedBase)

// export default enhance(GetStartedBase)

