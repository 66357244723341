import React from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import { observable, decorate } from 'mobx'

import { compose } from 'recompose'


import styles from 'styles/pages/privacypolicy.module.css'

 class PrivacyPolicy extends React.Component{


	constructor(props){

		super(props)
	}

	componentDidUpdate(){

	}

	componentDidMount(){

	}

	
	render(){

		return(
			<div className={styles.container}>
				<h2>Privacy Policy</h2>
				<small>Effective 4/10/2021</small>

				<p>This policy covers ScriptMime and all its contents.</p>

				<section>
					<p>In order to give you the best possible experience using ScriptMime, we collect information from your interactions with our network. We use common internet technologies, such as cookies and web server logs. We collect this basic information from everybody, whether they have an account or not.</p>
					<p>The information we collect about all visitors to our website includes:</p>
					<ul>
						<li>The visitor’s browser type</li>
						<li>Referring site</li>
						<li>The date and time of each visitor request</li>
						<li>We also collect potentially personally-identifying information like Internet Protocol (IP) addresses.</li>
					</ul>

					<p>We use this information to:</p>
					<ul>
						<li>Provide, test, improve, promote and personalize ScriptMime Services</li>
						<li>Fight spam and other forms of abuse</li>
						<li>Generate aggregate, non-identifying information about how people use ScriptMime Services</li>
					</ul>

					<p>In order for you to create an account on ScriptMime and use our Services, we need to collect and process certain information. Depending on your use of the Services, that may include:</p>
					<ul>
						<li>Communications you send to us (for example, when you ask for support, send us questions or comments, or report a problem);</li>
						<li>Information that you submit on or to ScriptMime in the form of reactions, comments, or messages to other users;</li>
						<li>The email address associated with your Google account, if you choose to sign up using your Google credentials. ScriptMime will also request permission to access additional information (these permissions are governed by Google’s privacy policies and can be managed through your Google privacy settings). We never post anything to your Google without your permission.</li>
					</ul>

					<p>You also have the option to give us more information if you want to, and this may include “User Personal Information.”</p>

				</section>
				
				<section>
					<h3>Information Disclosure</h3>
					<p>We do not share, sell, rent, or trade User Personal Information with third parties for commercial purposes.</p>
					<p>We do share certain aggregated, non-personally identifying information with others about how our users, collectively, use ScriptMime. For example, we may share information pertaining to the popularity of different programming languages for advertising partners.</p>
					<p>We do host first-party advertising on ScriptMime. We do not run any code from advertisers and all ad images are hosted on managed ScriptMime servers. For more details, see our section on Advertising Details.</p>
					<p>We may use User Personal Information with your permission, so we can perform services you have authorized.</p>
					<p>We may share User Personal Information with a limited number of third party vendors who process it on our behalf to provide or improve our service, and who have agreed to privacy restrictions similar to our own Privacy Statement. Our third party vendors are listed below.</p>

				</section>

				<section>
					<h3>Advertising Details</h3>
					
					<p>We target advertisements based solely upon:</p>
					<p>Details of the page where the advertisement is shown, including:</p>
					<ul>
						<li>The name and keywords associated with the page or article being viewed</li>
					</ul>

					<p>We allow advertisers to target ads to a list of keywords advertising.</p>
					<p>We may place ads in:</p>
					<ul>
						<li>Sidebars</li>
						<li>Below articles</li>
						<li>On search result pages</li>
						<li>feeds</li>
						<li>On tag pages</li>
						<li>other</li>
					</ul>

				</section>

				<section>
					<h3>Third Party Vendors</h3>
					<p>We may share your account information with third parties in some circumstances, including: (1) with your consent; (2) to a service provider or partner who meets our data protection standards; (3) for survey or research purposes, after aggregation, anonymization, or pseudonomization; (4) when we have a good faith belief it is required by law, such as pursuant to a subpoena or other legal process; (5) when we have a good faith belief that doing so will help prevent imminent harm to someone.</p>

					<h4>Data Storage</h4>
					<p>ScriptMime uses third-party vendors and hosting partners for hardware, software, networking, storage, and related technology we need to run ScriptMime. By using ScriptMime Services, you authorize ScriptMime to transfer, store, and use your information in the United States and any other country where we operate. All service providers and third-party vendors are required to meet our data protection standards.</p>

					<h4>Site monitoring</h4>
					<p>ScriptMime uses a variety of third-party services to diagnose errors and improve the performance of our site. We aim to minimize the amount of personal information shared, but the information may include your IP address or other identifying information. All service providers and third-party vendors are required to meet our data protection standards.</p>

					<h4>Payment processing</h4>
					<p>ScriptMime does not process payments directly — we rely on third-party services such as Stripe to receive payments and store any payment information. We reserve the right in the future to introduce services which may utilize these payment processing methods.</p>

					<h4>Third-Party Embeds</h4>
					<p>Some of the content that you see displayed on ScriptMime is not hosted by ScriptMime. These “embeds” are hosted by a third-party and embedded in ScriptMime. For example: YouTube videos, Codepens, Twitter tweets, or GitHub code or links or other third party embeds that appear within a ScriptMime page. These files send data to the hosted site just as if you were visiting that site directly (for example, when you load a ScriptMime post page with a YouTube video embedded in it, YouTube receives data about your activity). ScriptMime does not control what data third parties collect in cases like this, or what they will do with it. Third-party embeds on ScriptMime are not covered by this privacy policy; they are covered by the privacy policy of the third-party service. Be mindful when interacting with these services.</p>

					<h4>Tracking & Cookies</h4>
					<p>We use browser cookies and similar technologies to recognize you when you return to our Services. Third-party vendors may also use cookies for various reasons.</p>
					<p>ScriptMime uses a specific cookie in order to facilitate the use of Google Universal Analytics for users logged-in to the Applications or the Platforms (“Logged-In User). If you are a Logged-In User, ScriptMime may use your ScriptMime user ID in combination with Google Universal Analytics and Google Analytics to track and analyze the pages of the Services you visit. We do this only to better understand how you use the Website and the other Services, with a view to offering improvements for all ScriptMime users; and to tailor our business and marketing activities accordingly, both generally and specifically to you. Google Analytics cookies do not provide ScriptMime with any Personal Information.You can prevent Google Analytics from recognizing you on return visits to this site by disabling cookies on your browser.</p>
					<p>You may opt-out of this feature by installing the Google Analytics Opt-out Browser Add-on, by setting your web browser to refuse cookies, or by setting your browser to alert you when cookies are being sent. If you do so, note that some parts of the Site may not function properly.</p>

					<h4>Data Security</h4>
					<p>We use encryption (HTTPS/TLS) to protect data transmitted to and from our site. However, no data transmission over the Internet is 100% secure, so we can’t guarantee security. You use the Service at your own risk, and you’re responsible for taking reasonable measures to secure your account.</p>

					<h4>Administrative Emails from ScriptMime</h4>
					<p>Sometimes we’ll send you emails about your account, service changes or new policies. You can’t opt out of this type of “transactional” email (unless you delete your account).</p>
					<p>When you interact with a transactional email sent from ScriptMime (such as opening an email or clicking on a particular link in an email), we may receive information about that interaction.</p>


					<h4>Non-administrative Emails from ScriptMime</h4>
					<p>Upon creating a ScriptMime account, you may choose to be opted into the ScriptMime Newsletter and other non-administrative email. Your email address and user profile information may be stored by a third-party email provider such as MailChimp or Sendgrid. You can opt out of non-administrative emails such as digests, newsletters, and activity notifications through your account’s “Settings” page and at the link of the footer in any non-administrative email you receive from us.</p>
					<p>When you interact with a non-administrative email sent from ScriptMime (such as opening an email or clicking on a particular link in an email), we may receive information about that interaction.</p>

					<h4>Deleting Your Personal Information</h4>
					<p>You may request deletion of your personal information and account by emailing helpscriptmime@gmail.com.</p>
					<p>You must provide evidence of your ownership of the account, and we will ask for such at our own discretion.</p>
					<p>To protect information from accidental or malicious destruction, we may maintain residual copies for a brief time period. But, if you delete your account, your information and content will be unrecoverable after that time.</p>

					<h4>Business Transfers</h4>
					<p>If we are involved in a merger, acquisition, bankruptcy, reorganization or sale of assets such that your information would be transferred or become subject to a different privacy policy, we’ll notify you in advance of any such change.</p>

					<h4>Changes to this Policy</h4>
					<p>We reserve the right to revise this Privacy Policy at any time. If we change this Privacy Policy in the future, we will post the revised Privacy Policy and update the “Effective Date,” above, to reflect the date of the changes.</p>


				</section>

				<h4>Miscellaneous</h4>
				<p>This site is protected by reCAPTCHA and the Google 
					<Link to="https://policies.google.com/privacy"> Privacy Policy</Link> and 
					<Link to="https://policies.google.com/terms"> Terms of Service</Link> apply.
				</p>
				<p>Therefore, by accessing ScriptMime and all its contents you agree to Google's policies surrounding ReCaptcha</p>
			</div>

		)

	}
}


var enhance = compose(
	inject('authStore'),
	withRouter,
	observer
	)

export default enhance(PrivacyPolicy)

