import React from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import { observable, decorate } from 'mobx'

import { compose } from 'recompose'

import * as ROUTES from 'routes.js'

import Tippy from '@tippyjs/react'
import 'tippy.js/themes/light.css'
     

        


 class SearchScript extends React.Component{

	
	constructor(props){

		super(props)
		this.state = {
			query: '',
			maxlength: this.props.uiStore.maxScriptTagSearchBarlength,
			tippyvisible: false,
		}


	}

	componentDidUpdate(){

	}

	componentDidMount(){
		
	}

	onChangeInput = e =>{

		if(e.target.value.length > this.state.maxlength){
			this.setState({
				tippyvisible: true,
				query: e.target.value

			})
			return
		}


		this.setState({
			query: e.target.value,
			tippyvisible: false

		})
	}

	submitQuery = e =>{
		e.preventDefault()
		
		var { query, tippyvisible } = this.state

		if(query === '' || tippyvisible === true )
			return		
		
		this.props.history.push(`${ROUTES.SCRIPTSEARCHRESULTSPAGE}?query=${query}`)

		setTimeout(() => window.location.reload(false) , 10 )

	}
	
	render(){

		return (
			<div>

				<form onSubmit={this.submitQuery} className="pure-form form-custom">
				    <Tippy className='navbarTooltip' 
						content= 'Search query too large.'       
						trigger='manual' //for mouseover, trigger='mouseenter'
						placement='auto' 
						visible={this.state.tippyvisible} //to include this line and programatically control tippy visible, set trigger line to trigger='manual'
						interactive={false} //allow html content
						theme='light'
				     >
				      <input type="text" 
							onChange={this.onChangeInput}
				        	placeholder="Search Script or Tag" 
				      />
				    </Tippy>

				        
						<button disabled={this.state.tippyvisible} type="submit" className="pure-button button-custom"  >Search</button>

				</form>
			</div>

		)
	}
}


var enhance = compose(
	inject('authStore', 'uiStore'),
	withRouter,
	observer
	)

export default enhance(SearchScript)

