import React, {Suspense, lazy} from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import { observable, decorate, when, toJS } from 'mobx'

import { compose } from 'recompose'

import CodeEditor from 'components/codeeditor.js'

import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import requests, {Blog} from 'axios.js'

import * as ROUTES from 'routes.js'



import Tippy from '@tippyjs/react'
import 'tippy.js/themes/light.css'

import FilePond from 'components/files/filepond.js'



import { LazyComponent, slugify } from 'usefulFunctions.js'
import Loading from 'components/animations/loading.js'

import { Collapse } from 'react-collapse'

import { initblogtext } from 'components/scripts/ckeditor.js'

import BlogExampleGuide from 'components/files/blogExampleGuide.js'

import { sanitize } from 'dompurify'

import {blog_categories} from 'globalvars.js'

//icons
import Arrow from 'images/script/arrow.png'


//styles
import styles from 'styles/pages/blog/submitblog.module.css'

import classnames from 'classnames'

const RichTextEditor = lazy( () => import('components/scripts/ckeditor.js'))

// import RichTextEditor from 'components/blogs/ckeditor.js'




//authorization-protected, page to submit blogs
 class Submitblog extends React.Component{


	formState = {
		//blog
		blog: '',
		rows: 15,
		cols: 50,

		//title
		blogTitle: '',
		tippyTitleVisible: false,
		tippyblogNameInputContent: '',
		
		//uploads
		showThumbnailPond: false,
		showVideoEmbed: false,
		showGifsPond: false,
		showPicsPond: false,
		videoLink: '',
		badVideoLink: false,
		submitCallbacks: [],
		submitLoading: false,
		blogReady: false,
		showExample: false,

		//blogtext
		blogtext: '',

		//max lengths
		maxblogtextLength: 20000,
		maxblogLength: 10000,
		maxTagLength: 30,
		maxblogitleLength: 200,


		
		//categories
		categories: [],
		selectedCategory: null,


		//timers
		keytimer1: null,
		keytimer2: null,
		keytimer3: null,
		delayKeyTimer: 500,
		blogtilekeytimer: null,
		blogtitledelayKeyTimer: 500,

		
	}
	authStore

	
	constructor(props){

		super(props)

		console.log(this.props.uiStore.maxblogtextLength)

		var uiStore = this.props.uiStore
		


		this.formState.rows = this.props.rows ? this.props.rows : this.formState.rows 
		this.formState.cols = this.props.cols ? this.props.cols : this.formState.cols 
		this.authStore = this.props.authStore

		
	}


	/* LIFE CYCLE */
	/*  _______________________________________ */
	componentDidUpdate(){


	}

	componentDidMount(){
		
		function leavingPage(e){
			e.preventDefault()
			e.returnValue = 'blog will not be saved. Are you sure?'

		}

		window.addEventListener("beforeunload", leavingPage)
	

		this.formState.categories = blog_categories
 
	}

	componentWillUnmount(){
		// window.removeEventListener("beforeunload", leavingPage)

	}

	/*  _______________________________________ */



	/* INTERNALS */
	/*  _______________________________________ */



	//title
	blogTitleOnChange = e =>{
		this.formState.blogTitle = e.target.value

	}

	blogOnTitleBlur = e =>{
		var title = this.formState.blogTitle
		var maxTitleLength = this.formState.maxblogitleLength


		if(title.length >maxTitleLength ){
			this.formState.tippyblogNameInputContent=`Too long (${this.formState.maxblogitleLength} character limit)`

			this.formState.tippyTitleVisible = true
		}
		else{
			if(this.formState.tippyblogNameInputContent ==='blog name taken')
				this.formState.tippyTitleVisible = true
			else
				this.formState.tippyTitleVisible = false


		}

	
		
	}

	blogTitleOnKeyUp = e =>{

		var state = this.formState
		// Init a timeout variable to be used below
		let timeout = this.formState.keytimer1;
		var title = this.formState.blogTitle
		var maxTitleLength = this.formState.maxblogitleLength

	
	    clearTimeout(timeout);

	    this.formState.keytimer1 = setTimeout( () => {
	    	Blog.getBlog(title)
	    		.then( res =>{
	    			console.log(res.data)

	    			if(res.data.message.blogname === title){
	    				// console.log('zam')

	    				this.formState.tippyblogNameInputContent='blog name taken'
	    				this.formState.tippyTitleVisible = true

	    			}
	    			else{
	    				this.formState.tippyblogNameInputContent=''
	    				title.length > maxTitleLength ? this.formState.tippyTitleVisible = true : this.formState.tippyTitleVisible = false

	    			}			

	    		})
	    }, this.formState.blogtitledelayKeyTimer);
		
	}
	//categories
	categoryClick = e =>{

		this.formState.selectedCategory = e.target.innerText

	}
	

	//blogtext
	getblogtext = blogtext =>{
		this.formState.blogtext = blogtext
	}


	//submit
	submitIt = e =>{
		e.preventDefault()

		toast.info('Processing. Do not exit or change contents on page. You will be automatically redirected', {
		position: "top-right",
		autoClose: 10000,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,

		});


		var { id, username } = this.authStore.authFields

		var blogTitle = this.formState.blogTitle
		var blog = this.formState.blog
		
		// DD-MM-YYYY
		var dateObj = new Date()
		var date = `${dateObj.getMonth() + 1}-${dateObj.getDate()}-${dateObj.getFullYear()}`

		// var categories = {category: this.formState.selectedCategory, subcategory: this.formState.selectedSubcategory}
		var categories = this.formState.selectedCategory



		var sanitizedHTML = sanitize(this.formState.blogtext, {FORBID_TAGS:['img', 'iframe', 'video']})

		if( sanitizedHTML.length > this.formState.maxblogtextLength || this.formState.blogTitle > this.formState.maxblogitleLength)
		{
			toast.error('Error pending. Go over your blog and make sure no errors are present before submitting.', {
			position: "top-right",
			autoClose: 10000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,

			});
		}
		// var blogtext = this.formState.blogtext

		Blog.submitBlog(sanitizedHTML, id, username, blogTitle, categories)
			.then( res =>{

				if(res.data.message){
					toast.success('Blog submitted!', {
					position: "top-right",
					autoClose: 10000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,

					});


					this.props.history.push(`${ROUTES.BLOGPAGE}?id=${res.data.blogid}&name=/${slugify(this.formState.blogTitle)}`)
					

				}







			})
			

		


	}



	


	/*  _______________________________________ */


	render(){

		var formState = this.formState

		var howtoguideclass = classnames(styles.howtoguide, styles.submitbutton)
		var howtoguideclosebuttonclass = classnames(styles.howtoguideclosebutton, styles.submitbutton)

		var submitbuttonclass = classnames(styles.submitbutton, styles.submit, {[styles.submitexpand]: this.formState.showExample}, {[styles.blogtextExpand]: this.formState.blogtext.length === 0} )
		// let classNames = classnames(styles.sideMenu, { [styles.active]: this.props.menuOpen }); // <div className={classNames}></div>


		const submitDisabled = formState.tippyTitleVisible
			|| formState.blogTitle.length < 5 
			|| this.formState.selectedCategory === null

		console.log(this.formState.blogtext.length)
		console.log(this.formState.maxblogtextLength)

		console.log(this.formState)


		console.log(this.formState.blogtext.length > this.formState.maxblogtextLength)

		
		return (

			<div className={styles.maincontainer}>
				<h1>Submit a blog!</h1>
				<small>Anything from news of AHK, developments of ScriptMime and latest releases, tutorial blogs, news about specific membemers, and more</small>

{/*Choose category*/}
				<h3 className={styles.numberheading}><div className={styles.numbercircle}>1</div> Choose a Category for your blog</h3>
				<div className={styles.categoriescontainer}>

					{this.formState.categories.map((category, index) =>{
						return(
							<div  onClick={this.categoryClick} key={index} >
								<button className={styles.categorybutton}>{category}</button>
							</div>
						)
					})}



				</div>

			
				<div>Category: {this.formState.selectedCategory}</div>




{/*Create title*/}
				<h3 className={styles.numberheading}><div className={styles.numbercircle}>2</div> Give your blog a meaningful title</h3>


				<form className="pure-form form-custom" onSubmit={this.submitIt}>
					<Tippy
						placement='auto'
						trigger='manual'
						content={this.formState.tippyblogNameInputContent}
						visible={this.formState.tippyTitleVisible}
						ref={ref => this.tippyblogNameInput = ref}
						theme='light'
					>
						<input 
							type="text"
							size="100"
							ref={ref => this.blogNameInput = ref}
							onChange={this.blogTitleOnChange}
							onKeyUp={this.blogTitleOnKeyUp}
							onBlur={this.blogOnTitleBlur}
							placeholder='blog name'/>
					</Tippy>

{/*Create blogtext*/}
					<div >
					<h3 className={styles.numberheading}><div className={styles.numbercircle}>3</div>(Also the blog itself!)</h3>
						<div className={styles.howtoguidecontainer}>
							<img src={Arrow} alt=""/>
							<div  className={howtoguideclass} onMouseEnter={() => this.formState.showExample = true}> Expand How-to Guide</div>
							
						</div>

						<Collapse isOpened={this.formState.showExample}>
							<BlogExampleGuide/>
							<button className={howtoguideclosebuttonclass} type='button' onClick={() => this.formState.showExample = false}>Close</button>

						</Collapse>
					</div>

					<Suspense fallback={<Loading />}>
						<div className={styles.blogtexteditor}>
							<RichTextEditor contentCallback={this.getblogtext}/>
							
						</div>
					</Suspense>

					{
						this.formState.blogtext.length > this.formState.maxblogtextLength
						?
							<p>blogtext too long. Max {this.formState.maxblogtextLength} char. Currently: {this.formState.blogtext.length}</p>
						:
							null
					}
	
	



{/*submitblog button*/}
					<button className={submitbuttonclass} disabled={submitDisabled}>Submit blog</button>

					

				</form>
			
				


			</div>

		)

	}
}


decorate(Submitblog, {
	formState: observable
})

var enhance = compose(
	inject('authStore', 'uiStore'),
	withRouter,
	observer
	)

export default enhance(Submitblog)

