import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'mobx-react'

import uiStore from './stores/UiStore'
import firebaseStore from './stores/firebaseStore'
import authStore from './stores/authStore'
import scriptStore from './stores/scriptStore.js'

import userStore from './stores/userStore.js'

import commentStore from 'stores/commentStore.js'

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';


const recaptcha_key = process.env.REACT_APP_RECAPTCHA_SITE





const Root = (

	<Provider 
	uiStore={uiStore} 
	authStore={authStore} 
	firebaseStore={firebaseStore} 
	scriptStore={scriptStore}
	userStore={userStore}
	commentStore={commentStore}
	>
		<GoogleReCaptchaProvider
		    reCaptchaKey={recaptcha_key}
		    // language="[optional_language]"
		    // useRecaptchaNet="[optional_boolean_value]"
	  	>
	    	<App />
	    </GoogleReCaptchaProvider>,

	</Provider>

	)

ReactDOM.render(Root, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
