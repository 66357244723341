

export const HOME = '/home-page'
export const LANDING = '/'
export const SIGNUP = '/signup-page'
export const AUTHHOME = '/protected/home-page'
export const LOGIN = '/login-page'
export const GETSTARTED = '/get-started'
export const LOADING = '/loading-page'

export const INFOLANDING = '/info'
export const DOWNLOADINFOPAGE = '/get-started-with-autohotkey'



		/*SCRIPTMIME*/
/*_____________________________*/

export const EMPTY = '/empty'
export const TEST = '/test-page'





		/*SCRIPTMIME*/
/*_____________________________*/
export const PRIVACYPOLICY = '/about/privacy'
export const TERMSCONDITIONS = '/about/terms-and-conditions'


		/*SCRIPTS */
/*_____________________________*/

export const SCRIPT_NAVIGATION = '/scripts/navigation'

export const SCRIPT_SUBMITSCRIPT = '/scripts/submit-script'
export const SCRIPT_GLOBALFEED = '/scripts/global-feed'
export const SCRIPTPAGE = '/scripts/script-page/script/' //?id=x&name=y    AKA /scripts/script-page/script?id=32&name=y/slug*

	//tags
export const MASTERTAGSPAGE = '/scripts/tags/alltags/' 

export const TAGPAGE = '/scripts/tags/tags-page/tag/' //?id=x&name=y    AKA /scripts/tags/tags-page/tag/?id=32&tag=tag1/*slug


		/*PROFILE */
/*_____________________________*/

export const PROFILEPAGE = '/profile-page'
export const OTHERUSERPROFILEPAGE = '/profile/profile-page' //?id=x    AKA /profile/profile-page?id=32

		/*MESSAGES*/
/*_____________________________*/

export const CONVERSATIONSPAGE = '/messages/all'


		/*SEARCH*/
/*_____________________________*/
export const SCRIPTSEARCHRESULTSPAGE = '/scriptsearch/result' // AKA /scriptsearch/result?query=
export const USERSEARCHRESULTSPAGE = '/usersearch/result' // AKA /scriptsearch/result?query=

		/*BLOG*/
/*_____________________________*/
export const BLOGMASTERPAGE = '/blog/'
export const BLOGPAGE = '/blog/article/' //?id=x&name=y    AKA /scripts/script-page/script?id=32&name=y/slug*
export const SUBMITBLOGPAGE = '/blog/submitblog'


		/*ACCOUNT */
/*_____________________________*/

export const NAVIGATION = '/accountsettings/navigation'
export const ACCOUNTSETTINGS_DETAILS = '/accountsettings/details'
export const ACCOUNTSETTINGS_PROFILE = '/accountsettings/profile'
export const ACCOUNTSETTINGS_BILLING = '/accountsettings/billing'
export const ACCOUNTSETTINGS_MEMBERSHIP = '/accountsettings/membership'


		/*ACCOUNT */
/*_____________________________*/
export const SUPPORTPAGE = '/support/contactus'



export const API = {

	// USERS
	signup: 'api/users/signup',
	signup3rdparty: 'api/users/signup-with-3rd-party',
	login: 'api/users/login',
	changeusername: 'api/users/changeusername',
	checkusername: 'api/users/check-username',
	getid: 'api/users/getid',
	getuserfromid: 'api/users/getuserfromid',
	getidfromuser: 'api/users/getidfromuser',
	getInitialUserMetaData: 'api/users/getusermetadata',
	storeMobxStores: 'api/users/storemobxstores',
	getMobxStores: 'api/users/getmobxstores',
	changePasswordDatabase: 'api/users/changepassworddatabase',
	addUsertoMailList: 'api/users/addusertomaillist',

	// FILES
	getavatar: 'api/upload/getavatar',
	insertembedvideo: 'api/upload/scriptsubmitembedvideo',
	getfilesforscriptid: 'api/files/getfilesforscriptid',
	checkServerBusy: 'api/upload/checkserverbusy',


	// SCRIPTS

	submitscript: 'api/scripts/submitscript',
	submitTags: 'api/scripts/submittags',
	getscript: 'api/scripts/getscript',
	deleteScript: 'api/scripts/deletescript',
		//-----FEEDS------

	getallscripts: 'api/scripts/getallscripts',
	authorgetallscripts: 'api/scripts/author-getallscripts',
	getscriptfromid: 'api/scripts/getscriptfromid',
	setScriptVoteCount: 'api/scripts/setscriptvotecount',
	editScriptPagePart: 'api/scripts/editscriptpagepart',
	getallscriptsuserfollow: 'api/scripts/getallscriptsuserfollow',
	getallscriptstagfollow: 'api/scripts/getallscriptstagfollow',
	getallscriptsalltimebest: 'api/scripts/getallscriptsalltimebest',

		//-----END_FEEDS------

		//saved scripts
	getallscriptssaved: 'api/scripts/getallscriptssaved',
	saveScript: 'api/scripts/savescript',
	unsaveScript: 'api/scripts/unsavescript',
		//tags
	getAllTags: 'api/scripts/getalltags',
	getNumScriptsPublished: 'api/scripts/getnumscriptspublished',
	followTag: 'api/scripts/followtag',
	unfollowTag: 'api/scripts/unfollowtag',
	getAllTaggedScripts: 'api/scripts/getalltaggedscripts',
	getScriptByTagtag: 'api/scripts/getscriptbytagtag',


	// COMMENTS
		//scripts
	submitscriptcomment: 'api/comments/submitscriptcomment',
	getcommentsbyscriptid: 'api/comments/getcommentsbyscriptid',
	getscriptcommentrelationship: 'api/comments/getscriptcommentrelationship',
	editcomment: 'api/comments/editcomment',
	getCommentVoteCount: 'api/comments/getcommentvotecount',
	getUseridFromScriptid: 'api/comments/getuseridfromscriptid',
	getNumComments: 'api/comments/getnumcomments',
	


		//profile
	submitUserProfileComment: 'api/comments/submituserprofilecomment',
	deleteProfileComment: 'api/comments/deleteprofilecommentbyid',
	getUserProfileCommentsByUserid: 'api/comments/getuserprofilecommentsbyuserid',

	//PROFILE
	getUserMetaData: 'api/profile/getusermetadata',
	submitProfileDescription: 'api/profile/submitprofiledescription',
	getFollowersForUser: 'api/profile/getfollowersforuser',
	followUser:'api/profile/followuser',
	unfollowUser: 'api/profile/unfollowuser',

	//NOTIFICATION
	triggerRootCommentNotification: 'api/notification/triggerrootcommentnotification',
	triggerReplyCommentNotification: 'api/notification/triggerreplycommentnotification',
	triggerFollowerNotification: 'api/notification/triggerfollowernotification',
	unfollowDelete: 'api/notification/unfollowdelete',
	getAllNotificationsForUser: 'api/notification/getallnotificationsforuser',
	getReplyCommentHostUserid: 'api/notification/getreplycommenthostuserid',
	deleteNotification: 'api/notification/deletenotification',
	deleteAllNotifications: 'api/notification/deleteallnotifications',
	

	//MESSAGES
	getMessagesForUser: 'api/messages/getmessagesforuser',
	getNumberOfMessages: 'api/messages/getnumberofmessages',
	sendMessageToUser: 'api/messages/sendmessagetouser',

	//SEARCH

	searchScriptsTags: 'api/search/searchscriptstags',
	searchTags: 'api/search/searchtags',
	searchUsers: 'api/search/searchusers',
	searchUsersNavbar:'api/search/searchusersnavbar',

	//BLOG

	getBlogs: 'api/blog/getblogs',
	submitBlog: 'api/blog/submitblog',
	getBlog: 'api/blog/getblog',
	getBlogFromId: 'api/blog/getblogfromid',
	editBlogPagePart: 'api/blog/editblogpagepart',
	



}


// export withProtectedRoute 
