
import React from 'react'

const SVG = props => (

	<svg height={props.height} width={props.width} viewBox='0 0 550 550' xmlns="http://www.w3.org/2000/svg"><linearGradient id="a" gradientUnits="userSpaceOnUse" x1="256" x2="256" y1="512" y2="0"><stop offset="0" stop-color="#fd3a84"/><stop offset="1" stop-color="#ffa68d"/></linearGradient><path d="M417.543 209.792c-7.09-17.153-23.027-27.803-41.543-27.803H244.633l113.89-113.961c5.859-5.859 5.859-15.352 0-21.211L316.101 4.395c-5.859-5.859-15.352-5.859-21.211 0L104.184 195.172c-13.096 13.081-16.816 31.86-9.727 49.014s23.027 27.803 41.543 27.803h131.367L162.016 377.34l-21.943-36.563c-3.281-5.449-9.58-8.174-15.82-6.987a15.017 15.017 0 00-11.895 12.583L91.146 494.865c-1.428 9.905 7.156 18.408 16.963 16.978l148.506-21.211c6.299-.908 11.338-5.654 12.598-11.88s-1.553-12.568-7.002-15.835l-36.563-21.943 182.168-182.168c13.096-13.082 16.817-31.861 9.727-49.014z" fill="url(#a)"/></svg>
	
	//height={props.height} width={props.width} viewBox={`0 0 ${props.viewW} ${props.viewH}`}
	
)

export default SVG


