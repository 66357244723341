import React, {lazy, Suspense} from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import { observable, decorate } from 'mobx'

import { compose } from 'recompose'

import * as ROUTES from 'routes.js'

// import RichTextEditor from 'components/scripts/ckeditor.js'

import styles from 'styles/components/comments/createcomment.module.css'

import requests, { Profile } from 'axios.js'

import { sanitize } from 'dompurify'

import Loading from 'components/animations/loading.js'

const RichTextEditor = lazy( () => import('components/scripts/ckeditor.js') )

//create a comment on the script page
 class CreateNoteComment extends React.Component{

	formState = {
		description: '',
		submitDisabled: false,

	}

	cancelcallback

	constructor(props){

		super(props)
		this.userid = this.props.authStore.authFields.id
	}
	/* LIFECYCLE METHODS */
	/* _________________ */
	componentDidUpdate(){

	}

	componentDidMount(){
	}
	/* _________________ */


	

	/* ASYNC DATA FETCH */
	/* _________________ */


	/* _________________ */




	/* INTERNALS */

	submitComment = e =>{

		var commentbody = this.formState.description
		commentbody = sanitize(commentbody)
		// var userid = this.props.authStore.authFields.id
		var userid = this.props.userid


		if(commentbody.length === 0)
			return

		Profile.submitUserProfileComment(commentbody, userid)
			.then( res =>{ 
				console.log( res.data.message)
				this.props.commentStore.clearRichTextEditor()
				window.location.reload(false)

			})

		

	}
	/* _________________ */

	getDescription = description =>{

		if(description.length > this.props.uiStore.maxUserProfileNoteLength){
			this.formState.submitDisabled = true
		}
		else{
			this.formState.submitDisabled = false

		}
		this.formState.description = description
	}

	cancel = e =>{

		this.props.commentStore.clearRichTextEditor()
		
		this.props.cancelcallback ? this.props.cancelcallback() : ''
	}
	/* _________________ */

	
	render(){
				// console.log('were in' + this.props.location.pathname)
		return(

			<div className={styles.container}>


				<Suspense fallback={<Loading />}>
					<RichTextEditor
						contentCallback={this.getDescription}					
						description=' '
					/>
				</Suspense>

				<div className={styles.row}>
					<button className={styles.submitbutton} disabled={this.formState.submitDisabled} onClick={this.submitComment}>Submit</button>
					<button className={styles.submitbutton} onClick={this.cancel}>Cancel</button>
					
				</div>
				{
					this.formState.description.length > this.props.uiStore.maxUserProfileNoteLength
					?
						<p>description too long. max {this.props.uiStore.maxUserProfileNoteLength} </p>
					:
						null
				}

			</div>
		)

	}
}

decorate(CreateNoteComment, {
	formState: observable
})

var enhance = compose(
	inject('authStore', 'commentStore', 'uiStore'),
	withRouter,
	observer
	)

export default enhance(CreateNoteComment)

