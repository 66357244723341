import React from 'react'

const SVG = props => {

	return (
		<svg height={props.height} width={props.width} viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M10.86 11.7241C14.6689 11.7241 17.7566 9.0996 17.7566 5.86207C17.7566 2.62454 14.6689 0 10.86 0C7.05116 0 3.96347 2.62454 3.96347 5.86207C3.96347 9.0996 7.05116 11.7241 10.86 11.7241Z" fill="black"/>
		<path d="M17.1996 10.8364L15.9807 9.80023L15.4931 10.2147L16.219 10.8317C13.111 13.2559 8.26275 13.1876 5.25206 10.6291C3.75654 9.35794 2.93309 7.66498 2.93309 5.8621C2.93309 4.1577 3.6693 2.5512 5.01344 1.30697L5.73965 1.92396L6.22723 1.50951L5.00827 0.473389L4.76447 0.680613C3.13861 2.0626 2.24344 3.90241 2.24344 5.8621C2.24344 7.82179 3.13896 9.6616 4.76482 11.0436C6.30999 12.357 8.3424 13.1082 10.5193 13.1797V16.1207C10.5193 16.2828 10.6734 16.4138 10.8641 16.4138C11.0548 16.4138 11.209 16.2828 11.209 16.1207V13.1794C13.3827 13.1064 15.4124 12.3555 16.9559 11.0436L17.1996 10.8364Z" fill="#556080"/>
		<path d="M14.3124 16.1207H7.41586V17H14.3124V16.1207Z" fill="#556080"/>
		<path d="M6.98827 1.01121C6.9862 1.11116 6.98241 1.21521 6.97551 1.32805C6.97207 1.38228 6.98345 1.4365 7.01034 1.48574C7.47793 2.34219 8.07965 3.79598 9.10724 4.13071C9.60689 4.29367 10.0686 4.23212 10.5421 4.42938C10.5928 4.45048 10.6666 4.49943 10.6983 4.539C10.939 4.83826 10.8003 5.27674 11.039 5.38402C11.2279 5.46902 11.3352 5.64224 11.2545 5.81048C10.9252 6.49722 11.21 7.28274 11.9759 7.82205C12.5148 8.20133 12.0662 9.08093 11.6128 9.77119C11.4507 10.018 11.7397 10.3032 12.0531 10.2088C12.5486 10.059 12.959 9.84183 13.4338 9.49421C14.1224 8.99065 14.2714 8.44226 14.9541 8.01491C14.9617 8.01022 14.9672 8.00436 14.9703 7.99674C15.1448 7.56207 15.6869 6.61271 15.14 6.3709C14.6293 6.1455 14.4383 5.49247 13.94 5.04753C13.8828 4.99653 13.8014 4.97104 13.719 4.96195C13.1083 4.89512 12.8331 4.50002 12.4224 4.76264C12.1145 4.95931 11.7686 4.83943 11.661 4.52493C11.6259 4.42234 11.5645 4.3309 11.3428 4.27286C11.1745 4.2289 11.0924 4.06652 11.1531 3.92612C11.1541 3.92407 11.1548 3.92202 11.1559 3.91997C11.2369 3.73209 10.7803 3.76081 10.5479 3.79364C10.4828 3.80272 10.7038 3.57967 10.631 3.61602C10.3455 3.75934 9.99103 3.51197 10.0062 3.23059C10.0217 2.9451 10.0845 2.95859 10.6603 2.90231C11.2803 2.65698 11.5803 2.83578 11.5603 3.4384C11.9434 3.48676 12.0776 3.34255 11.9621 3.00666C11.9283 2.90847 11.9555 2.79884 12.0286 2.71795C12.7345 1.93712 13.3245 1.59683 14.4055 1.28145C14.5659 1.23455 14.6562 1.09826 14.6352 0.959035C13.5507 0.354655 12.2548 0 10.86 0C9.42448 0 8.09207 0.373414 6.98827 1.01121Z" fill="#C4C4C4"/>
		</svg>


	)
	//height={props.height} width={props.width} viewBox={`0 0 ${props.viewW} ${props.viewH}`}
	
}

export default SVG