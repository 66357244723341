import React, {lazy, Suspense} from 'react'

import LandingTopNav from 'components/landingtopnav.js'

import { Link, NavLink, withRouter } from 'react-router-dom'


import styles from 'styles/pages/scriptmimeuserguide.module.css'

import Loading from 'components/animations/loading.js'

import * as ROUTES from 'routes.js'


 /* IMAGES */

import ComputerImage from 'images/landing/1.jpg'

import BottomDivider from 'images/landing/divider.png'

import GUIExample from 'images/infopages/guiexample.png'
import Feedpic1 from 'images/infopages/feedpic1.png'
import Feedpic2 from 'images/infopages/feedpic2.png'
import Feedpic3 from 'images/infopages/feedpic3.png'

import Hashtagicon from 'images/infopages/hashtagicon.png'

import Anonicon from 'images/infopages/anonicon.png'
import Bellicon from 'images/infopages/bellicon.png'
import Envelopeicon from 'images/infopages/envelopeicon.png'
import Pencilicon from 'images/infopages/pencilicon.png'





import Collapse from 'react-collapse'




/* VIDEOS */

// import ShowoffVid from 'images/landing/Finalvidrealready-min4.mp4'

/* ICONS */

import GamingSVG from 'icons/circularnav/joystick.js'
import ProductivitySVG from 'icons/circularnav/graph.js'
import JustforfunSVG from 'icons/circularnav/party_popper.js'
import SchoolworkSVG from 'icons/circularnav/school.js'
import EditingcreativeSVG from 'icons/circularnav/lightbulb.js'
import SoftwareSVG from 'icons/circularnav/dvd.js'

import AutomationsSVG from 'icons/circularnav/automations.js'
import ShortcutsSVG from 'icons/circularnav/shortcuts.js'
import GeneralSVG from 'icons/circularnav/hammer.js'
import GUISVG from 'icons/circularnav/GUI.js'

import MagnifyingGlass from 'images/infopages/magnifyingglass.png'
import FeedHeadingIcon from 'images/infopages/feedheadingicon.png'



import AddButton from 'icons/general/additem.js'

import ScriptUnitImage from 'images/infopages/realscriptunit.png'
// const ScriptUnitImage = lazy(() => import('images/infopages/realscriptunit.png') )


class ScriptMimeUserGuide extends React.Component {

	
	constructor(props){

		super(props)
		this.state = {
			openGUIexample: false
		}
	}
	
	render(){
		return (
			<div className={styles.landingpagetopcontainer}>
				<LandingTopNav className={styles.topnav}/>

				<div className={styles.container}>

					<div>
						<h1 className={styles.mainheading}>ScriptMime User Guide</h1>

						<h2 className={styles.section1heading}><img src={MagnifyingGlass} width="40px" height="40px" alt=""/>Search for Scripts, Tags, or Users</h2>

						<p className={styles.section1text}>Search directly for scripts scripts by title, key words in the script, or by their tags. You can also search for tags directly. The results are a mix of scripts that match your query. </p>
						<p className={styles.section1text}>In a different search bar you can search for users directly. Tip: for better search if you are unsure, type out as many characters of a user that you know are there. The search will get the rest.</p>
						
					</div>

					<div>
						<h2 className={styles.section2heading}><img src={FeedHeadingIcon} width="40px" height="40px" alt=""/>Explore Customized Feeds</h2>
						<p className={styles.section2text}>Find new scripts submitted by creators.</p>


						<h3>See the script in action</h3>
						<p className={styles.section2text}>When you search for scripts, explore scripts in feeds, view your saved scripts and more; you will find scripts in this form.
						
						</p>
						<p className={styles.section2text}><b>See the script in action</b> before you click to see the script's page. You will see either a <b>gif or an image of how it works.</b></p>
						<p className={styles.section2text}> You can save the script, upvote/downvote it,
						and see information about it.</p>

						<Suspense fallback={<Loading/>}>
							<img src={ScriptUnitImage} width="500px" alt=""/>


						</Suspense>




						
					</div>


					<div className={styles.categoriesandsubcategoriescontainer}>
						<div className={styles.leftgroup}>
							<h3>Filter By Categories</h3>
							<p className={styles.section2text}> Scripts fall into 6 main categories and 4 subcategories. Most feeds allow you to filter by these. </p>

							<div className={styles.row}>
								<div className={styles.categoryiconcontainer}>
									<GamingSVG className={styles.categorysubcategoryicon} width="30px" height="30px" />
								</div>

								<div className={styles.text}>
									<h5 className={styles.categoryheading}>Gaming</h5>
									<p className={styles.categorydescription}>Get macros, autoclickers, auto key presses, smart tools, bots and more to elevate your game play.</p>
								</div>
								
							</div>

							<div className={styles.row}>
								<div className={styles.categoryiconcontainer}>
									<ProductivitySVG  className={styles.categorysubcategoryicon} width="30px" height="30px" />
								</div>

								<div className={styles.text}>
									<h5 className={styles.categoryheading}>Productivity</h5>
									<p className={styles.categorydescription}>Any script whose main purpose is helping people get their work done faster, for general computer use.</p>

								</div>
							</div>

							<div className={styles.row}>
								<div className={styles.categoryiconcontainer}>
									<EditingcreativeSVG  className={styles.categorysubcategoryicon} width="30px" height="30px" />							
								</div>
								<div className={styles.text}>
									<h5 className={styles.categoryheading}>Editing & Creative</h5>
									<p className={styles.categorydescription}>Are you a video editor? 3d modeler? 2d art creator? Get scripts to boost your creative process, inspire you and get new ideas, or automateyour workflow. Even create or find scripts for your favorite software like Blender, Krita, Resolve, etc. </p>

								</div>
							</div>

							<div className={styles.row}>
								<div className={styles.categoryiconcontainer}>
									<JustforfunSVG  className={styles.categorysubcategoryicon} width="30px" height="30px" />							
								</div>
								<div className={styles.text}>
									<h5 className={styles.categoryheading}>Just For Fun! Scripts</h5>
									<p className={styles.categorydescription}>Some scripts are just entertaining. From creating art on your desktop to creating full games coded in Autohotkey, nearly anything is possible.</p>

								</div>
							</div>

							<div className={styles.row}>
								<div className={styles.categoryiconcontainer}>
									<SchoolworkSVG  className={styles.categorysubcategoryicon} width="30px" height="30px" />
								</div>
								<div className={styles.text}>
									<h5 className={styles.categoryheading}>School & Workplace</h5>
									<p className={styles.categorydescription}>Get scripts specifically for school or workplace related tasks to help you do more in less time, reduce cognitive load, and finish your tasks at your workplace or school faster than ever. </p>

								</div>
							</div>

							<div className={styles.row}>
								<div className={styles.categoryiconcontainer}>
									<SoftwareSVG  className={styles.categorysubcategoryicon} width="30px" height="30px" />
								</div>

								<div className={styles.text}>
									<h5 className={styles.categoryheading}>Software</h5>
									<p className={styles.categorydescription}>For the techy people. These are scripts that make designing new scripts (or software in general) easier. Think new Classes, Libraries, code editor enhancements and more.</p>

								</div>
							</div>
						</div>

						
							<div className={styles.leftgroup}>
								<h3>And subcategories</h3>

								<div className={styles.row}>
									<div className={styles.categoryiconcontainer}>
										<ShortcutsSVG className={styles.categorysubcategoryicon}  width="30px" height="30px"  />
									</div>
									<div className={styles.text}>
										<h5 className={styles.categoryheading}>Shortcuts</h5>
										<p className={styles.categorydescription}>Shortcuts scripts simplify your work. Maybe a key press launches a program or website. If your script is making it easier to do something, it probably belongs in Shortcuts.</p>
									
									</div>
								</div>
								<div className={styles.row}>
									<div className={styles.categoryiconcontainer}>
										<GUISVG  className={styles.categorysubcategoryicon} width="30px" height="30px" />
									</div>
									<div className={styles.text}>
										<h5 className={styles.categoryheading}>Graphical User Intefaces</h5>
										<p className={styles.categorydescription}>Graphical User Interfaces (GUI) scripts are what we typically think of software. These scripts are designed with buttons and fields that make it obvious what the script is supposed to do. Example below.</p>
										
										<button onClick={() => this.setState({openGUIexample: !this.state.openGUIexample})}><AddButton width="20px" height="20px" /></button>
										<Collapse isOpened={this.state.openGUIexample}>

											<img src={GUIExample} width="292px" height="524px" alt=""/>
											
										</Collapse>

									</div>


								</div>
								<div className={styles.row}>
									<div className={styles.categoryiconcontainer}>
										<AutomationsSVG  className={styles.categorysubcategoryicon} width="30px" height="30px" />
									</div>
									<div className={styles.text}>
										<h5 className={styles.categoryheading}>Automation</h5>
										<p className={styles.categorydescription}>Think autoclickers and scripts that combine many key presses or mouse clicks to perform a unique action. </p>
									
									</div>

								</div>
								<div className={styles.row}>
									<div className={styles.categoryiconcontainer}>
										<GeneralSVG  className={styles.categorysubcategoryicon} width="30px" height="30px" />
									</div>
									<div className={styles.text}>
										<h5 className={styles.categoryheading}>General</h5>
										<p className={styles.categorydescription}>Autohotkey has many types of scripts. General is for all other scripts that don’t belong to other subcategories.</p>
									
									</div>

								</div>
								
							</div>


							

						</div>

					<div className={styles.section3container}>
						<h3 className={styles.section3heading}>The Explore section allows to view the all feeds. </h3>

						<img src={Feedpic1} width="600px"  alt=""/>

						<p>The default is <b>New</b>. The newest scripts by any and all creators are shown.</p>

						<img src={Feedpic2} width="600px"  alt=""/>

						<p>The <b>Tags Followed</b> tab lets you view scripts only with tags you are currently following. More on tags below.</p>


						<img src={Feedpic3} width="600px"  alt=""/>

						<p>The <b>Users Followed</b> tab lets you view scripts only from users you are currently following. </p>
						<p><b>All-Time Best</b>,<b> Weekly Popular</b> and <b>Most Active</b> are in development. All-Time Best will show the highest upvoted scripts of all time. Weekly Popular will show the most upvoted scripts of the week. And Most Active will show the scripts with the most comments.  </p>


					</div>
					

					<div className={styles.tagscontainer}>
						<h2><img src={Hashtagicon} width="30px" height="30px" alt=""/> Tags</h2>	
						<p>Up to 3 Tags are assigned to scripts. Each tag represents a unique topic that the script is about. For example, a gaming script for Minecraft might fall under Gaming category and Automation subcategory. The tag for this script would be #minecraft.</p>
						<p>Tags let you quickly find scripts under a given topic. Visit the Tags section in the left navigation bar to view all Tags that users have created and start following the tags you are interested in!</p>

					</div>

					<div className={styles.submitscriptcontainer}>
						<h2><img src={Pencilicon} width="45px" height="45px" alt=""/>Submitting a Script</h2>
						<p>Submitting a script will allow it to enter the global feed. Each script has its own page.</p>

						<div>
							<div className={styles.row}>
								<p>Choosing Category and Subcategory</p>
								
								<p>Choose the best category and subcategory for your script. If you’re unsure, take a look around other scripts and see what yours is most similar to. </p>
							</div>

							<div className={styles.row}>
								<p>Give your script a tag</p>
								<p>You can submit up to 3 tags about your script. If one tag is enough, then just use one. They should be related to a topic of the script and should be as specific as possible. Spaces are allowed but special characters are not.</p>
								<p>For example, a script for speeding up workflow  in Blender might have tags: #blender #3d art , or just #blender if you prefer.  </p>
								<p>Remember, tags show up in searches and customized feeds and are important for users to quickly find your script.</p>
								<p>When you are submitting a script, check if a tag already exists by typing. If not, it will be a new tag!</p>
							</div>

							<div className={styles.row}>
								<p>Give your script a description</p>
								<p>A description tells your audience what your script is about, information about yourself, or anything else you think is relevant for your script. Just keep it appropriate. </p>
								<p>You can insert images/short gifs from sites like Imgur or others. You can also insert additional code blocks. You can also embed one YouTube, Vimeo, or other hosted video. See the “Create Script” page for instructions.</p>
								
							</div>

							<div className={styles.row}>
								<p>Enter your script</p>
								<p>This is the section where you actually type or paste your script. No formatting required! </p>
							</div>

							<div className={styles.row}>
								<p>Thumbnail</p>
								<p>The thumbnail is the gif or image that will be shown in feeds. Take advantage of making the perfect thumbnail to show off what your script does!</p>
							</div>
						
						</div>

						<div>
							<h2><img src={Bellicon} width="45px" height="48px" alt=""/>Notifications</h2>
							<p>Notifications alert you when a user follows you, comments on your script, or replies to one of your comments. You have up to 20 notifications. Remember to delete a notification after you’ve seen it.</p>		
							<p>Notifications are viewed by hovering over the Bell icon. A drop down menu is shown.</p>
						</div>

						<div>
							<h2><img src={Envelopeicon} width="54px" height="45px" alt=""/>Private Messages</h2>
							<p>You can send any user a private message. Keep in mind these are text only, no images or code blocks. But other things like links, tables, quotes, etc. are allowed. </p>		
							<p>Private Messages can be viewed by clicking on Envelope icon. You will be redirected to a page with all your messages, sorted by date.</p>
						</div>

						<div>
							<h2><img src={Anonicon} width="45px" height="45px" alt=""/>User Profile</h2>
							<p>Your profile is the page where you can set your avatar, your profile description, write notes to yourself or others that view your profile, see the scripts you save, see your followers, and even view the scripts you created.</p>		
							<p>You can view your status on ScriptMime, awards you’ve received, your followers, and other useful data about your self.</p>
							<p>Clicking on other users will pull up their profiles and allow you to view most of the above data.</p>
						</div>

					</div>


				</div>


			</div>
		
		)
	}
	
	
}

export default ScriptMimeUserGuide