

import React from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import requests, { Users } from 'axios.js'

import { observable, decorate, toJS, when } from 'mobx'
import { sanitize } from 'dompurify'

import { compose } from 'recompose'

import { timeDifference } from 'usefulFunctions.js'

import * as ROUTES from 'routes.js'

import CommentIcon from 'icons/topnav/notifications/comment.js'
import XButtonIcon from 'icons/general/xclosebutton.js'

import styles from 'styles/topnav/notification.module.css'



 class ReplyCommentNotification extends React.Component{

 	commenteruserid
 	hostuserid
 	comment
 	scriptid
 	timestamp
 	deleteFunction
 	id

	constructor(props){

		super(props)

		this.commenteruserid = this.props.commenteruserid
		this.hostuserid = this.props.hostuserid
		this.comment = sanitize( this.props.comment, {ALLOWED_TAGS: []} )
		this.scriptid = this.props.scriptid

		this.timestamp = this.props.timestamp
		this.deleteFunction = this.props.deleteFunction


		// var t = '2021-01-17 04:07:40.210151'

		var date_times = ['exactdate', 'months', 'days', 'hours', 'minutes', 'seconds']

		var timediffresult = timeDifference(this.timestamp)
		var timediff

		date_times.forEach( date_time =>{
			if(timediffresult.hasOwnProperty(date_time)){
				if(date_time !== 'exactdate'){
					timediff = `${timediffresult[date_time]} ${date_time} ago`

				}
				else{

					var temparrexactdate = []
					for( const property in timediffresult)
						temparrexactdate.push(property)

					timediff = `${timediffresult[temparrexactdate[0]]} ${temparrexactdate[0]}, ${timediffresult[temparrexactdate[1]]} ${temparrexactdate[1]} ${timediffresult[temparrexactdate[2]]} ${temparrexactdate[2]} ago`

				}
			}
		})


		this.state = {
			commenterusername: '',
			timedifference: timediff,
			commenterprofilepic: '',
		}

		// console.log(this.state.timedifference)

	}

	/* ASYNC DATA FETCH */
	/* _________________ */

	getInfo = () =>{

		Users.getuserfromid(this.commenteruserid)
			.then( resCommenterUsername =>{

				this.setState({
					commenterusername: resCommenterUsername.data.message ? resCommenterUsername.data.message : 'user'
				})  

				Users.getAvatar(this.commenteruserid)
					.then(resAvatar =>{
						if(resAvatar.data.message){
							this.setState({
								commenterprofilepic: resAvatar.data.message
							})

						}
						else{
							this.setState({
								commenterprofilepic: this.props.userStore.anon.file
							})
						}

						// console.log(this.state.commenterprofilepic)

					})
			})

		
	}

	/* _________________ */


	/* LIFECYCLE METHODS */
	/* _________________ */
	componentDidUpdate(){

	}

	componentDidMount(){
		when( () => typeof this.props.authStore.authFields.id === 'number' ,
			  () =>{
			  	this.getInfo()
			  }
			)

	}
	/* _________________ */



	/* INTERNALS */
	/* _________________ */


	/* _________________ */

	
	render(){

		var xbuttonwidth = '12px'
		var xbuttonheight =  '12px'

		return(
			//className={styles.notificationcontainer}

			<div className={styles.container}>

				<div className={styles.top}>
					<Link to={`${ROUTES.OTHERUSERPROFILEPAGE}?id=${this.commenteruserid}`}>
						<img className={styles.avatar} src={this.state.commenterprofilepic} width={this.props.userStore.avatar.width} height={this.props.userStore.avatar.height} alt="user"/>
					</Link>
					<div className={styles.textblock}>
						<p className={styles.replyusername}>{this.state.commenterusername}</p>
						<p className={styles.notificationtext}> replied to your comment:</p>
						<p className={styles.comment}>"{this.comment}..."</p>
						<Link className={styles.linktoscript} to={`${ROUTES.SCRIPTPAGE}?id=${this.scriptid}`}>See the <span className={styles.conversationword}>conversation</span></Link>
						
					</div>

					<div onClick={(e) => this.deleteFunction(e, this.props.id, 'reply')}>

						<XButtonIcon className={styles.xbutton}
							width={xbuttonwidth}
							height={xbuttonheight}
						/>
					</div>
				</div>
				<div className={styles.bottom}>
					
					<div className={styles.timedifference}>
						{this.state.timedifference}
					</div>

							
					<CommentIcon className={styles.typeIcon}
						width={this.props.userStore.notification.typeIcon.width}
						height={this.props.userStore.notification.typeIcon.height}
					/>
					

				</div>

			</div>

		)

	}
}


var enhance = compose(
	inject('authStore','userStore'),
	withRouter,
	observer
	)

export default enhance(ReplyCommentNotification)
