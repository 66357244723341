import React from 'react'

import { Link, withRouter } from 'react-router-dom'

import { inject, observer } from 'mobx-react'

import { observable, decorate } from 'mobx'
import * as ROUTES from 'routes.js'

import requests, {Users} from 'axios.js'

import { compose } from 'recompose'

import { GithubSignIn, GoogleSignIn } from 'pages/account/login.js'


import Signout from 'pages/signout'

import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
// import a

import styles from 'styles/pages/signup.module.css'

import 'purecss/build/pure.css'

import 'styles/components/buttonForm.css'
import classnames from 'classnames'

import NeonButton from 'components/neon_button.js'

class SignUpPage extends React.Component{


	componentDidMount(){

	}

	componentDidUpdate(){
		var authStore = this.props.authStore

		if(authStore.authorizedUser && (authStore.authFields.username === '' || authStore.authFields.username === null) ){
			// console.log('nope?')

			this.props.history.push(ROUTES.GETSTARTED)
		}

		//signed in with google or github and no id
		else if(authStore.authorizedUser && authStore.authFields.username.length > 0 && typeof authStore.authFields.id !== 'number'){
			Users.getid(authStore.authFields.username)
				.then( res =>{
					if(res.data.id){
						this.props.authStore.authFields.id = res.data.id
						this.props.history.push(ROUTES.AUTHHOME)
						
					}
				})
		}

		else if(authStore.authorizedUser && authStore.authFields.username.length > 0){
			this.props.history.push(ROUTES.AUTHHOME)
		}
	}
	render(){
		return(
			<div className={styles.maincontainer}>
				<div className={styles.leftpanel}>
					<h1>Sign up</h1>
					<SignUpForm />
					{/*<GithubSignIn signup={true} />*/}
					<Link className={styles.alreadyhaveaccountlink} to={ROUTES.LOGIN}>Already have an Account? Click here</Link>


				</div>
				
				<div className={styles.rightpanel}>
				
				<div className={styles.googlebutton}>
					<GoogleSignIn signup={true} />
										
				</div>

				</div>				
			

			</div>
		)
	}


}

var enhance = compose(
	inject('authStore', 'firebaseStore'),
	withRouter,
	observer
)

export default enhance(SignUpPage)





class SignUpFormBase extends React.Component{

	 formState = {
		username: '',
		email: '',
		password: '',
		passwordRepeat: '',
		recaptcha: false,
		captchaLoad: false,
		signupError: '',
		keepEmail: true,
		agreeterms: true,

		//max characters
		maxUsernameLength: 40
	}

	constructor(props){
		super(props)
	
	}

	componentDidMount(){

	}

	onSubmit = event => {

		event.preventDefault()
		var { authorizedUser, emailVerified, authFields, errors, inProgress } = this.props.authStore
	
		var authStore = this.props.authStore
		var { username, email, password, passwordRepeat } = this.formState

		var firebaseStore = this.props.firebaseStore

		if(this.formState.signupError.length > 0)
			return

		if(typeof this.props.authStore.authFields.id === 'number')
			this.formState.signupError = 'Already logged in to an account. Please log out.'

		requests.post(ROUTES.API.checkusername, {username: username, email: 'noemail'})
			.then( resUsernamecheck =>{

				//valid false means username taken
				if(resUsernamecheck.valid === false){

					this.formState.signupError = 'username taken'
					return
				}

				this.formState.signupError = ''

				requests.post(ROUTES.API.signup, 
					{username: username, email: email, password: password, role: 'none'})
					.then( res =>{

						//error 3 contains errors in adding a user to firebase
						if(res.data.err3){

							// console.log(res.data.err3)
							// return
							if(res.data.err3.message.includes('email address')){

								this.formState.signupError = 'email address already in use'
								return
							}
						}
						var data = res.data


						firebaseStore.firebaseAccess.firebase
							.doSignInWithCustomToken(res.data.token)
								.then( authUser => {

									console.log(authUser)


									authStore.setAuthorizedUser(true)

									authStore.setUsername(username)
									authStore.setPassword(password)
									authStore.setEmail(email)
									authStore.setPasswordRepeat(passwordRepeat)							


									//add user to email list

									// var useridforlist = res.data.userid

									if(this.formState.keepEmail === true){
										Users.addUsertoMailList(authUser.user.uid, email, username  )
											.then( resEmailList =>{
												this.props.history.push(ROUTES.AUTHHOME)

											})

									}
									else{
										this.props.history.push(ROUTES.AUTHHOME)

									}
								})
								.catch( error => {
									var errorCode = error.code;
									  var errorMessage = error.message;
									  if (errorCode === 'auth/invalid-custom-token') {
									    alert('The token you provided is not valid.');
									  } else {
									    console.error(error);
									  }


								})

					.catch( err =>
						console.log(`Server Error in ${ROUTES.API.signup}: ${err} `))
					})
			})
		
		
		


	}

	onChangeUsername = event => {

		if(event.target.value === ""){
			this.formState.signupError = ""
			this.formState.username = event.target.value

			return
		}
		else if(event.target.value.length > this.formState.maxUsernameLength){
			this.formState.signupError = `username cannot exceed ${this.formState.maxUsernameLength} characters`
			return
		}
		

		var arr = event.target.value.match(/[a-z0-9A-Z\s_]/g)

		if(arr === null && event.target.value.length >0){
			this.usernameinput.value = ""
			this.formState.username = event.target.value

			this.formState.signupError = "username can only contain letters and numbers."
			return
		}
		if( arr.length === event.target.value.length){
			this.formState.signupError = ""
			this.formState.username = event.target.value

			this.formState.username = event.target.value


		}
		else{
			this.formState.username = event.target.value
		
			this.formState.signupError = "username can only contain letters and numbers."
			return
		}

		// this.formState.username = event.target.value

	}

	onChangeEmail = event => {
		this.formState.email = event.target.value

		
	}

	onChangePassword = event => {
		this.formState.password = event.target.value

		
	}

	onChangePasswordRepeat = event => {
		this.formState.passwordRepeat = event.target.value

		if((event.target.value.length >= this.formState.password.length ) && this.formState.password !== event.target.value){
			this.formState.signupError = "passwords don't match"
		}
		else{
			this.formState.signupError = ""

		}


	}
	recaptchaVerify = token => {
		this.formState.recaptcha = true
		 // console.log('recaptcha')
	}
	loadCaptcha = e => {
		if(this.agreeBox.checked){
			this.formState.captchaLoad = true

		}
		 // console.log('recaptcha')
	}

	keepEmailForMarketing = e =>{
		
		if(this.agreeBoxEmail.checked){
			this.formState.keepEmail = true
		}
		else{
			this.formState.keepEmail = false

		}
	}

	agreeTerms = e =>{
		
		console.log("here")
		if(this.agreeBoxTerms.checked){
			this.formState.agreeterms = true
		}
		else{
			this.formState.agreeterms = false

		}
	}
	


	render(){

		var { authFields, errors, inProgress } = this.props.authStore
		
		var formState = this.formState
		
		var formclass = classnames(styles.mainform)

		console.log(formState.agreeterms)

		const isInvalid =
			formState.username === '' ||
			formState.username.length < 2 ||
			formState.password !== formState.passwordRepeat ||
			formState.password.length < 8 ||
			formState.password === '' ||
			formState.email === '' ||
			formState.agreeterms === false
			// || formState.recaptcha === false;

		return (
			
			<form className={formclass} onSubmit={this.onSubmit}>
				
				<input 
					name="username"
					type="text"
					onChange={this.onChangeUsername}
					value={formState.username}
					ref={ref => this.usernameinput = ref}
					placeholder="username"
					className={styles.input}
				/>
				<input 
					name="email"
					type="text"
					onChange={this.onChangeEmail}
					value={formState.email}
					placeholder="email"
					className={styles.input}
				/>
				<input 
					name="password"
					type="password"
					onChange={this.onChangePassword}
					value={formState.password}
					placeholder="password (8+ characters)"
					className={styles.input}
				/>
				<input 
					name="repeat_password"
					type="password"
					onChange={this.onChangePasswordRepeat}
					value={formState.passwordRepeat}
					placeholder="confirm password "
					className={styles.input}
				/>

				<p className={styles.signuperror}>{formState.signupError}</p>
				<small className={styles.emailnotice}>We will never share your email.</small>


				<div className={styles.checkboxrow}>
					<input defaultChecked className={styles.checkbox} ref={ref => this.agreeBoxTerms = ref} type="checkbox" onChange={this.agreeTerms} />

					<p>I agree to the <Link to={ROUTES.PRIVACYPOLICY}>Privacy Policy</Link> and <Link to={ROUTES.TERMSCONDITIONS}>Terms and Conditions</Link></p>
				</div>

				<div className={styles.checkboxrow}>
					<input defaultChecked className={styles.checkbox} ref={ref => this.agreeBoxEmail = ref} type="checkbox" onChange={this.keepEmailForMarketing}/>

					<p>Send me script recommendations, newsletters, and site announcements.</p>
				</div>

				<p className={styles.captchaline}>This site is protected by reCAPTCHA and the Google 
					<a href="https://policies.google.com/privacy"> Privacy Policy</a> and 
					<a href="https://policies.google.com/terms"> Terms of Service</a> apply.
				</p>
				

				{formState.captchaLoad && <GoogleReCaptcha onVerify={this.recaptchaVerify}/>}

				<button className={styles.submitbutton} type="submit" disabled={isInvalid}>Sign Up</button>

			{/*error && <p>{error.message}</p>}*/}
			</form>
		
		)
	}


}

decorate(SignUpFormBase, {
	formState: observable
})

var SignUpForm = compose(
	inject("authStore", "firebaseStore"),
	withRouter,
	observer
	)(SignUpFormBase)

const SignUpLink = () => (
	
	<p>
		Don't have an account? <Link to={ROUTES.SIGNUP} >Sign Up</Link>
	</p>

	)


export { SignUpForm, SignUpLink }