import React from 'react'

const SVG = props => {

	return (
		<svg height={props.height} width={props.width} viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M9.24584 0C13.8837 0 17.6782 3.79457 17.6782 8.43238C17.6782 13.0702 13.8837 16.8648 9.24584 16.8648C4.60803 16.8648 0.813461 13.0702 0.813461 8.43238C0.813461 3.79457 4.60803 0 9.24584 0ZM8.4026 12.6486H10.0891V7.58914H8.4026V12.6486ZM8.4026 5.90267H10.0891V4.21619H8.4026V5.90267Z" fill="#a3a5b0"/>
		</svg>


	)
	//height={props.height} width={props.width} viewBox={`0 0 ${props.viewW} ${props.viewH}`}
	
}

export default SVG