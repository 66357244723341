
import React from 'react'

const SVG = props => {

	return (
		<svg height={props.height} width={props.width} viewBox="0 0 42 10" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M37.059 0H26H16H4.941C2.224 0 0 2.282 0 5C0 7.718 2.224 10 4.941 10H16H26H37.059C39.776 10 42 7.718 42 5C42 2.282 39.776 0 37.059 0Z" fill="#000"/>
		</svg>


	)
	//height={props.height} width={props.width} viewBox={`0 0 ${props.viewW} ${props.viewH}`} COLOR IS #EBEBEB
	
}

export default SVG





