import React from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import { observable, decorate, toJS, when } from 'mobx'
import { sanitize } from 'dompurify'

import * as ROUTES from 'routes.js'

import { compose } from 'recompose'

import styles from 'styles/pages/account/accountsettings/accountnavigation.module.css'

/* LARGE CLASS */

 class AccountNavigation extends React.Component{


	constructor(props){

		super(props)
	}

	/* ASYNC DATA FETCH */
	/* _________________ */


	/* _________________ */


	/* LIFECYCLE METHODS */
	/* _________________ */
	componentDidUpdate(prevProps, prevState){
		
	}

	componentDidMount(){

	}
	/* _________________ */



	/* INTERNALS */
	/* _________________ */


	/* _________________ */

	
	render(){

		return(
			
			<div className={styles.container}>
				<h2 className={styles.heading}>Account</h2>
				
				<div className={styles.column}>
					<Link className={styles.link} to={ROUTES.ACCOUNTSETTINGS_DETAILS}>Account Details </Link>
					<Link className={styles.link} to={ROUTES.PROFILEPAGE}>Profile</Link>
					

				</div>
				

			</div>	
				
		)

	}
}


var enhance = compose(
	inject('authStore'),
	withRouter,
	observer
	)

export default enhance(AccountNavigation)



// function AccountNavigation(props){
	
// 	return(
// 		<div>
// 			<ul>
// 				<li><Link to={ROUTES.ACCOUNTSETTINGS_DETAILS}>Account Details</Link></li>
// 				<li><Link to={ROUTES.ACCOUNTSETTINGS_PROFILE}>Profile</Link></li>
// 				<li><Link to={ROUTES.ACCOUNTSETTINGS_BILLING}>Billing</Link></li>
// 				<li><Link to={ROUTES.ACCOUNTSETTINGS_MEMBERSHIP}>Membership</Link></li>
// 			</ul>

			
// 		</div>		
// 	)
// }


// export default AccountNavigation 

