import React from 'react'


import styles from 'styles/components/script/scriptExampleGuide.module.css'
const scriptExampleGuide = props =>{



	return (
		<div className={styles.content} >


			<h2 className={styles.p}>Quick Guide:</h2>
			<h3 className={styles.p}>Media Insert</h3>

			<p className={styles.p}>* You can insert images/gifs/videos through direct URL links from (imgur, imgBB, etc)</p>
	

			<p className={styles.p}>* Insert any images, gifs or videos through direct URL links from (imgur, imgBB, etc) like this: <span className={styles.span}>[himg]URL[/himg]</span></p>

			<h3 className={styles.p}>Code Blocks</h3>

			<p className={styles.p}>Insert Autohotkey code blocks like this: <span className={styles.span}>[code]YOUR_CODE[/code]</span></p>
			<p className={styles.p}>Spaces or line breaks are okay!</p>
			



		</div>
		

	)
}



export default scriptExampleGuide