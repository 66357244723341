

import React from 'react'
import { observer, inject } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'

import { observable, decorate, toJS, when } from 'mobx'
import { sanitize } from 'dompurify'

import { Notifications } from 'axios.js'

import { compose } from 'recompose'

import RootCommentNotificationUnit from 'components/topnav/rootcommentnotification.js'
import ReplyCommentNotificationUnit from 'components/topnav/replycommentnotification.js'
import FollowerNotificationUnit from 'components/topnav/followernotification.js'


import NotificationsPanel from 'components/topnav/notificationpanel.js'
import BellIcon from 'icons/topnav/notifications/bell.js'

import styles from 'styles/topnav/notifications.module.css'

 class NotificationsMaster extends React.Component{


	constructor(props){

		super(props)
		this.state = {
			rootCommentNotifications: [],
			replyCommentNotifications: [],
			followerNotifications: [],
			notifications: [],
			limit: 20,//at one pull

		}
	}

	/* ASYNC DATA FETCH */
	/* _________________ */

	getNotifications = () =>{
		var userid = this.props.authStore.authFields.id
		var limit = this.state.limit
		console.log('id be '+userid)
		Notifications.getAllNotificationsForUser(userid, limit)
			.then( res =>{
				// console.log(res.data.message)

				//compare incoming notifications to our current list

				var currentrootnotifications = this.state.rootCommentNotifications
				var currentreplynotifications = this.state.replyCommentNotifications
				var currentfollowernotifications = this.state.followerNotifications


				var incomingrootnotifications = res.data.message.root ? res.data.message.root : []
				var incomingreplynotifications = res.data.message.reply ? res.data.message.reply : []
				var incomingfollowernotifications = res.data.message.follower ? res.data.message.follower : []


				incomingrootnotifications.forEach( incoming =>{
					var x = currentrootnotifications.filter(currrootnoti => currrootnoti.id === incoming.id)
					if( x.length === 0){

						let temparr = this.state.rootCommentNotifications
						temparr.unshift(incoming)

						this.setState({
							rootCommentNotifications: temparr 
						})
					}
				})

				incomingreplynotifications.forEach( incoming =>{
					var x = currentreplynotifications.filter(currreplynoti => currreplynoti.id === incoming.id)
					if( x.length === 0){

						let temparr = this.state.replyCommentNotifications
						temparr.unshift(incoming)

						this.setState({
							replyCommentNotifications:  temparr
						})
					}
				})

				incomingfollowernotifications.forEach( incoming =>{
					var x = currentfollowernotifications.filter(currfollowernoti => currfollowernoti.id === incoming.id)
					if( x.length === 0){

						let temparr = this.state.followerNotifications
						temparr.unshift(incoming)

						this.setState({
							followerNotifications:  temparr
						})

					}
				})

				//create components of notifications now and put those inside the state arrays
				this.makeNotificationComponent()

				var total = [...this.state.rootCommentNotifications, ...this.state.replyCommentNotifications, ...this.state.followerNotifications]

				// console.log(total)
				total.sort(function(a,b){
				  return a.props.timestamp.localeCompare(b.props.timestamp);
				})

				this.setState({
					rootCommentNotifications: [],
					replyCommentNotifications: [],
					followerNotifications: [],
					notifications: total.reverse(),
				})


				// console.log(this.state.notifications)

				
			})

	}
	getRootCommentNotifications = () =>{
		
	}


	/* _________________ */


	/* LIFECYCLE METHODS */
	/* _________________ */
	componentDidUpdate(){

	}

	componentDidMount(){
		var interval = null

		when( () => typeof this.props.authStore.authFields.id === 'number' ,
			  () =>{
			  	setTimeout(this.getNotifications, 250)
			  	interval = setInterval(this.getNotifications, 10000) 
			  }
			)

		when( () => this.props.authStore.authorizedUser === false,
			  () =>{
			  	clearInterval(interval) 
			  }
			)

		when( () => this.state.notifications.length > 20,
			  () =>{
			  	// clearInterval(interval) 
			  	console.log("EMPTY NOTIFICATIONS")
			  }
			)

	}
	/* _________________ */


	

	




	/* INTERNALS */
	/* _________________ */

	deleteNotification = (e, id, type) =>{


		Notifications.deleteNotification(id, type)
			.then( res => {
				if(res.data.message){

					var notifications = this.state.notifications

					notifications = notifications.filter( noti => noti.props.id !== id)

					this.setState({
						notifications: notifications
					})

					console.log(this.state.notifications)

				}
			})


	}

	deleteAllNotifications = (e) =>{

		if(this.state.notifications.length === 0)
			return

		var deleteconfirm = confirm('Delete All notifications?')

		if(deleteconfirm){
			var hostuserid = this.props.authStore.authFields.id
			Notifications.deleteAllNotifications(hostuserid)
				.then( res =>{
					console.log(res.data.message)
				})
		}
		else{
			return
		}
	

	}
	makeNotificationComponent = () =>{

		var roots = this.state.rootCommentNotifications
		var replies = this.state.replyCommentNotifications
		var follows = this.state.followerNotifications

		var rootUnits = roots.map( rootnoti =>{
			return (
				<RootCommentNotificationUnit key={rootnoti.id + Math.floor(Math.random() * 10000)}
					commenteruserid={rootnoti.commenteruserid}
					hostuserid={rootnoti.hostuserid}
					comment={rootnoti.comment}
					scriptid={rootnoti.scriptid}
					timestamp={rootnoti.submitdate}
					deleteFunction={this.deleteNotification}
					id={rootnoti.id}
				/>
			)
		})

		var replyUnits = replies.map( replynoti =>{
			return (
				<ReplyCommentNotificationUnit key={replynoti.id + Math.floor(Math.random() * 10000)}
					commenteruserid={replynoti.commenteruserid}
					hostuserid={replynoti.hostuserid}
					comment={replynoti.comment}
					scriptid={replynoti.scriptid}
					timestamp={replynoti.submitdate}
					deleteFunction={this.deleteNotification}
					id={replynoti.id}

				/>
			)
		})

		var followUnits = follows.map( follownoti =>{
			return(

				<FollowerNotificationUnit key={follownoti.id + Math.floor(Math.random() * 10000)}
					hostuserid={follownoti.hostuserid}
					followeruserid={follownoti.followeruserid}
					timestamp={follownoti.submitdate}
					deleteFunction={this.deleteNotification}
					id={follownoti.id}

				/>
			)
		})

		this.setState({
			rootCommentNotifications: rootUnits,
			replyCommentNotifications: replyUnits,
			followerNotifications: followUnits
		})



	}



	/* _________________ */

	
	render(){
		var width = this.props.uiStore.topNavIcons.notification_bell.width
		var height = this.props.uiStore.topNavIcons.notification_bell.height


		return(
			<div >
				<div className="link-custom dropdown "> 

					<BellIcon
						width={width}
						height={height}
					/>
					{
						this.state.messagecount === 0 ?
						<div className={styles[`messagecount${0}`]}>{this.state.notifications.length}</div>
						:
						<div className={styles.messagecount}>{this.state.notifications.length}</div>

					}
					
					<div className="dropdown-content">
						<div className={styles.toolbar}>
							<button onClick={this.deleteAllNotifications}>Delete All</button>
						</div>
						<NotificationsPanel>

								{this.state.notifications.length === 0 ? 
									<p>All caught up!</p>
									:  this.state.notifications}
						</NotificationsPanel>


					 </div> 


				</div>


				<div>

					
				</div>





					
					

			</div>

		)

	}
}


var enhance = compose(
	inject('authStore', 'uiStore'),
	withRouter,
	observer
	)

export default enhance(NotificationsMaster)
