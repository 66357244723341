import React from 'react'

import { Link } from 'react-router-dom'

import * as ROUTES from 'routes.js'



function ScriptNavigation(props){
	
	return(
		<div>

			<ul>
				<li><Link to={ROUTES.SCRIPT_SUBMITSCRIPT}>Submit a Script</Link></li>
				<li><Link to={ROUTES.SCRIPT_GLOBALFEED}>Global Feed</Link></li>
			</ul>
		</div>		
	)
}


export default ScriptNavigation 

