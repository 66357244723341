import {observable, action, decorate, reaction, when} from 'mobx'


import { requests, Scripts } from 'axios.js'




class ScriptStore {
	
	selectedCategory = null
	selectedSubcategory = null

	scripts = []

	categories = ['gaming', 'productivity', 'just_for_fun', 'school_work', 'editing_creative', 'software']
	sub_categories = ['shortcuts', 'GUI', 'automation', 'general']

	currentScriptToSubmit = {
		id: -1,
		// callbacks: [],
		index: 0,
		start: true,
		done: false
	}

	callbacks = []

	loadingMessage = `Script is being submitted! It shouldn't take more than a minute.`

	videoPlayBackSpeed = {
		thumbnail: 1,
		gifs: 1
	}

	votedScripts = {}

	savedScripts = {}

	followedTags = {}


	resetScriptToSubmit(){
		this.currentScriptToSubmit.index = 0
		this.callbacks = []
		this.currentScriptToSubmit.done = false

	}

	


	// getAllScriptsByAuthor(){
	// 	// Scripts.all()

	// }

	resetCategories(){
		this.selectedCategory = null
		this.selectedSubcategory = null

	}

	addScript(script){

		for(var scripti of this.scripts){
			if(script === scripti)
				return
		}
		
		this.scripts.push(script)
	}

}

decorate(ScriptStore,{
	scripts:  observable,
	selectedSubcategory:  observable,
	selectedCategory:  observable,
	categories:  observable,
	sub_categories:  observable,
	callbacks: observable,
	loadingMessage: observable,
	currentScriptToSubmit: observable,
	videoPlayBackSpeed: observable,
	votedScripts: observable,
	savedScripts: observable,
	followedTags: observable,
	addScript: action,
	resetCategories: action,
	resetScriptToSubmit: action
})


var scriptStore = new ScriptStore()

// when( () =>  scriptStore.currentScriptToSubmit.index === scriptStore.callbacks.length,
// 		() => scriptStore.currentScriptToSubmit.done = true)

reaction( () => scriptStore.currentScriptToSubmit.index,
			index =>{

				console.log('REACTION TRIGGERED. legnth is '+scriptStore.callbacks.length +'. index is '+index)
				if(index < scriptStore.callbacks.length){

					console.log('I"M HERE NO DENIAL')
					scriptStore.callbacks[index]()
				}

				else if(scriptStore.currentScriptToSubmit.index === scriptStore.callbacks.length && scriptStore.currentScriptToSubmit.index !== 0)
					scriptStore.currentScriptToSubmit.done = true
				// else{
				// 	scriptStore.currentScriptToSubmit.index = 0
				// }
			})

reaction( () => JSON.stringify(scriptStore),
			json => {
				localStorage.setItem('scriptStore', json)
			},
			{
				delay: 500
			})

export default scriptStore

